var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"page-header"},[(_vm.isEdit)?_c('span',[_vm._v("Edit")]):_vm._e(),_vm._v(" Articles Category")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-body"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.doSave.apply(null, arguments)}}},[_c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"col"},[_c('label',{staticClass:"form-label"},[(!_vm.isEdit)?_c('span',[_vm._v("Add New")]):_c('span',[_vm._v("Edit")]),_vm._v(" Articles Category")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.category),expression:"category"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Add New Articles Name"},domProps:{"value":(_vm.category)},on:{"input":function($event){if($event.target.composing){ return; }_vm.category=$event.target.value}}}),(_vm.$v.category.$error)?_c('span',{staticClass:"text-danger"},[_vm._v("Category Name is required")]):_vm._e()]),(_vm.add || _vm.edit)?_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-outline-primary",attrs:{"type":"submit"}},[(!_vm.isEdit && _vm.add)?_c('span',[_vm._v("Add")]):_c('span',[_vm._v("Save")])])]):_vm._e(),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-outline-primary",attrs:{"disabled":!_vm.isEdit,"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.doCancel.apply(null, arguments)}}},[_c('span',[_vm._v("Cancel")])])]),_c('br')])])])]),_c('vue-good-table',{attrs:{"slot":"outsideBody","mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"pagination-options":{
            enabled: true,
            dropdownAllowAll: false,
          },"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
  enabled: false,
  initialSortBy: { field: 'category_name', type: 'asc' },
},"styleClass":"vgt-table"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},slot:"outsideBody",scopedSlots:_vm._u([{key:"column-filter",fn:function(ref){
var column = ref.column;
var updateFilters = ref.updateFilters;
return [(column.filterOptions &&
              column.filterOptions.customFilter &&
              column.field === 'category_name'
              )?_c('input',{ref:"category_name",staticClass:"form-control",attrs:{"placeholder":"Search category"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.category_name.value); }}}):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('div',{staticClass:"row g-2 align-items-center justify-content-end"},[(_vm.edit)?_c('div',{staticClass:"col-auto"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"type":"button","title":"Edit"},on:{"click":function($event){return _vm.doEdit(props.row)}}},[_c('i',{staticClass:"fas fa-pencil"})])]):_vm._e(),(_vm.deletePostArticleCategory)?_c('div',{staticClass:"col-auto"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"type":"button","title":"Delete"},on:{"click":function($event){$event.preventDefault();return _vm.showAlertConfirm(props.row.id)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }