import api from "./api";

class PostCategoryService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('post-category')
    }
    
    getPostCategoryList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postPostCategory(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getPostCategoryById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchPostCategory(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deletePostCategoryById(serverParams) {
        return this.api.delete("/" + serverParams);
    }
}

export default new PostCategoryService();