<template>
  <div class="product-form">
    <FormPost form-type="add" @onValidateSuccess="doSave" :on-loading="onLoading" />
  </div>
</template>
<script>
import FormPost from "./Form.vue";
import RewardService from "@/service/reward.service";
import base64Converter from "@/pages/Helper/base64";
import slugify from "@/pages/Helper/slug";
import moment from "moment";

export default {
  components: {
    FormPost,
  },
  data() {
    return {
      onLoading: false
    }
  },
  methods: {
    async doSave(payload) {
      this.onLoading = true
      let { form, status } = payload
      // console.log(form)
      if (form) {
        let req = {
          "periode_start": this.dateFormat(form.date.start),
          "periode_end": this.dateFormat(form.date.end),
          "is_active": status,
          "name": form.title,
          "slug": slugify(form.title),
          "content": form.description,
          "price_point": parseInt(form.pointCharge),
          "price_currency": 0,
          "image_dekstop_file": await base64Converter(form.images.desktop[0]),
          "image_mobile_file": await base64Converter(form.images.mobile[0]),
          "stock": parseInt(form.stockInventory),
          "reward_type": form.reward_type.value,
          "excerpt": form.excerpt ? form.excerpt : '',
          google_form_url: form.google_form_url ? form.google_form_url : null,
          alt_image_dekstop: form.images.alt_desktop_image ? form.images.alt_desktop_image : null,
          alt_image_mobile: form.images.alt_mobile_image ? form.images.alt_mobile_image : null,
          seo_content_title: form.meta.title ? form.meta.title : null,
          seo_content_content: form.meta.description ? form.meta.description : null,
          seo_content_image: (form.meta.image && form.meta.image.length > 0) ?
            await base64Converter(form.meta.image[0]) : null,
          seo_content_alt: form.meta.alt_image ? form.meta.alt_image : null,
          seo_content_keyword: form.meta.keywords.toString(),
        }

        RewardService.postReward(req).then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: '/reward/list' })
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          }
        }).finally(() =>
          this.onLoading = false)
      }
    },
    dateFormat(date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
    }
  },
};
</script>
