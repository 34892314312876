import api from "./api";

class FaqCategoryService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('category')
    }
    
    getFaqCategoryList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postFaqCategory(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getFaqCategoryById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchFaqCategory(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteFaqCategoryById(serverParams) {
        return this.api.delete("/" + serverParams);
    }
}

export default new FaqCategoryService();