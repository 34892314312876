<template>
  <ul class="nav nav-tabs nav-tabs--wp">
    <li class="nav-item">
      <router-link :to="`/customer/edit/${this.$route.params.id}`" class="nav-link" active-class="active" exact>
        Profile
      </router-link>
    </li>
    <li class="nav-item">
      <router-link :to="`/customer/loyalty/${this.$route.params.id}`" class="nav-link" active-class="active" exact>
        Loyalty
      </router-link>
    </li>
  </ul>
</template>

<script>
  export default {
    name: 'CustomerNav',
  }
</script>
