<template>
  <div class="product-form">
    <FormProduct form-type="edit" :detail-data="product" @onValidateSuccess="doSave" :on-loading="onLoading" />
  </div>
</template>
<script>
import FormProduct from "./Form.vue";
import EcomerceService from "@/service/ecomerce.service";
import TokenService from "@/service/token.service";
import base64Converter from "@/pages/Helper/base64";

export default {
  data() {
    return {
      onLoading: false,
      product: {
        code: this.$route.params.id,
        name: "",
        cta: '',
        images: {
          desktop: null,
          desktopSrc: '',
          mobile: null,
          mobileSrc: '',
          alt: null,
        },
        publishedDate: null,
        lastModified: null,
        author: null,
      },
    };
  },
  components: {
    FormProduct,
  },
  mounted() {
    this.loadItems(this.$route.params.id)
  },
  methods: {
    async doSave(payload) {
      // Submit here
      this.onLoading = true
      let { product, status } = payload
      if (product) {
        // console.log(product)
        payload = {
          user_id: (TokenService.getUser()).id,
          ecommerce_name: product.name,
          cta_url: product.cta ? product.cta : null,
          store_status: product.store_status ? product.store_status : null,
          desktop_image: (product.images.desktop && product.images.desktop.length > 0) ?
            await base64Converter(product.images.desktop[0]) : null,
          mobile_image: (product.images.mobile && product.images.mobile.length > 0) ?
            await base64Converter(product.images.mobile[0]) : null,
          alt_desktop_image: product.images.alt_desktop_image ? product.images.alt_desktop_image : null,
          alt_mobile_image: product.images.alt_mobile_image ? product.images.alt_mobile_image : null,
          status: status
        }

        EcomerceService.patchEcommerce(this.$route.params.id, payload).then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: '/products/ecommerce' })
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          }
        }).finally(() =>
          this.onLoading = false)
      }
    },
    loadItems(id) {
      EcomerceService.getEcommerceById(id)
        .then(async ({ data: { data } }) => {
          this.product = {
            code: this.$route.params.id,
            name: data.ecommerce_name ? data.ecommerce_name : '',
            cta: data.cta_url ? data.cta_url : '',
            store_status: data.store_status ? data.store_status : null,
            images: {
              desktop: data.desktop_image ? [await this.convertURLtoFile(this.loadSrcImage(data.desktop_image))] : null,
              desktopSrc: data.desktop_image ? this.loadSrcImage(data.desktop_image) : '',
              mobile: data.mobile_image ? [await this.convertURLtoFile(this.loadSrcImage(data.mobile_image))] : null,
              mobileSrc: data.mobile_image ? this.loadSrcImage(data.mobile_image) : '',
              alt_desktop_image: data.alt_desktop_image ? data.alt_desktop_image : null,
              alt_mobile_image: data.alt_mobile_image ? data.alt_mobile_image : null,
            },
            publishedDate: data.updatedAt ? data.updatedAt : '',
            lastModified: data.updatedAt ? data.updatedAt : '',
            author: data.User && data.User?.name ? data.User?.name : '',
          };

        })
    },
    loadSrcImage(url) {
      return this.$api_name + '/' + url
    },
    async convertURLtoFile(url) {
      const response = await fetch(url);
      if (response.status != 200) {
        return "";
      }
      const data = await response.blob();
      const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
      const metadata = { type: `image/jpeg` };
      return new File([data], filename, metadata);
    },
  },
};
</script>
