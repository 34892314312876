<template>
  <div class="product-form">
    <FormAbout form-type="add" @onValidateSuccess="doSave" :on-loading="onLoading" />
  </div>
</template>
<script>
import aboutusService from '@/service/aboutus.service';
import FormAbout from './Form.vue';
import TokenService from "@/service/token.service";
import base64Converter from "@/pages/Helper/base64";

export default {
  components: {
    FormAbout,
  },
  data() {
    return {
      onLoading: false,
    }
  },
  methods: {
    async doSave(payload) {
      this.onLoading = true
      let { id, data, section, status, seo } = payload
      let request = {
        user_id: (TokenService.getUser()).id,
      }
      switch (section) {
        case 1:
          request.section1 = {
            desktop_image: (data.one.images.desktop && data.one.images.desktop.length > 0) ?
              await base64Converter(data.one.images.desktop[0]) : null,
            mobile_image: (data.one.images.mobile && data.one.images.mobile.length > 0) ?
              await base64Converter(data.one.images.mobile[0]) : null,
            alt_desktop_image: data.one.images.alt_desktop_image ? data.one.images.alt_desktop_image : null,
            alt_mobile_image: data.one.images.alt_mobile_image ? data.one.images.alt_mobile_image : null,
            heading: data.one.heading ? data.one.heading : null,
            sub_heading: data.one.subheading ? data.one.subheading : null,
            description: data.one.content ? data.one.content : null
          }
          request.section1_status = status
          break;
        case 2:
          let section2 = []
          for (const [k, v] of Object.entries(data.two.tabs)) {
            // console.log(v)
            if ((v.icon && v.icon.length > 0)
              || v.section_title
              || (v.items.length > 0)
              || (v.contentItems.length > 0)
              || (v.subContent.length > 0)
              || v.alt_icon) {
              let items = [], contentItems = [], addNew = []
              if (v.items.length > 0) {
                for (const [k1, v1] of Object.entries(v.items)) {
                  items.push({
                    description: v1.description ? v1.description : null,
                    sub_title: v1.subtitle ? v1.subtitle : null,
                    title: v1.title ? v1.title : null
                  })
                }
              }
              if (v.contentItems.length > 0) {
                for (const [k1, v1] of Object.entries(v.contentItems)) {
                  contentItems.push({
                    description: v1.description ? v1.description : null,
                    no: v1.fieldNo ? v1.fieldNo : null,
                  })
                }
              }
              if (v.subContent.length > 0) {
                for (const [k1, v1] of Object.entries(v.subContent)) {
                  console.log(v1.image);
                  addNew.push({
                    title: v1.title ? v1.title : null,
                    sub_title: v1.subtitle ? v1.subtitle : null,
                    description: v1.description ? v1.description : null,
                    image: (v1.image && v1.image.length > 0) ?
                      await base64Converter(v1.image[0]) : null,
                    alt_image: v1.alt_image ? v1.alt_image : null,
                  })
                }
              }

              section2.push(
                {
                  title_section: v.section_title ? v.section_title : null,
                  icon: (v.icon && v.icon.length > 0) ?
                    await base64Converter(v.icon[0]) : null,
                  image: (v.image && v.image.length > 0) ?
                    await base64Converter(v.image[0]) : null,
                  alt_icon: v.alt_icon ? v.alt_icon : null,
                  description1: v.description1 ? v.description1 : null,
                  description2: v.description2 ? v.description2 : null,
                  items: items,
                  addNew: addNew,
                  contentItems: contentItems
                }
              )
            }
          }
          request.section2 = section2
          request.section2_status = status
          break;
        case 3:
          let section3 = []
          for (const [k, v] of Object.entries(data.three.steps)) {
            section3.push({
              description: v.description ? v.description : null,
              year: v.year ? v.year : null
            })
          }
          request.section3 = section3
          request.section3_status = status
          break;
        case 4:
          request.section4 = {
            link_youtube: data.four.youtube ? data.four.youtube : null
          }
          request.section4_status = status
          break;
        case 5:
          request.seoInformation = {
            meta_title: seo.title ? seo.title : null,
            meta_description: seo.description ? seo.description : null,
            meta_keyword: seo.keywords ? seo.keywords.toString() : null,
            image: (seo.image && seo.image.length > 0) ?
              await base64Converter(seo.image[0]) : null,
            alt_image: seo.alt_image ? seo.alt_image : null
          }
          break;
        default:
          break;
      }

      if (!id) {
        this.addRequest(request)
      } else {
        this.editRequest(id, request)
      }
    },
    addRequest(req) {
      aboutusService.postAboutUs(req).then((rsp) => {
        this.$swal({
          title: "Data berhasil disimpan",
          showCloseButton: true,
        }).then(() => {
          location.reload()
        });
      }).catch((e) => {
        if (e.response?.status === 409) {
          this.$notify({
            type: "warn",
            title: "Error Message",
            text: "Data Sudah Ada",
          });
        } else {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }
      }).finally(() =>
        this.onLoading = false)
    },
    editRequest(id, req) {
      aboutusService.patchAboutUs(id, req).then((rsp) => {
        this.$swal({
          title: "Data berhasil disimpan",
          showCloseButton: true,
        }).then(() => {
          location.reload()
        });
      }).catch((e) => {
        if (e.response?.status === 409) {
          this.$notify({
            type: "warn",
            title: "Error Message",
            text: "Data Sudah Ada",
          });
        } else {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }
      }).finally(() =>
        this.onLoading = false)
    }
  }
}
</script>
