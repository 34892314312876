<template>
  <form @submit.prevent="doSave('Publish')" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          {{ formTitle }} Category
        </h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="mb-15px">
          <input type="text" class="form-control form-control-lg" placeholder="Category Name" v-model="category.name" />
          <span class="text-danger" v-if="$v.category.name.$error">Category Name is required</span>
        </div>
        <h3 class="fw-normal mb-15px pt-3">Category Information</h3>
        <panel title="Category" variant="inverse" :is-collapse="true">
          <div class="row mb-15px">
            <div class="col-md-12">
              <label class="form-label">Parent Category</label>
            </div>
            <v-select :options="optionsParentCategory" placeholder="Parent Category" v-model="category.parent"
              label="parent_name" />
            <span class="text-danger" v-if="$v.category.parent.$error">Parent Category is required</span>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="form-label">Icon</label>
            </div>
            <div class="col-md-6">
              <div class="mb-15px">
                <p class="mb-2">version : max. 1mb, (jpeg, jpg, png)</p>
                <UploadImage v-model="category.image" size="1024" :src="category.imageSrc"
                  @image-err-message="imgErrMessage" />
                <span class="text-danger" v-if="$v.category.image.$error">Icon is required</span>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-15px">
              <label class="form-label">Alt</label>
              <input type="text" v-model="category.alt_icon_image" class="form-control" placeholder="Alt text" />
              <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-15px">
              <label class="form-label">Nomor Urut</label>
              <input type="text" v-model="category.order_number" class="form-control" placeholder="Nomor Urut"
                v-on:keypress="isNumber" />
              <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
            </div>
          </div>

          <div class="col-md-12">
            <div class="mb-15px">
              <label class="form-label">Link</label>
              <div class="input-group">
                <span class="input-group-text">https://www.youtube.com/watch?v=</span>
                <input type="text" v-model="category.video_url" class="form-control"
                  placeholder="cjnTfNfbQeOFihNv" />
              </div>
              <!-- <span class="text-danger" v-if="$v.post.youtube.$error">Link is required</span> -->
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-15px">
              <label class="form-label">Excerpt</label>
              <input type="text" v-model="category.excerpt_video" class="form-control" placeholder="Excerpt" />
              <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
            </div>
          </div>
        </panel>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../../config/Helpers";
import UploadImage from "../../../components/upload/Image.vue";
import { required } from "vuelidate/lib/validators/";
import dateFormat from "@/pages/Helper/formate_date";
import parentcategoryService from '@/service/parentcategory.service';

export default {
  components: {
    UploadImage,
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // console.log(this.detailData);
    this.loadItems()
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;
    return {
      dateFormat,
      optionsParentCategory: [],
      customToolbar: defaultToolbarEditor,
      category: {
        name: detail ? detail.name : null,
        parent: detail ? detail.parent : null,
        image: detail ? detail.images : null,
        imageSrc: detail ? detail.imageSrc : '',
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add" : "Edit";
    },
  },
  methods: {
    doSave(status) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          category: this.category,
        };

        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
    imgErrMessage(mssg) {
      this.$notify({
        type: "error",
        title: "Image Notification",
        text: mssg,
      });
    },
    loadItems() {
      this.isLoading = true;
      parentcategoryService
        .getParentCategoryList({})
        .then(({ data: { data } }) => {
          if (data.ParentProductCategory) {
            this.optionsParentCategory = data.ParentProductCategory
          } else {
            this.optionsParentCategory = []
          }
        }).catch((e) => {
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
  },
  watch: {
    'detailData'(newVal) {
      this.category = newVal
    }
  },
  validations: {
    category: {
      name: { required },
      parent: { required },
      image: { required },
    },
  },
};
</script>
