<template>
  <form @submit.prevent="doSave" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          Claim Poin Data
        </h1>
      </div>
      <div class="col-lg-12">
        <div class="panel">
          <div class="panel-body">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <h5>{{ form.customer.full_name }}</h5>
                    <p>GOO.N Poin</p>
                    <input type="text" class="w-auto form-control fw-bolder text-black" v-model="form.total_point"
                      :disabled="form.status != 'pending' && form.status != 'in_review'">
                  </div>
                  <div class="col-md-6">
                    <div class="mb-10px" v-for="(item, index) in form.birthday.relatedChild">
                      <h5>Birthday of</h5>
                      <input type="text" class="col-md-12 form-control fw-bolder text-black" v-model="item.full_name"
                        disabled>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Nama</label>
                  <input type="text" class="form-control" v-model="form.full_name" readonly disabled />
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Nama Official Store</label>
                  <input type="text" class="form-control" v-model="form.bought_at" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label"></label>
                  <div class="form-check">
                    <input class="form-check-input mb-15px" type="checkbox" v-model="form.tnc" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                      <b>Terms And Conditions</b>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Produk Goon</label>
                  <input type="text" class="form-control" v-model="form.product.product_title" readonly disabled />
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Nomor Claim Point</label>
                  <input type="text" class="form-control" v-model="form.no_receipt" readonly disabled />
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Alamat Official Store</label>
                  <input type="text" class="form-control" v-model="form.address_store" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Nomor Struk</label>
                  <input type="text" class="form-control" v-model="form.struk_id" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Item</label>
                  <input type="text" class="form-control" v-model="form.item_count" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Item Approved</label>
                  <input type="text" class="form-control" v-model="form.item_approved" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Total Price</label>
                  <input type="text" class="form-control" v-model="form.total_price_count" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Total Price Approved</label>
                  <input type="text" class="form-control" v-model="form.total_price_approved" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Status</label>
                  <!-- <v-select :options="optionStatus" v-model="form.status" /> -->
                  <select class="form-select" placeholder="Cari productCategory" v-model="form.status">
                    <option v-for="(option, key) in optionStatus" v-bind:value="option.value" :key="key"
                      v-text="option.label" :disabled="option.value == 'approved_by_ocr'" />
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="mb-15px">
                    <label class="form-label"> Notes</label>
                    <textarea class="form-control" rows="7" v-model="form.notes" placeholder="Notes"></textarea>
                    <span class="text-danger" v-if="$v.form.notes.$error">Notes is required</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-15px">
                    <label class="form-label"> Approve User</label><input type="text" class="form-control"
                      v-model="form.approved_user" disabled />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-15px">
                    <label class="form-label"> Rejected User</label><input type="text" class="form-control"
                      v-model="form.rejected_user" disabled />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-md-4">
                <label class="form-label">Bill Pembelian</label>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-md-4">
                <viewer :images="images">
                  <img style="object-fit: contain; width: 100%; height: 100%;" v-for="src in images" :key="src"
                    :src="src">
                </viewer>
                <br>
                <button class="btn btn-primary" type="button" @click="show">Click to show details</button>
                <!-- <v-zoomer style="width: 500px; height: 500px; border: solid 1px silver;">
                  <img :src="form.imageSrc" style="object-fit: contain; width: 100%; height: 100%;">
                </v-zoomer> -->
                <!-- <div class="portrait">
                  <inner-image-zoom :src="form.imageSrc" :zoomSrc="form.imageSrc" />
                  <img :src="form.imageSrc" width="275" height="500" />
                  <UploadImage type="hidden" v-model="form.image" :src="form.imageSrc" size="1000" @image-err-message="imgErrMessage" />
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">Save</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
export default {
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
  },
  data() {
    const detail = this.detailData;

    return {
      customer: {},
      images: [],
      optionStatusTrueFalse: [
        {
          label: 'Yes',
          value: true
        },
        {
          label: 'No',
          value: false
        },
      ],
      optionStatus: [
        {
          label: 'Pending',
          value: 'pending'
        },
        {
          label: 'In Review',
          value: 'in_review'
        },
        {
          label: 'Approved By Ocr',
          value: 'approved_by_ocr'
        },
        {
          label: 'Approved By Admin',
          value: 'approved_by_admin'
        },
        {
          label: 'Rejected',
          value: 'rejected'
        }
      ],
      form: {
        image: null,
        imageSrc: '',
        customer: { full_name: null },
        product: { product_title: null },
        approved_user: {},
        birthday: {
          relatedChild: []
        }
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
    reject() {
      return this.form.status
    }
  },
  watch: {
    detailData(newVal) {
      this.form = newVal;
      this.images = [newVal.imageSrc]
    },

  },
  methods: {
    show() {
      this.$viewerApi({
        images: this.images,
      })
    },
    doSave() {
      const payload = {
        form: this.form,
      };

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit("onValidateSuccess", payload);

      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
        this.onLoading = false
      }
    },
    imgErrMessage(mssg) {
      this.$notify({
        type: "error",
        title: "Image Notification",
        text: mssg,
      });
    },
  },
  validations: {
    form: {
      // nomor: { required },
      // status_pengiriman: { required },
      notes: {
        required: requiredIf(function (model) {
          if (model.status == "rejected") {
            return true
          } else {
            return false
          }
        })
      },
      status: { required },
    },
  }
};
</script>

<style lang="scss" scoped>
.card {
  background-color: #F2F2F2;
  border: none;
  margin-bottom: 16px;

  &-body {
    padding: 32px;
  }

  &-avatar {
    width: 58px;
    height: 58px;
    overflow: hidden;
    position: relative;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
    }
  }

  h5 {
    font-size: 14px;
    line-height: 21px;
    color: #293C7C;
    font-weight: 700;
    margin-bottom: 7px;
  }

  .badge {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    padding: 2.5px 20px;
    background-color: #A4D3EC;
    border-radius: 24px;
    display: inline-block;
    margin-right: 12px;
    color: #293C7C;
  }

  h6 {
    margin-bottom: 0;
    color: #E20518;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
  }
}
</style>
