import api from "./api";

class RedeemMilestonesService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('redeem-milestone')
    }
    
    getRedeemMilestonesList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postRedeemMilestones(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getRedeemMilestonesById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchRedeemMilestones(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteRedeemMilestonesById(serverParams) {
        return this.api.delete("/" + serverParams);
    }
}

export default new RedeemMilestonesService();