<template>
  <div class="customer-form">
    <FormCustomer form-type="edit" :detail-data="detail" @onValidateSuccess="doSave" :on-loading="onLoading"
      @onRefresh="doRefresh" :childrens="childrens" />
  </div>
</template>
<script>
import FormCustomer from "./Form.vue";
import CustomerService from "@/service/customer.service";
import CustomerChildService from "@/service/customerchild.service";
import moment from "moment";
import base64Converter from "../Helper/base64";
export default {
  components: {
    FormCustomer,
  },
  data() {
    return {
      onLoading: false,
      childrens: [],
      detail: {},
    };
  },
  mounted() {
    this.loadItems(this.$route.params.id)
  },
  methods: {
    async doSave(payload) {
      try {
        let { customer, childrens } = payload
        let { rePassword, password, changePassword, avatar, ...newCustomer } = customer

        newCustomer = {
          ...newCustomer,
          avatar_img: avatar ? await base64Converter(avatar[0]) : null,
          otp: 0,
        }

        if (changePassword) {
          Object.assign(newCustomer, { password: password })
        }

        let { data } = await this.editRequest(newCustomer)

        if (data) {
          let { resp } = await this.childrensRequest(childrens)
        }

        this.$swal({
          title: "Data berhasil disimpan",
          showCloseButton: true,
        }).then(() => {
          this.loadItems(this.$route.params.id)
        })
      } catch (e) {
        // console.log(e)
        if (e.response?.status === 409) {
          this.$notify({
            type: "warn",
            title: "Error Message",
            text: "Data Sudah Ada",
          });
        } else if (e.response?.status === 400) {
          this.$notify({
            type: "warn",
            title: "Error Message",
            text: e.response?.data?.message.toString(),
          });
        } else {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }
      }
    },
    editRequest(customer) {
      return CustomerService.patchCustomer(this.$route.params.id, customer)
        .then(({ data: { data } }) => Promise.resolve({ accepted: true, data }))
        .catch((e) => Promise.reject(e))
    },
    async childrensRequest(childrens) {
      let arrResp = []
      for (const [k, v] of Object.entries(childrens)) {
        let { full_name, gender, dob, height, weight, avatar_img } = v
        let req = {
          gender,
          avatar_img: avatar_img?.length > 0 && avatar_img ? await base64Converter(avatar_img[0]) : null,
          full_name, dob, height, weight,
          customer_id: this.$route.params.id,
          is_active: "Publish",
        }

        if (v.id) {
          arrResp.push(CustomerChildService.patchCustomerChild(v.id, req))
        } else {
          arrResp.push(CustomerChildService.postCustomerChild(req))
        }
      }
      return Promise.all(arrResp)
        .then((r) => Promise.resolve({ accepted: true }))
        .catch((e) => Promise.reject({ accepted: false, errorResponse: e }))
    },
    loadItems(id) {
      CustomerService.getCustomerById(id)
        .then(async ({ data: { data } }) => {
          let { dob, customer_child, phoneNumber, password, avatar_img, ...newData } = data?.customer
          for (const [k, v] of Object.entries(customer_child)) {
            let { dob, avatar_img } = v
            customer_child[k].dob = this.dateFormat(dob)
            if (avatar_img) {
              customer_child[k].avatar_img = avatar_img ? [await this.convertURLtoFile(this.loadSrcImage(avatar_img))] : []
              customer_child[k].avatar_imgSrc = avatar_img ? this.loadSrcImage(avatar_img) : null
            }
          }
          this.childrens = customer_child
          this.detail = {
            ...newData,
            avatar_img: avatar_img ? this.loadSrcImage(avatar_img) : '',
            avatar: avatar_img ? [await this.convertURLtoFile(this.loadSrcImage(avatar_img))] : '',
            phoneNumber: phoneNumber ? phoneNumber.replace('+62', '') : null,
            password: data.full_name,
            dob: this.dateFormat(dob),
            password: this.dateFormat(dob),
            rePassword: this.dateFormat(dob),
            changePassword: false
          }

        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }).finally(() =>
          this.onLoading = false)
    },
    dateFormat(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    loadSrcImage(url) {
      return this.$api_name + '/' + url
    },
    async convertURLtoFile(url) {
      const response = await fetch(url);
      if (response.status != 200) {
        return "";
      }
      const data = await response.blob();
      const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
      const metadata = { type: `image/jpeg` };
      return new File([data], filename, metadata);
    },
    saveChild(childrens) {
      let arrReq = []
      for (const [k, v] of Object.entries(childrens)) {
        let { id, ...newData } = v
        let data = {
          ...newData,
          is_active: 'Publish',
          customer_id: this.detail.id
        }
        if (!id) {
          arrReq.push(CustomerChildService.postCustomerChild(data))
        } else {
          arrReq.push(CustomerChildService.patchCustomerChild(id, data))
        }
      }
      return Promise.all(arrReq).then((r) => Promise.resolve({ accepted: true, data: r })).catch((e) => Promise.reject({ accepted: false }))
    },
    doRefresh() {
      this.loadItems(this.$route.params.id)
    }
  },
};
</script>
