<template>
  <div>
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
        setCurrentPage: setCurrentPage,
        perPage: serverParams.take,
      }" :columns="columns" :rows="rows" :sort-options="{
        enabled: false,
        initialSortBy: { field: 'title', type: 'asc' },
      }" styleClass="vgt-table" slot="outsideBody">

      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="title" class="form-control" placeholder="Articles Name" v-if="column.filterOptions &&
        column.filterOptions.customFilter &&
        column.field === 'title'
        " @input="(value) => updateFilters(column, $refs.title.value)" />
        
        <div class="input-group" v-if="column.filterOptions &&
        column.filterOptions.customFilter &&
        column.field === 'createdAt'">
          <input type="date" ref="createdAt1" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
          <span class="input-group-text">
            -
          </span>
          <input type="date" ref="createdAt2" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
        </div>
        
        <div class="input-group" v-if="column.filterOptions &&
        column.filterOptions.customFilter &&
        column.field === 'updatedAt'">
          <input type="date" ref="updatedAt1" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.updatedAt1.value + '#' + $refs.updatedAt2.value)" />
          <span class="input-group-text">
            -
          </span>
          <input type="date" ref="updatedAt2" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.updatedAt1.value + '#' + $refs.updatedAt2.value)" />
        </div>

        <div class="input-group" v-if="column.filterOptions &&
        column.filterOptions.customFilter &&
        column.field === 'publishAt'">
          <input type="date" ref="publishAt1" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.publishAt1.value + '#' + $refs.publishAt2.value)" />
          <span class="input-group-text">
            -
          </span>
          <input type="date" ref="publishAt2" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.publishAt1.value + '#' + $refs.publishAt2.value)" />
        </div>

        <select ref="status" class="form-select" placeholder="Cari productCategory" v-if="column.filterOptions &&
        column.filterOptions.customFilter &&
        column.field === 'status'
        " @change="(value) => updateFilters(column, $refs['status'].value)">
          <option />
          <option v-for="(option, key) in optionsStatus" v-bind:value="option" :key="key" v-text="option" />
        </select>
        
        <select ref="post_category.category_name" class="form-select" placeholder="Cari productCategory" v-if="column.filterOptions &&
        column.filterOptions.customFilter &&
        column.field === 'post_category.category_name'
        " @change="(value) => updateFilters(column, $refs['post_category.category_name'].value)">
          <option />
          <option v-for="(option, key) in optionsPostCategory" v-bind:value="option.category_name" :key="key"
            v-text="option.category_name" />
        </select>
      </template>

      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end" v-if="props.row.status !== 'Deleted'">
            <div class="col-auto" v-if="edit">
              <router-link :to="`/posts/edit/${props.row.id}`" class="btn btn-lg text-dark" v-b-tooltip.hover
                title="Edit">
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>
            <div class="col-auto" v-if="duplicate">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Duplicate"
                @click.prevent="doDuplicate(props.row.id)">
                <i class="fas fa-copy"></i>
              </button>
            </div>
            <div class="col-auto" v-if="deletePostArticle">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                @click.prevent="showAlertConfirm(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else :class="[props.row.status === 'Deleted' && 'text-muted']">
          {{ (props.formattedRow[props.column.field].length > 55) ?
        props.formattedRow[props.column.field].substring(0, 55) + "..." :
        props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import PostService from "@/service/post.service";
import postcategoryService from "@/service/postcategory.service";
import tokenService from "@/service/token.service";
import moment from "moment";
import _ from 'lodash';
export default {
  data() {
    return {
      optionsPostCategory: [],
      optionsStatus: ['Publish', 'Draft'],
      isLoading: false,
      setCurrentPage: this.$store.state.post.serverParams.skip / this.$store.state.post.serverParams.take == 0 ?
        1 : this.$store.state.post.serverParams.skip / this.$store.state.post.serverParams.take + 1,
      columns: [
        {
          label: "Articles Name",
          field: "title",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Total View",
          field: "counter",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          sortable: true,
        },
        {
          label: "Updated Date",
          field: "updatedAt",
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Created Date",
          field: "createdAt",
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Publish Date",
          field: "publishAt",
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Category",
          field: "post_category.category_name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Status",
          field: "status",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: this.$store.state.post.serverParams,
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
      permisions: tokenService.getPermission()
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.$store.state.post.serverParams = Object.assign({}, this.$store.state.post.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ skip: 0 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      let payload = {}
      if (params[0].type != 'none') {
        payload.sortView = params[0].type
      } else {
        delete this.$store.state.post.serverParams.sortView
      }
      this.updateParams(payload);
      this.loadItems();
    },

    onColumnFilter: _.debounce(function (params) {
      let payload = {}
      console.log(params.columnFilters['updatedAt']);
      if (params.columnFilters['updatedAt'] && params.columnFilters['updatedAt'] != '') {
        let arrSplit = params.columnFilters['updatedAt'].split('#')
        if (arrSplit[0] && arrSplit[1]) {
          payload.updatedAtStartDate = arrSplit[0] ? moment(arrSplit[0]).startOf('day').toISOString() : ''
          payload.updatedAtEndDate = arrSplit[1] ? moment(arrSplit[1]).endOf('day').toISOString() : ''
        }
      } else {
        delete this.$store.state.post.serverParams.createdAtStartDate
        delete this.$store.state.post.serverParams.createdAtEndDate
      }

      if (params.columnFilters['createdAt'] && params.columnFilters['createdAt'] != '') {
        let arrSplit = params.columnFilters['createdAt'].split('#')
        
        if (arrSplit[0] && arrSplit[1]) {
          payload.createdAtStartDate = arrSplit[0] ? moment(arrSplit[0]).startOf('day').toISOString() : ''
          payload.createdAtEndDate = arrSplit[1] ? moment(arrSplit[1]).endOf('day').toISOString() : ''
        
        }
      } else {
        delete this.$store.state.post.serverParams.createdAtStartDate
        delete this.$store.state.post.serverParams.createdAtEndDate
      }

      if (params.columnFilters.status && params.columnFilters.status != '') {
        payload.status = params.columnFilters.status ? params.columnFilters.status : ''
      } else {
        delete this.$store.state.post.serverParams.status
      }

      if (params.columnFilters.title && params.columnFilters.title != '') {
        payload.postTitle = params.columnFilters.title ? params.columnFilters.title : ''
      } else {
        delete this.$store.state.post.serverParams.postTitle
      }

      if (params.columnFilters['post_category.category_name'] && params.columnFilters['post_category.category_name'] != '') {
        payload.category = params.columnFilters['post_category.category_name'] ? params.columnFilters['post_category.category_name'] : ''
      } else {
        delete this.$store.state.post.serverParams.category
      }
      this.updateParams(payload);
      this.loadItems();
    }, 300),
    loadItems() {
      this.isLoading = true;
      PostService
        .getArticleList(this.$store.state.post.serverParams)
        .then(({ data: { data } }) => {
          let arrData = []
          if (data.postArticle.length > 0) {
            for (const [k, v] of Object.entries(data.postArticle)) {
              let { counter } = v
              // console.log(v);
              arrData.push({
                ...v,
                // updatedAt: v.status == 'Draft' ? null : v.updatedAt,
                // updatedAt: v.updatedAt,
                counter: counter ? counter : 0
              });
            }
          }
          this.rows = arrData
          this.totalRecords = data.totalData
        }).catch((e) => {
          if (e.response.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadItemsCategory() {
      postcategoryService
        .getPostCategoryList()
        .then(async ({ data: { data } }) => {
          this.optionsPostCategory = data.PostCategory
        }).catch((e) => {
          if (e.response.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },
    doDelete(id) {
      this.isLoading = true;
      PostService
        .deleteArticleById(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },
    doDuplicate(id) {
      this.isLoading = true;
      PostService
        .postDuplicateArticle(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil diduplicate",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    }
  },
  computed: {
    duplicate: function () {
      if (this.permisions.find((r) => r == 'PostArticle Duplicate')) {
        return true
      }
      return false;
    },
    edit: function () {
      if (this.permisions.find((r) => r == 'PostArticle Patch')) {
        return true
      }
      return false;
    },
    deletePostArticle: function () {
      if (this.permisions.find((r) => r == 'PostArticle Delete')) {
        return true
      }
      return false;
    },
  },
  mounted() {
    this.loadItems();
    this.loadItemsCategory()
  },
};
</script>
