<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">Supervisor Reseller</h1>
        <!-- END page-header -->
      </div>
      <div class="col-auto mb-3">
        <div class="row">
          <div class="col-auto mb-15px" v-if="exportExcel">
            <a class="btn btn-outline-primary" @click.prevent="downloadExcel">
              <i class="fa-solid fa-file-import"></i>
              Export
            </a>
          </div>
          <div class="col-auto">
            <a class="btn btn-info" href="/assets/ContactPersonSPVGoonTemplate.xlsx" download> Template</a>
            <!-- <button class="btn btn-info" @click="downloadTemplate">Template</button> -->
          </div>
          <div class="col-auto" v-if="importReseller">
            <input id="fileUpload"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              ref="fileUpload" type="file" @change="postPdf" hidden>
            <button class="btn btn-dark" @click="chooseFiles">Import</button>
          </div>
          <div class="col-auto" v-if="addReseller">
            <router-link to="/reseller-submission/supervisor/add">
              <a class="btn btn-outline-primary">Add Data</a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
        setCurrentPage: setCurrentPage,
        perPage: serverParams.take,
      }" :columns="columns" :rows="rows" :sort-options="{
        enabled: true,
        initialSortBy: { field: 'name', type: 'asc' },
      }" styleClass="vgt-table" slot="outsideBody">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="name" class="form-control" placeholder="Search.." v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'name'
        " @input="(value) => updateFilters(column, $refs.name.value)" />

      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end" v-if="props.row.status !== 'Deleted'">
            <div class="col-auto" v-if="editReseller">
              <router-link :to="`/reseller-submission/supervisor/edit/${props.row.id}`" class="btn btn-lg text-dark"
                v-b-tooltip.hover title="Edit">
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>
            <div class="col-auto" v-if="deleteReseller">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                @click.prevent="showAlertConfirm(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else :class="[props.row.status === 'Deleted' && 'text-muted']">
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import resellermanagerService from "@/service/resellermanager.service";
import tokenService from "@/service/token.service";
import _ from 'lodash';
export default {
  data() {
    return {
      setCurrentPage: this.$store.state.resellersupervisor.serverParams.skip / this.$store.state.resellersupervisor.serverParams.take == 0 ?
        1 : this.$store.state.resellersupervisor.serverParams.skip / this.$store.state.resellersupervisor.serverParams.take + 1,
      permisions: tokenService.getPermission(),
      isLoading: false,
      columns: [
        {
          label: "Name",
          field: "name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Area",
          field: "area",
          sortable: false,
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Phone",
          field: "phone",
          sortable: false,
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: this.$store.state.resellersupervisor.serverParams,
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.$store.state.resellersupervisor.serverParams = Object.assign({}, this.$store.state.resellersupervisor.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ skip: 0 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams();
      this.loadItems();
    },

    onColumnFilter: _.debounce(function (params) {
      let payload = {}

      if (params.columnFilters.name && params.columnFilters.name != '') {
        payload.name = params.columnFilters.name ? params.columnFilters.name : ''
      } else {
        delete this.$store.state.resellersupervisor.serverParams.name
      }

      if (params.columnFilters.area && params.columnFilters.area != '') {
        payload.area = params.columnFilters.area ? params.columnFilters.area : ''
      } else {
        delete this.$store.state.resellersupervisor.serverParams.area
      }

      if (params.columnFilters.phone && params.columnFilters.phone != '') {
        payload.phone = params.columnFilters.phone ? params.columnFilters.phone : ''
      } else {
        delete this.$store.state.resellersupervisor.serverParams.phone
      }

      this.updateParams(payload);
      this.loadItems();
    }, 300),
    loadItems() {
      resellermanagerService
        .getResellerManagerList(this.$store.state.resellersupervisor.serverParams)
        .then(async ({ data: { data } }) => {
          this.rows = data.resellerManager
          this.totalRecords = data.totalData
        }).catch((e) => {
          if (e.response.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },
    doDelete(id) {
      this.isLoading = true;
      resellermanagerService
        .deleteResellerManagerById(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },
    async postPdf(e) {
      let formData = new FormData()
      formData.append('file', e.target.files[0], e.target.files[0].name)

      resellermanagerService
        .postImportResellerManager(formData)
        .then((rsp) => {
          // console.log(rsp)
          this.$swal({
            title: "Data berhasil ditambah",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
          this.loadItems();
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        })
    },
    chooseFiles() {
      const elem = this.$refs.fileUpload
      elem.click()
    },

    downloadExcel() {
      this.isLoading = true;
      resellermanagerService.exportExcel()
        .then(({ data: { data } }) => {
          let { path } = data
          if (path) {
            window.open(this.$api_name + '/' + path, '_blank')
          }
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },
  },
  computed: {
    addReseller: function () {
      if (this.permisions.find((r) => r == 'ResellerManager Add')) {
        return true
      }
      return false;
    },
    editReseller: function () {
      if (this.permisions.find((r) => r == 'ResellerManager Patch')) {
        return true
      }
      return false;
    },
    deleteReseller: function () {
      if (this.permisions.find((r) => r == 'ResellerManager Delete')) {
        return true
      }
      return false;
    },
    importReseller: function () {
      if (this.permisions.find((r) => r == 'ResellerManager Import')) {
        return true
      }
      return false;
    },
    exportExcel: function () {
      if (this.permisions.find((r) => r == 'ResellerManager Export')) {
        return true
      }
      return false;
    },
  },
  mounted() {
    this.loadItems();
  },
};
</script>
