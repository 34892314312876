// GOON
import Dashboard from "../pages/Dashboard.vue";
import UserLogin from "../pages/Auth/User-login.vue";
import LupaPassword from "../pages/Auth/Lupa-password.vue";

// Products
import ProductsLayout from "../pages/Products/Layout.vue";
import ProductsLayoutList from "../pages/Products/LayoutList.vue";
import ProductsList from "../pages/Products/index.vue";
import ProductAdd from "../pages/Products/Add.vue";
import ProductEdit from "../pages/Products/Edit.vue";
import ProductsEcommerceList from "../pages/Products/Ecommerce/index.vue";
import ProductEcommerceAdd from "../pages/Products/Ecommerce/Add.vue";
import ProductEcommerceEdit from "../pages/Products/Ecommerce/Edit.vue";
import ProductType from "../pages/Products/Type.vue";
import ParentCategory from "../pages/Products/ParentCategory/index.vue";
import ParentCategoryAdd from "../pages/Products/ParentCategory/Add.vue";
import ParentCategoryEdit from "../pages/Products/ParentCategory/Edit.vue";

import ProductCategory from "../pages/Products/Category/index.vue";
import ProductCategoryEdit from "../pages/Products/Category/Edit.vue";
import ProductCategoryAdd from "../pages/Products/Category/Add.vue";

// Posts
import PostsLayout from "../pages/Posts/Layout.vue";
import PostsLayoutList from "../pages/Posts/LayoutList.vue";
import PostsList from "../pages/Posts/index.vue";
import PostsAdd from "../pages/Posts/Add.vue";
import PostsEdit from "../pages/Posts/Edit.vue";
import PostsCategory from "../pages/Posts/Category.vue";
import PostsPromoList from "../pages/Posts/Promo/index.vue";
import PostsPromoAdd from "../pages/Posts/Promo/Add.vue";
import PostsPromoEdit from "../pages/Posts/Promo/Edit.vue";
import PostsPromoCategory from "../pages/Posts/Promo/Category.vue";

// Career
import CareerList from "../pages/Career/index.vue";
import CareerAdd from "../pages/Career/Add.vue";
import CareerEdit from "../pages/Career/Edit.vue";
import CareerCategory from "../pages/Career/Category.vue";
import LevelCategory from "../pages/Career/Level.vue";
import TypeCategory from "../pages/Career/Type.vue";
import CareerListCandidate from "../pages/Career/Candidate/index.vue";
import CareerEditCandidate from "../pages/Career/Candidate/Edit.vue";

// Redeem
import RedeemList from "../pages/Redeem/index.vue";
import RedeemAdd from "../pages/Redeem/Add.vue";
import RedeemEdit from "../pages/Redeem/Edit.vue";

// Customer
import CustomerList from "../pages/Customer/index.vue";
import CustomerAdd from "../pages/Customer/Add.vue";
import CustomerEdit from "../pages/Customer/Edit.vue";
import CustomerLoyalty from "../pages/Customer/Loyalty.vue";
import CustomerChild from "../pages/Customer/ChildrenList.vue";

// Pages
import PagesLayout from "../pages/Pages/Layout.vue";
import PagesLayoutList from "../pages/Pages/LayoutList.vue";
import PagesList from "../pages/Pages/index.vue";
import PagesAdd from "../pages/Pages/Add.vue";
import PageEdit from "../pages/Pages/Edit.vue";

// About Us
import AboutUsForm from "../pages/Pages/AboutUs/EditPage.vue";

// HomepageManagement
import HomepageManagementForm from "../pages/HomepageManagement/EditPage.vue";

// Sections
import SectionsLayout from "../pages/Sections/Layout.vue";
import SectionsBannerLayoutList from "../pages/Sections/Banner/LayoutList.vue";
import SectionsBannerList from "../pages/Sections/Banner/index.vue";
import SectionsBannerAdd from "../pages/Sections/Banner/Add.vue";
import SectionsBannerEdit from "../pages/Sections/Banner/Edit.vue";
import SectionsMenus from "../pages/Sections/Menus/index.vue";
import SectionsMenusAdd from "../pages/Sections/Menus/Add.vue";
import SectionsMenusLocation from "../pages/Sections/Menus/Location.vue";

//Users
import UsersLayout from "../pages/Users/Layout.vue";
import UsersUserList from "../pages/Users/ManageUser/index.vue";
import UsersUserAdd from "../pages/Users/ManageUser/Add.vue";
import UsersUserEdit from "../pages/Users/ManageUser/Edit.vue";
import UsersRoleList from "../pages/Users/ManageRole/index.vue";
import UsersRoleAdd from "../pages/Users/ManageRole/Add.vue";
import UsersRoleEdit from "../pages/Users/ManageRole/Edit.vue";

// Reward Catalogue
import RewardCatalogueList from "../pages/RewardCatalogue/index.vue";
import RewardCatalogueAdd from "../pages/RewardCatalogue/Add.vue";
import RewardCatalogueEdit from "../pages/RewardCatalogue/Edit.vue";

// Settings
import SettingSite from "../pages/Setting/Site.vue";
import SettingSeo from "../pages/Setting/Seo.vue";
import SettingLoyalty from "../pages/Setting/Loyalty.vue";
import SettingFreeSample from "../pages/Setting/FreeSample.vue";
import SettingSocialList from "../pages/Setting/SocialMedia/index.vue";
import SettingSocialAdd from "../pages/Setting/SocialMedia/Add.vue";
import SettingSocialEdit from "../pages/Setting/SocialMedia/Edit.vue";

// Submission
import SubmissionList from "../pages/Submission/index.vue";
import SubmissionEdit from "../pages/Submission/Edit.vue";

// Submission
import ClaimPointList from "../pages/ClaimPoint/index.vue";
import ClaimPointEdit from "../pages/ClaimPoint/Edit.vue";

//FAQ
import FaqCategory from "../pages/Pages/Faq/Category.vue";
import FaqList from "../pages/Pages/Faq/index.vue";
import FaqAdd from "../pages/Pages/Faq/Add.vue";
import FaqEdit from "../pages/Pages/Faq/Edit.vue";

//Contact Us
import ContactUsList from "../pages/Pages/ContactUs/index.vue";
import ContactUsAdd from "../pages/Pages/ContactUs/Add.vue";
import ContactUsEdit from "../pages/Pages/ContactUs/Edit.vue";

import SurveyList from "../pages/Survey/index.vue";
import SurveyAdd from "../pages/Survey/Add.vue";
import SurveyEdit from "../pages/Survey/Edit.vue";
import SurveyCategory from "../pages/Survey/Category.vue";
import SurveySubmission from "../pages/Survey/Submission/index.vue";
import SurveySubmissionDetail from "../pages/Survey/Submission/Detail.vue";


import SurveyAfterFreeSampleList from "@/pages/Survey/AfterFreesample/Question/index.vue";
import SurveyAfterFreeSampleAdd from "@/pages/Survey/AfterFreesample/Question/Add.vue";
import SurveyAfterFreeSampleEdit from "@/pages/Survey/AfterFreesample/Question/Edit.vue";
import SurveyAfterFreeSampleSubmission from "@/pages/Survey/AfterFreesample/Submission/index.vue";
import SurveyAfterFreeSampleSubmissionDetail from "@/pages/Survey/AfterFreesample/Submission/Detail.vue";

//Nutri Expert
import NutriExpertList from "../pages/Pages/NutriExpert/index.vue";
import NutriExpertEdit from "../pages/Pages/NutriExpert/Edit.vue";

//Baby Name Generator
import BabyNameGeneratorList from "../pages/BabyNameGenerator/index.vue";
import BabyNameGeneratorAdd from "../pages/BabyNameGenerator/Add.vue";
import BabyNameGeneratorEdit from "../pages/BabyNameGenerator/Edit.vue";

//Reseller Submission
import ResellerSubmissionList from "../pages/ResellerSubmission/index.vue";
import ResellerSubmissionAdd from "../pages/ResellerSubmission/Add.vue";
import ResellerSubmissionEdit from "../pages/ResellerSubmission/Edit.vue";
import ResellerSupervisorList from "../pages/ResellerSubmission/ResellerSupervisor/index.vue";
import ResellerSupervisorAdd from "../pages/ResellerSubmission/ResellerSupervisor/Add.vue";
import ResellerSupervisorEdit from "../pages/ResellerSubmission/ResellerSupervisor/Edit.vue";

//Tumbuh Kembang
import TumbuhKembangBeratTinggiList from "../pages/TumbuhKembang/BeratTinggi/index.vue";
import TumbuhKembangBeratTinggiAdd from "../pages/TumbuhKembang/BeratTinggi/Add.vue";
import TumbuhKembangBeratTinggiEdit from "../pages/TumbuhKembang/BeratTinggi/Edit.vue";
import TumbuhKembangStatusGiziList from "../pages/TumbuhKembang/StatusGizi/index.vue";
import TumbuhKembangStatusGiziAdd from "../pages/TumbuhKembang/StatusGizi/Add.vue";
import TumbuhKembangStatusGiziEdit from "../pages/TumbuhKembang/StatusGizi/Edit.vue";
import TumbuhKembangCategoryStatusGizi from "../pages/TumbuhKembang/Category/CategoryStatusGizi.vue";
import TumbuhKembangCategoryAmbangBatas from "../pages/TumbuhKembang/Category/CategoryAmbangBatas.vue";

const routes = [
  // Goon
  // { path: "*", redirect: "/login" },
  { path: "/dashboard", component: Dashboard },
  { path: "/login", component: UserLogin },
  {
    path: "/password",
    component: LupaPassword,
    name: "resetForgotPassword",
  },

  {
    path: "/products",
    component: ProductsLayout,
    children: [
      {
        path: "list",
        component: ProductsLayoutList,
        children: [
          {
            path: "",
            component: ProductsList,
            name: "ProductsList",
          },
        ]
      },
      {
        path: "add",
        component: ProductAdd,
        name: "productAdd",
      },
      {
        path: "edit/:id",
        component: ProductEdit,
        name: "productEdit",
      },
      {
        path: "category",
        component: ProductCategory,
        name: "productCategory",
      },
      {
        path: "category/add",
        component: ProductCategoryAdd,
        name: "productCategoryAdd",
      },
      {
        path: "category/edit/:id",
        component: ProductCategoryEdit,
        name: "productCategoryEdit",
      },
      {
        path: "parent_category",
        component: ParentCategory,
        name: "parentCategory",
      },
      {
        path: "parent_category/add",
        component: ParentCategoryAdd,
        name: "productCategoryAdd",
      },
      {
        path: "parent_category/edit/:id",
        component: ParentCategoryEdit,
        name: "productCategoryEdit",
      },
      {
        path: "ecommerce",
        component: ProductsEcommerceList,
        name: "ProductsEcommerceList",
      },
      {
        path: "ecommerce/add",
        component: ProductEcommerceAdd,
        name: "ProductEcommerceAdd",
      },
      {
        path: "ecommerce/edit/:id",
        component: ProductEcommerceEdit,
        name: "ProductEcommerceEdit",
      },
      {
        path: "type",
        component: ProductType,
        name: "ProductType",
      },
    ],
  },

  {
    path: "/homepage-management",
    component: HomepageManagementForm,
  },

  {
    path: "/posts",
    component: PostsLayout,
    children: [
      {
        path: "list",
        component: PostsLayoutList,
        children: [
          {
            path: "data",
            component: PostsList,
            name: "PostsList",
          },
          {
            path: "promo",
            component: PostsPromoList,
            name: "PostsPromoList",
          },
        ]
      },
      {
        path: "add",
        component: PostsAdd,
        name: "PostsAdd",
      },
      {
        path: "edit/:id",
        component: PostsEdit,
        name: "PostsEdit",
      },
      {
        path: "category",
        component: PostsCategory,
        name: "PostsCategory",
      },
      {
        path: "promo/add",
        component: PostsPromoAdd,
        name: "PostsPromoAdd",
      },
      {
        path: "promo/edit/:id",
        component: PostsPromoEdit,
        name: "PostsPromoEdit",
      },
      {
        path: "promo/category",
        component: PostsPromoCategory,
        name: "PostsPromoCategory",
      },
    ],
  },
  {
    path: "/survey",
    component: PostsLayout,
    children: [
      {
        path: "list",
        component: SurveyList,
        name: "SurveyList",
      },
      {
        path: "add",
        component: SurveyAdd,
        name: "SurveyAdd",
      },
      {
        path: "edit/:id",
        component: SurveyEdit,
        name: "SurveyEdit",
      },
      {
        path: "category",
        component: SurveyCategory,
        name: "SurveyCategory",
      },
      {
        path: "submission",
        component: SurveySubmission,
        name: "SurveySubmission",
      },
      {
        path: "submission/:id/:type",
        component: SurveySubmissionDetail,
        name: "SurveySubmissionDetail",
      },
    ],
  },
  {
    path: "/survey-after-free-sample",
    component: PostsLayout,
    children: [
      {
        path: "list",
        component: SurveyAfterFreeSampleList,
        name: "SurveyAfterFreeSampleList",
      },
      {
        path: "add",
        component: SurveyAfterFreeSampleAdd,
        name: "SurveyAfterFreeSampleAdd",
      },
      {
        path: "edit/:id",
        component: SurveyAfterFreeSampleEdit,
        name: "SurveyAfterFreeSampleEdit",
      },
      {
        path: "submission",
        component: SurveyAfterFreeSampleSubmission,
        name: "SurveyAfterFreeSampleSubmission",
      },
      {
        path: "submission/:id",
        component: SurveyAfterFreeSampleSubmissionDetail,
        name: "SurveyAfterFreeSampleSubmissionDetail",
      },
    ],
  },
  {
    path: "/faq",
    component: PostsLayout,
    children: [
      {
        path: "list",
        component: FaqList,
        name: "FaqList",
      },
      {
        path: "add",
        component: FaqAdd,
        name: "FaqAdd",
      },
      {
        path: "edit/:id",
        component: FaqEdit,
        name: "FaqEdit",
      },
      {
        path: "category",
        component: FaqCategory,
        name: "FaqCategory",
      },
    ],
  },
  {
    path: "/pages",
    component: PagesLayout,
    children: [
      {
        path: "list",
        component: PagesLayoutList,
        children: [
          {
            path: "",
            component: PagesList,
            name: "PagesList",
          },
        ]
      },
      {
        path: "add",
        component: PagesAdd,
        name: "PagesAdd",
      },
      {
        path: "edit/:id",
        component: PageEdit,
        name: "PageEdit",
      },
      {
        path: "about",
        component: AboutUsForm,
        name: "AboutUsForm",
      },
      {
        path: "contact-us",
        component: PostsLayout,
        children: [
          {
            path: "",
            component: ContactUsList,
            name: "ContactUsList",
          },
          {
            path: "add",
            component: ContactUsAdd,
            name: "ContactUsAdd",
          },
          {
            path: "edit/:id",
            component: ContactUsEdit,
            name: "ContactUsEdit",
          },
        ],
      },
      {
        path: "nutri-expert",
        component: PostsLayout,
        children: [
          {
            path: "",
            component: NutriExpertList,
            name: "NutriExpertList",
          },
          {
            path: "edit/:id",
            component: NutriExpertEdit,
            name: "NutriExpertEdit",
          },
        ],
      },
    ],
  },

  {
    path: "/sections/banner",
    component: SectionsLayout,
    children: [
      {
        path: "",
        component: SectionsBannerLayoutList,
        children: [
          {
            path: "",
            component: SectionsBannerList,
            name: "SectionsBannerList",
          },
        ]
      },
      {
        path: "add",
        component: SectionsBannerAdd,
        name: "SectionsBannerAdd",
      },
      {
        path: "edit/:id",
        component: SectionsBannerEdit,
        name: "SectionsBannerEdit",
      },
    ],
  },

  {
    path: "/sections/menus",
    component: SectionsLayout,
    children: [
      {
        path: "",
        component: SectionsMenus,
        name: "SectionsMenus",
      },
      {
        path: "add",
        component: SectionsMenusAdd,
        name: "SectionsMenusAdd",
      },
      {
        path: "location",
        component: SectionsMenusLocation,
        name: "SectionsMenusLocation",
      },
    ],
  },

  {
    path: "/users",
    component: UsersLayout,
    children: [
      {
        path: "manage",
        component: UsersLayout,
        children: [
          {
            path: "user",
            component: UsersLayout,
            children: [
              {
                path: "",
                component: UsersUserList,
                name: "UsersUserList",
              },
              {
                path: "add",
                component: UsersUserAdd,
                name: "UsersUserAdd",
              },
              {
                path: "edit/:id",
                component: UsersUserEdit,
                name: "UsersUserEdit",
              },
            ]
          },
          {
            path: "role",
            component: UsersLayout,
            children: [
              {
                path: "",
                component: UsersRoleList,
                name: "UsersRoleList",
              },
              {
                path: "add",
                component: UsersRoleAdd,
                name: "UsersRoleAdd",
              },
              {
                path: "edit/:id",
                component: UsersRoleEdit,
                name: "UsersRoleEdit",
              },
            ]
          },
        ],
      },
    ],
  },

  {
    path: "/career",
    component: PostsLayout,
    children: [
      {
        path: "list",
        component: CareerList,
        name: "CareerList",
      },
      {
        path: "add",
        component: CareerAdd,
        name: "CareerAdd",
      },
      {
        path: "edit/:id",
        component: CareerEdit,
        name: "CareerEdit",
      },
      {
        path: "category",
        component: CareerCategory,
        name: "CareerCategory",
      },
      {
        path: "level",
        component: LevelCategory,
        name: "LevelCategory",
      },
      {
        path: "type",
        component: TypeCategory,
        name: "TypeCategory",
      },
      {
        path: "candidate",
        component: ProductsLayout,
        children: [
          {
            path: "list",
            component: CareerListCandidate,
            name: "CareerListCandidate",
          },
          {
            path: "edit/:id",
            component: CareerEditCandidate,
            name: "CareerEditCandidate",
          },
        ],
      },
    ],
  },

  {
    path: "/reward",
    component: ProductsLayout,
    children: [
      {
        path: "list",
        component: RewardCatalogueList,
        name: "RewardCatalogueList",
      },
      {
        path: "add",
        component: RewardCatalogueAdd,
        name: "RewardCatalogueAdd",
      },
      {
        path: "edit/:id",
        component: RewardCatalogueEdit,
        name: "RewardCatalogueEdit",
      },
    ],
  },
  {
    path: "/redeem",
    component: ProductsLayout,
    children: [
      {
        path: "list",
        component: RedeemList,
        name: "RedeemList",
      },
      {
        path: "add",
        component: RedeemAdd,
        name: "RedeemAdd",
      },
      {
        path: "edit/:id",
        component: RedeemEdit,
        name: "RedeemEdit",
      },
    ],
  },
  {
    path: "/customer",
    component: ProductsLayout,
    children: [
      {
        path: "children",
        component: CustomerChild,
        name: "CustomerChild",
      },
      {
        path: "list",
        component: CustomerList,
        name: "CustomerList",
      },
      {
        path: "add",
        component: CustomerAdd,
        name: "CustomerAdd",
      },
      {
        path: "edit/:id",
        component: CustomerEdit,
        name: "CustomerEdit",
      },
      {
        path: "loyalty/:id",
        component: CustomerLoyalty,
        name: "CustomerLoyalty",
      },
    ],
  },
  {
    path: "/setting/site",
    component: SettingSite,
    name: "SettingSite"
  },
  {
    path: "/setting/seo",
    component: SettingSeo,
    name: "SettingSeo"
  },
  {
    path: "/setting/loyalty",
    component: SettingLoyalty,
    name: "SettingLoyalty"
  },

  {
    path: "/setting/freesample",
    component: SettingFreeSample,
    name: "SettingFreeSample"
  },
  {
    path: "/setting/social-media",
    component: SettingSocialList,
    name: "SettingSocialList"
  },
  {
    path: "/setting/social-media/add",
    component: SettingSocialAdd,
    name: "SettingSocialAdd"
  },
  {
    path: "/setting/social-media/edit/:id",
    component: SettingSocialEdit,
    name: "SettingSocialEdit"
  },

  {
    path: "/submission",
    component: ProductsLayout,
    children: [
      {
        path: "list",
        component: SubmissionList,
        name: "SubmissionList",
      },
      {
        path: "edit/:id",
        component: SubmissionEdit,
        name: "SubmissionEdit",
      },
    ],
  },

  {
    path: "/claim-point",
    component: ProductsLayout,
    children: [
      {
        path: "list",
        component: ClaimPointList,
        name: "ClaimPointList",
      },
      {
        path: "edit/:id",
        component: ClaimPointEdit,
        name: "ClaimPointEdit",
      },
    ],
  },

  {
    path: "/baby-name-generator",
    component: ProductsLayout,
    children: [
      {
        path: "list",
        component: BabyNameGeneratorList,
        name: "BabyNameGeneratorList",
      },
      {
        path: "add",
        component: BabyNameGeneratorAdd,
        name: "BabyNameGeneratorAdd",
      },
      {
        path: "edit/:id",
        component: BabyNameGeneratorEdit,
        name: "BabyNameGeneratorEdit",
      },
    ],
  },
  {
    path: "/reseller-submission",
    component: ProductsLayout,
    children: [
      {
        path: "list",
        component: ResellerSubmissionList,
        name: "ResellerSubmissionList",
      },
      {
        path: "add",
        component: ResellerSubmissionAdd,
        name: "ResellerSubmissionAdd",
      },
      {
        path: "edit/:id",
        component: ResellerSubmissionEdit,
        name: "ResellerSubmissionEdit",
      },
      {
        path: "supervisor",
        component: ResellerSupervisorList,
        name: "ResellerSupervisorList",
      },
      {
        path: "supervisor/add",
        component: ResellerSupervisorAdd,
        name: "ResellerSupervisorAdd",
      },
      {
        path: "supervisor/edit/:id",
        component: ResellerSupervisorEdit,
        name: "ResellerSupervisorEdit",
      },
    ],
  },
  {
    path: "/tumbuh-kembang",
    component: ProductsLayout,
    children: [
      {
        path: "berat-tinggi",
        component: TumbuhKembangBeratTinggiList,
        name: "TumbuhKembangBeratTinggiList",
      },
      {
        path: "berat-tinggi/add",
        component: TumbuhKembangBeratTinggiAdd,
        name: "TumbuhKembangBeratTinggiAdd",
      },
      {
        path: "berat-tinggi/edit/:id",
        component: TumbuhKembangBeratTinggiEdit,
        name: "TumbuhKembangBeratTinggiEdit",
      },
      {
        path: "status-gizi",
        component: TumbuhKembangStatusGiziList,
        name: "TumbuhKembangStatusGiziList",
      },
      {
        path: "status-gizi/add",
        component: TumbuhKembangStatusGiziAdd,
        name: "TumbuhKembangStatusGiziAdd",
      },
      {
        path: "status-gizi/edit/:id",
        component: TumbuhKembangStatusGiziEdit,
        name: "TumbuhKembangStatusGiziEdit",
      },
      {
        path: "category/status-gizi",
        component: TumbuhKembangCategoryStatusGizi,
        name: "TumbuhKembangCategoryStatusGizi",
      },
      {
        path: "category/ambang-batas",
        component: TumbuhKembangCategoryAmbangBatas,
        name: "TumbuhKembangCategoryAmbangBatas",
      },
    ],
  },
];

export default routes;
