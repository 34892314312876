import api from "./api";

class ResellerService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('reseller')
    }

    getResellerList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postReseller(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getResellerById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchReseller(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteResellerById(serverParams) {
        return this.api.delete("/" + serverParams);
    }

    getResellerExport(serverParams) {
        return this.api.get("/export/excel", { params: serverParams });
    }
}

export default new ResellerService();