var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-good-table',{attrs:{"slot":"outsideBody","mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"pagination-options":{
      enabled: true,
      dropdownAllowAll: false,
      setCurrentPage: _vm.setCurrentPage,
      perPage: _vm.serverParams.take,
    },"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
      enabled: false,
      initialSortBy: { field: 'title', type: 'asc' },
    },"styleClass":"vgt-table"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},slot:"outsideBody",scopedSlots:_vm._u([{key:"column-filter",fn:function(ref){
    var column = ref.column;
    var updateFilters = ref.updateFilters;
return [(column.filterOptions &&
      column.filterOptions.customFilter &&
      column.field === 'title'
      )?_c('input',{ref:"title",staticClass:"form-control",attrs:{"placeholder":"Articles Name"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.title.value); }}}):_vm._e(),(column.filterOptions &&
      column.filterOptions.customFilter &&
      column.field === 'createdAt')?_c('div',{staticClass:"input-group"},[_c('input',{ref:"createdAt1",staticClass:"form-control",attrs:{"type":"date","placeholder":"Search product"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.createdAt1.value + '#' + _vm.$refs.createdAt2.value); }}}),_c('span',{staticClass:"input-group-text"},[_vm._v(" - ")]),_c('input',{ref:"createdAt2",staticClass:"form-control",attrs:{"type":"date","placeholder":"Search product"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.createdAt1.value + '#' + _vm.$refs.createdAt2.value); }}})]):_vm._e(),(column.filterOptions &&
      column.filterOptions.customFilter &&
      column.field === 'updatedAt')?_c('div',{staticClass:"input-group"},[_c('input',{ref:"updatedAt1",staticClass:"form-control",attrs:{"type":"date","placeholder":"Search product"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.updatedAt1.value + '#' + _vm.$refs.updatedAt2.value); }}}),_c('span',{staticClass:"input-group-text"},[_vm._v(" - ")]),_c('input',{ref:"updatedAt2",staticClass:"form-control",attrs:{"type":"date","placeholder":"Search product"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.updatedAt1.value + '#' + _vm.$refs.updatedAt2.value); }}})]):_vm._e(),(column.filterOptions &&
      column.filterOptions.customFilter &&
      column.field === 'publishAt')?_c('div',{staticClass:"input-group"},[_c('input',{ref:"publishAt1",staticClass:"form-control",attrs:{"type":"date","placeholder":"Search product"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.publishAt1.value + '#' + _vm.$refs.publishAt2.value); }}}),_c('span',{staticClass:"input-group-text"},[_vm._v(" - ")]),_c('input',{ref:"publishAt2",staticClass:"form-control",attrs:{"type":"date","placeholder":"Search product"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.publishAt1.value + '#' + _vm.$refs.publishAt2.value); }}})]):_vm._e(),(column.filterOptions &&
      column.filterOptions.customFilter &&
      column.field === 'status'
      )?_c('select',{ref:"status",staticClass:"form-select",attrs:{"placeholder":"Cari productCategory"},on:{"change":function (value) { return updateFilters(column, _vm.$refs['status'].value); }}},[_c('option'),_vm._l((_vm.optionsStatus),function(option,key){return _c('option',{key:key,domProps:{"value":option,"textContent":_vm._s(option)}})})],2):_vm._e(),(column.filterOptions &&
      column.filterOptions.customFilter &&
      column.field === 'post_category.category_name'
      )?_c('select',{ref:"post_category.category_name",staticClass:"form-select",attrs:{"placeholder":"Cari productCategory"},on:{"change":function (value) { return updateFilters(column, _vm.$refs['post_category.category_name'].value); }}},[_c('option'),_vm._l((_vm.optionsPostCategory),function(option,key){return _c('option',{key:key,domProps:{"value":option.category_name,"textContent":_vm._s(option.category_name)}})})],2):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[(props.row.status !== 'Deleted')?_c('div',{staticClass:"row g-2 align-items-center justify-content-end"},[(_vm.edit)?_c('div',{staticClass:"col-auto"},[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"to":("/posts/edit/" + (props.row.id)),"title":"Edit"}},[_c('i',{staticClass:"fas fa-pencil"})])],1):_vm._e(),(_vm.duplicate)?_c('div',{staticClass:"col-auto"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"type":"button","title":"Duplicate"},on:{"click":function($event){$event.preventDefault();return _vm.doDuplicate(props.row.id)}}},[_c('i',{staticClass:"fas fa-copy"})])]):_vm._e(),(_vm.deletePostArticle)?_c('div',{staticClass:"col-auto"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"type":"button","title":"Move to Trash"},on:{"click":function($event){$event.preventDefault();return _vm.showAlertConfirm(props.row.id)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()]):_vm._e()]):_c('span',{class:[props.row.status === 'Deleted' && 'text-muted']},[_vm._v(" "+_vm._s((props.formattedRow[props.column.field].length > 55) ? props.formattedRow[props.column.field].substring(0, 55) + "..." : props.formattedRow[props.column.field])+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }