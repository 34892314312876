import api from "./api";
import apim from "./apim";

class BabyNameService {
    /** @type {ApiAxiosInstance} */
    api;
    apim;

    constructor() {
        this.api = api.create('baby-name')
        this.apim = apim.create('baby-name')
    }

    getBabyNameList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postBabyName(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getBabyNameById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchBabyName(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteBabyNameById(serverParams) {
        return this.api.delete("/" + serverParams);
    }

    postImportBabyName(serverParams) {
        return this.apim.post("/import", serverParams);
    }
}

export default new BabyNameService();