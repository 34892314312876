<template>
  <div class="product-form">
    <FormUsers form-type="edit" :detail-data="detail" @onValidateSuccess="doSave" :on-loading="onLoading" />
  </div>
</template>
<script>
import FormUsers from "./Form.vue";
import UserService from "@/service/user.service";
import RoleService from "@/service/role.service";

export default {
  data() {
    return {
      onLoading: false,
      detail: {
        id: this.$route.params.id,
        name: "",
        role: {
          options: [],
          value: {}
        }
      },
    };
  },
  components: {
    FormUsers,
  },
  mounted() {
    // console.log(this.$route.params.id)
    this.loadItems(this.$route.params.id)
  },
  methods: {
    async doSave(payload) {
      // Submit here
      this.onLoading = true
      // console.log(payload, 'payload')
      if (payload) {
        let { user, status, changePassword } = payload
        let { rePassword, role, phoneNumber, password, ...newUser } = user
        let req = {
          ...newUser,
          role_cms_id: role.value.id,
          phoneNumber: (phoneNumber) ? '+62' + phoneNumber : null,
          status
        }

        if (changePassword) {
          req.password = password
        }

        UserService.patchUser(this.$route.params.id, req).then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: '/users/manage/user' })
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          }
        }).finally(() =>
          this.onLoading = false)
      }
    },
    loadItems(id) {
      UserService.getUserById(id)
        .then(async ({ data: { data } }) => {
          this.detail = {
            name: data.name,
            email: data.email,
            phoneNumber: data.phoneNumber ? data.phoneNumber.replace('+62', '') : null,
            password: data.name,
            rePassword: data.name,
            role: {
              options: await this.loadRoleList(),
              value: data.role_cms,
            }
          }
        })
    },

    loadRoleList() {
      this.isLoading = true;
      RoleService
        .getRoleList(this.serverParams)
        .then(({ data: { data } }) => {
          return Promise.resolve(data.RoleCms)
        }).catch((e) => {
          return Promise.reject({ ...e })
        })
    },
  },
};
</script>
