<template>
  <form @submit.prevent="doSave" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          {{ formTitle }} Status Gizi Anak
        </h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <panel variant="inverse" title="Form Status Gizi Anak" :noButton="true">
          <div class="mb-15px">
            <label class="form-label">Usia</label>
            <div class="input-group">
              <!-- <v-select class="form-control p-0 border-0" style="margin-right: -2px;" :options="['2 Bulan']" /> -->
              <input type="text" class="form-control" placeholder="Umur" v-model="form.age_in_month1"
                v-on:keypress="isNumber">
              <span class="input-group-text position-relative" style="z-index: 10;"><i
                  class="fa-solid fa-minus"></i></span>
              <input type="text" class="form-control" placeholder="Umur" v-model="form.age_in_month2"
                v-on:keypress="isNumber">
            </div>
            <span class="text-danger" v-if="$v.form.age_in_month1.$error || $v.form.age_in_month2.$error">Usia
              required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Ambang Batas</label>
            <div class="input-group">
              <!-- <v-select class="form-control p-0 border-0" style="margin-right: -2px;" :options="optionsAmbang"
                label="name" v-model="form.threshold1_id" /> -->

              <select class="form-control" placeholder="Cari ..." v-model="form.threshold1_id">
                <option />
                <option v-for="(option, key) in optionsAmbang" v-bind:value="option.id" :key="key" v-text="option.name" />
              </select>
              <span class="input-group-text position-relative" style="z-index: 10;"><i
                  class="fa-solid fa-minus"></i></span>
              <select class="form-control" placeholder="Cari ..." v-model="form.threshold2_id">
                <option />
                <option v-for="(option, key) in optionsAmbang" v-bind:value="option.id" :key="key" v-text="option.name" />
              </select>
            </div>
            <span class="text-danger" v-if="$v.form.threshold1_id.$error || $v.form.threshold1_id.$error">Ambang Batas
              required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Kategori Status Gizi</label>
            <!-- <v-select :options="optionsCategory" label="name" v-model="form.nutritional_status_id" /> -->

            <select class="form-select" placeholder="Cari ..." v-model="form.nutritional_status_id">
              <option />
              <option v-for="(option, key) in optionsCategory" v-bind:value="option.id" :key="key" v-text="option.name" />
            </select>
            <span class="text-danger" v-if="$v.form.nutritional_status_id.$error">Kategori Status Gizi
              required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Type</label>
            <!-- <v-select :options="optionsCategory" label="name" v-model="form.nutritional_status_id" /> -->

            <select class="form-select" placeholder="Cari ..." v-model="form.growth_type">
              <option />
              <option v-for="(option, key) in optionsType" v-bind:value="option.value" :key="key" v-text="option.label" />
            </select>
            <span class="text-danger" v-if="$v.form.growth_type.$error">Type required</span>
          </div>
        </panel>
      </div>

      <div v-if="form.author" class="col-lg-3 col-md-4">
        <div class="panel panel-sticky">
          <div class="panel-body">
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Published Date</p>
              <p class="mb-0">{{ form.publishedDate ? dateFormat(form.publishedDate) : '' }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Last Modified Date</p>
              <p class="mb-0">{{ form.lastModified ? dateFormat(form.lastModified) : '' }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Author</p>
              <p class="mb-0">{{ form.author }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="button" class="btn btn-outline-primary">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Save as Draft
          </button>
        </div>
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Publish</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../../config/Helpers";
import UploadImage from "../../../components/upload/Image.vue";
import ambangbatascategoryService from "@/service/ambangbatascategory.service";
import statusgiziCategoryService from "@/service/gizistatuscategory.service";
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    UploadImage,
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.loadItems()
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;

    return {
      options: {
        format: "DD/MM/YYYY",
        useCurrent: true,
      },
      optionsType: [
        {
          label: "Panjang Badan",
          value: "height"
        },
        {
          label: "Berat Badan",
          value: "weight"
        }
      ],
      optionsCategory: [],
      optionsAmbang: [],
      customToolbar: defaultToolbarEditor,
      form: {
        age_in_month1: null,
        age_in_month2: null,
        threshold1_id: null,
        threshold2_id: null,
        nutritional_status_id: null,
        nutritional_status: null
      }
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
  },
  watch: {
    detailData(newVal) {
      this.form = newVal;
    },
  },
  methods: {
    doSave() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const payload = {
          form: this.form,
        };

        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
    loadItems() {
      this.loadAmbang();
      this.loadStatusGizi()
    },
    loadAmbang() {
      this.isLoading = true;
      ambangbatascategoryService
        .getAmbangBatasCategoryList(this.serverParams)
        .then(({ data: { data } }) => {
          this.optionsAmbang = data.ThresholdCategory
        }).catch((e) => {
          if (e.response.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadStatusGizi() {
      this.isLoading = true;
      statusgiziCategoryService
        .getGiziStatusCategoryList(this.serverParams)
        .then(({ data: { data } }) => {
          this.optionsCategory = data.NutritionalStatusCategory
        }).catch((e) => {
          if (e.response.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true; // Match with regex 
      else e.preventDefault(); // If not match, don't add to input text
    },
    dateFormat(date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
    }
  },
  validations: {
    form: {
      age_in_month1: { required },
      age_in_month2: { required },
      threshold1_id: { required },
      threshold2_id: { required },
      nutritional_status_id: { required },
      growth_type: { required },
    }
  }
};
</script>
