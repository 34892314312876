<template>
  <div class="product-form">
    <FormPost form-type="edit" :detail-data="form" @onValidateSuccess="doSave" :on-loading="onLoading" />
  </div>
</template>
<script>
import FormPost from "./Form.vue";
import CareerService from "@/service/career.service";
import base64ToFile from "@/pages/Helper/base64ToFile";
import CareerCategoryService from "@/service/careercategory.service";
import base64Converter from "@/pages/Helper/base64";

export default {
  data() {
    return {
      onLoading: false,
      form: {
        id: this.$route.params.id,
        title: "",
        description: "",
        location: "",
        images: {
          desktop: null,
          desktopSrc: '',
          alt: null,
        },
        meta: {
          title: "",
          description: "",
          keywords: [],
          image: null,
          imageSrc: '',
        },
      },
    };
  },
  components: {
    FormPost,
  },
  mounted() {
    this.loadItems(this.$route.params.id)
  },
  methods: {
    async doSave(payload) {
      // Submit here

      this.onLoading = true
      let { form, status } = payload
      if (form) {
        payload = {
          "position": form.title,
          "description": form.description,
          "location": form.location,
          "image": await base64Converter(form.images.desktop[0]),
          "career_category_id": form.category.id,
          career_type_id: form.type ? form.type : null,
          career_level_id: form.level ? form.level : null,
          level: form.level ? form.level : null,
          alt_image: form.images.alt_image ? form.images.alt_image : null,
          alt_desktop_image: form.images.alt_desktop_image ? form.images.alt_desktop_image : null,
          alt_mobile_image: form.images.alt_mobile_image ? form.images.alt_mobile_image : null,
          desktop_image: (form.images.desktop && form.images.desktop.length > 0) ?
            await base64Converter(form.images.desktop[0]) : null,
          mobile_image: (form.images.mobile && form.images.mobile.length > 0) ?
            await base64Converter(form.images.mobile[0]) : null,
          excerpt: form.excerpt ? form.excerpt : null,
          "seoInformation": {
            "meta_title": form.meta.title,
            "meta_description": form.meta.description,
            "meta_keyword": form.meta.keywords.toString(),
            alt_image: form.meta.alt_image ? form.meta.alt_image : null,
          },
          status
        }

        if (form.meta.image) {
          payload.seoInformation.image = await base64Converter(form.meta.image[0])
        }

        CareerService.patchCareer(this.$route.params.id, payload).then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: '/career/list' })
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          }
        }).finally(() =>
          this.onLoading = false)
      }
    },
    loadItems(id) {
      CareerService.getCareerById(id)
        .then(async ({ data: { data } }) => {
          this.form = {
            id: this.$route.params.id,
            title: data.position ? data.position : null,
            description: data.description ? data.description : null,
            category: data.career_category_id ? data.career_category : null,
            location: data.location ? data.location : null,
            level: data.career_level_id ? data.career_level_id : null,
            type: data.career_type_id ? data.career_type_id : null,
            excerpt: data.excerpt ? data.excerpt : null,
            images: {
              desktop: data.desktop_image ? [await this.convertURLtoFile(this.loadSrcImage(data.desktop_image))] : null,
              desktopSrc: data.desktop_image ? this.loadSrcImage(data.desktop_image) : '',
              mobile: data.mobile_image ? [await this.convertURLtoFile(this.loadSrcImage(data.mobile_image))] : null,
              mobileSrc: data.mobile_image ? this.loadSrcImage(data.mobile_image) : '',
              alt_desktop_image: data.alt_desktop_image ? data.alt_desktop_image : null,
              alt_mobile_image: data.alt_mobile_image ? data.alt_mobile_image : null,
            },
            meta: {
              title: (data.seo_information?.meta_title && data.seo_information) ? data.seo_information.meta_title : null,
              description: (data.seo_information?.meta_description && data.seo_information) ? data.seo_information.meta_description : null,
              keywords: (data.seo_information?.meta_keyword && data.seo_information) ? data.seo_information.meta_keyword.split(',') : null,
              image: (data.seo_information?.image && data.seo_information) ? [await this.convertURLtoFile(this.loadSrcImage(data.seo_information.image))] : null,
              imageSrc: (data.seo_information?.image && data.seo_information) ? this.loadSrcImage(data.seo_information.image) : '',
              alt_image: data.seo_information.alt_image ? data.seo_information.alt_image : null,
            },
          };
        }).catch((e) => {
          // console.log(e)
        })
    },
    getCategoryCareerById(id) {
      return CareerCategoryService.getCareerCategoryById(id)
        .then(async ({ data: { data } }) => {
          return data
        }).catch((e) => data)
    },
    dataToFile(data) {
      let { base64Img, originalName, fileType: { mime } } = data
      return base64ToFile(base64Img, originalName, mime)
        .then((r) => {
          return r
        }).catch((e) => {
          return ''
        })
    },
    loadSrcImage(url) {
      return this.$api_name + '/' + url
    },
    async convertURLtoFile(url) {
      const response = await fetch(url);
      if (response.status != 200) {
        return "";
      }
      const data = await response.blob();
      const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
      const metadata = { type: `image/jpeg` };
      return new File([data], filename, metadata);
    },
  },
};
</script>
