import api from "./api";

class MenuItemsService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('menuitem')
    }

    getMenuItemsList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postMenuItems(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getMenuItemsById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchMenuItems(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteMenuItemsById(serverParams) {
        return this.api.delete("/" + serverParams);
    }

    getMenuItemsListFromSlug(serverParams) {
        return this.api.get("/menu-structure/" + serverParams);
    }
}

export default new MenuItemsService();