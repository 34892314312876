<template>
  <div class="product-form">
    <FormProduct form-type="add" @onValidateSuccess="doSave" :on-loading="onLoading" />
  </div>
</template>
<script>
import FormProduct from './Form.vue'
import ProductCategoryService from "@/service/productcategory.service";
import base64Converter from "@/pages/Helper/base64";

export default {
  data() {
    return {
      onLoading: false
    }
  },
  components: {
    FormProduct,
  },

  methods: {
    async doSave(payload) {
      this.onLoading = true
      // Submit here
      let { category } = payload
      if (category) {
        payload = {
          category_name: category.name,
          parent_product_category_id: category.parent.id,
          icon_image: category.image[0] ? await base64Converter(category.image[0]) : null,
          alt_icon_image: category.alt_icon_image ? category.alt_icon_image : null,
          order_number: category.order_number ? category.order_number : null,
          // video_url: category.video_url ? 'https://youtu.be/4ApqUMIPl0s?si=' + category.video_url : null,
          video_url: category.video_url ? category.video_url : null,
          excerpt_video: category.excerpt_video ? category.excerpt_video : null,
        };

        ProductCategoryService.postProductCategory(payload).then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: '/products/category' })
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          }
        }).finally(() =>
          this.onLoading = false)
      }
    }
  }
}
</script>
