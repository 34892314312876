import api from "./api";
import { IdentityService } from "./identify.service";

class UserService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('cms-user')
    }

    getListUser(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    getUserById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    postUser(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    deleteUserById(serverParams) {
        return this.api.delete("/" + serverParams);
    }

    patchUser(id, serverParams) {
        return this.api.put("/" + id, { ...serverParams });
    }

    exportExcel(serverParams) {
        return this.api.get("/export", { params: serverParams });
    }
}

export default new UserService();