<template>
  <form @submit.prevent="doSave" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          Information Candidate Data
        </h1>
      </div>
      <div class="col-lg-12">
        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Nama</label>
                  <input type="text" class="form-control" v-model="candidate.name" />
                  <span class="text-danger" v-if="$v.candidate.name.$error">Name is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Email</label>
                  <input type="email" class="form-control" v-model="candidate.email" />
                  <span class="text-danger" v-if="$v.candidate.email.$error">Email is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Job Position</label>
                  <input type="text" class="form-control" v-model="candidate.job_position" />
                  <span class="text-danger" v-if="$v.candidate.job_position.$error">Job Position is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">No. Handphone</label>
                  <input type="text" class="form-control" v-model="candidate.phone_number" />
                  <span class="text-danger" v-if="$v.candidate.phone_number.$error">No. Handphone is required</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Linkedin</label>
                  <input type="url" class="form-control" v-model="candidate.linkedin" />
                  <span class="text-danger" v-if="$v.candidate.linkedin.$error">Linkedin is required</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">URL Portofolio</label>
                  <div class="input-group">
                    <span class="input-group-text"><i class="fa-solid fa-link"></i></span>
                    <input type="url" class="form-control" v-model="candidate.portofolio_url"
                      placeholder="https://shopee.com/GoonIndonesia">
                  </div>
                  <span class="text-danger" v-if="$v.candidate.portofolio_url.$error">Url Portofolio is required</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Uploaded CV</label>
                  <div class="input-group">
                    <span class="input-group-text"><i class="fa-solid fa-link"></i></span>
                    <input type="text" class="form-control" placeholder="uploaded_cv.pdf" v-model="candidate.upload_cv"
                      readonly>
                    <a :href="candidate.upload_cv_src" class="btn btn-primary" target="_blank">Download</a>
                  </div>
                  <span class="text-danger" v-if="$v.candidate.upload_cv.$error">CV is required</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // console.log(this.detailData);
  },
  data() {
    const detail = this.detailData;

    return {
      candidate: {
        name: detail ? detail.name : null,
        email: detail ? detail.email : null,
        phone_number: detail ? detail.phone_number : null,
        portofolio_url: detail ? detail.portofolio_url : null,
        linkedin: detail ? detail.linkedin : null,
        upload_cv: detail ? detail.upload_cv : null,
        upload_cv_src: detail ? detail.upload_cv_src : null,
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
  },
  watch: {
    detailData(newVal) {
      this.candidate = newVal;
    },
  },
  methods: {

    doSave() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          candidate: this.candidate,
        };
        // console.log(payload);

        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
  },

  validations: {
    candidate: {
      name: { required },
      email: { required },
      phone_number: { required },
      portofolio_url: { required },
      linkedin: { required },
      upload_cv: { required },
      job_position: { required }
    },
  }
};
</script>
