import api from "./api";

class BannerService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('banner-management')
    }

    getBannerList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postBanner(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getBannerById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchBanner(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteBannerById(serverParams) {
        return this.api.delete("/" + serverParams);
    }
}

export default new BannerService();