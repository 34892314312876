import api from "./api";

class SurveySubmissionService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('submission')
    }

    getSurveySubmissionList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postSurveySubmission(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getSurveySubmissionById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchSurveySubmission(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteSurveySubmissionById(serverParams) {
        return this.api.delete("/" + serverParams);
    }
}

export default new SurveySubmissionService();