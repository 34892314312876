<template>
  <form @submit.prevent="doSave('Publish')" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          {{ formTitle }} Product
        </h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="mb-15px">
          <input type="text" class="form-control form-control-lg" placeholder="Product Name" v-model="product.name" />
          <span class="text-danger" v-if="$v.product.name.$error">Name is required</span>
        </div>
        <div class="panel">
          <div class="panel-body">
            <div class="col-md-12">
              <div class="mb-15px">
                <label class="form-label">Slug</label>
                <div class="input-group">
                  <span class="input-group-text bg-white border-end-0 text-muted">http://goon.co.id/</span>
                  <input type="text" class="form-control border-start-0 ps-0" placeholder="Post Title" v-model="slug" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Sort Number</label>
                  <input type="text" class="form-control form-control-md" placeholder="" v-model="product.order"
                    :maxlength="11" v-on:keypress="isNumber" />
                  <!-- <span class="text-danger" v-if="$v.product.type.$error">Product Type is required</span> -->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Product Parent Category</label>
                  <v-select v-model="product.categoryParent" :options="optionsParentCategory"
                    label="parent_name"></v-select>
                  <span class="text-danger" v-if="$v.product.categoryParent.$error">Parent Category is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Product Type</label>
                  <v-select v-model="product.type" :options="optionsType" label="product_type" multiple></v-select>
                  <span class="text-danger" v-if="$v.product.type.$error">Product Type is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Qty</label>
                  <input type="text" class="form-control form-control-md" placeholder="" v-model="product.qty_in_box" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Weight</label>
                  <input type="text" class="form-control form-control-md" placeholder="" v-model="product.weight" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Product Category</label>
                  <v-select v-model="product.category" :options="optionsCategory" label="category_name"></v-select>
                  <!-- <span class="text-danger" v-if="$v.product.category.$error">Product Category is required</span> -->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label class="form-label">Description</label>
                <ckeditor v-model="product.description" :config="{
                  delayDetached: true,
                  autoParagraph: false,
                  enterMode: 2,
                  versionCheck: false
                }" />
                <span class="text-danger" v-if="$v.product.description.$error">description is required</span>
              </div>
            </div>
          </div>
        </div>
        <h3 class="fw-normal mb-15px pt-3">Product Information</h3>
        <panel title="Image Product" variant="inverse" :is-collapse="true">
          <div class="row">
            <div class="col-md-12">
              <label class="form-label">Main Image</label>
            </div>
            <div class="col-md-6">
              <div class="mb-15px">
                <p class="mb-2">(dekstop version : 505x523 px, max. 1mb, jpeg, jpg, png)</p>
                <UploadImage v-model="product.images.desktop" :src="product.images.desktopSrc" size="1024"
                  :dimensions="{ width: 505, height: 523 }" @image-err-message="imgErrMessage" />
                <span class="text-danger" v-if="$v.product.images.desktop.$error">dekstop image is required</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-15px">
                <p class="mb-2">(mobile version : 350x363 px, max. 1mb, jpeg, jpg, png)</p>
                <UploadImage v-model="product.images.mobile" :src="product.images.mobileSrc" size="1000"
                  :dimensions="{ width: 350, height: 363 }" @image-err-message="imgErrMessage" />
                <span class="text-danger" v-if="$v.product.images.mobile.$error">mobile image is required</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-15px">
                <label class="form-label">Dekstop Alt Text</label>
                <input type="text" v-model="product.images.alt_desktop_image" class="form-control"
                  placeholder="Alt text" />
                <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-15px">
                <label class="form-label">Mobile Alt Text</label>
                <input type="text" v-model="product.images.alt_mobile_image" class="form-control"
                  placeholder="Alt text" />
                <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-15px">
                <label class="form-label">Main Image Alt Text</label>
                <input type="text" v-model="product.images.alt" class="form-control" placeholder="Alt text" />
                <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-15px">
                <label class="form-label">Link</label>
                <div class="input-group">
                  <span class="input-group-text">https://www.youtube.com/watch?v=</span>
                  <input type="text" v-model="product.video_url" class="form-control" placeholder="cjnTfNfbQeOFihNv" />
                </div>
                <!-- <span class="text-danger" v-if="$v.post.youtube.$error">Link is required</span> -->
              </div>
            </div>
            <!-- <div class="col-md-12">
              <div class="mb-15px">
                <label class="form-label">Excerpt</label>
                <input type="text" v-model="product.excerpt_video" class="form-control" placeholder="Excerpt" />
              </div>
            </div> -->
            <!-- <div class="col-md-12">
              <div class="mb-15px">
                <label class="form-label">Choose Thumbnail (Video/Photo)<br /><span class="fw-normal">(Ratio 4 :
                    3)</span></label>
                <v-select :clearable="false" :options="['Foto', 'Video']" label="thumbnail"
                  v-model="product.thumbnail"></v-select>
                <span class="text-danger" v-if="$v.post.thumbnail.$error">Thumbnail is required</span>
              </div>
            </div> -->
          </div>
        </panel>
        <panel title="E-commerce Display" variant="inverse" :is-collapse="true">
          <panel v-for="(item, index) in product.ecommerce" :key="index" :index="index"
            :title="`E-commerce ${index + 1}`" variant="grey" :is-collapse="true" :checkbox-hidden="false"
            :show-collapse="index > 0 ? false : true" v-model="item.isShow" :checkbox-name="`sliderItem-${index}`"
            :has-remove="true" @onRemove="removeEcommerce(index)">
            <div class="mb-15px" :key="index">
              <label class="form-label">Select E-commerce {{ index + 1 }}</label>
              <v-select :options="optionsEcomerce" v-model="item.item" label="ecommerce_name" />
              <span class="text-danger" v-if="item.item.$error">Ecommerce is required</span>
            </div>
          </panel>
          <button @click="addEcommerce()" class="btn btn-outline-primary w-100 d-block text-start" type="button">
            <i class="fa-solid fa-plus"></i> Add new E-commerce List
          </button>
          <span class="text-danger" v-if="$v.product.ecommerce.$error">E-commerce is required</span>
        </panel>
        <panel title="Testimonial" variant="inverse" :is-collapse="true">
          <panel v-for="(item, index) in product.testimonials" :key="index" :index="index"
            :title="`Testimoni ${index + 1}`" variant="grey" :is-collapse="true" :checkbox-hidden="false"
            :show-collapse="index > 0 ? false : true" v-model="item.isShow" :checkbox-name="`sliderItem-${index}`"
            :has-remove="true" @onRemove="removeTestimonial(index)">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Name</label>
                  <input type="text" class="form-control" placeholder="Name" v-model="item.name" />
                </div>
              </div>
              <!-- <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Product</label>
                  <input type="text" class="form-control" placeholder="Goon Pants L" v-model="item.job" />
                </div>
              </div> -->
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Rating</label>
                  <input type="number" class="form-control" placeholder="5.0 / 5.0" v-model="item.rating" max="5"
                    min="0" />
                </div>
              </div>
              <div class="col-md-12">
                <ckeditor v-model="item.description" :config="{
                  delayDetached: true,
                  autoParagraph: false,
                  enterMode: 2,
                  versionCheck: false
                }" />
              </div>
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Foto Profile </label>
                  <p class="mb-2">(Foto Profile version: 59x59 px, max. 1mb, jpeg, jpg, png)</p>
                  <UploadImage v-model="item.image" :src="item.imageSrc" size="1000" @image-err-message="imgErrMessage"
                    :dimensions="{ width: 59, height: 59 }" />
                </div>
              </div>
            </div>
          </panel>
          <button @click="addTestimonial()" class="btn btn-outline-primary w-100 d-block text-start" type="button">
            <i class="fa-solid fa-plus"></i> Add new Testimoni
          </button>
          <!-- <span class="text-danger" v-if="$v.product.testimonials.$error">Testimonial is required</span> -->
        </panel>
        <panel title="SEO Information" variant="inverse" :is-collapse="true">
          <div class="mb-15px">
            <label class="form-label">Meta Title</label>
            <input type="text" v-model="product.meta.title" placeholder="Meta Title" class="form-control" />
            <span class="text-danger" v-if="$v.product.meta.title.$error">Meta Title is required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Meta Description</label>
            <textarea class="form-control" rows="7" v-model="product.meta.description"
              placeholder="Meta Description"></textarea>
            <span class="text-danger" v-if="$v.product.meta.description.$error">Meta Description is required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Meta Keywords</label>
            <input-tag v-model="product.meta.keywords" class="form-control default"></input-tag>
            <small class="fw-normal text-muted">Separate with commas</small>
            <br>
            <span class="text-danger" v-if="$v.product.meta.keywords.$error">Meta Keywords is required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Meta Image
              <span class="fw-normal text-muted">(Optional) (jpeg, jpg, png)</span></label>
            <UploadImage v-model="product.meta.image" :src="product.meta.imageSrc" size="1000"
              @image-err-message="imgErrMessage" />
          </div>
          <div class="col-md-12">
            <div class="mb-15px">
              <label class="form-label">Alt</label>
              <input type="text" v-model="product.meta.alt_image" class="form-control" placeholder="Alt text" />
              <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
            </div>
          </div>
        </panel>
      </div>

      <div v-if="product.author" class="col-lg-3 col-md-4">
        <div class="panel panel-sticky">
          <div class="panel-body">
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Published Date</p>
              <p class="mb-0">{{ product.publishedDate ? dateFormat(product.publishedDate) : '' }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Last Modified Date</p>
              <p class="mb-0">{{ product.lastModified ? dateFormat(product.lastModified) : '' }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Author</p>
              <p class="mb-0">{{ product.author }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="button" class="btn btn-outline-primary" @click.prevent="doSave('Draft')">
            Save as Draft
          </button>
        </div>
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Publish
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../config/Helpers";
import UploadImage from "../../components/upload/Image.vue";
import ProductCategoryService from "@/service/productcategory.service";
import parentcategoryService from "@/service/parentcategory.service";
import ProductTypeService from "@/service/producttype.service";
import { required } from 'vuelidate/lib/validators'
import dateFormat from "@/pages/Helper/formate_date";
import EcomerceService from "@/service/ecomerce.service";

export default {
  components: {
    UploadImage,
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.loadItems()
    this.loadItemsParent()
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;
    return {
      optionsCategory: [],
      optionsType: [],
      customToolbar: defaultToolbarEditor,
      dateFormat: dateFormat,
      optionsEcomerce: [],
      optionsParentCategory: [],
      product: {
        name: detail ? detail.name : null,
        qty_in_box: detail ? detail.qty_in_box : null,
        category: detail ? detail.category : null,
        type: detail ? detail.type : null,
        description: detail ? detail.description : null,
        images: {
          desktop: null,
          desktopSrc: '',
          mobile: null,
          desktopSrc: '',
          alt: null,
        },
        ecommerce: [],
        testimonials: [],
        meta: {
          title: null,
          description: null,
          keywords: [],
          image: null,
          imageSrc: '',
        },
        publishedDate: detail ? detail.publishedDate : null,
        lastModified: detail ? detail.lastModified : null,
        author: detail ? detail.author : null,
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add" : "Edit";
    },
    slug: function () {
      if (this.product.name) {
        return this.slugify(this.product.name);
      }
    },
  },
  watch: {
    'detailData'(newVal) {
      this.product = newVal
    },
    'product.categoryParent'(newVal) {
      if (newVal) {
        this.loadCategory(newVal);
      }
    },
    'product.ecommerce':
    {
      handler(value) {
        for (const [k, v] of Object.entries(value)) {
          let { item } = v
          if (item) {
            let filter = this.optionsEcomerce.filter((r) => r.id !== item.id)
            this.optionsEcomerce = filter
          }

        }
      }, deep: true
    }
  },
  methods: {
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    slugify(text, ampersand = "and") {
      if (text == null) {
        return null;
      }

      const a = "àáäâèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧ";
      const b = "aaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzh";
      const p = new RegExp(a.split("").join("|"), "g");

      return text
        .toString()
        .toLowerCase()
        .replace(/[\s_]+/g, "-")
        .replace(p, (c) => b.charAt(a.indexOf(c)))
        .replace(/&/g, `-${ampersand}-`)
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-")
        .replace(/^-+|-+$/g, "");
    },
    addEcommerce() {
      this.product.ecommerce.push({
        id: '',
        item: '',
      });
    },
    doSave(status) {

      this.$v.$touch();

      if (!this.$v.$invalid) {

        const payload = {
          product: this.product,
          status: status,
          slug: this.slug
        };

        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
    loadCategory(productParent) {
      this.isLoading = true;
      ProductCategoryService
        .getProductCategoryList({ parent_product_category: productParent.parent_name })
        .then(({ data: { data } }) => {
          if (data.productCategory.length > 0) {
            this.optionsCategory = data.productCategory
          } else {
            this.product.category = '';
            this.optionsCategory = []
          }
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },
    hasDuplicates(array) {
      return (new Set(array)).size !== array.length;
    },
    loadType() {
      this.isLoading = true;
      ProductTypeService
        .getProductTypeList({})
        .then(({ data: { data } }) => {
          this.optionsType = data.ProudctType
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadItems() {
      this.loadParentCategory()
      this.loadType();
      this.loadEcommerce();
    },
    loadParentCategory() {
      this.isLoading = true;
      ProductCategoryService
        .getProductCategoryList({})
        .then(({ data: { data } }) => {
          this.optionsCategory = data.ProductCategory
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadEcommerce() {
      this.isLoading = true;
      EcomerceService
        .getEcommerceList(this.serverParams)
        .then(({ data: { data } }) => {
          this.optionsEcomerce = data.ecommerce
        }).catch((e) => {
          if (e.response.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    imgErrMessage(mssg) {
      this.$notify({
        type: "error",
        title: "Image Notification",
        text: mssg,
      });
    },
    addTestimonial() {
      this.product.testimonials.push({
        isShow: true,
        name: "",
        product: "",
        rating: "",
        description: ""
      });
    },
    removeTestimonial(index) {
      this.product.testimonials.splice(index, 1);
    },
    removeEcommerce(index) {
      this.product.ecommerce.splice(index, 1);
    },
    loadItemsParent() {
      this.isLoading = true;
      parentcategoryService
        .getParentCategoryList({})
        .then(({ data: { data } }) => {
          if (data.ParentProductCategory) {
            this.optionsParentCategory = data.ParentProductCategory
          } else {
            this.optionsParentCategory = []
          }
        }).catch((e) => {
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
  },
  validations: {
    product: {
      name: { required },
      type: { required },
      description: { required },
      categoryParent: { required },
      images: {
        desktop: { required },
        mobile: { required },
        // alt: { required },
      },
      ecommerce: {
        required,
        $each: {
          item: {
            required
          }
        }
      },
      meta: {
        title: { required },
        description: { required },
        keywords: { required },
      },
    },
  }
};
</script>
