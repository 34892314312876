<template>
  <form @submit.prevent="doSave" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          {{ formTitle }} Information Supervisor
        </h1>
      </div>
      <div class="col-lg-12 col-md-12">
        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Nama</label>
                  <input type="text" class="form-control" placeholder="Nama" v-model="reseller.nama">
                  <!-- {{ $v }} -->
                  <span class="text-danger" v-if="$v.reseller.nama.$error">Nama is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <label class="form-label">No. Hp</label>
                <div class="input-group">
                  <span class="input-group-text">+62</span>
                  <input type="text" class="form-control" v-model="reseller.phone" :maxlength="11"
                    v-on:keypress="isNumber" />
                </div>
                <div>Note: Phone Number must be at least {{ $v.reseller.phone.$params.minLength.min }}</div>
                <div class="text-danger" v-if="$v.reseller.phone.$error">Phone Number must have at most
                  {{ $v.reseller.phone.$params.minLength.min }} letters.</div>
              </div>
              <div class="col-md-12">
                <label class="form-label">Area Seller</label>
                <input type="text" class="form-control" placeholder="Area Seller" v-model="reseller.area">
                <span class="text-danger" v-if="$v.reseller.area.$error">Area is required</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Save</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../../config/Helpers";
import UploadImage from "../../../components/upload/Image.vue";
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  components: {
    UploadImage,
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // console.log(this.detailData);
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;

    return {
      options: {
        format: "DD/MM/YYYY",
        useCurrent: true,
      },
      customToolbar: defaultToolbarEditor,
      reseller: {
        nama: detail ? detail.nama : null,
        phone: detail ? detail.phone : null,
        area: detail ? detail.area : null,
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
  },
  watch: {
    detailData(newVal) {
      this.reseller = newVal;
    },
  },
  methods: {
    doSave() {

      this.$v.$touch();

      if (!this.$v.$invalid) {
        const payload = {
          reseller: this.reseller,
        };
        // console.log(payload);

        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    }
  },
  validations: {
    reseller: {
      nama: { required },
      phone: { required, minLength: minLength(10) },
      area: { required },
    },
  }
};
</script>
