<template>
  <div class="product-form">
    <FormPost form-type="edit" :detail-data="form" @onValidateSuccess="doSave" />
  </div>
</template>
<script>
import FormPost from "./Form.vue";
import contactusService from "@/service/contactus.service";
import base64Converter from "@/pages/Helper/base64";
import slugify from "@/pages/Helper/slug";
import moment from "moment";
export default {
  data() {
    return {
      form: {
        id: this.$route.params.id,
        title: "",
      },
    };
  },
  components: {
    FormPost,
  },
  mounted() {
    this.loadItems(this.$route.params.id)
  },
  methods: {
    async doSave(payload) {
      // Submit here
      this.onLoading = true
      let { form } = payload
      let req = {
        name: form.name ? form.name : null,
        email: form.email ? form.email : null,
        phune_number: form.phune_number ? '+62' + form.phune_number : null,
        description: form.description ? form.description : null,
      }

      contactusService.patchContactUs(this.$route.params.id, req).then((rsp) => {
        this.$swal({
          title: "Data berhasil disimpan",
          showCloseButton: true,
        }).then(() => {
          this.$router.push({ path: '/pages/contact-us' })
        });
      }).catch((e) => {
        if (e.response?.status === 409) {
          this.$notify({
            type: "warn",
            title: "Error Message",
            text: "Data Sudah Ada",
          });
        } else {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }
      }).finally(() =>
        this.onLoading = false)
    },
    loadItems(id) {
      contactusService.getContactUsById(id)
        .then(async ({ data: { data } }) => {
          let { phune_number } = data
          this.form = {
            ...data,
            phune_number: phune_number ? phune_number.replace('+62', '') : null,
          }
        })
    },
  },
};
</script>
