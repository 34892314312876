<template>
  <form @submit.prevent="doSave('Publish')" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          {{ formTitle }} Promo
        </h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="mb-15px">
          <input type="text" class="form-control form-control-lg" placeholder="Promo Title" v-model="promo.title" />
          <span class="text-danger" v-if="$v.promo.title.$error">Title is required</span>
        </div>
        <div class="panel">
          <div class="panel-body">
            <div class="col-md-12">
              <div class="mb-15px">
                <label class="form-label">Image
                  <span class="fw-normal text-muted">version : 747x485 px (jpeg, jpg, png)</span>
                </label>
                <UploadImage v-model="promo.images.img_homepage" :src="promo.images.img_homepageSrc" size="1000"
                  :dimensions="{ width: 747, height: 485 }" />
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-15px">
                <label class="form-label">Image Thumbnail
                  <span class="fw-normal text-muted">version : 400x292 px (jpeg, jpg, png)</span></label>
                <UploadImage v-model="promo.images.thumbnail" :src="promo.images.thumbnailSrc" size="1000"
                  :dimensions="{ width: 400, height: 292 }" />
              </div>
            </div>

            <div class="col-md-12">
              <div class="mb-15px">
                <label class="form-label">Alt Thumbnail</label>
                <input type="text" v-model="promo.images.alt_thumbnail" class="form-control" placeholder="Alt text" />
                <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label class="form-label">Main Image</label>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <!-- <p class="mb-2">(dekstop version : 1240x367 px, max. 1mb,jpeg, jpg, png)</p> -->
                  <UploadImage v-model="promo.images.desktop" :src="promo.images.desktopSrc" size="1000"
                    @image-err-message="imgErrMessage" />
                  <span class="text-danger" v-if="$v.promo.images.desktop.$error">Dekstop is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <!-- <p class="mb-2">(mobile version : 350x350 px, max. 1mb,jpeg, jpg, png)</p> -->
                  <UploadImage v-model="promo.images.mobile" :src="promo.images.mobileSrc" size="1000"
                    @image-err-message="imgErrMessage" />
                  <span class="text-danger" v-if="$v.promo.images.desktop.$error">Mobile is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Dekstop Alt Text</label>
                  <input type="text" v-model="promo.images.alt_desktop_image" class="form-control"
                    placeholder="Alt text" />
                  <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Mobile Alt Text</label>
                  <input type="text" v-model="promo.images.alt_mobile_image" class="form-control"
                    placeholder="Alt text" />
                  <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Slug</label>
                  <div class="input-group">
                    <span class="input-group-text bg-white border-end-0 text-muted">http://goon.co.id/</span>
                    <input type="text" class="form-control border-start-0 ps-0" placeholder="Post Title"
                      v-model="slug" />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Promo Category</label>
                  <v-select :clearable="false" v-model="promo.category" :options="optionsCategory"
                    label="promo_name"></v-select>
                  <span class="text-danger" v-if="$v.promo.category.$error">Category is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Periode
                    <span class="text-primary">(Dari Tanggal)</span></label>
                  <div class="input-group">
                    <date-picker v-model="promo.date.start" :config="options"></date-picker>
                    <span class="input-group-text">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                  <span class="text-danger" v-if="$v.promo.date.start.$error">Date Start is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Periode
                    <span class="text-primary">(Sampai Tanggal)</span></label>
                  <div class="input-group">
                    <date-picker v-model="promo.date.end" :config="options"></date-picker>
                    <span class="input-group-text">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                  <span class="text-danger" v-if="$v.promo.date.end.$error">Date End is required</span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Link Youtube</label>
                  <div class="input-group">
                    <span class="input-group-text"><i class="fa-solid fa-link"></i></span>
                    <input type="url" v-model="promo.youtube" class="form-control"
                      placeholder="https://www.youtube.com/@GoonIndonesia" />
                  </div>
                  <!-- <span class="text-danger" v-if="$v.promo.youtube.$error">Youtube is required</span> -->
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-15px">
                  <ckeditor v-model="promo.content" :config="{
                    delayDetached: true,
                    autoParagraph: false,
                    enterMode: 2,
                    versionCheck: false
                  }" />
                  <span class="text-danger" v-if="$v.promo.content.$error">Description is required</span>
                </div>
              </div>

            </div>
          </div>
        </div>
        <panel title="E-commerce" variant="inverse" :is-collapse="true">
          <panel v-for="(item, index) in promo.ecommerce" :key="index" :title="`E-commerce ${index + 1}`" variant="grey"
            :is-collapse="true" :has-remove="true" @onRemove="removeEcommerce(index, item)">
            <div class="col-md-12">
              <div class="mb-15px">
                <label class="form-label">Link Ecommerce</label>
                <div class="input-group">
                  <span class="input-group-text"><i class="fa-solid fa-link"></i></span>
                  <input type="url" v-model="item.url" class="form-control"
                    placeholder="https://www.tokopedia.com/goon/" />
                </div>
                <!-- <span class="text-danger" v-if="$v.promo.youtube.$error">Youtube is required</span> -->
              </div>
            </div>
            <div class="mb-15px">
              <label class="form-label">Select E-commerce {{ index + 1 }}</label>
              <v-select :options="optionsEcomerce" v-model="item.ecommerce" label="ecommerce_name" />
              <!-- <span class="text-danger" v-if="item.item.$error">Ecommerce is required</span> -->
            </div>
          </panel>
          <button @click="addEcommerce()" class="btn btn-outline-primary w-100 d-block text-start" type="button">
            <i class="fa-solid fa-plus"></i> Add new E-commerce List
          </button>
          <!-- <span class="text-danger" v-if="$v.promo.ecommerce.$error">E-commerce is required</span> -->
        </panel>
        <panel title="SEO Information" variant="inverse" :is-collapse="true">
          <div class="mb-15px">
            <label class="form-label">Meta Title</label>
            <input type="text" v-model="promo.meta.title" placeholder="Meta Title" class="form-control" />
            <span class="text-danger" v-if="$v.promo.meta.title.$error">Meta Title is required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Meta Description</label>
            <textarea class="form-control" rows="7" v-model="promo.meta.description"
              placeholder="Meta Description"></textarea>
            <span class="text-danger" v-if="$v.promo.meta.description.$error">Meta Description is required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Meta Keywords</label>
            <input-tag v-model="promo.meta.keywords" class="form-control default"></input-tag>
            <small class="fw-normal text-muted">Separate with commas</small>
            <br>
            <span class="text-danger" v-if="$v.promo.meta.keywords.$error">Meta Keywords is required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Meta Image
              <span class="fw-normal text-muted">(Optional) (jpeg, jpg, png)</span></label>
            <UploadImage v-model="promo.meta.image" :src="promo.meta.imageSrc" size="1000"
              :dimensions="{ width: 1280, height: 550 }" />
          </div>
          <div class="col-md-12">
            <div class="mb-15px">
              <label class="form-label">Alt</label>
              <input type="text" v-model="promo.meta.alt_image" class="form-control" placeholder="Alt text" />
              <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
            </div>
          </div>
        </panel>
      </div>
      <div v-if="promo.author" class="col-lg-3 col-md-4">
        <div class="panel panel-sticky">
          <div class="panel-body">
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Published Date</p>
              <p class="mb-0">{{ promo.publishedDate ? dateFormat(promo.publishedDate) : '' }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Last Modified Date</p>
              <p class="mb-0">{{ promo.lastModified ? dateFormat(promo.lastModified) : '' }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Author</p>
              <p class="mb-0">{{ promo.author }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="button" class="btn btn-outline-primary" @click.prevent="doSave('Draft')">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Save as Draft
          </button>
        </div>
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Publish</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../../config/Helpers";
import UploadImage from "../../../components/upload/Image.vue";
import PromoCategoryService from "@/service/promocategory.service";
import EcomerceService from "@/service/ecomerce.service";
import { required } from 'vuelidate/lib/validators'
import dateFormat from "@/pages/Helper/formate_date";

export default {
  components: {
    UploadImage,
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // console.log(this.detailData);
    this.loadItems()
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;

    return {
      options: {
        format: "DD/MM/YYYY",
        useCurrent: true,
      },
      dateFormat,
      optionsCategory: [],
      optionsEcomerce: [],
      customToolbar: defaultToolbarEditor,
      promo: {
        title: detail ? detail.title : null,
        category: detail ? detail.category : '',
        slug: detail ? detail.slug : null,
        content: '',
        youtube: null,
        date: {
          start: null,
          end: null,
        },
        images: {
          desktop: detail ? detail.images.desktop : null,
          desktopSrc: detail ? detail.images.desktopSrc : '',
          mobile: detail ? detail.images.mobile : null,
          mobileSrc: detail ? detail.images.mobileSrc : '',
          alt: detail ? detail.images.alt : null,
        },
        ecommerce: [
        ],
        meta: {
          title: detail ? detail.meta.title : null,
          description: detail ? detail.meta.description : null,
          keywords: detail ? detail.meta.keywords : [],
          image: detail ? detail.meta.image : null,
          imageSrc: detail ? detail.meta.imageSrc : ''
        },
        publishedDate: detail ? detail.publishedDate : null,
        lastModified: detail ? detail.lastModified : null,
        author: detail ? detail.author : null,
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
    slug: function () {
      if (this.promo.title) {
        return this.slugify(this.promo.title);
      }
    },
  },
  watch: {
    detailData(newVal) {
      this.promo = newVal;
    },
  },
  methods: {
    imgErrMessage(mssg) {
      this.$notify({
        type: "error",
        title: "Image Notification",
        text: mssg,
      });
    },
    addEcommerce() {
      this.promo.ecommerce.push({
        id: '',
        item: '',
      });
      this.loadEcommerce()
    },
    doSave(status) {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const payload = {
          promo: { ...this.promo, slug: this.slug },
          status
        };
        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
    removeEcommerce(index) {
      this.promo.ecommerce.splice(
        index,
        1
      );
    },
    loadCategory() {
      this.isLoading = true;
      PromoCategoryService
        .getPromoCategoryList({})
        .then(({ data: { data } }) => {
          this.optionsCategory = data.PromoCategory
          this.totalRecords = data.totalData
        }).catch((e) => {
          if (e.response.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadItems() {
      this.loadCategory()
      this.loadEcommerce()
    },

    loadEcommerce() {
      this.isLoading = true;
      EcomerceService
        .getEcommerceList({})
        .then(({ data: { data } }) => {
          let ecommerce_id = []
          for (const [k, v] of Object.entries(this.promo.ecommerce)) {
            if (v.ecommerce?.id) {
              ecommerce_id.push(v.ecommerce?.id)
            }
          }
          this.optionsEcomerce = data.ecommerce.filter((e) => !ecommerce_id.includes(e.id))
        }).catch((e) => {
          // console.log(e)
          if (e.response?.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    slugify(text, ampersand = "and") {
      if (text == null) {
        return null;
      }

      const a = "àáäâèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧ";
      const b = "aaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzh";
      const p = new RegExp(a.split("").join("|"), "g");

      return text
        .toString()
        .toLowerCase()
        .replace(/[\s_]+/g, "-")
        .replace(p, (c) => b.charAt(a.indexOf(c)))
        .replace(/&/g, `-${ampersand}-`)
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-")
        .replace(/^-+|-+$/g, "");
    },
    imgErrMessage(mssg) {
      this.$notify({
        type: "error",
        title: "Image Notification",
        text: mssg,
      });
    },
  },
  validations: {
    slug: { required },
    promo: {
      title: { required },
      content: { required },
      category: { required },
      date: {
        start: { required },
        end: { required },
      },
      images: {
        desktop: { required },
        mobile: { required },
      },
      meta: {
        title: { required },
        description: { required },
        keywords: { required },
      },
    },
  }
};
</script>
