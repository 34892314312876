import api from "./api";

class MenuService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('menu')
    }

    getMenuList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postMenu(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getMenuById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchMenu(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteMenuById(serverParams) {
        return this.api.delete("/" + serverParams);
    }
}

export default new MenuService();