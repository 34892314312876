import api from "./api";

class SurveyService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('question')
    }

    getSurveyList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postSurvey(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getSurveyById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchSurvey(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteSurveyById(serverParams) {
        return this.api.delete("/" + serverParams);
    }
}

export default new SurveyService();