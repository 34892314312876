<template>
  <div class="product-form">
    <FormUsers form-type="add" :detail-data="detail" @onValidateSuccess="doSave" :on-loading="onLoading" />
  </div>
</template>
<script>
import FormUsers from "./Form.vue";
import UserService from "@/service/user.service";

export default {
  data() {
    return {
      onLoading: false,
      detail: {
        id: '',
        email: '',
        role: {
          options: [],
          value: {}
        }
      },
      serverParams: {
        searchField: "",
        searchValue: "",
        sort: "",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 0
      }
    };
  },
  components: {
    FormUsers,
  },
  mounted() {
    // this.loadItems()
    // console.log(this.detail, 'detailData');
  },
  methods: {
    async doSave(payload) {
      // Submit here
      this.onLoading = true
      if (payload) {
        let { user, status } = payload
        let { rePassword, role, phoneNumber, ...newUser } = user

        let req = {
          ...newUser,
          role_cms_id: role.value.id,
          phoneNumber: (phoneNumber) ? '+62' + phoneNumber : null,
          status
        }

        UserService.postUser(req).then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: '/users/manage/user' })
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          }
        }).finally(() =>
          this.onLoading = false)
      }
    },
  },
};
</script>
