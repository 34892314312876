<template>
  <div class="product-form">
    <FormProduct form-type="edit" :detail-data="parent" @onValidateSuccess="doSave" :on-loading="onLoading" />
  </div>
</template>
<script>
import FormProduct from "./Form.vue";
import ProductCategoryService from "@/service/productcategory.service";
import base64Converter from "@/pages/Helper/base64";

export default {
  data() {
    return {
      onLoading: false,
      parent: {
        id: this.$route.params.id,
        name: "",
        image: null,
        imageSrc: '',
      },
    };
  },
  components: {
    FormProduct,
  },
  mounted() {
    this.loadItems(this.$route.params.id)
  },
  methods: {
    async doSave(payload) {
      // Submit here
      this.onLoading = true
      let { category, status } = payload
      if (category) {
        payload = {
          category_name: category.name,
          parent_product_category_id: category.parent.id,
          icon_image: category.image[0] ? await base64Converter(category.image[0]) : null,
          alt_icon_image: category.alt_icon_image ? category.alt_icon_image : null,
          order_number: category.order_number ? category.order_number : null,
          // video_url: category.video_url ? 'https://youtu.be/4ApqUMIPl0s?si=' + category.video_url : null,
          video_url: category.video_url ? category.video_url : null,
          excerpt_video: category.excerpt_video ? category.excerpt_video : null,
        };

        ProductCategoryService.patchProductCategory(this.$route.params.id, payload).then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: '/products/category' })
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          }
        }).finally(() =>
          this.onLoading = false)
      }
    },
    loadItems(id) {
      ProductCategoryService.getProductCategoryById(id)
        .then(async ({ data: { data } }) => {
          this.parent = {
            id: id,
            name: data.category_name,
            parent: data.parent_product_category,
            image: data.icon_image ? [await this.convertURLtoFile(this.loadSrcImage(data.icon_image))] : null,
            imageSrc: data.icon_image ? this.loadSrcImage(data.icon_image) : '',
            alt_icon_image: data.alt_icon_image ? data.alt_icon_image : null,
            order_number: data.order_number ? data.order_number : null,
            // video_url: data.video_url ? data.video_url.split('=')[1] : null,
            video_url: data.video_url ? data.video_url : null,
            excerpt_video: data.excerpt_video ? data.excerpt_video : null,
          }
        }).catch((e) => {
          // console.log(e)
        })
    },
    loadSrcImage(url) {
      return this.$api_name + '/' + url
    },
    async convertURLtoFile(url) {
      const response = await fetch(url);
      if (response.status != 200) {
        return "";
      }
      const data = await response.blob();
      const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
      const metadata = { type: `image/jpeg` };
      return new File([data], filename, metadata);
    },
  },
};
</script>
