import api from "./api";

class RoleService {
  /** @type {ApiAxiosInstance} */
  api;

  constructor() {
    this.api = api.create('cms-role')
  }

  getRoleList(serverParams) {
    return this.api.get("/", { params: serverParams });
  }

  getPermissionsList() {
    return this.api.get("/get-all-permission");
  }

  postRole(serverParams) {
    return this.api.post("/", { ...serverParams });
  }

  getRoleById(serverParams) {
    return this.api.get("/" + serverParams);
  }

  patchRole(id, serverParams) {
    return this.api.patch("/" + id, { ...serverParams });
  }

  deleteRoleById(serverParams) {
    return this.api.delete("/" + serverParams);
  }

  getAllPermision(serverParams) {
    return this.api.get("/get-all-permission'" + serverParams);
  }
}

export default new RoleService();