<template>
  <div>
    <h1 class="page-header"><span v-if="isEdit">Edit</span> FAQ Category</h1>
    <div class="row">
      <div class="col-md-6">
        <div class="panel">
          <div class="panel-body">
            <form @submit.prevent="doSave">
              <div class="row align-items-end">
                <div class="col">
                  <label class="form-label"><span v-if="!isEdit">Add New</span><span v-else>Edit</span> Category</label>
                  <input type="text" class="form-control" placeholder="Add New Category Name" v-model="category" />
                  <span class="text-danger" v-if="$v.category.$error">Category Name is required</span>
                </div>
                <div class="col-md-2">
                  <!-- <label class="form-label">Top 5</label> -->
                  <input type="checkbox" class="form-check-input mb-15px" placeholder="Add New Category Name"
                    v-model="topfive" id="flexCheckDefault" />
                  <label class="form-check-label px-1" for="flexCheckDefault">
                    <b> Top 5</b>
                  </label>
                  <span class="text-danger" v-if="$v.category.$error">Category Name is required</span>
                </div>
                <div class="col-auto" v-if="add || edit">
                  <button type="submit" class="btn btn-outline-primary">
                    <span v-if="!isEdit && add">Add</span>
                    <span v-else>Save</span>
                  </button>
                </div>
                <div class="col-auto">
                  <button :disabled="!isEdit" type="submit" class="btn btn-outline-primary" @click.prevent="doCancel">
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
          :isLoading.sync="isLoading" :pagination-options="{
            enabled: true,
            dropdownAllowAll: false,
          }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'sort', type: 'desc' },
}" styleClass="vgt-table" slot="outsideBody">
          <template slot="column-filter" slot-scope="{ column, updateFilters }">
            <input ref="name" class="form-control" placeholder="Search category" v-if="column.filterOptions &&
              column.filterOptions.customFilter &&
              column.field === 'name'
              " @input="(value) => updateFilters(column, $refs.name.value)" />
            <select ref="top_five.text" class="form-select" placeholder="Cari Category" v-if="column.filterOptions &&
              column.filterOptions.customFilter &&
              column.field === 'top_five.text'
              " @change="(value) => updateFilters(column, $refs['top_five.text'].value)">
              <option />
              <option v-for="(option, key) in optionsTopFive" v-bind:value="option.value" :key="key"
                v-text="option.text" />
            </select>
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'action'">
              <div class="row g-2 align-items-center justify-content-end">
                <div class="col-auto">
                  <button type="button" class="btn btn-lg text-dark" @click="doEdit(props.row)" v-b-tooltip.hover
                    title="Edit">
                    <i class="fas fa-pencil"></i>
                  </button>
                </div>
                <div class="col-auto">
                  <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Delete"
                    @click.prevent="showAlertConfirm(props.row.id)">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </div>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import faqCategoryService from "@/service/faqcategory.service";
import tokenService from "@/service/token.service";
import { required } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      optionsTopFive: [
        {
          value: true,
          text: 'Ya'
        },
        {
          value: false,
          text: 'Tidak'
        },
      ],
      permisions: tokenService.getPermission(),
      category: "",
      topfive: false,
      isEdit: false,
      isLoading: false,
      columns: [
        {
          label: "Category Name",
          field: "name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Top Five",
          field: "top_five.text",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          width: "200px",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        search: '',
        take: 10,
        skip: 0,
        sort: 'desc'
      },
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ skip: 0 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({});
      this.loadItems();
    },

    onColumnFilter(params) {
      let payload = {}
      if (params.columnFilters['top_five.text']) {
        payload.top5Category = params.columnFilters['top_five.text']
      } else {
        delete this.serverParams.top5Category
      }
      if (params.columnFilters['name'] && params.columnFilters['name'] != '') {
        payload.search = params.columnFilters['name']
      } else {
        delete this.serverParams.search
      }
      this.updateParams(payload);
      this.loadItems();
    },
    loadItems() {
      this.isLoading = true;
      faqCategoryService
        .getFaqCategoryList(this.serverParams)
        .then(({ data: { data } }) => {
          let arrData = []
          for (const [k, v] of Object.entries(data.FaqCategory)) {
            arrData.push({
              ...v,
              top_five: this.optionsTopFive.find((r) => r.value == v.is_top_5)
            })
          }
          this.rows = arrData
          this.totalRecords = data.totalData
        }).catch((e) => {
          if (e.response?.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    doSave() {
      this.$v.category.$touch();

      if (!this.$v.category.$invalid) {
        const payload = {
          name: this.category,
          is_top_5: this.topfive
        };

        if (this.isEdit) {
          this.editRequest(payload)
        } else {
          this.addRequest(payload)
        }
      }
    },

    doEdit(data) {
      this.isEdit = true
      this.category = data.name
      this.topfive = data.is_top_5
      this.id = data.id
    },

    doCancel() {
      this.isEdit = false
      this.category = ''
      this.id = ''
      this.topfive = false
      this.$v.$reset();
    },

    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },

    doDelete(id) {
      this.isLoading = true;
      faqCategoryService
        .deleteFaqCategoryById(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
          this.doCancel()
        })
    },


    addRequest(req) {
      this.isLoading = true;
      faqCategoryService
        .postFaqCategory(req)
        .then((response) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          }
        }).finally(() => {
          this.isLoading = false;
          this.doCancel()
        })
    },
    editRequest(req) {
      this.isLoading = true;
      faqCategoryService
        .patchFaqCategory(this.id, req)
        .then((response) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message " + e.response?.data?.error,
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          }
        }).finally(() => {
          this.isLoading = false;
          this.doCancel()
        })
    },

  },
  computed: {
    add: function () {
      if (this.permisions.find((r) => r == 'CareerCategory Add')) {
        return true
      }
      return false;
    },
    edit: function () {
      if (this.permisions.find((r) => r == 'CareerCategory Patch')) {
        return true
      }
      return false;
    },
    deleteCareerCategory: function () {
      if (this.permisions.find((r) => r == 'CareerCategory Delete')) {
        return true
      }
      return false;
    },
  },
  mounted() {
    this.loadItems();
  },
  validations: {
    category: { required },
  }
};
</script>
