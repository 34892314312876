<template>
  <div>
    <h1 class="page-header">All Menus</h1>
    <div class="panel">
      <NavTab />
      <div class="panel-body">
        <p class="mb-3">This microsite supports 2 menus. Select which menu appears in each location.</p>
        <div class="row align-items-center mb-15px">
          <div class="col-md-2">
            <label class="form-label">Header navigation</label>
          </div>
          <div class="col-md-4">
            <v-select v-model="navigation.header" placeholder="Header navigation" :options="headerOptions" label="name" />
          </div>
        </div>
        <div class="row align-items-center mb-15px">
          <div class="col-md-2">
            <label class="form-label">Footer navigation</label>
          </div>
          <div class="col-md-4">
            <v-select v-model="navigation.footer" placeholder="Footer navigation" :options="footerOptions" label="name" />
          </div>
        </div>
        <button class="btn btn-primary" type="button" @click.prevent="doSave">Save Changes</button>
      </div>
    </div>
  </div>
</template>
<script>
import NavTab from "./Nav.vue";
import menuService from "@/service/menu.service";
import menuLocationService from "@/service/menulocation.service";
import { required } from 'vuelidate/lib/validators'
export default {
  components: {
    NavTab,
  },
  data() {
    return {
      headerOptions: [],
      footerOptions: [],
      id: {
        header: null,
        footer: null
      },
      navigation: {
        header: "",
        footer: ""
      }
    };
  },
  mounted() {
    this.loadMenu()
  },
  methods: {
    doSave() {
      try {
        let arrPost = []
        for (const v in this.navigation) {
          let payload = null
          if (this.navigation[v]) {
            payload = {
              is_active: "Publish",
              name: v,
              slug: "string",
              menu_id: this.navigation[v].id
            }
          }

          if (payload) {
            if (this.id[v]) {
              arrPost.push(menuLocationService.patchMenuLocation(this.id[v], payload))
            } else {
              arrPost.push(menuLocationService.postMenuLocation(payload))
            }
          }
        }
        Promise.all(arrPost)
          .then((r) => {
            this.$swal({
              title: "Data berhasil disimpan",
              showCloseButton: true,
            }).then(() => {
              this.loadItems();
            });
          }).catch((e) => {
            if (e.response?.status === 409) {
              this.$notify({
                type: "warn",
                title: "Error Message",
                text: "Data Sudah Ada",
              });
            } else {
              this.$notify({
                type: "error",
                title: "Error Message",
                text: e.response?.data?.message || "Terjadi Kesalahan",
              });
            }
          })
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Error Message",
          text: e.response?.data?.message || "Terjadi Kesalahan",
        });
      }

    },
    loadMenu() {
      this.isLoading = true;
      menuService
        .getMenuList()
        .then(({ data: { data } }) => {
          // console.log(data)
          if (data.length > 0) {
            this.headerOptions = data
            this.footerOptions = data
          } else {
            this.headerOptions = []
            this.footerOptions = []
          }
        }).catch((e) => {
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.name = ''
            this.id = ''
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
  },
};
</script>
