<template>
  <div class="product-form">
    <FormPost form-type="edit" :detail-data="promo" @onValidateSuccess="doSave" :on-loading="onLoading" />
  </div>
</template>
<script>
import FormPost from "./Form.vue";
import PromoService from "@/service/promo.service";
import TokenService from "@/service/token.service";
import moment from "moment";
import base64Converter from "@/pages/Helper/base64";

export default {
  data() {
    return {
      onLoading: false,
      promo: {
        id: this.$route.params.id,
        title: null,
        category: '',
        slug: null,
        youtube: null,
        date: {
          start: null,
          end: null,
        },
        images: {
          alt: null,
        },
        ecommerce: [
        ],
        meta: {
          title: null,
          description: null,
          keywords: [],
          image: null,
          imageSrc: ''
        },
        publishedDate: null,
        lastModified: null,
        author: null,
      },
    };
  },
  components: {
    FormPost,
  },
  mounted() {
    this.loadItems(this.$route.params.id)
  },
  methods: {
    async doSave(payload) {
      // Submit here
      this.onLoading = true
      let { promo, status } = payload
      if (promo) {
        let Ecommerce = []
        if (promo.ecommerce.length > 0) {
          for (const [k, v] of Object.entries(promo.ecommerce)) {
            if (v.ecommerce.id || v.url) {
              Ecommerce.push({
                ecommerce_id: v.ecommerce.id,
                url_market_place: v.url ? v.url : null
              })
            }
          }
        }
        // console.log(promo)
        payload = {
          user_id: (TokenService.getUser()).id,
          promo_title: promo.title,
          slug: promo.slug,
          ecommerce: Ecommerce,
          promo_category_id: promo.category.id,
          start_period: this.dateFormatToDate(promo.date.start),
          end_period: this.dateFormatToDate(promo.date.end),
          description: promo.content,
          link_youtube: promo.youtube ? promo.youtube : null,
          main_image_alt_text: promo.images.alt ? promo.images.alt : null,
          desktop_image: (promo.images.desktop && promo.images.desktop.length > 0 && promo.images?.desktop[0]) ?
            await base64Converter(promo.images.desktop[0]) : null,
          mobile_image: (promo.images.mobile && promo.images.mobile.length > 0 && promo.images?.mobile[0]) ?
            await base64Converter(promo.images.mobile[0]) : null,
          image_thumbnail: (promo.images.thumbnail && promo.images.thumbnail.length > 0 && promo.images?.thumbnail[0]) ?
            await base64Converter(promo.images.thumbnail[0]) : null,
          alt_image_thumbnail: promo.images.alt_thumbnail ? promo.images.alt_thumbnail : null,
          alt_desktop_image: promo.images.alt_desktop_image ? promo.images.alt_desktop_image : null,
          alt_mobile_image: promo.images.alt_mobile_image ? promo.images.alt_mobile_image : null,
          img_homepage: (promo.images.img_homepage && promo.images.img_homepage.length > 0) ?
            await base64Converter(promo.images.img_homepage[0]) : null,
          "seoInformation": {
            meta_title: promo.meta.title,
            meta_description: promo.meta.description,
            meta_keyword: promo.meta.keywords.toString(),
            image: (promo.meta.image && promo.meta.image.length > 0) ?
              await base64Converter(promo.meta.image[0]) : null,
            alt_image: promo.meta.alt_image ? promo.meta.alt_image : null,
          },
          status
        }

        PromoService.patchPromo(this.$route.params.id, payload).then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: '/posts/list/promo' })
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          }
        }).finally(() =>
          this.onLoading = false)
      }
    },
    loadItems(id) {
      PromoService.getPromoById(id)
        .then(async ({ data: { data } }) => {
          let ecomerce = [], ecommerce_id = []

          for (const [k, v] of Object.entries(data.promo.ecommerce)) {
            ecomerce.push({
              ecommerce: v.ecommerce,
              url: v.url_market_place ? v.url_market_place : null
            })
            ecommerce_id.push(v.ecommerce_id)
          }


          this.promo = {
            id: this.$route.params.id,
            title: data.promo.promo_title ? data.promo.promo_title : '',
            category: data.promo.promo_category ? data.promo.promo_category : '',
            slug:data.promo.slug ? data.promo.slug : '',
            youtube: data.promo.link_youtube ? data.promo.link_youtube : '',
            content: data.promo.description ? data.promo.description : '',
            date: {
              start: data.promo.start_period ? this.dateFormat(data.promo.start_period) : '',
              end: data.promo.end_period ? this.dateFormat(data.promo.end_period) : '',
            },
            images: {
              desktop: data ? [await this.convertURLtoFile(this.loadSrcImage(data.promo.desktop_image))] : null,
              desktopSrc: data ? this.loadSrcImage(data.promo.desktop_image) : '',
              mobile: data ? [await this.convertURLtoFile(this.loadSrcImage(data.promo.mobile_image))] : '',
              mobileSrc: data ? this.loadSrcImage(data.promo.mobile_image) : '',
              thumbnail: data.promo.image_thumbnail ? [await this.convertURLtoFile(this.loadSrcImage(data.promo.image_thumbnail))] : '',
              thumbnailSrc: data.promo.image_thumbnail ? this.loadSrcImage(data.image_thumbnail) : '',
              img_homepage: data.promo.img_homepage ? [await this.convertURLtoFile(this.loadSrcImage(data.img_homepage))] : '',
              img_homepageSrc: data.promo.img_homepage ? this.loadSrcImage(data.img_homepage) : '',
              alt_thumbnail: data.promo.alt_image_thumbnail ? data.alt_image_thumbnail : null,
              alt: data.promo.main_image_alt_text ? data.promo.main_image_alt_text : '',
              alt_desktop_image: data.promo.alt_desktop_image ? data.promo.alt_desktop_image : null,
              alt_mobile_image: data.promo.alt_mobile_image ? data.promo.alt_mobile_image : null,
            },
            ecommerce: ecomerce,
            ecommerce_id: ecommerce_id,
            meta: {
              title: (data.promo.seo_information?.meta_title && data.promo.seo_information) ? data.promo.seo_information.meta_title : '',
              description: (data.promo.seo_information?.meta_description && data.promo.seo_information) ? data.promo.seo_information.meta_description : '',
              keywords: (data.promo.seo_information?.meta_keyword && data.promo.seo_information) ? data.promo.seo_information.meta_keyword.split(',') : [],
              image: (data.promo.seo_information?.image && data.promo.seo_information) ? [await this.convertURLtoFile(this.loadSrcImage(data.promo.seo_information.image))] : null,
              imageSrc: (data.promo.seo_information?.image && data.promo.seo_information) ? this.loadSrcImage(data.promo.seo_information.image) : '',
              alt_image: data.promo.seo_information.alt_image ? data.promo.seo_information.alt_image : null,
            },
            publishedDate: data.promo.publishedDate ? data.promo.publishedDate : '',
            lastModified: data.promo.updatedAt ? data.promo.updatedAt : '',
            author: data.promo.User && data.promo.User?.name ? data.promo.User?.name : '',
          }
        }).catch((e) => {

          console.log(this.promo)
        })
    },
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    dateFormatToDate(date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
    },

    loadSrcImage(url) {
      return this.$api_name + '/' + url
    },
    async convertURLtoFile(url) {
      const response = await fetch(url);
      if (response.status != 200) {
        return "";
      }
      const data = await response.blob();
      const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
      const metadata = { type: `image/jpeg` };
      return new File([data], filename, metadata);
    },
  },
};
</script>
