import api from "./api";

class PointHistoryService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('point-history')
    }

    getPointHistoryList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postPointHistory(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getPointHistoryById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchPointHistory(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deletePointHistoryById(serverParams) {
        return this.api.delete("/" + serverParams);
    }

    getPointHistoryByCustomerList(id, serverParams) {
        return this.api.get("/customer/" + id, { params: serverParams });
    }
}

export default new PointHistoryService();