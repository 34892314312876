<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">Status Gizi Anak</h1>
        <!-- END page-header -->
      </div>
      <div class="col-auto mb-3" v-if="add">
        <router-link to="/tumbuh-kembang/status-gizi/add">
          <a class="btn btn-outline-primary">Add New</a>
        </router-link>
      </div>
    </div>
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
        setCurrentPage: setCurrentPage,
        perPage: serverParams.take,
      }" :columns="columns" :rows="rows" :sort-options="{
  enabled: true,
  initialSortBy: { field: 'name', type: 'asc' },
}" styleClass="vgt-table" slot="outsideBody">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <div class="input-group" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'usia'
          ">
          <input type="text" ref="usia1" class="form-control" placeholder="Search ..."
            @input="(value) => updateFilters(column, $refs.usia1.value + '#' + $refs.usia2.value)"
            v-on:keypress="isNumber" />
          <span class="input-group-text">
            -
          </span>
          <input type="text" ref="usia2" class="form-control" placeholder="Search ..."
            @input="(value) => updateFilters(column, $refs.usia1.value + '#' + $refs.usia2.value)"
            v-on:keypress="isNumber" />
        </div>

        <div class="input-group" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'ambang'
          ">
          <select ref="ambang1" class="form-select" placeholder="Cari ..."
            @change="(value) => updateFilters(column, $refs.ambang1.value + '#' + $refs.ambang2.value)">
            <option />
            <option v-for="(option, key) in optionsAmbang" v-bind:value="option.name" :key="key" v-text="option.name" />
          </select>
          <span class="input-group-text">
            -
          </span>
          <select ref="ambang2" class="form-select" placeholder="Cari ..."
            @change="(value) => updateFilters(column, $refs.ambang1.value + '#' + $refs.ambang2.value)">
            <option />
            <option v-for="(option, key) in optionsAmbang" v-bind:value="option.name" :key="key" v-text="option.name" />
          </select>
        </div>

        <select ref="category" class="form-select" placeholder="Cari ..." v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'category'
          " @change="(value) => updateFilters(column, $refs.category.value)">
          <option />
          <option v-for="(option, key) in optionsStatusCategory" v-bind:value="option.id" :key="key"
            v-text="option.name" />
        </select>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end" v-if="props.row.status !== 'Deleted'">
            <div class="col-auto" v-if="edit">
              <router-link :to="`/tumbuh-kembang/status-gizi/edit/${props.row.id}`" class="btn btn-lg text-dark"
                v-b-tooltip.hover title="Edit">
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>
            <div class="col-auto" v-if="deleteStatusGizi">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                @click.prevent="showAlertConfirm(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else :class="[props.row.status === 'Deleted' && 'text-muted']">
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import statusgiziService from "@/service/statusgizi.service";
import statusgiziCategoryService from "@/service/gizistatuscategory.service";
import ambangbatascategoryService from "@/service/ambangbatascategory.service";
import tokenService from "@/service/token.service";
import moment from "moment";
import _ from 'lodash';
export default {
  data() {
    return {
      setCurrentPage: this.$store.state.statusgizi.serverParams.skip / this.$store.state.statusgizi.serverParams.take == 0 ?
        1 : this.$store.state.statusgizi.serverParams.skip / this.$store.state.statusgizi.serverParams.take + 1,
      permisions: tokenService.getPermission(),
      isLoading: false,
      columns: [
        {
          label: "Usia",
          field: "usia",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          sortable: false,
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Kategori status gizi",
          field: "category",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          sortable: false,
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Ambang Batas",
          field: "ambang",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          sortable: false,
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      optionsStatusCategory: [],
      optionsAmbang: [],
      serverParams: this.$store.state.statusgizi.serverParams,
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.$store.state.statusgizi.serverParams = Object.assign({}, this.$store.state.statusgizi.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ skip: 0 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams();
      this.loadItems();
    },
    onColumnFilter: _.debounce(function (params) {
      let payload = {}
      if (params.columnFilters['ambang'] && params.columnFilters['ambang'] != '#') {
        let arrSplit = params.columnFilters['ambang'].split('#')
        // console.log(arrSplit)
        if (arrSplit[0] && arrSplit[1]) {
          payload.threshold1 = arrSplit[0] ? arrSplit[0] : ''
          payload.threshold2 = arrSplit[1] ? arrSplit[1] : ''
        }
      } else {
        delete this.$store.state.statusgizi.serverParams.threshold1
        delete this.$store.state.statusgizi.serverParams.threshold2
      }

      if (params.columnFilters['usia'] && params.columnFilters['usia'] != '#') {
        let arrSplit = params.columnFilters['usia'].split('#')
        if (arrSplit[0] && arrSplit[1]) {
          payload.age1 = arrSplit[0] ? arrSplit[0] : ''
          payload.age2 = arrSplit[1] ? arrSplit[1] : ''
        }
      } else {
        delete this.$store.state.statusgizi.serverParams.age1
        delete this.$store.state.statusgizi.serverParams.age2
      }

      if (params.columnFilters['category'] && params.columnFilters['category'] != '#') {
        payload.nutritionalStatusCategory = params.columnFilters['category'] ? params.columnFilters['category'] : '';
      } else {
        delete this.$store.state.statusgizi.serverParams.nutritionalStatusCategory
      }

      this.updateParams(payload);
      this.loadItems();
    }, 300),
    loadCategory() {
      this.isLoading = true;
      statusgiziCategoryService
        .getGiziStatusCategoryList({})
        .then(({ data: { data } }) => {
          this.optionsStatusCategory = data.NutritionalStatusCategory
          // this.totalRecords = data.totalData
        }).catch((e) => {
          if (e.response.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadAmbang() {
      this.isLoading = true;
      ambangbatascategoryService
        .getAmbangBatasCategoryList({})
        .then(({ data: { data } }) => {
          this.optionsAmbang = data.ThresholdCategory
        }).catch((e) => {
          if (e.response.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadItems() {
      this.isLoading = true;
      statusgiziService
        .getStatusGiziList(this.$store.state.statusgizi.serverParams)
        .then(({ data: { data } }) => {
          // console.log(data);
          if (data.NutritionalStatusOfChildren.length > 0) {
            let arrData = []
            for (const [k, v] of Object.entries(data.NutritionalStatusOfChildren)) {
              arrData.push({
                id: v.id,
                usia: v.age_in_month1 + ' - ' + v.age_in_month2,
                ambang: this.optionsAmbang.find((r) => r.id == v.threshold1_id)?.name + ' - ' + this.optionsAmbang.find((r) => r.id == v.threshold2_id)?.name,
                category: this.optionsStatusCategory.find((r) => r.id == v.nutritional_status_id)?.name
              })
            }
            this.rows = arrData
            this.totalRecords = data.totalData
          }
        }).catch((e) => {
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },

    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },
    doDelete(id) {
      this.isLoading = true;
      statusgiziService
        .deleteStatusGiziById(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },
  },
  computed: {
    add: function () {
      if (this.permisions.find((r) => r == 'NutritionalStatusOfChilder Create')) {
        return true
      }
      return false;
    },
    edit: function () {
      if (this.permisions.find((r) => r == 'NutritionalStatusOfChilder Patch')) {
        return true
      }
      return false;
    },
    deleteStatusGizi: function () {
      if (this.permisions.find((r) => r == 'NutritionalStatusOfChilder Delete')) {
        return true
      }
      return false;
    },
  },
  mounted() {
    this.loadCategory();
    this.loadAmbang();
    this.loadItems();
  },
};
</script>
