<template>
  <div>
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
        setCurrentPage: setCurrentPage,
        perPage: serverParams.take,
      }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'slug', type: 'asc' },
}" styleClass="vgt-table" slot="outsideBody">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="title" class="form-control" placeholder="Search pages" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'title'
          " @input="(value) => updateFilters(column, $refs.title.value)" />
        <div class="input-group" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'createdAt'">
          <input type="date" ref="createdAt1" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
          <span class="input-group-text">
            -
          </span>
          <input type="date" ref="createdAt2" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
        </div>
        <select ref="status" class="form-select" placeholder="Cari productCategory" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'status'
          " @change="(value) => updateFilters(column, $refs['status'].value)">
          <option />
          <option v-for="(option, key) in optionsStatus" v-bind:value="option" :key="key" v-text="option" />
        </select>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end" v-if="props.row.status !== 'Deleted'">
            <div class="col-auto" v-if="edit">
              <router-link :to="`/pages/edit/${props.row.id}`" class="btn btn-lg text-dark" v-b-tooltip.hover
                title="Edit">
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>
            <div class="col-auto" v-if="duplicate">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Duplicate"
                @click="doDuplicate(props.row.id)">
                <i class="fas fa-copy"></i>
              </button>
            </div>
            <div class="col-auto" v-if="deletePage">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                @click="showAlertConfirm(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else :class="[props.row.status === 'Deleted' && 'text-muted']">
          <span v-if="props.column.field == 'publishedDate'">
            {{ props.formattedRow[props.column.field] | getDate }}
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import dayjs from "dayjs";
import PageService from "@/service/page.service";
import tokenService from '@/service/token.service';
import formatUtc from "@/pages/Helper/formatUTC";
import _ from 'lodash';
export default {
  data() {
    return {
      setCurrentPage: this.$store.state.page.serverParams.skip / this.$store.state.page.serverParams.take == 0 ?
        1 : this.$store.state.page.serverParams.skip / this.$store.state.page.serverParams.take + 1,
      optionsStatus: ['Publish', 'Draft'],
      isLoading: false,
      columns: [
        {
          label: "Page Name",
          field: "title",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          sortable: true,
          firstSortType: "asc",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Published Date",
          field: "createdAt",
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Status",
          field: "status",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        search: '',
        take: 10,
        skip: 0,
        sort: 'desc'
      },
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
      permisions: tokenService.getPermission()
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.$store.state.page.serverParams = Object.assign({}, this.$store.state.page.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ skip: 0 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        // sort: [
        //   {
        //     type: params[0].type,
        //     field: params[0].field,
        //   },
        // ],
      });
      this.loadItems();
    },

    onColumnFilter: _.debounce(function (params) {
      let payload = {}
      if (params.columnFilters['createdAt'] && params.columnFilters.createdAt != '#') {
        let arrSplit = params.columnFilters['createdAt'].split('#')
        if (arrSplit[0] && arrSplit[1]) {
          let { startDate, endDate } = formatUtc(arrSplit[0], arrSplit[1])
          payload.createdAtStartDate = arrSplit[0] ? startDate : ''
          payload.createdAtEndDate = arrSplit[1] ? endDate : ''
        }
      } else {
        delete this.$store.state.page.serverParams.createdAtStartDate
        delete this.$store.state.page.serverParams.createdAtEndDate
      }

      if (params.columnFilters.status && params.columnFilters.status != '') {
        payload.status = params.columnFilters.status ? params.columnFilters.status : ''
      } else {
        delete this.$store.state.page.serverParams.status
      }

      if (params.columnFilters.title && params.columnFilters.title != '') {
        payload.pageTitle = params.columnFilters.title ? params.columnFilters.title : ''
      } else {
        delete this.$store.state.page.serverParams.pageTitle
      }


      this.updateParams(payload);
      this.loadItems();
    }, 300),
    loadItems() {
      // this.rows = [
      //   {
      //     id: 1,
      //     name: "About Us",
      //     publishedDate: "4 December 2022",
      //     status: "Publish",
      //   },
      //   {
      //     id: 2,
      //     name: "Homepage",
      //     publishedDate: "3 December 2022",
      //     status: "Draft",
      //   },
      //   {
      //     id: 3,
      //     name: "Product Page",
      //     publishedDate: "1 December 2022",
      //     status: "Deleted",
      //   },
      // ];
      this.isLoading = true;
      PageService
        .getPageList(this.$store.state.page.serverParams)
        .then(({ data: { data } }) => {
          this.rows = data.Page
          this.totalRecords = data.totalData
        }).catch((e) => {
          if (e.response.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },

    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },
    doDelete(id) {
      this.isLoading = true;
      PageService
        .deletePageById(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },

    doDuplicate(id) {
      this.isLoading = true;
      PageService
        .postDuplicatePage(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil diduplicate",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    }
  },
  mounted() {
    this.loadItems();
  },
  computed: {
    add: function () {
      if (this.permisions.find((r) => r == 'Page Add')) {
        return true
      }
      return false;
    },
    edit: function () {
      if (this.permisions.find((r) => r == 'Page Patch')) {
        return true
      }
      return false;
    },
    deletePage: function () {
      if (this.permisions.find((r) => r == 'Page Delete')) {
        return true
      }
      return false;
    },
    duplicate: function () {
      if (this.permisions.find((r) => r == 'Page Duplicate')) {
        return true
      }
      return false;
    },
  },
  filters: {
    getDate(date) {
      return `${dayjs(date).format("D MMMM YYYY")} - ${dayjs(date).format(
        "h:mm a"
      )}`;
    },
  },
};
</script>
