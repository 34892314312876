<template>
  <div>
    <h1 class="page-header">Redeem Data</h1>
    <div class="col-auto mb-15px" v-if="exportExcel">
      <a class="btn btn-outline-primary" @click.prevent="downloadExcel">
        <i class="fa-solid fa-file-import"></i>
        Export
      </a>
    </div>
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
        setCurrentPage: setCurrentPage,
        perPage: serverParams.take,
      }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'name', type: 'asc' },
}" styleClass="vgt-table" slot="outsideBody">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="customer.full_name" class="form-control" placeholder="Search Name" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'customer.full_name'
          " @input="(value) => updateFilters(column, $refs['customer.full_name'].value)" />
        <input ref="customer.email" class="form-control" placeholder="Search Email" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'customer.email'
          " @input="(value) => updateFilters(column, $refs['customer.email'].value)" />
        <input ref="customer.phoneNumber" class="form-control" placeholder="Search Phone Number" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'customer.phoneNumber'
          " @input="(value) => updateFilters(column, $refs['customer.phoneNumber'].value)" />
        <input ref="reward_catalogue.name" class="form-control" placeholder="Search Reward" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'reward_catalogue.name'
          " @input="(value) => updateFilters(column, $refs['reward_catalogue.name'].value)" />
        <select ref="status" class="form-select" placeholder="Cari productCategory" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'status'
          " @change="(value) => updateFilters(column, $refs['status'].value)">
          <option />
          <option v-for="(option, key) in optionsStatusPengiriman" v-bind:value="option.value" :key="key"
            v-text="option.label" />
        </select>
        <select ref="status_pengiriman" class="form-select" placeholder="Cari productCategory" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'status_pengiriman'
          " @change="(value) => updateFilters(column, $refs['status_pengiriman'].value)">
          <option />
          <option v-for="(option, key) in optionsStatusPengirimanMilestones" v-bind:value="option.value" :key="key"
            v-text="option.label" />
        </select>
        <div class="input-group" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'createdAt'
          ">
          <input type="date" ref="createdAt1" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
          <span class="input-group-text">
            -
          </span>
          <input type="date" ref="createdAt2" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
        </div>

        <select ref="reward_type_label" class="form-select" placeholder="Cari productCategory" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'reward_type_label'
          " @change="(value) => updateFilters(column, $refs['reward_type_label'].value)">
          <option />
          <option v-for="(option, key) in optionsRewards" v-bind:value="option.value" :key="key" v-text="option.label" />
        </select>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end" v-if="props.row.status !== 'Deleted'">
            <div class="col-auto" v-if="edit">
              <router-link :to="`/redeem/edit/${props.row.id}`" class="btn btn-lg text-dark" v-b-tooltip.hover
                title="Edit">
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>

            <div class="col-auto" v-if="deleteRedeem">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                @click.prevent="showAlertConfirm(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else :class="[props.row.status === 'Deleted' && 'text-muted']">
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import RedeemService from "@/service/redeem.service";
import redeemMilestoneService from "@/service/redeemmilestones.service";
import CustomerService from "@/service/customer.service";
import RewardService from "@/service/reward.service";
import tokenService from "@/service/token.service";
import formatUtc from "@/pages/Helper/formatUTC";
import moment from "moment";
import _ from 'lodash';
export default {
  data() {
    return {
      setCurrentPage: this.$store.state.redeem.serverParams.skip / this.$store.state.redeem.serverParams.take == 0 ?
        1 : this.$store.state.redeem.serverParams.skip / this.$store.state.redeem.serverParams.take + 1,
      permisions: tokenService.getPermission(),
      optionsStatusPengirimanMilestones: [
        {
          label: 'Waiting Approval',
          value: 'waiting_approval'
        },
        {
          label: 'Processed',
          value: 'processed'
        },
        {
          label: 'Delivered',
          value: 'delivered'
        },
        {
          label: 'Arrived',
          value: 'arrived'
        },
      ],
      optionsStatusPengiriman: [
        {
          label: 'Pending',
          value: 'pending'
        },
        {
          label: 'In Review',
          value: 'in_review'
        },
        {
          label: 'Approved',
          value: 'approve'
        },
        {
          label: 'Reject',
          value: 'reject'
        }
      ],
      optionsRewards: [
        {
          label: 'Point',
          value: 'point'
        },
        {
          label: 'Free Sample',
          value: 'free_sample'
        }
      ],
      isLoading: false,
      columns: [
        {
          label: "Nama",
          field: "customer.full_name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Email",
          field: "customer.email",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Phone",
          field: "customer.phoneNumber",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Reward Redeemed",
          field: "reward_catalogue.name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Reward Type",
          field: "reward_type_label",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Status Redeem",
          field: "status",
          thClass: "text-nowrap",
          tdClass: this.statusClass,
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Status Pengiriman",
          field: "status_pengiriman",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Submitted At",
          field: "createdAt",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "text-end text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: this.$store.state.redeem.serverParams,
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
    };
  },
  methods: {
    statusClass(row) {
      let statusClass = ''
      switch (row.status) {
        case 'Approved':
          statusClass = 'bg-green text-white align-middle';
          break;
        case 'Pending':
          statusClass = 'bg-orange text-white align-middle';
          break;
        case 'In Review':
          statusClass = 'bg-blue text-white align-middle';
          break;
        case 'Reject':
          statusClass = 'bg-red text-white align-middle';
          break;
        default:
          statusClass = 'bg-orange text-white align-middle';
          break;
      }
      return statusClass
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.$store.state.redeem.serverParams = Object.assign({}, this.$store.state.redeem.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ skip: 0 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams();
      this.loadItems();
    },

    onColumnFilter: _.debounce(function (params) {
      let payload = {}
      if (params.columnFilters.status && params.columnFilters.status != '') {
        payload.status = params.columnFilters.status ? params.columnFilters.status : ''
      } else {
        delete this.$store.state.redeem.serverParams.status
      }

      if (params.columnFilters['createdAt'] && params.columnFilters['createdAt'] != '') {
        let arrSplit = params.columnFilters['createdAt'].split('#')
        if (arrSplit[0] && arrSplit[1]) {
          let { startDate, endDate } = formatUtc(new Date(arrSplit[0]), new Date(arrSplit[1]))
          payload.createdAtStartDate = arrSplit[0] ? startDate : ''
          payload.createdAtEndDate = arrSplit[1] ? endDate : ''
        }
      } else {
        delete this.$store.state.redeem.serverParams.createdAtStartDate
        delete this.$store.state.redeem.serverParams.createdAtEndDate
      }

      if (params.columnFilters.status_pengiriman && params.columnFilters.status_pengiriman != '') {
        payload.delivery_status = params.columnFilters.status_pengiriman ? params.columnFilters.status_pengiriman : ''
      } else {
        delete this.$store.state.redeem.serverParams.delivery_status
      }

      if (params.columnFilters['customer.email']) {
        payload.email = params.columnFilters['customer.email']
      } else {
        delete this.$store.state.redeem.serverParams.email
      }

      if (params.columnFilters['customer.phoneNumber'] && params.columnFilters['customer.phoneNumber'] != '') {
        payload.phoneNumber = params.columnFilters['customer.phoneNumber']
      } else {
        delete this.$store.state.redeem.serverParams.phoneNumber
      }

      if (params.columnFilters['customer.full_name'] && params.columnFilters['customer.full_name'] != '') {
        payload.name = params.columnFilters['customer.full_name']
      } else {
        delete this.$store.state.redeem.serverParams.name
      }

      if (params.columnFilters['reward_catalogue.name'] && params.columnFilters['reward_catalogue.name']) {
        payload.rewardRedeemed = params.columnFilters['reward_catalogue.name']
      } else {
        delete this.$store.state.redeem.serverParams.rewardRedeemed
      }

      if (params.columnFilters['reward_type_label'] && params.columnFilters.reward_type_label != '') {
        payload.rewardType = params.columnFilters.reward_type_label ? params.columnFilters.reward_type_label : ''
      } else {
        delete this.$store.state.redeem.serverParams.rewardType
      }

      this.updateParams(payload);
      this.loadItems();
    }, 300),

    loadItems() {
      this.isLoading = true;
      RedeemService
        .getRedeemList(this.$store.state.redeem.serverParams)
        .then(async ({ data: { data } }) => {
          let { redeemMilestone, totalData } = data
          let arrData = []
          for (const [k, v] of Object.entries(redeemMilestone)) {
            let { customer_id, reward_id, status, id, redeem_milestone, reward_catalogue } = v
            let length = 0;
            if (redeem_milestone) {
              length = redeem_milestone.length - 1
            }
            arrData.push({
              ...v,
              status: (this.optionsStatusPengiriman.find((r) => r.value == status)).label,
              reward_type_label: (this.optionsRewards.find((r) => r.value == reward_catalogue.reward_type)).label,
              status_pengiriman: redeem_milestone.length > 0 && redeem_milestone[length].status ? this.optionsStatusPengirimanMilestones.find((r) => r.value == redeem_milestone[length].status).label : ''
            })
          }
          this.rows = arrData
          this.totalRecords = totalData
        }).catch((e) => {
          // console.log(e)
          this.rows = []
          if (e?.response?.statusCode !== 200) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadCustomerByID(id) {
      return CustomerService.getCustomerById(id)
        .then(async ({ data: { data } }) => {
          return Promise.resolve({ ...data })
          // let { dob, customer_child, ...newData } = data
          // for (const [k, v] of Object.entries(customer_child)) {
          //   let { dob } = v
          //   customer_child[k].dob = this.dateFormat(dob)
          // }
          // console.log(customer_child)
          // this.childrens = customer_child
          // this.detail = {
          //   ...newData,
          //   dob: this.dateFormat(dob)
          // }
        }).catch((e) => {
          return Promise.reject({ ...e })
        })
    },
    loadRewardById(id) {
      return RewardService
        .getRewardById(id)
        .then(({ data: { data } }) => {
          return Promise.resolve({ ...data })
          // this.totalRecords = data?.totalData
        }).catch((e) => {
          return Promise.reject({ ...e })
        })
    },
    loadRedeemMilestones(id) {
      this.isLoading = true;
      return redeemMilestoneService
        .getRedeemMilestonesList({ redeem_id: id, sort: 'desc' })
        .then(async ({ data: { data } }) => {
          let { redeemMilestone } = data
          return Promise.resolve({ milestone: redeemMilestone[0] })
        }).catch((e) => {
          // console.log(e)
          return Promise.reject({ ...e })
        })
    },
    downloadExcel() {
      this.isLoading = true;
      let { take, skip, sort, ...NewParams } = this.$store.state.redeem.serverParams
      let payload = {
        ...NewParams
      }
      RedeemService.exportExcel(payload)
        .then(({ data: { data } }) => {
          let { path } = data
          if (path) {
            window.open(this.$api_name + '/' + path, '_blank')
          }
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },

    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },

    doDelete(id) {
      this.isLoading = true;
      RedeemService
        .deleteRedeemById(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
          this.loadItems();
        }).finally(() => {
          this.isLoading = false;
        })
    },
  },
  computed: {
    exportExcel: function () {
      if (this.permisions.find((r) => r == 'Redeem Export')) {
        return true
      }
      return false;
    },
    edit: function () {
      if (this.permisions.find((r) => r == 'Redeem Patch')) {
        return true
      }
      return false;
    },
    deleteRedeem: function () {
      if (this.permisions.find((r) => r == 'Redeem Delete')) {
        return true
      }
      return false;
    },
  },
  mounted() {
    this.loadItems();
  },
};
</script>
