<template>
  <div>
    <div class="panel">
      <div class="panel-body p-0">
        <div class="table-responsive mb-0">
          <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
            :isLoading.sync="isLoading" :pagination-options="{
              enabled: true,
              dropdownAllowAll: false,
              setCurrentPage: setCurrentPage,
              perPage: serverParams.take,
            }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'product_title', type: 'asc' },
}" styleClass="vgt-table">
            <template slot="column-filter" slot-scope="{ column, updateFilters }">
              <input ref="banner_title" class="form-control" placeholder="Search Banner Title" v-if="column.filterOptions &&
                column.filterOptions.customFilter &&
                column.field === 'banner_title'
                " @input="(value) => updateFilters(column, $refs.banner_title.value)" />
              <div class="input-group" v-if="column.filterOptions &&
                column.filterOptions.customFilter &&
                column.field === 'createdAt'
                ">
                <input type="date" ref="createdAt1" class="form-control" placeholder="Search product"
                  @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
                <span class="input-group-text">
                  -
                </span>
                <input type="date" ref="createdAt2" class="form-control" placeholder="Search product"
                  @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
              </div>
              <select ref="product_category.category_name" class="form-select" placeholder="Cari productCategory" v-if="column.filterOptions &&
                column.filterOptions.customFilter &&
                column.field === 'product_category.category_name'
                " @change="(value) => updateFilters(column, $refs['product_category.category_name'].value)">
                <option />
                <option v-for="(option, key) in optionsProductCategory" v-bind:value="option.category_name" :key="key"
                  v-text="option.category_name" />
              </select>
              <select ref="status" class="form-select" placeholder="Cari productCategory" v-if="column.filterOptions &&
                column.filterOptions.customFilter &&
                column.field === 'status'
                " @change="(value) => updateFilters(column, $refs['status'].value)
    ">
                <option />
                <option v-for="(option, key) in optionsStatus" v-bind:value="option" :key="key" v-text="option" />
              </select>
              <select ref="location_banner_page" class="form-select" placeholder="Cari productCategory" v-if="column.filterOptions &&
                column.filterOptions.customFilter &&
                column.field === 'location_banner_page'
                " @change="(value) => updateFilters(column, $refs['location_banner_page'].value)">
                <option />
                <option v-for="(option, key) in optionsLocationPage" v-bind:value="option" :key="key" v-text="option" />
              </select>
            </template>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'action'">
                <div class="row g-2 align-items-center justify-content-end" v-if="props.row.status !== 'Deleted'">
                  <div class="col-auto" v-if="edit">
                    <router-link :to="`/sections/banner/edit/${props.row.id}`" class="btn btn-lg text-dark"
                      v-b-tooltip.hover title="Edit">
                      <i class="fas fa-pencil"></i>
                    </router-link>
                  </div>
                  <div class="col-auto" v-if="deleteBannerManagement">
                    <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                      @click.prevent="showAlertConfirm(props.row.id)">
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </div>
              </span>
              <span v-else :class="[props.row.status === 'Deleted' && 'text-muted']">
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
            <!-- <tr v-for="item in rows" :key="item.id" class="bg-white">
                <td>{{ item.banner_title }}</td>
                <td>{{ isoToDate(item.updatedAt) }}</td>
                <td :class="[item.status === 'Deleted' && 'text-muted']">{{ item.status }}</td>
                <td>
                  <div class="row g-2 align-items-center justify-content-end" v-if="item.status !== 'Deleted'">
                    <div class="col-auto">
                      <router-link :to="`/sections/banner/edit/${item.id}`" class="btn btn-lg text-dark" v-b-tooltip.hover
                        title="Edit">
                        <i class="fas fa-pencil"></i>
                      </router-link>
                    </div>
                    <div class="col-auto">
                      <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move">
                        <i class="fa-solid fa-arrows-up-down"></i>
                      </button>
                    </div>
                    <div class="col-auto">
                      <button type="button" @click.prevent="showAlertConfirm(item.id)" class="btn btn-lg text-dark"
                        v-b-tooltip.hover title="Move to Trash">
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </td>
              </tr> -->
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import BannerService from "@/service/banner.service";
import tokenService from "@/service/token.service";
import moment from "moment";
import _ from 'lodash';
export default {
  components: {
    draggable,
  },
  data() {
    return {
      setCurrentPage: this.$store.state.banner.serverParams.skip / this.$store.state.banner.serverParams.take == 0 ?
        1 : this.$store.state.banner.serverParams.skip / this.$store.state.banner.serverParams.take + 1,
      optionsStatus: ['Publish', 'Draft'],
      isLoading: false,
      dragging: false,
      rows: [],
      optionsLocationPage: [
        'homepage'
        , 'product-list'
        , 'reseller'
        , 'article-list'
        , 'promo-list'
        , 'career-list'
        , 'free-sample'
        , 'birthday'
        , 'claim-point'
        , 'point-history'
        , 'point-store'
        , 'redeem-tracking'
        , 'contact-us'],
      columns: [
        {
          label: "Banner Name",
          field: "banner_title",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Location",
          field: "location_banner_page",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Published Date",
          field: "createdAt",
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
          filterOptions: {
            styleClass: 'vgt-relative',
            customFilter: true,
            enabled: true,
            filterValue: "",
            filterFn: this.filterFn,
          },
        },
        {
          label: "Status",
          field: "status",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          width: "200px",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      totalRecords: 0,
      serverParams: {
        skip: 0,
        take: 10,
        sort: 'desc'
      },
      permisions: tokenService.getPermission()
    };
  },
  methods: {

    updateParams(newProps) {
      this.$store.state.banner.serverParams = Object.assign({}, this.$store.state.banner.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ skip: 0 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({});
      this.loadItems();
    },

    onColumnFilter: _.debounce(function (params) {
      let payload = {}
      if (params.columnFilters['createdAt'] && params.columnFilters['createdAt'] != '#') {
        let arrSplit = params.columnFilters['createdAt'].split('#')
        if (arrSplit[0] && arrSplit[1]) {
          payload.createdAtStartDate = arrSplit[0] ? moment(arrSplit[0]).startOf('day').toISOString() : ''
          payload.createdAtEndDate = arrSplit[1] ? moment(arrSplit[1]).endOf('day').toISOString() : ''
        }
      } else {
        delete this.$store.state.banner.serverParams.createdAtStartDate
        delete this.$store.state.banner.serverParams.createdAtEndDate
      }

      if (params.columnFilters.status && params.columnFilters.status != '') {
        payload.status = params.columnFilters.status ? params.columnFilters.status : ''
      } else {
        delete this.$store.state.banner.serverParams.status
      }
      payload.locationBanner = params.columnFilters.location_banner_page ? params.columnFilters.location_banner_page : ''
      payload.bannerTitle = params.columnFilters.banner_title ? params.columnFilters.banner_title : ''
      this.updateParams(payload);
      this.loadItems();
    }, 300),
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    checkMove() {
      // console.log("dragging");
    },
    loadItems() {
      this.isLoading = true;
      BannerService
        .getBannerList(this.$store.state.banner.serverParams)
        .then(({ data: { data } }) => {
          this.rows = data.banner
          this.totalRecords = data.totalData
        }).catch((e) => {
          if (e.response.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },

    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },
    doDelete(id) {
      this.isLoading = true;
      BannerService
        .deleteBannerById(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },
    isoToDate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss")
    }
  },
  computed: {
    add: function () {
      if (this.permisions.find((r) => r == 'BannerManagement Add')) {
        return true
      }
      return false;
    },
    edit: function () {
      if (this.permisions.find((r) => r == 'BannerManagement Patch')) {
        return true
      }
      return false;
    },
    deleteBannerManagement: function () {
      if (this.permisions.find((r) => r == 'BannerManagement Delete')) {
        return true
      }
      return false;
    },
  },
  mounted() {
    this.loadItems();
  },
};
</script>
