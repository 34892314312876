<template>
  <form @submit.prevent="doSave" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">Home Page</h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="mb-15px">
          <input type="text" class="form-control form-control-lg" placeholder="Page Title" v-model="page.title" />
          <span class="text-danger" v-if="$v.page.title.$error">Title is required</span>
        </div>
        <div v-if="activeTemplate === 'Homepage'">
          <panel title="Section 1 - Banner Slider" variant="inverse" :is-collapse="true"
            v-model="page.homepage.sections.one.isShow">
            <panel v-for="(item, index) in page.homepage.sections.one.items" :key="index" :index="index"
              :title="`Banner ${index + 1}`" variant="grey" :is-collapse="true" :checkbox-hidden="false"
              :show-collapse="index > 0 ? false : true" v-model="item.isShow" :checkbox-name="`sliderItem-${index}`"
              :has-remove="true" @onRemove="removeBanner(index)">
              <div class="row">
                <div class="col-md-12">
                  <label class="form-label">Main Image</label>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-15px">
                      <p class="mb-2">dekstop version : 1440x500 px, max. 1mb, (jpeg, jpg, png)</p>
                      <p class="mb-2"></p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-15px">
                      <p class="mb-3">(mobile with text version : 375x375 px, max. 1mb, jpeg, jpg, png)</p>
                      <p class="mb-3">(mobile without text version : 375x725 px, max. 1mb, jpeg, jpg, png)</p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <UploadImage v-model="item.images.desktop" :src="item.images.desktopSrc ? item.images.desktopSrc : ''"
                      :dimensions="{ width: 1440, height: 500 }" size="1000" @image-err-message="imgErrMessage" />
                  </div>
                  <div class="col-md-6">
                    <UploadImage v-model="item.images.mobile" :src="item.images.mobileSrc ? item.images.mobileSrc : ''"
                      size="1000" @image-err-message="imgErrMessage" />
                  </div>
                </div>


                <div class="col-md-6">
                  <div class="mb-15px">
                    <label class="form-label">Dekstop Alt Text</label>
                    <input type="text" v-model="item.images.alt_desktop_image" class="form-control"
                      placeholder="Alt text" />
                    <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-15px">
                    <label class="form-label">Mobile Alt Text</label>
                    <input type="text" v-model="item.images.alt_mobile_image" class="form-control"
                      placeholder="Alt text" />
                    <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-15px">
                    <label class="form-label">Title</label>
                    <input type="text" class="form-control" placeholder="" v-model="item.title" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-15px">
                    <label class="form-label">Caption</label>
                    <input type="text" class="form-control" placeholder="GOO.N Baby Super Premium"
                      v-model="item.caption" />
                  </div>
                </div>
              </div>
              <div class="mb-15px">
                <label class="form-label">CTA #{{ index + 1 }} URL</label>
                <input type="url" class="form-control" placeholder="https://goon.cms/article-selengkapnya"
                  v-model="item.cta_url" />
              </div>
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Urutan</label>
                  <input type="text" v-model="item.order" class="form-control" placeholder="Urutan" />
                  <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
                </div>
              </div>
            </panel>
            <button @click="addBanner()" class="btn btn-outline-primary w-100 d-block text-start" type="button">
              <i class="fa-solid fa-plus"></i> Add new Banner
            </button>
            <span class="text-danger" v-if="$v.page.homepage.sections.one.items.$error">Banner is required</span>
            <br>
            <div class="row justify-content-end">
              <div class="col-auto">
                <button @click.prevent="postBanner" class="btn btn-primary">
                  <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                  Publish
                </button>
              </div>
            </div>
          </panel>
          <panel title="Section 2 - Produk Terbaik dari Goon" variant="inverse" :is-collapse="true"
            v-model="page.homepage.sections.two.isShow">
            <div class="row">
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Select Product Parent Category</label>
                  <v-select :options="optionsProductCategory" v-model="selectedProduct1" label="parent_name" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Select Product Parent Category</label>
                  <v-select :options="optionsProductCategory" v-model="selectedProduct2" label="parent_name" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Select Product Parent Category</label>
                  <v-select :options="optionsProductCategory" v-model="selectedProduct3" label="parent_name" />
                </div>
              </div>
            </div>
            <span class="mb-15px text-danger" v-if="$v.page.homepage.sections.two.categories.$error">Product Parent
              Category
              Required</span>
            <br>
            <div class="row">
              <div class="col-md-4">
                <div class="mb-15px" v-for="( item, index ) in  page.homepage.sections.two.products.one " :key="index"
                  :index="index">
                  <label class="form-label">Select Product </label>
                  <v-select :options="optionsProduct.one" label="product_title" v-model="item.item" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-15px" v-for="( item, index ) in  page.homepage.sections.two.products.two " :key="index"
                  :index="index">
                  <label class="form-label">Select Product </label>
                  <v-select :options="optionsProduct.two" label="product_title" v-model="item.item" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-15px" v-for="( item, index ) in  page.homepage.sections.two.products.three " :key="index"
                  :index="index">
                  <label class="form-label">Select Product </label>
                  <v-select :options="optionsProduct.three" label="product_title" v-model="item.item" />
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <button @click="addProduct" class="btn btn-outline-primary w-100 d-block text-start" type="button">
                <i class="fa-solid fa-plus"></i> Add Product
              </button>
            </div>
            <br>
            <div class="row justify-content-end">
              <div class="col-auto">
                <button @click.prevent="postProduct" class="btn btn-primary">
                  <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                  Publish
                </button>
              </div>
            </div>
          </panel>
          <panel title="Section 3 - E-commerce Display" variant="inverse" :is-collapse="true"
            v-model="page.homepage.sections.three.isShow">
            <panel v-for="( item, index ) in  page.homepage.sections.three.ecommerce " :key="index" :index="index"
              :title="`Select E-commerce ${index + 1}`" variant="grey" :is-collapse="true" :checkbox-hidden="false"
              :show-collapse="index > 0 ? false : true" v-model="item.isShow" :checkbox-name="`sliderItem-${index}`"
              :has-remove="true" @onRemove="removeEcommerce(index)">
              <v-select :options="optionsEcomerce" v-model="item.item" label="ecommerce_name" />
            </panel>
            <button @click="addEcommerce()" class="btn btn-outline-primary w-100 d-block text-start" type="button">
              <i class="fa-solid fa-plus"></i> Add new E-commerce List
            </button>
            <span class="text-danger" v-if="$v.page.homepage.sections.three.ecommerce.$error">E-commerce is
              required</span>
            <br>
            <div class="row justify-content-end">
              <div class="col-auto">
                <button @click.prevent="postEcommerce" class="btn btn-primary">
                  <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                  Publish
                </button>
              </div>
            </div>
          </panel>
          <panel title="Section 4 - Goo.N Club" variant="inverse" :is-collapse="true"
            v-model="page.homepage.sections.six.isShow">
            <div class="row">
              <div class="col-md-12">
                <label class="form-label">Main Image</label>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <p class="mb-2">dekstop version : 1238x449 px, max. 1mb, (jpeg, jpg, png)</p>
                </div>
                <div class="col-md-6">
                  <p class="mb-3">mobile without text version : 709x798 px, max. 1mb, (jpeg, jpg, png)</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <UploadImage v-model="page.homepage.sections.six.desktop"
                    :src="page.homepage.sections.six.desktopSrc ? page.homepage.sections.six.desktopSrc : ''"
                    :dimensions="{ width: 1238, height: 449 }" size="1000" @image-err-message="imgErrMessage" />
                  <span class="text-danger" v-if="$v.page.homepage.sections.six.desktop.$error">Image is
                    required</span>
                </div>
                <div class="col-md-6">
                  <UploadImage v-model="page.homepage.sections.six.mobile"
                    :src="page.homepage.sections.six.mobileSrc ? page.homepage.sections.six.mobileSrc : ''" size="1000"
                    :dimensions="{ width: 709, height: 798 }" @image-err-message="imgErrMessage" />
                  <span class="text-danger" v-if="$v.page.homepage.sections.six.mobile.$error">Image is
                    required</span>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="mb-15px">
                    <label class="form-label">Dekstop Alt Text</label>
                    <input type="text" v-model="page.homepage.sections.six.alt_desktop_image" class="form-control"
                      placeholder="Alt text" />
                    <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-15px">
                    <label class="form-label">Mobile Alt Text</label>
                    <input type="text" v-model="page.homepage.sections.six.alt_mobile_image" class="form-control"
                      placeholder="Alt text" />
                    <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-15px">
              <label class="form-label">CTA URL</label>
              <input type="url" class="form-control" placeholder="www.goon.co.id"
                v-model="page.homepage.sections.six.cta_url" />
              <span class="text-danger" v-if="$v.page.homepage.sections.six.cta_url.$error">CTA is
                required</span>
            </div>
            <div class="row justify-content-end">
              <div class="col-auto">
                <button @click.prevent="postEnchancement" class="btn btn-primary">
                  <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                  Publish
                </button>
              </div>
            </div>
          </panel>
          <panel title="Section 5 - Promotion" variant="inverse" :is-collapse="true"
            v-model="page.homepage.sections.four.isShow">
            <div class="row">
              <div class="col-md-6" v-for="( item, index ) in  page.homepage.sections.four.promotions " :key="index">
                <div class="mb-15px">
                  <label class="form-label">Select Promotion {{ index + 1 }}</label>
                  <v-select :options="optionsPromo" v-model="item.item" label="promo_title" />
                </div>
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="col-auto">
                <button @click.prevent="postPromo" class="btn btn-primary">
                  <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                  Publish
                </button>
              </div>
            </div>
          </panel>
          <panel title="Section 6 - News & Event" variant="inverse" :is-collapse="true"
            v-model="page.homepage.sections.five.isShow">
            <div class="mb-15px" v-for="( item, index ) in  page.homepage.sections.five.news " :key="index">
              <label class="form-label">Select News {{ index + 1 }}</label>
              <v-select :options="optionsPage" v-model="item.item" label="title" />
            </div>
            <span class="text-danger" v-if="$v.page.homepage.sections.five.news.$error">News is
              required</span>
            <div class="row justify-content-end">
              <div class="col-auto">
                <button @click.prevent="postArticle" class="btn btn-primary">
                  <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                  Publish
                </button>
              </div>
            </div>
          </panel>
        </div>
      </div>
      <div v-if="page.author" class="col-lg-3 col-md-4">
        <div class="panel panel-sticky">
          <div class="panel-body">
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Published Date</p>
              <p class="mb-0">{{ page.publishedDate ? dateFormat(page.publishedDate) : '' }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Last Modified Date</p>
              <p class="mb-0">{{ page.lastModified ? dateFormat(page.lastModified) : '' }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Author</p>
              <p class="mb-0">{{ page.author }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Publish
          </button>
        </div>
      </div>
    </div> -->
  </form>
</template>

<script>
import { defaultToolbar } from "../../config/Helpers";
import UploadImage from "../../components/upload/Image.vue";
import { required, minLength } from 'vuelidate/lib/validators'
import ProductCategoryService from "@/service/productcategory.service";
import ProductService from "@/service/product.service";
import EcommerceService from "@/service/ecomerce.service";
import PromoService from "@/service/promo.service";
import PostArticles from "@/service/post.service";
import dateFormat from "@/pages/Helper/formate_date";
import parentcategoryService from "@/service/parentcategory.service";

export default {
  components: {
    UploadImage,
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;
    return {
      dateFormat,
      activeTemplate: "Homepage",
      customToolbar: defaultToolbarEditor,
      selectedProduct1: null,
      selectedProduct2: null,
      selectedProduct3: null,
      optionsProductCategory: [],
      optionsProduct: {
        one: [],
        two: [],
        three: []
      },
      optionsEcomerce: [],
      optionsPromo: [],
      optionsPage: [],
      page: {
        id: detail ? detail.id : null,
        title: detail ? detail.title : null,
        template: detail ? detail.template : "Homepage",
        publishedDate: detail ? detail.publishedDate : null,
        lastModified: detail ? detail.lastModified : null,
        author: detail ? detail.author : null,
        homepage: {
          sections: {
            one: {
              isShow: true,
              title: "",
              items: [],
            },
            two: {
              isShow: true,
              categories: {
                one: {},
                two: {},
                three: {}
              },
              products: {
                one: [],
                two: [],
                three: []
              },
            },
            three: {
              isShow: true,
              ecommerce: []
            },
            four: {
              isShow: true,
              promotions: [
                {
                  id: 0,
                  item: '',
                },
                {
                  id: 1,
                  item: '',
                },
              ]
            },
            five: {
              isShow: true,
              news: [
                {
                  id: 0,
                  item: '',
                },
                {
                  id: 1,
                  item: '',
                },
                {
                  id: 2,
                  item: '',
                },
                {
                  id: 3,
                  item: '',
                },
              ]
            },
            six: {
              isShow: true,
              alt: '',
              desktop: null,
              desktopSrc: '',
              alt_desktop_image: null,
              mobile: null,
              mobileSrc: '',
              cta_url: null,
              alt_mobile_image: null,
            },
          },
        },
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
  },
  watch: {
    'detailData'(newVal) {
      let { selected, product } = newVal
      if (selected) {
        let i = 0
        for (const v in selected) {
          if (i === 0) {
            this.selectedProduct1 = selected[v]
          } else if (i === 1) {
            this.selectedProduct2 = selected[v]
          } else {
            this.selectedProduct3 = selected[v]
          }
          i++
        }
      }
      this.page = newVal
    },
    'selectedProduct1'(v) {
      this.page.homepage.sections.two.categories.one = v
      if (v) {
        this.loadProduct(1)
      } else {
        this.optionsProduct.one = []
      }
    },
    'selectedProduct2'(v) {
      this.page.homepage.sections.two.categories.two = v
      if (v) {
        this.loadProduct(2)
      } else {
        this.optionsProduct.two = []
      }
    },
    'selectedProduct3'(v) {
      this.page.homepage.sections.two.categories.three = v
      if (v) {
        this.loadProduct(3)
      } else {
        this.optionsProduct.three = []
      }
    }
  },
  mounted() {
    this.loadItems()
    this.loadItemsParent()
  },
  methods: {
    addBanner() {
      this.page.homepage.sections.one.items.push({
        isShow: true,
        title: "",
        caption: "",
        cta: "",
        images: {
          desktop: null,
          mobile: null,
        },
      });
    },
    removeBanner(index) {
      this.page.homepage.sections.one.items.splice(index, 1);
    },

    removeEcommerce(index) {
      this.page.homepage.sections.three.ecommerce.splice(index, 1);
    },

    addEcommerce() {
      this.page.homepage.sections.three.ecommerce.push({
        id: '',
        item: '',
      });
    },
    addProduct() {
      this.page.homepage.sections.two.products.one.push({
        item: '',
      });
      this.page.homepage.sections.two.products.two.push({
        item: '',
      });
      this.page.homepage.sections.two.products.three.push({
        item: '',
      });
    },
    pageTemplateSelected(value) {
      this.checkSelectedTemplate(value);
    },
    doSave() {

      this.$v.$touch();

      if (!this.$v.$invalid) {
        const payload = {
          page: this.page,
        };

        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
    loadItems() {
      // this.loadProductCategory()
      // this.loadProduct()
      this.loadEcommerce()
      this.loadPromo()
      this.loadPageNews()
    },
    loadProductCategory() {
      this.isLoading = true;
      ProductCategoryService
        .getProductCategoryList(this.serverParams)
        .then(({ data: { data } }) => {
          this.optionsProductCategory = data.productCategory
        }).catch((e) => {
          if (e.response.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.optionsProductCategory = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadProduct(type) {
      this.isLoading = true;
      let payload = {}
      if (type === 1) {
        payload.parentProductCategory = this.selectedProduct1.parent_name
      } else if (type === 2) {
        payload.parentProductCategory = this.selectedProduct2.parent_name
      } else if (type === 3) {
        payload.parentProductCategory = this.selectedProduct3.parent_name
      }
      ProductService
        .getProductList(payload)
        .then(({ data: { data } }) => {
          let { product } = data
          if (type === 1) {
            this.optionsProduct.one = product
          } else if (type === 2) {
            this.optionsProduct.two = product
          } else if (type === 3) {
            this.optionsProduct.three = product
          }
        }).catch((e) => {
          if (e.response.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.optionsProduct = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadEcommerce() {
      this.isLoading = true;
      EcommerceService
        .getEcommerceList({})
        .then(({ data: { data } }) => {
          this.optionsEcomerce = data.ecommerce
        }).catch((e) => {
          if (e.response.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.optionsEcomerce = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadPromo() {
      this.isLoading = true;
      PromoService.getPromoListFilterActive({ status: 'Publish' })
        .then(({ data: { data } }) => {
          this.optionsPromo = data.promo
        }).catch((e) => {
          if (e.response.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.optionsPromo = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadPageNews() {
      this.isLoading = true;
      PostArticles
        .getArticleList({})
        .then(({ data: { data } }) => {
          this.optionsPage = data.postArticle
        }).catch((e) => {
          if (e.response.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.optionsPage = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    imgErrMessage(mssg) {
      this.$notify({
        type: "error",
        title: "Image Notification",
        text: mssg,
      });
    },
    loadItemsParent() {
      this.isLoading = true;
      parentcategoryService
        .getParentCategoryList({})
        .then(({ data: { data } }) => {
          if (data.ParentProductCategory) {
            this.optionsProductCategory = data.ParentProductCategory
          } else {
            this.optionsProductCategory = []
          }
        }).catch((e) => {
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    postBanner() {
      this.$v.page.homepage.sections.one.$touch();
      this.$v.page.title.$touch();
      if (!this.$v.page.homepage.sections.one.$invalid &&
        !this.$v.page.title.$invalid) {
        const payload = {
          page: this.page,
          section: 'one'
        };
        // console.log(payload)
        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
    postProduct() {
      this.$v.page.homepage.sections.one.$touch()
      this.$v.page.homepage.sections.two.categories.$touch()
      this.$v.page.title.$touch()

      if (
        !this.$v.page.homepage.sections.one.$invalid &&
        !this.$v.page.homepage.sections.two.categories.$invalid &&
        !this.$v.page.title.$invalid
      ) {
        const payload = {
          page: this.page,
          section: 'two'
        };
        // console.log(payload)
        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
    postEcommerce() {
      this.$v.page.homepage.sections.one.$touch()
      this.$v.page.homepage.sections.three.$touch()
      this.$v.page.title.$touch()
      if (!this.$v.page.homepage.sections.one.$invalid &&
        !this.$v.page.homepage.sections.three.$invalid &&
        !this.$v.page.title.$invalid) {
        const payload = {
          page: this.page,
          section: 'three'
        };
        // console.log(payload)
        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
    postPromo() {
      this.$v.page.homepage.sections.one.$touch()
      this.$v.page.title.$touch()
      if (!this.$v.page.homepage.sections.one.$invalid &&
        !this.$v.page.title.$invalid) {
        const payload = {
          page: this.page,
          section: 'four'
        };
        // console.log(payload)
        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
    postArticle() {
      this.$v.page.homepage.sections.one.$touch()
      this.$v.page.homepage.sections.five.$touch()
      this.$v.page.title.$touch()
      if (!this.$v.page.homepage.sections.one.$invalid &&
        !this.$v.page.homepage.sections.five.$invalid &&
        !this.$v.page.title.$invalid) {
        const payload = {
          page: this.page,
          section: 'five'
        };
        // console.log(payload)
        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },

    postEnchancement() {
      this.$v.page.homepage.sections.one.$touch()
      this.$v.page.homepage.sections.six.$touch()
      this.$v.page.title.$touch()
      if (!this.$v.page.homepage.sections.one.$invalid &&
        !this.$v.page.homepage.sections.six.$invalid &&
        !this.$v.page.title.$invalid) {
        const payload = {
          page: this.page,
          section: 'six'
        };
        // console.log(payload)
        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    }
  },
  validations: {
    page: {
      title: { required },
      template: { required },
      homepage: {
        sections: {
          one: {
            items: {
              required,
              $each: {
                // title: { required },
                // caption: { required },
                // cta: { required },
              },
            },
          },
          two: {
            categories: {
              valid: (value) => {
                let { one, two, three } = value
                if (one || two || three) {
                  return true
                } else {
                  return false
                }
              },
            }
          },
          three: {
            ecommerce: {
              required,
              $each: {
                item: { required }
              }
            }
          },
          five: {
            news: {
              required,
              $each: {
                item: { required }
              }
            }
          },
          six: {
            desktop: { required },
            mobile: { required },
            cta_url: { required },
          },
        },
      },
    },
  }
};
</script>
