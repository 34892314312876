<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">Career List</h1>
        <!-- END page-header -->
      </div>
      <div class="col-auto mb-3" v-if="add">
        <router-link to="/career/add">
          <a class="btn btn-outline-primary"> Add New Position</a>
        </router-link>
      </div>
    </div>

    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
        setCurrentPage: setCurrentPage,
        perPage: serverParams.take,
      }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'position', type: 'asc' },
}" styleClass="vgt-table" slot="outsideBody">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="position" class="form-control" placeholder="Search user" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'position'
          " @input="(value) => updateFilters(column, $refs.position.value)" />
        <input ref="career_category.category_name" class="form-control" placeholder="Search category" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'career_category.category_name'
          " @input="(value) => updateFilters(column, $refs['career_category.category_name'].value)" />
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end" v-if="props.row.status !== 'Deleted'">
            <div class="col-auto" v-if="edit">
              <router-link :to="`/career/edit/${props.row.id}`" class="btn btn-lg text-dark" v-b-tooltip.hover
                title="Edit">
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>
            <div class="col-auto" v-if="deleteCareer">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                @click.prevent="showAlertConfirm(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else :class="[props.row.status === 'Deleted' && 'text-muted']">
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import CareerService from "@/service/career.service";
import CareerCategoryService from "@/service/careercategory.service";
import tokenService from "@/service/token.service";
import _ from 'lodash';
export default {
  data() {
    return {
      setCurrentPage: this.$store.state.career.serverParams.skip / this.$store.state.career.serverParams.take == 0 ?
        1 : this.$store.state.career.serverParams.skip / this.$store.state.career.serverParams.take + 1,
      isLoading: false,
      columns: [
        {
          label: "Job Position",
          field: "position",
          thClass: "text-nowrap",
          tdClass: "align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Category",
          field: "career_category.category_name",
          thClass: "text-nowrap",
          tdClass: "align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        // {
        //   label: "Status",
        //   field: "status",
        //   thClass: "text-nowrap",
        //   tdClass: "text-nowrap align-middle",
        // },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "text-end text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: this.$store.state.career.serverParams,
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
      permisions: tokenService.getPermission()
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.$store.state.career.serverParams = Object.assign({}, this.$store.state.career.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ skip: 0 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.loadItems();
    },

    onColumnFilter: _.debounce(function (params) {
      this.updateParams({
        search: params.columnFilters.position,
        category: params.columnFilters['career_category.category_name'],
      });
      this.loadItems();
    }, 300),
    loadItems() {
      CareerService
        .getCareerList(this.$store.state.career.serverParams)
        .then(async ({ data: { data } }) => {
          this.rows = data.career
          this.totalRecords = data.totalCareer
        }).catch((e) => {
          if (e.response?.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },

    doDelete(id) {
      this.isLoading = true;
      CareerService
        .deleteCareerById(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },

    getCategoryCareerById(id) {
      return CareerCategoryService.getCareerCategoryById(id)
        .then(async ({ data: { data } }) => {
          return data ? data : ''
        }).catch((e) => '')
    },
  },
  computed: {
    add: function () {
      if (this.permisions.find((r) => r == 'Career Add')) {
        return true
      }
      return false;
    },
    edit: function () {
      if (this.permisions.find((r) => r == 'Career Patch')) {
        return true
      }
      return false;
    },
    deleteCareer: function () {
      if (this.permisions.find((r) => r == 'Career Delete')) {
        return true
      }
      return false;
    },
  },
  mounted() {
    this.loadItems();
  },
};
</script>
