<template>
  <div>
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
        setCurrentPage: setCurrentPage,
        perPage: serverParams.take,
      }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'product_title', type: 'asc' },
}" styleClass="vgt-table">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="product_title" class="form-control" placeholder="Search product" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'product_title'
          " @input="(value) => updateFilters(column, $refs.product_title.value)" />

        <div class="input-group" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'createdAt'
          ">
          <input type="date" ref="createdAt1" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
          <span class="input-group-text">
            -
          </span>
          <input type="date" ref="createdAt2" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
        </div>
        <select ref="product_category.category_name" class="form-select" placeholder="Cari productCategory" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'product_category.category_name'
          " @change="(value) => updateFilters(column, $refs['product_category.category_name'].value)">
          <option />
          <option v-for="(option, key) in optionsProductCategory" v-bind:value="option.category_name" :key="key"
            v-text="option.category_name" />
        </select>
        <select ref="status" class="form-select" placeholder="Cari productCategory" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'status'
          " @change="(value) => updateFilters(column, $refs['status'].value)
    ">
          <option />
          <option v-for="(option, key) in optionsStatus" v-bind:value="option" :key="key" v-text="option" />
        </select>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end" v-if="props.row.status !== 'Deleted'">
            <div class="col-auto" v-if="edit">
              <router-link :to="`/products/edit/${props.row.id}`" class="btn btn-lg text-dark" v-b-tooltip.hover
                title="Edit">
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>
            <!-- <div class="col-auto">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Duplicate">
                <i class="fas fa-copy"></i>
              </button>
            </div> -->
            <div class="col-auto" v-if="deleteProduct">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                @click.prevent="showAlertConfirm(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else :class="[props.row.status === 'Deleted' && 'text-muted']">
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import ProductService from "@/service/product.service";
import productcategoryService from "@/service/productcategory.service";
import tokenService from "@/service/token.service";
import moment from "moment";
import _ from 'lodash';
export default {
  data() {
    return {
      setCurrentPage: this.$store.state.product.serverParams.skip / this.$store.state.product.serverParams.take == 0 ?
        1 : this.$store.state.product.serverParams.skip / this.$store.state.product.serverParams.take + 1,
      dateRange: {
        opens: "right",
        singleDatePicker: false,
        timePicker: false,
        timePicker24Hour: false,
        showWeekNumbers: false,
        showDropdowns: false,
        autoApply: false,
        linkedCalendars: false,
        range: {
          startDate: Date.now(),
          endDate: Date.now(),
        },
      },
      optionsProductCategory: [],
      optionsStatus: ['Publish', 'Draft'],
      isLoading: false,
      columns: [
        {
          label: "Product Name",
          field: "product_title",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Published Date",
          field: "createdAt",
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
          filterOptions: {
            styleClass: 'vgt-relative',
            customFilter: true,
            enabled: true,
            filterValue: "",
            filterFn: this.filterFn,
          },
        },
        {
          label: "Category",
          field: "product_category.category_name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            styleClass: 'vgt-relative',
            customFilter: true,
            enabled: true,
            filterValue: "",
            filterFn: this.filterFn,
          },
        },
        {
          label: "Status",
          field: "status",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            styleClass: 'vgt-relative',
            customFilter: true,
            enabled: true,
            filterValue: "",
            filterFn: this.filterFn,
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: this.$store.state.product.serverParams,
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
      permisions: tokenService.getPermission(),
    };
  },
  methods: {
    dateFormat(classes, date) {
      return moment(date).format('DD/MM/YYYY')
    },
    filterFn: function (data, filterString) {
      var x = parseInt(filterString)
      return data >= x - 5 && data <= x + 5;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.$store.state.product.serverParams = Object.assign({}, this.$store.state.product.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ skip: 0 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({});
      this.loadItems();
    },

    onColumnFilter: _.debounce(function (params) {
      let payload = {}
      if (params.columnFilters['createdAt'] && params.columnFilters['createdAt'] != '#') {
        let arrSplit = params.columnFilters['createdAt'].split('#')
        if (arrSplit[0] && arrSplit[1]) {
          payload.createdAtStartDate = arrSplit[0] ? moment(arrSplit[0]).startOf('day').toISOString() : ''
          payload.createdAtEndDate = arrSplit[1] ? moment(arrSplit[1]).endOf('day').toISOString() : ''
        }
      } else {
        delete this.$store.state.product.serverParams.createdAtStartDate
        delete this.$store.state.product.serverParams.createdAtEndDate
      }

      if (params.columnFilters.status && params.columnFilters.status != '') {
        payload.status = params.columnFilters.status ? params.columnFilters.status : ''
      } else {
        delete this.$store.state.product.serverParams.status
      }

      if (params.columnFilters['product_category.category_name'] && params.columnFilters['product_category.category_name'] != '') {
        payload.productCategory = params.columnFilters['product_category.category_name'] ? params.columnFilters['product_category.category_name'] : ''
      } else {
        delete this.$store.state.product.serverParams.productCategory
      }

      if (params.columnFilters.product_title && params.columnFilters.product_title != '') {
        payload.productTitle = params.columnFilters.product_title ? params.columnFilters.product_title : ''
      } else {
        delete this.$store.state.product.serverParams.productTitle
      }

      this.updateParams(payload);
      this.loadItems();
    }, 300),
    loadItems() {
      ProductService
        .getProductList(this.$store.state.product.serverParams)
        .then(async ({ data: { data } }) => {
          this.rows = data.product
          this.totalRecords = data.totalData
        }).catch((e) => {
          if (e.response.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadItemsCategory() {
      productcategoryService
        .getProductCategoryList()
        .then(async ({ data: { data } }) => {
          this.optionsProductCategory = data.productCategory
        }).catch((e) => {
          if (e.response.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },

    doDelete(id) {
      this.isLoading = true;
      ProductService
        .deleteProductById(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
          this.loadItems();
        }).finally(() => {
          this.isLoading = false;
        })
    },
  },
  computed: {
    add: function () {
      if (this.permisions.find((r) => r == 'Product Add')) {
        return true
      }
      return false;
    },
    edit: function () {
      if (this.permisions.find((r) => r == 'Product Patch')) {
        return true
      }
      return false;
    },
    deleteProduct: function () {
      if (this.permisions.find((r) => r == 'Product Delete')) {
        return true
      }
      return false;
    },
  },
  mounted() {
    this.loadItems();
    this.loadItemsCategory();
  },
};
</script>
