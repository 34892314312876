import api from "./api";

class SiteSettingService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('sitesetting')
    }

    postSiteSetting(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getSiteSettingById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchSiteSetting(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteSiteSettingById(serverParams) {
        return this.api.delete("/" + serverParams);
    }

    getSiteSettingCaller(serverParams) {
        return this.api.get("/caller/" + serverParams);
    }

    getSiteSettingGrup(serverParams) {
        return this.api.get("/grup/" + serverParams);
    }
}

export default new SiteSettingService();