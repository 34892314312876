<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">Contact Us Data</h1>
        <!-- END page-header -->
      </div>
      <div class="col-auto mb-3">
        <div class="row">
          <div class="col-auto mb-3">
            <div class="col-auto" v-if="exportExcel">
              <a class="btn btn-outline-primary" @click.prevent="downloadExcel">
                <i class="fa-solid fa-file-import">
                </i>
                Export
              </a>
            </div>
          </div>
          <div class="col-auto" v-if="add">
            <router-link to="/pages/contact-us/add">
              <a class="btn btn-outline-primary">Add Data</a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
        setCurrentPage: setCurrentPage,
        perPage: serverParams.take,
      }" :columns="columns" :rows="rows" :sort-options="{
  enabled: true,
  initialSortBy: { field: 'name', type: 'asc' },
}" styleClass="vgt-table" slot="outsideBody">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="name" class="form-control" placeholder="Search Name" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'name'
          " @input="(value) => updateFilters(column, $refs.name.value)" />
        <input ref="email" class="form-control" placeholder="Search email" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'email'
          " @input="(value) => updateFilters(column, $refs.email.value)" />
        <input ref="phune_number" class="form-control" placeholder="Search Phone Number" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'phune_number'
          " @input="(value) => updateFilters(column, $refs.phune_number.value)" />
        <input ref="description" class="form-control" placeholder="Search Pesan" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'description'
          " @input="(value) => updateFilters(column, $refs.description.value)" />
        <div class="input-group" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'createdAt'">
          <input type="date" ref="createdAt1" class="form-control" placeholder="Search ..."
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
          <span class="input-group-text">
            -
          </span>
          <input type="date" ref="createdAt2" class="form-control" placeholder="Search ..."
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
        </div>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end" v-if="props.row.status !== 'Deleted'">
            <div class="col-auto" v-if="edit">
              <router-link :to="`/pages/contact-us/edit/${props.row.id}`" class="btn btn-lg text-dark" v-b-tooltip.hover
                title="Edit">
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>
            <div class="col-auto" v-if="deleteContact">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                @click.prevent="showAlertConfirm(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else :class="[props.row.status === 'Deleted' && 'text-muted']">
          {{ (props.formattedRow[props.column.field].length > 55) ?
            props.formattedRow[props.column.field].substring(0, 55) + "..." :
            props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import contactusService from "@/service/contactus.service";
import tokenService from '@/service/token.service';
import moment from "moment";
import _ from 'lodash';
export default {
  data() {
    return {
      setCurrentPage: this.$store.state.contactus.serverParams.skip / this.$store.state.contactus.serverParams.take == 0 ?
        1 : this.$store.state.contactus.serverParams.skip / this.$store.state.contactus.serverParams.take + 1,
      isLoading: false,
      columns: [
        {
          label: "Name",
          field: "name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Email",
          field: "email",
          sortable: false,
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "No. Telp",
          field: "phune_number",
          sortable: false,
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Pesan",
          field: "description",
          sortable: false,
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Submitted At",
          field: "createdAt",
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        search: '',
        take: 10,
        skip: 0,
        sort: 'desc'
      },
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
      permisions: tokenService.getPermission()
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.$store.state.contactus.serverParams = Object.assign({}, this.$store.state.contactus.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ skip: 0 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams();
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams();
      this.loadItems();
    },

    onColumnFilter: _.debounce(function (params) {
      let payload = {}
      if (params.columnFilters['createdAt'] && params.columnFilters.createdAt != '#') {
        let arrSplit = params.columnFilters['createdAt'].split('#')
        if (arrSplit[0] && arrSplit[1]) {
          payload.createdAtStartDate = arrSplit[0] ? moment(arrSplit[0]).startOf('day').toISOString() : ''
          payload.createdAtEndDate = arrSplit[1] ? moment(arrSplit[1]).endOf('day').toISOString() : ''
        }
      } else {
        delete this.$store.state.contactus.serverParams.createdAtStartDate
        delete this.$store.state.contactus.serverParams.createdAtEndDate
      }

      if (params.columnFilters.name && params.columnFilters.name != '') {
        payload.name = params.columnFilters.name ? params.columnFilters.name : ''
      } else {
        delete this.$store.state.contactus.serverParams.name
      }

      if (params.columnFilters.email && params.columnFilters.email != '') {
        payload.email = params.columnFilters.email ? params.columnFilters.email : ''
      } else {
        delete this.$store.state.contactus.serverParams.email
      }

      if (params.columnFilters.phune_number && params.columnFilters.phune_number != '') {
        payload.phoneNumber = params.columnFilters.phune_number ? params.columnFilters.phune_number : ''
      } else {
        delete this.$store.state.contactus.serverParams.phoneNumber
      }

      if (params.columnFilters.description && params.columnFilters.description != '') {
        payload.description = params.columnFilters.description ? params.columnFilters.description : ''
      } else {
        delete this.$store.state.contactus.serverParams.description
      }

      this.updateParams(payload);
      this.loadItems();
    }, 300),
    loadItems() {
      this.isLoading = true;
      contactusService
        .getContactUsList(this.$store.state.contactus.serverParams)
        .then(({ data: { data } }) => {
          this.rows = data.ContactUs
          this.totalRecords = data.totalData
        }).catch((e) => {
          if (e.response.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },

    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },
    doDelete(id) {
      this.isLoading = true;
      contactusService
        .deleteContactUsById(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },

    downloadExcel() {
      this.isLoading = true;
      let { take, skip, sort, ...NewParams } = this.$store.state.contactus.serverParams
      let payload = {
        ...NewParams
      }
      contactusService.getContactUsExport(payload)
        .then(({ data: { data } }) => {
          let { path } = data
          if (path) {
            window.open(this.$api_name + '/' + path, '_blank')
          }
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },
  },
  mounted() {
    this.loadItems();
  },

  computed: {
    add: function () {
      if (this.permisions.find((r) => r == 'ContactUs Add')) {
        return true
      }
      return false;
    },
    edit: function () {
      if (this.permisions.find((r) => r == 'ContactUs Patch')) {
        return true
      }
      return false;
    },
    deleteContact: function () {
      if (this.permisions.find((r) => r == 'ContactUs Delete')) {
        return true
      }
      return false;
    },
    exportExcel: function () {
      if (this.permisions.find((r) => r == 'ContactUs Export')) {
        return true
      }
      return false;
    },
  },
};
</script>
