import api from "./api";

class CustomerChildService {
  /** @type {ApiAxiosInstance} */
  api;

  constructor() {
    this.api = api.create('customer-child')
  }

  getCustomerChildList(serverParams) {
    return this.api.get("/", { params: serverParams });
  }

  postCustomerChild(serverParams) {
    return this.api.post("/", { ...serverParams });
  }

  getCustomerChildById(serverParams) {
    return this.api.get("/" + serverParams);
  }

  patchCustomerChild(id, serverParams) {
    return this.api.patch("/" + id, { ...serverParams });
  }

  deleteCustomerChildById(serverParams) {
    return this.api.delete("/" + serverParams);
  }

  getCustomerChildByDateList(serverParams) {
    return this.api.get("/filter/get-by-range-date", { params: serverParams });
  }
}

export default new CustomerChildService();
