<template>
  <div>
    <h1 class="page-header">All Menus</h1>
    <div class="panel">
      <NavTab />
      <div class="panel-body">
        <div class="row align-items-center g-2 mb-4">
          <div class="col-auto">
            <p class="mb-0">
              Edit your menu below, or
              <router-link to="/sections/menus/add" class="text-blue text-decoration-underline">create a new
                menu</router-link>. Do not forget to save your
              changes!
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <h5 class="fw-bolder mb-3">Add menu items</h5>
            <panel title="Pages" body-class="py-0" variant="grey" :is-collapse="true">
              <div class="my-3" v-for="(item, index) in pageMenu" :key="index">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" :value="item" :id="`item-${item.id}`" name="menuSelect"
                    v-model="pageSelected" />
                  <label class="form-check-label" :for="`item-${item.id}`">
                    {{ item.slug }}
                  </label>
                </div>
              </div>
              <template #footer>
                <div class="row align-items-center justify-content-between">
                  <div class="col-auto">
                    <div class="form-check">
                      <input class="form-check-input" id="selectAll" type="checkbox" name="selectAll"
                        v-model="selectedAll" />
                      <label for="selectAll" class="form-check-label">Select all</label>
                    </div>
                  </div>
                  <div class="col-auto">
                    <button class="btn btn-white" type="button" @click.prevent="addToMenuItems">
                      Add to Menu
                    </button>
                  </div>
                </div>
              </template>
            </panel>
          </div>
          <div class="col-md-9">
            <h5 class="fw-bolder mb-3">Menu structure</h5>
            <div class="panel">
              <div class="panel-heading">
                <div class="row g-2 align-items-center">
                  <div class="col-auto">
                    <p class="mb-0 fw-normal">Menu name</p>
                  </div>
                  <div class="col-auto">
                    <input type="text" v-model="name" placeholder="Menu name" class="form-control" />
                  </div>
                  <div class="col">
                    <span class="text-danger" v-if="$v.name.$error">Menu Name is required</span>
                  </div>
                </div>
              </div>
              <div class="panel-body">
                <p>
                  Drag the items into the order you prefer. Click the arrow on
                  the right of the item to reveal additional configuration
                  options.
                </p>
                <nested-draggable v-model="list" @onDeleteItems="deleteItem" />
              </div>
              <div class="panel-footer bg-grey">
                <div class="row align-items-center justify-content-between">
                  <div v-if="id && deleteMenus" class="col-auto">
                    <button @click.prevent="onDelete()" class="btn btn-danger" type="button">
                      <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                      Delete Menu
                    </button>
                  </div>
                  <div class="col">
                    <span class="text-danger" v-if="$v.list.$error">Menu items is required</span>
                  </div>
                  <div class="col-auto" v-if="add || edit">
                    <button class="btn btn-primary" @click.prevent="onSubmit()" type="button">
                      <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                      Save Menu
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NestedDraggable from "./NestedDraggable.vue";
import NavTab from "./Nav.vue";
import pageService from "@/service/page.service";
import menuService from "@/service/menu.service";
import menuItemsService from "@/service/menuitems.service";
import tokenService from "@/service/token.service";
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    NavTab,
    NestedDraggable,
  },
  data() {
    return {
      permisions: tokenService.getPermission(),
      onLoading: false,
      selectedAll: '',
      menuItemsList: [],
      pageMenu: [],
      pageSelected: [],
      menuList: "",
      menuSelected: [1],
      menuItemsDeleted: [],
      menuItems: [
        {
          id: 1,
          title: "About us",
        },
        {
          id: 2,
          title: "Product page",
        },
        {
          id: 3,
          title: "Homepage",
        },
      ],
      id: null,
      name: '',
      list: [],
      payload: {
        searchField: "",
        searchValue: "",
        sort: "",
        direction: "ASC",
        pageNumber: 0,
        pageSize: 10
      }
    };
  },
  methods: {
    onSubmit() {
      this.onLoading = true
      this.$v.$touch();

      if (!this.$v.$invalid) {
        let payload = {
          id: this.id ? this.id : null,
          name: this.name,
          list: this.list,
          deleted: this.menuItemsDeleted
        }
        this.$store.dispatch("menu/postMenu", payload)
          .then((rsp) => {
            this.$swal({
              title: "Data berhasil disimpan",
              showCloseButton: true,
            }).then(() => {
              this.loadItems()
            });
          }).catch((e) => {
            if (e.response?.status === 409) {
              this.$notify({
                type: "warn",
                title: "Error Message",
                text: "Data Sudah Ada",
              });
            } else {
              this.$notify({
                type: "error",
                title: "Error Message",
                text: e.response?.data?.message || "Terjadi Kesalahan",
              });
            }
          }).finally((r) => {
            this.$v.$reset();
            this.onLoading = false
          })
      } else {
        this.onLoading = false
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
    onChangeMenu() {
      //
    },
    loadItems() {
      this.loadPages()
      this.loadMenu()
    },
    loadPages() {
      this.isLoading = true;
      pageService
        .getPageList({})
        .then(({ data: { data } }) => {
          this.pageMenu = data.Page
        }).catch((e) => {
          if (e.response?.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.$v.$reset()
          this.isLoading = false;
        })
    },
    loadMenu() {
      this.isLoading = true;
      menuService
        .getMenuList()
        .then(({ data: { data } }) => {
          if (data.length > 0) {
            this.name = data[0].name
            this.id = data[0].id
            this.loadMenuItems(data[0].slug)
          } else {
            this.id = ''
            this.name = ''
            this.list = []
          }
        }).catch((e) => {
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.name = ''
            this.id = ''
          }
        }).finally(() => {
          this.$v.$reset()
          this.isLoading = false;
        })
    },
    loadMenuItems(slug) {
      menuItemsService
        .getMenuItemsListFromSlug(slug)
        .then(async ({ data: { data } }) => {
          let newList = []
          for (const [k, v] of Object.entries(data)) {
            let { id, title, target, menu_href, child, icon, is_free_sample } = v
            newList.push({
              id: id,
              title: title,
              isShow: true,
              label: title,
              slug: menu_href,
              submenus: [],
              icon: icon ? [await this.convertURLtoFile(this.loadSrcImage(icon))] : '',
              iconSrc: icon ? await this.loadSrcImage(icon) : '',
              is_free_sample: is_free_sample ? is_free_sample : false
            })
            for (const [k1, v1] of Object.entries(child)) {
              let { id, title, target, menu_href, child, icon, is_free_sample } = v1
              newList[k].submenus.push({
                id: id,
                title: title,
                isShow: true,
                label: title,
                slug: menu_href,
                submenus: [],
                icon: icon ? [await this.convertURLtoFile(this.loadSrcImage(icon))] : '',
                iconSrc: icon ? await this.loadSrcImage(icon) : '',
                is_free_sample: is_free_sample ? is_free_sample : false
              })
            }
          }
          this.list = newList
        }).catch((e) => {
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.name = ''
            this.id = ''
          }
        }).finally(() => {
          this.$v.$reset()
          this.isLoading = false;
        })
    },
    onDelete() {
      this.isLoading = true;
      let payload = {
        id: this.id,
        list: this.list
      }
      this.$store.dispatch("menu/deleteAll", payload)
        .then((result) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.$v.$reset()
          this.isLoading = false;
        });
    },
    addToMenuItems() {
      if (this.pageSelected.length > 0) {
        for (const [k, v] of Object.entries(this.pageSelected)) {
          let cekList = this.list.filter((r) => r.title == v.slug)
          if (cekList.length == 0) {
            this.list.push({
              title: v.slug,
              isShow: true,
              label: v.slug,
              slug: v.slug,
              submenus: [],
            })
          } else {
            this.$notify({
              type: "warn",
              title: "Warning Message",
              text: "Pages sudah ada di menu structure",
            });
          }
        }
      } else {
        this.$notify({
          type: "warn",
          title: "Warning Message",
          text: "Checklist Page Terlebih dulu",
        });
      }
    },
    deleteItem(value) {
      if (value.id) {
        this.menuItemsDeleted.push(value)
      }
    },
    loadSrcImage(url) {
      return this.$api_name + '/' + url
    },
    async convertURLtoFile(url) {
      const response = await fetch(url);
      if (response.status != 200) {
        return "";
      }
      const data = await response.blob();
      const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
      const metadata = { type: `image/jpeg` };
      return new File([data], filename, metadata);
    },
  },
  mounted() {
    this.loadItems();
  },
  watch: {
    'menuList'(newVal) {
      if (newVal != null) {
        this.$store.dispatch("menu/getMenuById", newVal.id).then((rsp) => {
          this.mappingList(rsp.data.data)
        });
      } else {
        // data()
      }

    },
    selectedAll(v) {
      if (v) {
        let arrPage = []
        for (const [k, v] of Object.entries(this.pageMenu)) {
          arrPage.push(v)
        }
        this.pageSelected = []
        this.pageSelected = arrPage
      } else {
        this.pageSelected = []
      }
    }
  },
  computed: {
    add: function () {
      if (this.permisions.find((r) => r == 'Menus Add')) {
        return true
      }
      return false;
    },
    edit: function () {
      if (this.permisions.find((r) => r == 'Menus Patch')) {
        return true
      }
      return false;
    },
    deleteMenus: function () {
      if (this.permisions.find((r) => r == 'Menus Delete')) {
        return true
      }
      return false;
    },
  },
  validations: {
    name: { required },
    list: { required }
  }
};
</script>
