import PostService from "../service/post.service";

export default {
  namespaced: true,
  state: {
    serverParams: {
      search: '',
      take: 10,
      skip: 0,
      sort: 'desc'
    }
  },
  actions: {
    async getListArticle(_ctx, serverParams) {
      return PostService.getArticleList(serverParams).then((result) => {
        return result
      }).catch((err) => {
        return err
      });
    },
    async postArticle(_ctx, serverParams) {
      return PostService.postArticle(serverParams).then((result) => {
        return result
      }).catch((err) => {
        return err
      });
    },
    async getArticleById(_ctx, serverParams) {
      return PostService.getArticleById(serverParams).then((result) => {
        return result
      }).catch((err) => {
        return err
      });
    },
    async updateArticleById(_ctx, serverParams) {
      const { id, ...payload } = serverParams
      return PostService.putArticle(id, payload).then((result) => {
        return result
      }).catch((err) => {
        return err
      });
    },
    async deleteArticleById(_ctx, serverParams) {
      return PostService.deleteArticleById(serverParams).then((result) => {
        return result
      }).catch((err) => {
        return err
      });
    },
    //category
    async getListArticleCategory(_ctx, serverParams) {
      return PostService.getArticleCategoryList(serverParams).then((result) => {
        return result
      }).catch((err) => {
        return err
      });
    },
    async postArticleCategory(_ctx, serverParams) {
      return PostService.postArticleCategory(serverParams).then((result) => {
        return result
      }).catch((err) => {
        return err
      });
    },
    async getArticleCategoryById(_ctx, serverParams) {
      return PostService.getArticleCategoryById(serverParams).then((result) => {
        return result
      }).catch((err) => {
        return err
      });
    },
    async updateArticleCategoryById(_ctx, serverParams) {
      const { id, ...payload } = serverParams
      return PostService.putArticleCategory(id, payload).then((result) => {
        return result
      }).catch((err) => {
        return err
      });
    },
    async deleteArticleCategoryById(_ctx, serverParams) {
      return PostService.deleteArticleCategoryById(serverParams).then((result) => {
        return result
      }).catch((err) => {
        return err
      });
    },
  },
};
