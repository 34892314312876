<template>
  <div class="product-form">
    <FormSubmission form-type="edit" :detail-data="form" @onValidateSuccess="doSave" />
  </div>
</template>
<script>
import FormSubmission from "./Form.vue";
import freesampleService from "@/service/freesample.service";
import customerService from "@/service/customer.service";
export default {
  data() {
    return {
      form: {
        id: this.$route.params.id,
        customer: {}
      },
    };
  },
  components: {
    FormSubmission,
  },
  mounted() {
    this.loadItems(this.$route.params.id)
  },
  methods: {
    async doSave(payload) {
      // Submit here
      let { form } = payload
      let { email, form_url, full_name, user_id, sample_state, reject_reason } = form
      payload = {
        sample_state: sample_state,
        form_url: form_url,
        user_id: user_id,
        reject_description: reject_reason ? reject_reason : null
      }

      freesampleService.patchFreeSample(this.$route.params.id, payload).then((rsp) => {
        this.$swal({
          title: "Data berhasil disimpan",
          showCloseButton: true,
        }).then(() => {
          this.$router.push({ path: '/submission/list' })
        });
      }).catch((e) => {
        this.$notify({
          type: "error",
          title: "Error Message",
          text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
        });
      }).finally(() =>
        this.onLoading = false)
    },
    loadItems(id) {
      return freesampleService
        .getFreeSampleById(id)
        .then(async ({ data: { data } }) => {
          let { user_id, reject_description, ...newData } = data
          let { customer } = await this.loadCustomerById(user_id)
          // console.log(reject_description)
          this.form = {
            ...newData,
            reject_reason: reject_description,
            user_id,
            customer: customer
          }
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        })
    },
    loadCustomerById(id) {
      return customerService
        .getCustomerById(id)
        .then(({ data: { data } }) => {
          return Promise.resolve({ accepted: true, customer: data.customer })
        }).catch((e) => {
          return Promise.reject({ accepted: false })
        })
    }
  },
};
</script>
