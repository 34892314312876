<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <h1 class="page-header">Survey Data User</h1>
        <div class="col-auto" v-if="exportExcel">
          <a class="btn btn-outline-primary" @click.prevent="downloadExcel">
            <i class="fa-solid fa-file-import"></i>
            Export
          </a>
        </div>
      </div>
    </div>
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
        setCurrentPage: setCurrentPage,
        perPage: serverParams.take,
      }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'name', type: 'asc' },
}" styleClass="vgt-table" slot="outsideBody">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="full_name" class="form-control" placeholder="Search Full Name" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'full_name'
          " @input="(value) => updateFilters(column, $refs['full_name'].value)" />
        <input ref="email" class="form-control" placeholder="Search Email" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'email'
          " @input="(value) => updateFilters(column, $refs['email'].value)" />
        <input ref="phoneNumber" class="form-control" placeholder="Search Handphone" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'phoneNumber'
          " @input="(value) => updateFilters(column, $refs['phoneNumber'].value)" />

        <select ref="status" class="form-select" placeholder="Cari..." v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'status'
          " @change="(value) => updateFilters(column, $refs['status'].value)">
          <option />
          <option v-for="(option, key) in optionStatus" v-bind:value="option.value" :key="key" v-text="option.label" />
        </select>
        <div class="input-group" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'createdAt'
          ">
          <input type="date" ref="createdAt1" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
          <span class="input-group-text">
            -
          </span>
          <input type="date" ref="createdAt2" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
        </div>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="col-auto" v-if="props.row.status !== 'Deleted'">
            <div class="col-auto" v-if="edit">
              <router-link :to="`/survey/submission/${props.row.id}/${props.row.isTypeSurveySubmission}`"
                class="btn btn-lg text-dark" v-b-tooltip.hover title="Detail">
                <i class="fas fa-eye"></i>
              </router-link>
            </div>
            <div class="col-auto" v-if="deleteFreeSample">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                @click.prevent="showAlertConfirm(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else :class="[props.row.status === 'Deleted' && 'text-muted']">
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import surveySubmissionService from "@/service/surveysubmission.service";
import freesampleService from "@/service/freesample.service";
import redeemMilestoneService from "@/service/redeemmilestones.service";
import CustomerService from "@/service/customer.service";
import RewardService from "@/service/reward.service";
import moment from "moment";
import tokenService from "@/service/token.service";
import _ from 'lodash';
export default {
  data() {
    return {
      setCurrentPage: this.$store.state.survey.serverParams.skip / this.$store.state.survey.serverParams.take == 0 ?
        1 : this.$store.state.survey.serverParams.skip / this.$store.state.survey.serverParams.take + 1,
      optionStatus: [
        {
          label: 'Approve',
          value: 'approve'
        },
        {
          label: 'Pending',
          value: 'pending'
        },
        {
          label: 'In Review',
          value: 'in_review'
        },
        {
          label: 'Reject',
          value: 'reject'
        },
        {
          label: 'Unfinished',
          value: 'need_update'
        }
      ],
      permisions: tokenService.getPermission(),
      isLoading: false,
      optionsStatus: [
        'Published', 'Draft'
      ],
      columns: [
        {
          label: "Name",
          field: "full_name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Alamat Email",
          field: "email",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "No. Handphone",
          field: "phoneNumber",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },

        {
          label: "Status",
          field: "status",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Duplicate",
          field: "duplicate",
          thClass: "text-nowrap",
          tdClass: this.statusClass,
        },
        {
          label: "Submitted at",
          field: "createdAt",
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "text-end text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      optionsIsDuplicate: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        }
      ],
      rows: [],
      totalRecords: 0,
      serverParams: this.$store.state.survey.serverParams,
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
    };
  },
  methods: {
    statusClass(row) {
      let statusClass = ''
      switch (row.duplicate) {
        case 'Yes':
          statusClass = 'bg-red text-white align-middle';
          break;
        default:
          statusClass = 'bg-green text-white align-middle';
          break;
      }
      return statusClass
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.$store.state.survey.serverParams = Object.assign({}, this.$store.state.survey.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ skip: 0 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams();
      this.loadItems();
    },

    onColumnFilter: _.debounce(function (params) {
      let payload = {}
      if (params.columnFilters.status && params.columnFilters.status != '') {
        payload.status = params.columnFilters.status ? params.columnFilters.status : ''
      } else {
        delete this.$store.state.survey.serverParams.status
      }

      if (params.columnFilters['createdAt'] && params.columnFilters['createdAt'] != '') {
        let arrSplit = params.columnFilters['createdAt'].split('#')
        if (arrSplit[0] && arrSplit[1]) {
          payload.createdAtStartDate = arrSplit[0] ? moment(arrSplit[0]).startOf('day').toISOString() : ''
          payload.createdAtEndDate = arrSplit[1] ? moment(arrSplit[1]).endOf('day').toISOString() : ''
        }
      } else {
        delete this.$store.state.survey.serverParams.createdAtStartDate
        delete this.$store.state.survey.serverParams.createdAtEndDate
      }

      if (params.columnFilters.status_pengiriman && params.columnFilters.status_pengiriman != '') {
        payload.delivery_status = params.columnFilters.status_pengiriman ? params.columnFilters.status_pengiriman : ''
      } else {
        delete this.$store.state.survey.serverParams.delivery_status
      }

      if (params.columnFilters['email']) {
        payload.email = params.columnFilters['email']
      } else {
        delete this.$store.state.survey.serverParams.email
      }

      if (params.columnFilters['phoneNumber'] && params.columnFilters['phoneNumber'] != '') {
        payload.phoneNumber = params.columnFilters['phoneNumber']
      } else {
        delete this.$store.state.survey.serverParams.phoneNumber
      }

      if (params.columnFilters['full_name'] && params.columnFilters['full_name'] != '') {
        payload.name = params.columnFilters['full_name']
      } else {
        delete this.$store.state.survey.serverParams.name
      }

      if (params.columnFilters['status'] && params.columnFilters['status'] != '') {
        payload.sample_state = params.columnFilters.status ? params.columnFilters.status : ''
      } else {
        delete this.$store.state.survey.serverParams.sample_state
      }

      this.updateParams(payload);
      this.loadItems();
    }, 300),

    loadItems() {
      this.isLoading = true;
      freesampleService
        .getFreeSampleList(this.$store.state.survey.serverParams)
        .then(async ({ data: { data } }) => {
          let arrData = []
          if (data.freeSample.length > 0) {
            for (const [k, v] of Object.entries(data.freeSample)) {
              arrData.push({
                ...v,
                status: this.optionStatus.find((r) => r.value == v.sample_state).label,
                duplicate: this.optionsIsDuplicate.find((r) => r.value == v.isDuplicateAddress).label,
                email: v.customer?.email ? v.customer.email : null,
                full_name: v.customer?.full_name ? v.customer.full_name : null,
                phoneNumber: v.customer?.phoneNumber ? v.customer.phoneNumber : null
              })
            }
          }
          this.rows = arrData
          this.totalRecords = data.totalData
        }).catch((e) => {
          // console.log(e)
          this.rows = []
          if (e?.response?.statusCode !== 200) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadItemsSurvey() {
      this.isLoading = true;
      freesampleService
        .getFreeSampleList(this.$store.state.survey.serverParams)
        .then(async ({ data: { data } }) => {
          let arrData = []
          if (data.freeSample.length > 0) {
            for (const [k, v] of Object.entries(data.freeSample)) {
              arrData.push({
                ...v,
                email: v.customer?.email ? v.customer.email : null,
                full_name: v.customer?.full_name ? v.customer.full_name : null,
                phoneNumber: v.customer?.phoneNumber ? v.customer.phoneNumber : null
              })
            }
          }
          this.rows = arrData
          this.totalRecords = data.totalData
        }).catch((e) => {
          // console.log(e)
          this.rows = []
          if (e?.response?.statusCode !== 200) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },

    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },

    doDelete(id) {
      this.isLoading = true;
      freesampleService
        .deleteFreeSampleById(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
          this.loadItems();
        }).finally(() => {
          this.isLoading = false;
        })
    },

    downloadExcel() {
      this.isLoading = true;
      let { take, skip, sort, ...NewParams } = this.serverParams
      let payload = {
        ...NewParams
      }
      freesampleService.exportExcel(payload)
        .then(({ data: { data } }) => {
          let { path } = data
          if (path) {
            window.open(this.$api_name + '/' + path, '_blank')
          }
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },
  },
  computed: {
    exportExcel: function () {
      if (this.permisions.find((r) => r == 'FreeSample Export')) {
        return true
      }
      return false;
    },
    edit: function () {
      if (this.permisions.find((r) => r == 'FreeSample Patch')) {
        return true
      }
      return false;
    },
    deleteFreeSample: function () {
      if (this.permisions.find((r) => r == 'FreeSample Delete')) {
        return true
      }
      return false;
    },
  },
  mounted() {
    this.loadItems();
  },
};
</script>
