import api from "./api";
import apim from "./apim";

class ResellerManagerService {
  /** @type {ApiAxiosInstance} */
  api;
  apim;

  constructor() {
    this.api = api.create('reseller-manager')
    this.apim = apim.create('reseller-manager')
  }

  getResellerManagerList(serverParams) {
    return this.api.get("/", { params: serverParams });
  }

  postResellerManager(serverParams) {
    return this.api.post("/", { ...serverParams });
  }

  getResellerManagerById(serverParams) {
    return this.api.get("/" + serverParams);
  }

  patchResellerManager(id, serverParams) {
    return this.api.patch("/" + id, { ...serverParams });
  }

  deleteResellerManagerById(serverParams) {
    return this.api.delete("/" + serverParams);
  }

  postImportResellerManager(serverParams) {
    return this.apim.post("/import", serverParams);
  }

  exportExcel(serverParams) {
    return this.api.get("/export/excel", { params: serverParams });
  }
}

export default new ResellerManagerService();
