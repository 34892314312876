import api from "./api";

class PageService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('location')
    }

    getProvinces(serverParams) {
        return this.api.get("/province", { params: serverParams });
    }

    getCities(serverParams) {
        return this.api.get("/cities", { params: serverParams });
    }

    getDistricts(serverParams) {
        return this.api.get("/sub-district", { params: serverParams });
    }

    getSubDistricts(serverParams) {
        return this.api.get("/ward", { params: serverParams });
    }

    getPostalCode(serverParams) {
        return this.api.get("/postal-code", { params: serverParams });
    }
}

export default new PageService();