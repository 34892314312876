import api from "./api";
import apim from "./apim";

class EcommerceService {
  /** @type {ApiAxiosInstance} */
  api;
  apim;

  constructor() {
    this.api = api.create('e-commerce')
    this.apim = apim.create('e-commerce')
  }

  getEcommerceList(serverParams) {
    return this.api.get("/", { params: serverParams });
  }

  postEcommerce(serverParams) {
    return this.api.post("/", { ...serverParams });
  }

  getEcommerceById(serverParams) {
    return this.api.get("/" + serverParams);
  }

  patchEcommerce(id, serverParams) {
    return this.api.patch("/" + id, { ...serverParams });
  }

  deleteEcommerceById(serverParams) {
    return this.api.delete("/" + serverParams);
  }

  importExcel(serverParams) {
    return this.apim.post("/import", serverParams);
  }

  exportExcel(serverParams) {
    return this.api.get("/data/export", { params: serverParams });
  }
}

export default new EcommerceService();
