import api from "./api";

class DashboardService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('dashboard')
    }

    getDashboard(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    getDashboardChart(serverParams) {
        return this.api.get("/monthly-report", { params: serverParams });
    }

    getDashboardDuplikasi(serverParams) {
        return this.api.get("/customer-address-report", { params: serverParams });
    }

    getDashboardLeaderboard(serverParams) {
        return this.api.get("/customer-point-leader-board", { params: serverParams });
    }

    getDashboardActiveCustomer(serverParams) {
      return this.api.get("/customer/most-actvive-by-month", { params: serverParams });
  }
}

export default new DashboardService();
