import api from "./api";

class GiziStatusCategoryService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('nutritional-status-category')
    }

    getGiziStatusCategoryList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postGiziStatusCategory(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getGiziStatusCategoryById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchGiziStatusCategory(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteGiziStatusCategoryById(serverParams) {
        return this.api.delete("/" + serverParams);
    }
}

export default new GiziStatusCategoryService();