<script>
const sidebarMenu = [
  { path: "/dashboard", icon: "fa fa-th-large", title: "Dashboard" },
  {
    path: "#",
    icon: "fas fa-users-viewfinder",
    title: "Customer",
    children: [
      { path: "/customer/list", title: "Customer List" },
      { path: "/customer/children", title: "Children List" },
    ]
  },
  {
    path: "#",
    icon: "fas fa-star",
    title: "Products",
    children: [
      { path: "/products/list", title: "Product List" },
      { path: "/products/add", title: "Add Product" },
      { path: "/products/category", title: "Product Category" },
      { path: "/products/parent_category", title: "Parent Product Category" },
      { path: "/products/type", title: "Product Type" },
      { path: "/products/ecommerce", title: "Ecommerce List" },
    ],
  },
  {
    path: "/homepage-management",
    icon: "fas fa-house-chimney",
    title: "Homepage Management",
  },
  {
    path: "#",
    icon: "fas fa-file-lines",
    title: "Pages",
    children: [
      { path: "/pages/list", title: "All Pages" },
      { path: "/pages/add", title: "Add Page" },
      { path: "/pages/about", title: "About Us Page" },
      { path: "/pages/contact-us", title: "Contact Us" },
    ],
  },
  {
    path: "#",
    icon: "fas fa-thumbtack",
    title: "Articles & Promos",
    children: [
      { path: "/posts/list/data", title: "All Articles" },
      { path: "/posts/list/promo", title: "All Promos" },
      // { path: "/posts/add", title: "Add Post" },
      { path: "/posts/category", title: "Articles Category" },
      { path: "/posts/promo/category", title: "Promo Category" },
    ],
  },
  {
    path: "#",
    icon: "fas fa-puzzle-piece",
    title: "Sections",
    children: [
      { path: "/sections/menus", title: "Menus" },
      { path: "/sections/banner", title: "Banner" },
    ],
  },
  {
    path: "#",
    icon: "fas fa-gem",
    title: "Loyalty",
    children: [
      { path: "/claim-point/list", title: "Claim Point" },
      { path: "/reward/list", title: "Reward Catalogue" },
      { path: "/redeem/list", title: "Redeem" },
    ],
  },
  {
    path: "#",
    icon: "fas fa-desktop",
    title: "Career",
    children: [
      { path: "/career/list", title: "Career List" },
      { path: "/career/add", title: "Add Position" },
      { path: "/career/category", title: "Career Category" },
      { path: "/career/level", title: "Level Category" },
      { path: "/career/type", title: "Job Type Category" },
      { path: "/career/candidate/list", title: "Candidate List" },
    ],
  },
  {
    path: "/submission/list",
    icon: "fas fa-rectangle-list",
    title: "Free Sample",
    children: [
      { path: "/survey/list", title: "Question Survey" },
      { path: "/survey/submission", title: "Survey Submission" },
      // { path: "/survey-after-free-sample/list", title: "Question Survey After Free Sample" },
      // { path: "/survey-after-free-sample/submission", title: "Survey Submission After Free Sample" },
    ],
  },
  {
    path: "#",
    icon: "fas fa-address-book",
    title: "Reseller Submission",
    children: [
      { path: "/reseller-submission/list", title: "Reseller List" },
      { path: "/reseller-submission/add", title: "Add Reseller" },
      { path: "/reseller-submission/supervisor", title: "Supervisor Reseller" },
    ],
  },
  {
    path: "#",
    icon: "fas fa-rectangle-list",
    title: "Tumbuh Kembang",
    children: [
      { path: "/tumbuh-kembang/berat-tinggi", title: "Data Berat & Tinggi" },
      { path: "/tumbuh-kembang/status-gizi", title: "Status Gizi Anak" },
      { path: "/tumbuh-kembang/category/status-gizi", title: "Category Status Gizi" },
      { path: "/tumbuh-kembang/category/ambang-batas", title: "Category Ambang Batas" },
    ],
  },
  {
    path: "#",
    icon: "fas fa-circle-question",
    title: "Faq & Tanya GOON",
    children: [
      { path: "/faq/list", title: "All Faq" },
      { path: "/faq/category", title: "Category Faq" },
      { path: "/pages/nutri-expert", title: "Tanya GOON" },
    ],
  },
  {
    path: "#",
    icon: "fas fa-baby",
    title: "Baby Name Generator",
    children: [
      { path: "/baby-name-generator/list", title: "All Baby Name Generator" },
    ],
  },
  {
    path: "#",
    icon: "fas fa-users",
    title: "Users",
    children: [
      { path: "/users/manage/user", title: "Manage User" },
      { path: "/users/manage/role", title: "Manage Role" },
    ],
  },
  {
    path: "#",
    icon: "fas fa-gear",
    title: "Site Setting",
    children: [
      { path: "/setting/site", title: "Site" },
      { path: "/setting/seo", title: "SEO" },
      { path: "/setting/social-media", title: "Social Media" },
      { path: "/setting/loyalty", title: "Loyalty" },
      { path: "/setting/freesample", title: "Free Sample" },
    ],
  },
];

export default sidebarMenu;
</script>
