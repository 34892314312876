import MenuService from "../service/menu.service";
import MenuItemsService from "../service/menuitems.service";
import slugify from "@/pages/Helper/slug";
import base64Converter from "@/pages/Helper/base64";

export default {
  namespaced: true,
  state: {},
  actions: {
    async deleteAll(_ctx, serverParams) {
      try {
        let { id, list } = serverParams
        let arrReq = []
        if (list.length > 0) {
          for (const [k, v] of Object.entries(list)) {
            let { id } = v
            arrReq.push(await this.dispatch("menu/deleteMenuItems", id))
          }
        }
        arrReq.push(await this.dispatch("menu/deleteMenu", id))
        return Promise.all(arrReq)
          .then(({ data: { data } }) => {
            return Promise.resolve({ accepted: true, data })
          }).catch((err) => {
            return Promise.resolve({ accepted: false, error: err })
          });
      } catch (err) {
        return Promise.resolve({ accepted: false, error: err })
      }
    },
    // async getListMenu(_ctx, serverParams) {
    //     return MenuService.getMenuList(serverParams).then((result) => {
    //         return result
    //     }).catch((err) => {
    //         return err
    //     });
    // },
    async postMenu(_ctx, serverParams) {
      try {
        let direction = '';
        let { id, name, list, deleted } = serverParams
        let payloadMenu = {}
        if (id) {
          direction = 'menu/editMenu'
          payloadMenu = { id, name }
        } else {
          direction = 'menu/addMenu'
          payloadMenu = { name }
        }
        let { respMenu } = await this.dispatch(direction, payloadMenu)
        let arrResp = []

        let data = []
        if (respMenu) {
          direction = ''
          for (const [k, v] of Object.entries(list)) {
            // console.log(v)
            let { id, isShow, label, slug, submenus, title, icon, is_free_sample } = v
            let urlIcon = null
            if (icon) {
              urlIcon = (await this.dispatch('file/upload', icon)).data.data.filePath
            }

            let payloadMenuItems = {
              title: label,
              menu_href: slug,
              menu_image: "",
              description: "",
              parent_id: 0,
              target: "_blank",
              sort_no: k,
              menu_id: respMenu.id,
              icon: urlIcon,
              is_free_sample: is_free_sample ? is_free_sample : false
            }

            if (id) {
              direction = 'menu/editMenuItems'
              payloadMenuItems.id = id
            } else {
              direction = 'menu/addMenuItems'
            }

            arrResp.push(await this.dispatch(direction, payloadMenuItems))

            data.push([]);
            if (submenus.length > 0) {
              for (const [k1, v1] of Object.entries(submenus)) {
                let { id, isShow, label, slug, submenus, title, icon, is_free_sample } = v1

                let urlIcon = null
                if (icon) {
                  urlIcon = (await this.dispatch('file/upload', icon)).data.data.filePath
                }

                let payloadMenuItems = {
                  title: label,
                  menu_href: slug,
                  menu_image: "",
                  description: "",
                  parent_id: 0,
                  target: "_blank",
                  sort_no: k,
                  menu_id: respMenu.id,
                  icon: urlIcon,
                  is_free_sample: is_free_sample ? is_free_sample : false
                }

                if (id) {
                  payloadMenuItems.id = id
                }
                data[k].push(payloadMenuItems)
              }
            }
          }
        }

        if (deleted.length > 0) {
          for (const [k, v] of Object.entries(deleted)) {
            let { id } = v
            arrResp.push(await this.dispatch('menu/deleteMenuItems', id))
          }
        }

        return Promise.all(arrResp)
          .then(async (rsp) => {
            let arrResp = []
            for (const [k, v] of Object.entries(rsp)) {
              let { type, respMenuItems } = v
              if (type !== 'delete_menu_items') {
                if (data[k]) {
                  for (const [k1, v1] of Object.entries(data[k])) {
                    let { parent_id, id, ...newPayload } = v1
                    let direction = ''

                    let payload = {
                      parent_id: respMenuItems.id,
                      ...newPayload
                    }

                    if (id) {
                      direction = 'menu/editMenuItems'
                      payload.id = id
                    } else {
                      direction = 'menu/addMenuItems'
                    }

                    arrResp.push(await this.dispatch(direction, payload))
                  }
                }
              }
            }
            if (arrResp.length > 0) {
              return Promise
                .all(arrResp)
                .then((r) => {
                  return Promise.resolve({ accepted: true, respMenu })
                }).catch((e) => {
                  return Promise.resolve({ accepted: true, error: e })
                })
            } else {
              return Promise.resolve({ accepted: true, respMenu })
            }
          }).catch((err) => {
            return Promise.resolve({ accepted: true, error: err })
          });
      } catch (e) {
        console.log(e)
        return Promise.reject({ accepted: false, error: e })
      }
    },
    addMenu(_ctx, payload) {
      const { name } = payload
      let req = {
        name: name,
        slug: slugify(name),
        is_active: 'Publish'
      }
      return MenuService.postMenu(req).then(({ data: { data } }) => {
        return Promise.resolve({ accepted: true, type: 'add_menu', respMenu: data })
      }).catch((err) => {
        return Promise.reject({ accepted: false, error: err })
      });
    },
    editMenu(_ctx, payload) {
      const { id, name } = payload
      let req = {
        name: name,
        slug: slugify(name),
        is_active: 'Publish'
      }
      return MenuService
        .patchMenu(id, req)
        .then(({ data: { data } }) => {
          return Promise.resolve({ accepted: true, type: 'edit_menu', respMenu: data })
        }).catch((err) => {
          return Promise.reject({ accepted: false, error: err })
        });
    },
    deleteMenu(_ctx, id) {
      return MenuService
        .deleteMenuById(id)
        .then(({ data: { data } }) => {
          return Promise.resolve({ accepted: true, type: 'delete_menu', respMenuItems: data })
        }).catch((err) => {
          return Promise.reject({ accepted: false, error: err })
        });
    },
    addMenuItems(_ctx, payload) {
      return MenuItemsService
        .postMenuItems(payload)
        .then(({ data: { data } }) => {
          return Promise.resolve({ accepted: true, type: 'add_menu_items', respMenuItems: data })
        }).catch((err) => {
          return Promise.reject({ accepted: false, error: err })
        });
    },
    editMenuItems(_ctx, payload) {
      const { id, ...req } = payload
      return MenuItemsService
        .patchMenuItems(id, req)
        .then(({ data: { data } }) => {
          return Promise.resolve({ accepted: true, type: 'edit_menu_items', respMenuItems: data })
        }).catch((err) => {
          return Promise.reject({ accepted: false, error: err })
        });
    },
    deleteMenuItems(_ctx, id) {
      return MenuItemsService
        .deleteMenuItemsById(id)
        .then(({ data: { data } }) => {
          return Promise.resolve({ accepted: true, type: 'delete_menu_items', respMenuItems: data })
        }).catch((err) => {
          return Promise.reject({ accepted: false, error: err })
        });
    },
  },
};
