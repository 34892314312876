<template>
  <div class="page-settings">
    <form @submit.prevent="doSave" class="pb-5 mb-4">
      <h1 class="page-header">Loyalty Setting</h1>
      <div class="row">
        <div class="col-lg-9 col-md-8">
          <setting-nav />
          <div class="panel">
            <div class="panel-body">
              <label class="form-label d-block mb-2">Manual Checking</label>
              <div class="pt-1">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="manualChecking" id="manualChecking1" value="disabled"
                    v-model="form.checking">
                  <label class="form-check-label" for="manualChecking1">Disabled</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="manualChecking" id="manualChecking2" value="enabled"
                    v-model="form.checking">
                  <label class="form-check-label" for="manualChecking2">Enabled</label>
                </div>
              </div>
              <span class="text-danger" v-if="$v.form.checking.$error">
                Manual Checking required</span>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-3 col-md-4">
          <div class="panel panel-sticky">
            <div class="panel-body">
              <div class="mb-15px">
                <p class="fw-bolder mb-1">Published Date</p>
                <p class="mb-0">22 December 2022</p>
              </div>
              <div class="mb-15px">
                <p class="fw-bolder mb-1">Last Modified Date</p>
                <p class="mb-0">22 December 2022</p>
              </div>
              <div class="mb-0">
                <p class="fw-bolder mb-1">Author</p>
                <p class="mb-0">Wanda Vu</p>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="form-action" v-if="edit">
        <div class="row justify-content-end">
          <div class="col-auto">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import SettingNav from './Nav.vue'
import { required } from 'vuelidate/lib/validators'
import base64Converter from "@/pages/Helper/base64";
import SiteSettingService from "@/service/sitesetting.service";
import tokenService from "@/service/token.service";
export default {
  components: {
    SettingNav,
  },
  data() {
    return {
      permisions: tokenService.getPermission(),
      id: {
        checking: ''
      },
      form: {
        checking: '',
      }
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    async doSave() {

      this.$v.$touch();

      if (!this.$v.$invalid) {
        // let { faviconSrc, ...form } = this.form
        let arrReq = []
        for (const v in this.form) {
          if (this.id.id) {
            //Edit
            arrReq.push(
              this.editRequest(
                this.id.id, {
                caller_param: 'manual_checking_loyalty',
                title: 'manual_checking_loyalty',
                description: v + '_loyalty',
                default_value: (v == 'favicon') ? await base64Converter(this.form[v][0]) : this.form[v],
                attribute_value: "",
                type: (v == 'favicon') ? "image" : "text",
                grup: "loyalty"
              }))
          } else {
            // insert
            arrReq.push(this.addRequest({
              caller_param: 'manual_checking_loyalty',
              title: 'manual_checking_loyalty',
              description: v + '_loyalty',
              default_value: (v == 'favicon') ? await base64Converter(this.form[v][0]) : this.form[v],
              attribute_value: "",
              type: (v == 'favicon') ? "image" : "text",
              grup: "loyalty"
            }))
          }
        }

        Promise.all(arrReq)
          .then((r) => {
            this.$swal({
              title: "Data berhasil disimpan",
              showCloseButton: true,
            }).then(() => {
              this.loadItems();
            });
          }).catch((e) => {
            if (e.response?.status === 409) {
              this.$notify({
                type: "warn",
                title: "Error Message",
                text: "Data Sudah Ada",
              });
            } else if (e.response?.status === 403) {
              this.$notify({
                type: "error",
                title: "Error Message",
                text: "Permision Denied",
              });
            } else {
              this.$notify({
                type: "error",
                title: "Error Message",
                text: e.response?.data?.message || "Terjadi Kesalahan",
              });
            }
          })
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },

    loadItems() {
      // this.isLoading = true;
      SiteSettingService
        .getSiteSettingGrup('loyalty')
        .then(async ({ data: { data } }) => {
          if (data.length > 0) {
            for (const [k, v] of Object.entries(data)) {
              let { id, caller_param, default_value, type } = v
              this.form.checking = default_value
              this.id.id = id
            }
          }
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },

    addRequest(req) {
      return SiteSettingService
        .postSiteSetting(req)
        .then((response) => {
          return Promise.resolve({ ...response })
        }).catch((e) => {
          return Promise.reject({ ...e })
        })
    },
    editRequest(id, req) {
      return SiteSettingService
        .patchSiteSetting(id, req)
        .then((response) => {
          return Promise.resolve({ ...response })
        }).catch((e) => {
          return Promise.reject({ ...e })
        })
    },
  },
  computed: {
    edit: function () {
      if (this.permisions.find((r) => r == 'SiteSetting Patch')) {
        return true
      }
      return false;
    },
  },
  validations: {
    form: {
      checking: { required },
    },
  }
};
</script>
