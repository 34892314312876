<template>
  <div class="product-form">
    <FormPost form-type="edit" :detail-data="form" @onValidateSuccess="doSave" :on-loading="onLoading" />
  </div>
</template>
<script>
import FormPost from "./Form.vue";
import RedeemService from "@/service/redeem.service";
import RedeemMilestoneService from "@/service/redeemmilestones.service";
import CustomerService from "@/service/customer.service";
import RewardService from "@/service/reward.service";
import TokenService from "@/service/token.service";
export default {
  data() {
    return {
      onLoading: false,
      optionsStatusPengirimanMilestones: [
        {
          label: 'Waiting Approval',
          value: 'waiting_approval'
        },
        {
          label: 'Processed',
          value: 'processed'
        },
        {
          label: 'Delivered',
          value: 'delivered'
        },
        {
          label: 'Arrived',
          value: 'arrived'
        },
      ],
      optionsStatusPengiriman: [
        {
          label: 'Pending',
          value: 'pending'
        },
        {
          label: 'In Review',
          value: 'in_review'
        },
        {
          label: 'Approved',
          value: 'approve'
        },
        {
          label: 'Reject',
          value: 'reject'
        }
      ],
      form: {
        id: this.$route.params.id,
        title: "Diskon 40%",
      },
    };
  },
  components: {
    FormPost,
  },
  mounted() {
    this.loadItems(this.$route.params.id)
  },
  methods: {
    async doSave(payload) {
      // Submit here
      let { form } = payload
      payload = {
        customer_id: form.customer_id,
        reward_id: form.reward_id,
        status: form.status.value,
        incoming_request: form.incoming_request,
        reason: form.reason ? form.reason : null,
        notes: form.nomor ? form.nomor : null
      }

      let payloadRedeem = {
        redeem_id: this.$route.params.id,
        created_by: TokenService.getUser().id,
        redeemStatus: form.status.value,
        notes: form.notes ? form.notes : ''
      }

      if (form.status_pengiriman) {
        payloadRedeem.status = form.status_pengiriman.value
      }

      RedeemService
        .patchRedeem(this.$route.params.id, payload)
        .then((rsp) => {
          // if (form.status.value != 'reject') {
          RedeemMilestoneService.postRedeemMilestones(payloadRedeem).then((r) =>
            this.$swal({
              title: "Data berhasil disimpan",
              showCloseButton: true,
            }).then(() => {
              this.$router.push({ path: '/redeem/list' })
            })).catch((e) => {
              if (e.response?.status === 409) {
                this.$notify({
                  type: "warn",
                  title: "Error Message",
                  text: "Data Sudah Ada",
                });
              } else {
                this.$notify({
                  type: "error",
                  title: "Error Message",
                  text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
                });
              }

            })
          // } else {
          //   this.$swal({
          //     title: "Data berhasil disimpan",
          //     showCloseButton: true,
          //   }).then(() => {
          //     this.$router.push({ path: '/redeem/list' })
          //   })
          // }
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          }
        }).finally(() =>
          this.onLoading = false)
    },

    loadItems(id) {
      RedeemService.getRedeemById(id)
        .then(async ({ data: { data } }) => {
          let { customer_id, reward_id } = data
          let { full_name, phoneNumber, address, email } = (await this.loadCustomerByID(customer_id)).customer;
          let { name, reward_type } = (reward_id != 0) ? await this.loadRewardById(reward_id) : ''
          let { respMilestones } = await this.loadRedeemMilestones(id)
          let findStatus = this.optionsStatusPengirimanMilestones.find((r) => r.value == respMilestones.status)
          this.form = {
            ...data,
            customer_id,
            reward_type,
            reward_id,
            full_name,
            phoneNumber,
            address,
            nameReward: name,
            incoming_request: data.incoming_request,
            status: this.optionsStatusPengiriman.find((r) => r.value == data.status),
            nomor: data.notes,
            status_pengiriman: findStatus,
            notes: respMilestones.notes ? respMilestones.notes : '',
            reason: data.reason ? data.reason : '',
            email: email
          }

        }).catch((e) => {
          // console.log(e)
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }).finally(() =>
          this.onLoading = false)
    },
    loadCustomerByID(id) {
      return CustomerService.getCustomerById(id)
        .then(async ({ data: { data } }) => {
          return Promise.resolve({ ...data })
        }).catch((e) => {
          return Promise.reject({ ...e })
        })
    },
    loadRewardById(id) {
      return RewardService
        .getRewardById(id)
        .then(({ data: { data } }) => {
          return Promise.resolve({ ...data })
        }).catch((e) => {
          return Promise.reject({ ...e })
        })
    },

    loadRedeemMilestones(id) {
      this.isLoading = true;
      return RedeemMilestoneService
        .getRedeemMilestonesList({ redeem_id: id })
        .then(async ({ data: { data } }) => {
          let { redeemMilestone } = data
          let maxLength = redeemMilestone.length
          if (redeemMilestone.length > 0) {
            return Promise.resolve({ respMilestones: redeemMilestone[maxLength - 1] })
          } else {
            return Promise.resolve({ respMilestones: {} })
          }
        }).catch((e) => {
          return Promise.reject({ ...e })
        })
    },
  },
};
</script>
