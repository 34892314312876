<template>
  <ul class="nav nav-tabs nav-tabs--wp">
    <li class="nav-item">
      <router-link to="/setting/site" class="nav-link" active-class="active" exact>
        Site
      </router-link>
    </li>
    <li class="nav-item">
      <router-link to="/setting/seo" class="nav-link" active-class="active" exact>
        SEO
      </router-link>
    </li>
    <li class="nav-item">
      <router-link to="/setting/social-media" class="nav-link" active-class="active" exact>
        Social Media
      </router-link>
    </li>
    <li class="nav-item">
      <router-link to="/setting/loyalty" class="nav-link" active-class="active" exact>
        Loyalty
      </router-link>
    </li>
    <li class="nav-item">
      <router-link to="/setting/freesample" class="nav-link" active-class="active" exact>
        Free Sample
      </router-link>
    </li>
  </ul>
</template>

<script>
  export default {
    name: 'SettingNav',
  }
</script>
