<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">All Users</h1>
        <!-- END page-header -->
        <div class="col-auto mb-15px" v-if="exportExcel">
          <a class="btn btn-outline-primary" @click.prevent="downloadExcel">
            <i class="fa-solid fa-file-import"></i>
            Export
          </a>
        </div>
      </div>
      <div class="col-auto mb-3" v-if="add">
        <router-link to="/users/manage/user/add">
          <a class="btn btn-outline-primary"> Add New User</a>
        </router-link>
      </div>
    </div>

    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
        setCurrentPage: setCurrentPage,
        perPage: serverParams.take,
      }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'email', type: 'asc' },
}" styleClass="vgt-table" slot="outsideBody">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="email" class="form-control" placeholder="Search user" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'email'
          " @input="(value) => updateFilters(column, $refs.email.value)" />
        <input ref="role_cms.role_name" class="form-control" placeholder="Search role" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'role_cms.role_name'
          " @input="(value) => updateFilters(column, $refs['role_cms.role_name'].value)" />
        <select ref="status" class="form-select" placeholder="Cari productCategory" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'status'
          " @change="(value) => updateFilters(column, $refs['status'].value)">
          <option />
          <option v-for="(option, key) in optionsStatus" v-bind:value="option" :key="key" v-text="option" />
        </select>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end" v-if="props.row.status !== 'Deleted'">
            <div class="col-auto" v-if="edit">
              <router-link :to="`/users/manage/user/edit/${props.row.id}`" class="btn btn-lg text-dark" v-b-tooltip.hover
                title="Edit">
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>
            <div class="col-auto" v-if="deleteUser">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                @click.prevent="showAlertConfirm(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else :class="[props.row.status === 'Deleted' && 'text-muted']">
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import UserService from "@/service/user.service";
import tokenService from "@/service/token.service";
import _ from 'lodash';
export default {
  data() {
    return {
      setCurrentPage: this.$store.state.user.serverParams.skip / this.$store.state.user.serverParams.take == 0 ?
        1 : this.$store.state.user.serverParams.skip / this.$store.state.user.serverParams.take + 1,
      permisions: tokenService.getPermission(),
      optionsStatus: ['Publish', 'Draft'],
      isLoading: false,
      columns: [
        {
          label: "Username",
          field: "email",
          thClass: "text-nowrap",
          tdClass: "align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Role",
          field: "role_cms.role_name",
          thClass: "text-nowrap",
          tdClass: "align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Status",
          field: "status",
          thClass: "text-nowrap",
          tdClass: "align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "text-end text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: this.$store.state.user.serverParams,
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.$store.state.user.serverParams = Object.assign({}, this.$store.state.user.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ skip: 0 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams();
      this.loadItems();
    },

    onColumnFilter: _.debounce(function (params) {
      let payload = {}
      if (params.columnFilters.status && params.columnFilters.status != '') {
        payload.status = params.columnFilters.status ? params.columnFilters.status : ''
      } else {
        delete this.$store.state.user.serverParams.status
      }

      if (params.columnFilters.email && params.columnFilters.email != '') {
        payload.email = params.columnFilters.email
      } else {
        delete this.$store.state.user.serverParams.email
      }

      if (params.columnFilters['role_cms.role_name'] && params.columnFilters['role_cms.role_name'] != '') {
        payload.role = params.columnFilters['role_cms.role_name']
      } else {
        delete this.$store.state.user.serverParams.status
      }

      this.updateParams(payload);
      this.loadItems();
    }, 300),
    loadItems() {
      this.isLoading = true;
      UserService
        .getListUser(this.$store.state.user.serverParams)
        .then(({ data: { data } }) => {
          // console.log(data.users)
          this.rows = data.users
          this.totalRecords = data.totalData
        }).catch((e) => {
          if (e.response.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },
    doDelete(id) {
      this.isLoading = true;
      UserService
        .deleteUserById(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },
    downloadExcel() {
      this.isLoading = true;
      let { take, skip, sort, ...NewParams } = this.serverParams
      let payload = {
        ...NewParams
      }
      UserService.exportExcel(payload)
        .then(({ data: { data } }) => {
          let { path } = data
          if (path) {
            window.open(this.$api_name + '/' + path, '_blank')
          }
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    }
  },
  computed: {
    exportExcel: function () {
      if (this.permisions.find((r) => r == 'UserCms Export')) {
        return true
      }
      return false;
    },
    edit: function () {
      if (this.permisions.find((r) => r == 'UserCms Patch')) {
        return true
      }
      return false;
    },
    add: function () {
      if (this.permisions.find((r) => r == 'UserCms Add')) {
        return true
      }
      return false;
    },
    deleteUser: function () {
      if (this.permisions.find((r) => r == 'UserCms Delete')) {
        return true
      }
      return false;
    },
  },
  mounted() {
    this.loadItems();
  },
};
</script>
