<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">Reward Catalogue</h1>
        <!-- END page-header -->
      </div>
      <div class="col-auto mb-3" v-if="add">
        <router-link to="/reward/add">
          <a class="btn btn-outline-primary">Add New Reward</a>
        </router-link>
      </div>
    </div>
    <ul class="nav nav-tabs nav-tabs--wp">
      <li class="nav-item">
        <router-link to="/reward/list" class="nav-link" active-class="active" exact>
          List
        </router-link>
      </li>
    </ul>
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
        setCurrentPage: setCurrentPage,
        perPage: serverParams.take,
      }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'name', type: 'asc' },
}" styleClass="vgt-table" slot="outsideBody">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="name" class="form-control" placeholder="Search Catalogue" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'name'
          " @input="(value) => updateFilters(column, $refs.name.value)" />
        <input ref="stock" class="form-control" placeholder="Search Stock" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'stock'
          " @input="(value) => updateFilters(column, $refs.stock.value)" />

        <input ref="price_point" class="form-control" placeholder="Search Point Charge" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'price_point'
          " @input="(value) => updateFilters(column, $refs['price_point'].value)" />
        <select ref="is_active" class="form-select" placeholder="Cari productCategory" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'is_active'
          " @change="(value) => updateFilters(column, $refs['is_active'].value)">
          <option />
          <option v-for="(option, key) in optionsStatus" v-bind:value="option" :key="key" v-text="option" />
        </select>
        <select ref="reward.label" class="form-select" placeholder="Cari productCategory" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'reward.label'
          " @change="(value) => updateFilters(column, $refs['reward.label'].value)">
          <option />
          <option v-for="(option, key) in optionsRewards" v-bind:value="option.value" :key="key" v-text="option.label" />
        </select>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end" v-if="props.row.status !== 'Deleted'">
            <div class="col-auto" v-if="edit">
              <router-link :to="`/reward/edit/${props.row.id}`" class="btn btn-lg text-dark" v-b-tooltip.hover
                title="Edit">
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>
            <div class="col-auto" v-if="deleteRewardCatalogue">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                @click.prevent="showAlertConfirm(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else :class="[props.row.status === 'Deleted' && 'text-muted']">
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import RewardService from "@/service/reward.service";
import tokenService from "@/service/token.service";
import _ from 'lodash';
export default {
  data() {
    return {
      setCurrentPage: this.$store.state.reward.serverParams.skip / this.$store.state.reward.serverParams.take == 0 ?
        1 : this.$store.state.reward.serverParams.skip / this.$store.state.reward.serverParams.take + 1,
      optionsStatus: ['Publish', 'Draft'],
      isLoading: false,
      optionsRewards: [
        {
          label: 'Voucher',
          value: 'voucher'
        },
        {
          label: 'Physical Goods',
          value: 'physical_goods'
        },
        {
          label: 'Point',
          value: 'point'
        },
        {
          label: 'Free Sample',
          value: 'free_sample'
        },
        {
          label: 'Main Free Sample',
          value: 'main_free_sample'
        }
      ],
      columns: [
        {
          label: "Catalogue Name",
          field: "name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Stock Info",
          field: "stock",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Point Charge",
          field: "price_point",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Reward Type",
          field: "reward.label",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Status",
          field: "is_active",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "text-end text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: this.$store.state.reward.serverParams,
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
      permisions: tokenService.getPermission()
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.$store.state.reward.serverParams = Object.assign({},this.$store.state.reward.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ skip: 0 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({});
      this.loadItems();
    },

    onColumnFilter: _.debounce(function (params) {
      // console.log(params)
      let payload = {}
      if (params.columnFilters.is_active && params.columnFilters.is_active != '') {
        payload.is_active = params.columnFilters.is_active
      } else {
        delete this.$store.state.reward.serverParams.is_active
      }

      if (params.columnFilters.stock && params.columnFilters.stock != '') {
        payload.stock = params.columnFilters.stock ? params.columnFilters.stock : ''
      } else {
        delete this.$store.state.reward.serverParams.stock
      }

      if (params.columnFilters.name && params.columnFilters.name != '') {
        payload.name = params.columnFilters.name ? params.columnFilters.name : ''
      } else {
        delete this.$store.state.reward.serverParams.name
      }

      if (params.columnFilters['reward.label'] && params.columnFilters['reward.label'] != '') {
        payload.reward_type = params.columnFilters['reward.label'] ? params.columnFilters['reward.label'] : ''
      } else {
        delete this.$store.state.reward.serverParams.reward_type
      }

      if (params.columnFilters['price_point'] && params.columnFilters['price_point'] != '') {
        payload.price_point = params.columnFilters['price_point'] ? params.columnFilters['price_point'] : ''
      } else {
        delete this.$store.state.reward.serverParams.price_point
      }

      this.updateParams(payload);
      this.loadItems();
    }, 300),
    loadItems() {
      this.isLoading = true;
      RewardService
        .getRewardList(this.$store.state.reward.serverParams)
        .then(({ data: { data } }) => {
          let arrData = []
          if (data.rewardCatalogue.length > 0) {
            for (const [k, v] of Object.entries(data.rewardCatalogue)) {
              arrData.push({
                ...v,
                reward: this.optionsRewards.find((r) => r.value == v.reward_type)
              })
            }
          }
          this.rows = arrData
          this.totalRecords = data?.totalData
        }).catch((e) => {
          // console.log(e)
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },

    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },
    doDelete(id) {
      this.isLoading = true;
      RewardService
        .deleteRewardById(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    }
  },
  computed: {
    add: function () {
      if (this.permisions.find((r) => r == 'RewardCatalogue Add')) {
        return true
      }
      return false;
    },
    edit: function () {
      if (this.permisions.find((r) => r == 'RewardCatalogue Patch')) {
        return true
      }
      return false;
    },
    deleteRewardCatalogue: function () {
      if (this.permisions.find((r) => r == 'RewardCatalogue Delete')) {
        return true
      }
      return false;
    },
  },
  mounted() {
    this.loadItems();
  },
};
</script>
