import api from "./api";

class ContactUsService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('contact-us')
    }

    getContactUsList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postContactUs(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getContactUsById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchContactUs(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteContactUsById(serverParams) {
        return this.api.delete("/" + serverParams);
    }

    getContactUsExport(serverParams) {
        return this.api.get("/export/excel", { params: serverParams });
    }
}

export default new ContactUsService();