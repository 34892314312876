<template>
  <form @submit.prevent="doSave('Publish')" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          {{ formTitle }} User
        </h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="mb-15px">
          <input type="email" class="form-control form-control-lg" placeholder="Username/email" v-model="user.email" />
          <span class="text-danger" v-if="$v.user.email.$error">Email/Username is required</span>
        </div>

        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Nama</label>
                  <input type="text" class="form-control" v-model="user.name" />
                  <span class="text-danger" v-if="$v.user.name.$error">Name Required</span>
                </div>
              </div>
              <div class="col-md-6">
                <label class="form-label">Role</label>
                <v-select :clearable="false" v-model="user.role.value" :options="user.role.options" label="role_name" />
                <span class="text-danger" v-if="$v.user.role.value.$error">Role is required</span>
              </div>
              <div class="col-md-6">
                <label class="form-label">Phone Number</label>
                <div class="input-group">
                  <span class="input-group-text">+62</span>
                  <input type="text" class="form-control" v-model="user.phoneNumber" :maxlength="11"
                    v-on:keypress="isNumber" />
                </div>
                <div>Note: Phone Number must be at least {{ $v.user.phoneNumber.$params.minLength.min }}</div>
                <div class="text-danger" v-if="$v.user.phoneNumber.$error">Phone Number must have at most
                  {{ $v.user.phoneNumber.$params.minLength.min }} letters.</div>
              </div>
              <div class="col-md-6">
                <label class="form-label">Password</label>
                <div class="input-group">
                  <input :type="passwordType" class="form-control" v-model.trim="user.password"
                    :disabled="!changePassword && formType === 'edit'" />
                  <span v-if="(formType === 'edit' && changePassword) || formType === 'add'" class="input-group-text"><i
                      :class="passwordClass" id="togglePassword" @click="showPassword(1)"></i></span>
                </div>
                <span class="text-danger" v-if="$v.user.password.$error">Password must have at least 1 Uppercase, 1
                  Lowercase, 1 Number, 1 Special Character </span>
              </div>
              <div v-if="(changePassword && formType === 'edit') || formType === 'add'" class="col-md-6">
                <label class="form-label">Confirmasi Password</label>
                <div class="input-group">
                  <input :type="rePasswordType" class="form-control" v-model.trim="user.rePassword" />
                  <span class="input-group-text"><i :class="rePasswordClass" id="togglePassword"
                      @click="showPassword(2)"></i></span>
                </div>
                <span class="text-danger" v-if="!$v.user.rePassword.sameAsPassword">Konfirmasi Password Tidak Sama dengan
                  Password</span>
                <span class="text-danger" v-if="$v.user.rePassword.$error">Confirm Password Required</span>
              </div>
              <div v-else class="col-md-2">
                <div class="mb-15px">
                  <br>
                  <button type="button" class="form-control btn btn-primary" @click.prevent="doChangePassword">
                    Change Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="button" class="btn btn-outline-primary" @click.prevent="doSave('Draft')">
            Save as Draft
          </button>
        </div>
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../../config/Helpers";
import RoleService from "@/service/role.service";
import { minLength, required, requiredIf, sameAs } from "vuelidate/lib/validators";

export default {
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;
    this.loadItems()
    return {
      customToolbar: defaultToolbarEditor,
      msgRePassword: null,
      changePassword: false,
      passwordClass: "fas fa-eye",
      passwordType: 'password',
      rePasswordClass: "fas fa-eye",
      rePasswordType: 'password',
      user: {
        name: detail ? detail.username : null,
        email: null,
        phoneNumber: null,
        password: null,
        rePassword: null,
        role: {
          value: {},
          options: []
        }
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
  },
  watch: {
    'detailData'(newVal) {
      this.user = newVal
    },
    'user.rePassword'(newVal) {
      if (this.user.password != newVal) {
        this.msgRePassword = 'Password Tidak Sama'
      } else {
        this.msgRePassword = null
      }
    }
  },
  methods: {
    doSave(status) {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const payload = {
          user: this.user,
          status,
          changePassword: this.changePassword
        };

        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
    loadItems() {
      this.isLoading = true;
      RoleService
        .getRoleList(this.serverParams)
        .then(({ data: { data } }) => {
          this.user.role.options = data.RoleCms
        }).catch((e) => {
          // console.log(e)
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.user.role.options = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    doChangePassword() {
      this.changePassword = true
      this.user.password = ''
      this.user.rePassword = ''
    },
    showPassword(type) {
      if (type === 1) {
        this.passwordType =
          this.passwordType === "password" ? "text" : "password";
        this.passwordClass =
          this.passwordType === "password"
            ? "fas fa-eye"
            : "fas fa-eye-slash";
      } else {
        this.rePasswordType =
          this.rePasswordType === "password" ? "text" : "password";
        this.rePasswordClass =
          this.rePasswordType === "password"
            ? "fas fa-eye"
            : "fas fa-eye-slash";
      }
    },

    isNumber(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[1-9]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    }
  },
  validations: {
    user: {
      name: { required },
      email: { required },
      phoneNumber: { minLength: minLength(10) },
      password: {
        valid: function (value) {
          if (!this.changePassword) {
            return true
          }
          const containsUppercase = /[A-Z]/.test(value)
          const containsLowercase = /[a-z]/.test(value)
          const containsNumber = /[0-9]/.test(value)
          const containsSpecial = /[#?!@$%^&*-]/.test(value)
          return containsUppercase && containsLowercase && containsNumber && containsSpecial
        },
      },
      rePassword: {
        required: requiredIf(function (nestedModel) {
          if (!this.changePassword) {
            return false && nestedModel.rePassword
          } else {
            return true && nestedModel.rePassword
          }
        }),
        sameAsPassword: sameAs(function () {
          return this.user.password;
        })
      },
      role: {
        value: { required }
      }
    },
  },
};
</script>
