import api from "./api";

class ProductCategoryService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('product-category')
    }
    
    getProductCategoryList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postProductCategory(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getProductCategoryById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchProductCategory(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteProductCategoryById(serverParams) {
        return this.api.delete("/" + serverParams);
    }
}

export default new ProductCategoryService();