<template>
  <form @submit.prevent="doSave" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          Information Free Sample Submission
        </h1>
      </div>
      <div class="col-lg-12">
        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Nama</label>
                  <input type="text" class="form-control" v-model="form.full_name" disabled />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">No. Handphone</label>
                  <input type="text" class="form-control" v-model="form.phoneNumber" disabled />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Email</label>
                  <input type="email" class="form-control" v-model="form.email" disabled />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Link Google Form</label>
                  <div class="input-group">
                    <span class="input-group-text"><i class="fa-solid fa-link"></i></span>
                    <input type="url" class="form-control" placeholder="https://shopee.com/GoonIndonesia"
                      v-model="form.form_url">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label d-block mb-2">Status</label>
                  <div class="pt-1">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="status" id="status1" value="approve"
                        v-model="form.sample_state">
                      <label class="form-check-label" for="status1">Approve</label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="status" id="status2" value="pending"
                        v-model="form.sample_state">
                      <label class="form-check-label" for="status2">Pending</label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="status" id="status3" value="reject"
                        v-model="form.sample_state">
                      <label class="form-check-label" for="status3">Reject</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6" v-if="form.sample_state == 'reject'">
                <div class="mb-15px">
                  <label class="form-label">Reason</label>
                  <div class="input-group">
                    <input type="text" class="form-control" v-model="form.reject_reason">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">Save</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // console.log(this.detailData);
  },
  data() {
    const detail = this.detailData;

    return {
      form: {
        user_id: detail ? detail.full_name : null,
        full_name: detail ? detail.full_name : null,
        email: detail ? detail.email : null,
        phoneNumber: detail ? detail.phoneNumber : null,
        form_url: detail ? detail.form_url : null,
        sample_state: detail ? detail.sample_state : null,
        reject_reason: detail ? detail.sample_state : null
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
  },
  watch: {
    detailData(newVal) {
      // console.log(newVal)
      this.form = {
        user_id: newVal.user_id,
        full_name: newVal.customer.full_name,
        email: newVal.customer.email,
        phoneNumber: newVal.customer.phoneNumber,
        form_url: newVal.form_url,
        sample_state: newVal.sample_state,
        reject_reason: newVal.reject_reason
      };
    },
  },
  methods: {
    doSave() {
      const payload = {
        form: this.form,
      };
      // console.log(payload);

      this.$emit("onValidateSuccess", payload);
    },
  },
};
</script>
