<template>
    <form @submit.prevent="doSave('Publish')" class="pb-5 mb-4">
        <div class="row">
            <div class="col-12">
                <h1 class="page-header">
                    <button class="btn btn-primary" @click.prevent="$router.go(-1)">
                        <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                        <i class="fa fa-arrow-left" aria-hidden="true"></i>
                        Back
                    </button>
                    {{ formTitle }} Parent Category
                </h1>
            </div>
            <div class="col-lg-9 col-md-8">
                <div class="mb-15px">
                    <input type="text" class="form-control form-control-lg" placeholder="Parent Name"
                        v-model="parent.name" />
                    <span class="text-danger" v-if="$v.parent.name.$error">Parent Category Name is required</span>
                </div>
                <h3 class="fw-normal mb-15px pt-3">Parent Category Information</h3>
                <panel title="Parent Category" variant="inverse" :is-collapse="true">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label">Icon</label>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-15px">
                                <p class="mb-2">version : 55x55 px, max. 1mb, (jpeg, jpg, png)</p>
                                <UploadImage v-model="parent.image" size="1024" :src="parent.imageSrc"
                                    :dimensions="{ width: 55, height: 55 }" @image-err-message="imgErrMessage" />
                                <span class="text-danger" v-if="$v.parent.image.$error">Icon is required</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="mb-15px">
                            <label class="form-label">Alt</label>
                            <input type="text" v-model="parent.alt_icon_image" class="form-control"
                                placeholder="Alt text" />
                            <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
                        </div>
                    </div>
                </panel>
            </div>
        </div>
        <div class="form-action">
            <div class="row justify-content-end">
                <div class="col-auto">
                    <button type="submit" class="btn btn-primary">
                        <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                        Save
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>
  
<script>
import { defaultToolbar } from "../../../config/Helpers";
import UploadImage from "../../../components/upload/Image.vue";
import { required } from "vuelidate/lib/validators/";
import dateFormat from "@/pages/Helper/formate_date";

export default {
    components: {
        UploadImage,
    },
    props: {
        formType: {
            type: String,
            default: "add",
        },
        detailData: {
            type: Object,
            default: null,
        },
        onLoading: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        // console.log(this.detailData);
    },
    data() {
        const detail = this.detailData;
        const defaultToolbarEditor = defaultToolbar;
        return {
            dateFormat,
            optionsCategory: ["Pants L", "Outer Limit"],
            optionsType: ["Pants L", "Outer Limit"],
            customToolbar: defaultToolbarEditor,
            parent: {
                name: detail ? detail.name : null,
                image: detail ? detail.images : null,
                imageSrc: detail ? detail.imageSrc : '',
            },
        };
    },
    computed: {
        formTitle() {
            return this.formType === "add" ? "Add" : "Edit";
        },
    },
    methods: {
        doSave(status) {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                const payload = {
                    parent: this.parent,
                };

                this.$emit("onValidateSuccess", payload);
            } else {
                this.$notify({
                    type: "warn",
                    title: "Form",
                    text: "Form Belum Lengkap",
                });
            }
        },
        imgErrMessage(mssg) {
            this.$notify({
                type: "error",
                title: "Image Notification",
                text: mssg,
            });
        },
    },
    watch: {
        'detailData'(newVal) {
            this.parent = newVal
        }
    },
    validations: {
        parent: {
            name: { required },
            image: { required },
        },
    },
};
</script>
  