<template>
  <div class="product-form">
    <FormPost form-type="add" @onValidateSuccess="doSave" :on-loading="onLoading" />
  </div>
</template>
<script>
import FormPost from "./Form.vue";
import CareerService from "@/service/career.service";
import base64Converter from "@/pages/Helper/base64";

export default {
  data() {
    return {
      onLoading: false
    }
  },
  components: {
    FormPost,
  },

  methods: {
    async doSave(payload) {

      this.onLoading = true
      let { form, status } = payload
      if (form) {
        payload = {
          "position": form.title,
          "description": form.description,
          "location": form.location,
          "image": await base64Converter(form.images.desktop[0]),
          "career_category_id": form.category.id,
          career_type_id: form.type ? form.type : null,
          career_level_id: form.level ? form.level : null,
          level: form.level ? form.level : null,
          alt_image: form.images.alt_image ? form.images.alt_image : null,
          alt_desktop_image: form.images.alt_desktop_image ? form.images.alt_desktop_image : null,
          alt_mobile_image: form.images.alt_mobile_image ? form.images.alt_mobile_image : null,
          desktop_image: (form.images.desktop && form.images.desktop.length > 0) ?
            await base64Converter(form.images.desktop[0]) : null,
          mobile_image: (form.images.mobile && form.images.mobile.length > 0) ?
            await base64Converter(form.images.mobile[0]) : null,
          excerpt: form.excerpt ? form.excerpt : null,
          "seoInformation": {
            "meta_title": form.meta.title,
            "meta_description": form.meta.description,
            "meta_keyword": form.meta.keywords.toString(),
            alt_image: form.meta.alt_image ? form.meta.alt_image : null,
          },
          status
        }

        if (form.meta.image) {
          payload.seoInformation.image = await base64Converter(form.meta.image[0])
        }

        CareerService.postCareer(payload).then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: '/career/list' })
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          }
        }).finally(() =>
          this.onLoading = false)
      }
    },
  },
};
</script>
