import api from "./api";

class ParentCategoryService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('product-parent-category')
    }

    getParentCategoryList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postParentCategory(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getParentCategoryById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchParentCategory(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteParentCategoryById(serverParams) {
        return this.api.delete("/" + serverParams);
    }
}

export default new ParentCategoryService();