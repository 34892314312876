<template>
  <div class="product-form">
    <FormPost form-type="add" @onValidateSuccess="doSave" :on-loading="onLoading" />
  </div>
</template>
<script>
import FormPost from "./Form.vue";
import statusgiziService from "@/service/statusgizi.service";
import base64Converter from "@/pages/Helper/base64";
import slugify from "@/pages/Helper/slug";
import moment from "moment";

export default {
  components: {
    FormPost,
  },
  data() {
    return {
      onLoading: false
    }
  },
  methods: {
    async doSave(payload) {
      this.onLoading = true
      let { form, status } = payload
      if (form) {
        payload = {
          age_in_month1: form.age_in_month1 ? form.age_in_month1 : 0,
          age_in_month2: form.age_in_month2 ? form.age_in_month2 : 0,
          threshold1_id: form.threshold1_id ? form.threshold1_id : 0,
          threshold2_id: form.threshold2_id ? form.threshold2_id : 0,
          nutritional_status_id: form.nutritional_status_id ? form.nutritional_status_id : 0,
          growth_type: form.growth_type ? form.growth_type : null
        }

        statusgiziService.postStatusGizi(payload).then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: '/tumbuh-kembang/status-gizi' })
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          }
        }).finally(() =>
          this.onLoading = false)
      }
    },
    dateFormat(date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
    }
  },
};
</script>
