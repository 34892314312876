<template>
  <div>
    <!-- BEGIN page-header -->
    <h1 class="page-header">Dashboard</h1>
    <!-- END page-header -->

    <!-- BEGIN row -->
    <div class="panel">
      <div class="panel-heading">
        <div class="row">
          <label class="form-label col-form-label col-auto">Date range</label>
          <div class="form-group col-auto">
            <date-range-picker ref="picker" class="btn btn-white" :opens="dateRange.opens"
              :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }" :singleDatePicker="dateRange.singleDatePicker"
              :timePicker="dateRange.timePicker" :timePicker24Hour="dateRange.timePicker24Hour"
              :showWeekNumbers="dateRange.showWeekNumbers" :showDropdowns="dateRange.showDropdowns"
              :autoApply="dateRange.autoApply" v-model="dateRange.range" @update="updateValues"
              :linkedCalendars="dateRange.linkedCalendars">
              <template v-slot:input="picker">
                <i class="fa fa-calendar fa-fw ms-n1"></i>
                {{ picker.startDate | date }} - {{ picker.endDate | date }}
                <b class="caret ms-1 opacity-5"></b>
              </template>
            </date-range-picker>
          </div>
          <div class="col-auto">
            <button type="button" class="btn btn-outline-primary" @click="loadItems()">
              Filter
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-xl-3">
        <div class="widget widget-stats bg-primary">
          <div class="stats-info">
            <h4 class="text-uppercase">TOTAL REGISTRASI</h4>
            <p>{{ totalRegistration }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3">
        <div class="widget widget-stats bg-yellow">
          <div class="stats-info text-dark">
            <h4 class="text-uppercase text-dark">TOTAL CLAIM POIN</h4>
            <p>{{ totalClaimPoint }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3">
        <div class="widget widget-stats bg-lime">
          <div class="stats-info">
            <h4 class="text-uppercase">TOTAL REDEEM</h4>
            <p>{{ totalRedeem }}</p>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xl-3">
        <div class="widget widget-stats bg-red">
          <div class="stats-info">
            <h4 class="text-uppercase"> TOTAL DUPLIKASI CUSTOMER SURVEY SUBMISSION</h4>
            <p>{{ totalDuplicate }}</p>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-6 col-xl-3">
        <div class="widget widget-stats bg-green">
          <div class="stats-info">
            <h4 class="text-uppercase">TOTAL APE (Rp)</h4>
            <p>3,291,922</p>
          </div>
          <div class="stats-link">
            <a href="javascript:;">View Metric <i class="fa fa-chevron-right"></i></a>
          </div>
        </div>
      </div> -->
    </div>

    <div class="row">
      <div class="col-xl-4">
        <panel title="TOTAL REGISTRASI" :noButton="true">
          <apexchart type="bar" :options="barChartRegistration.options" :series="barChartRegistration.series"
            height="350">
          </apexchart>
        </panel>
      </div>

      <div class="col-xl-4">
        <panel title="TOTAL CLAIM POIN" :noButton="true">
          <apexchart type="bar" :options="barChartClaims.options" :series="barChartClaims.series" height="350">
          </apexchart>
        </panel>
      </div>


      <div class="col-xl-4">
        <panel title="TOTAL REDEEM" :noButton="true">
          <apexchart type="bar" :options="barChartRedeem.options" :series="barChartRedeem.series" height="350">
          </apexchart>
        </panel>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-4">
        <panel title="ACTIVE USER BULAN INI" :noButton="true">
          <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecordsCustomer"
            :isLoading.sync="isLoading" :pagination-options="{
              enabled: false,
              dropdownAllowAll: false,
            }" :columns="columnsCustomer" :rows="rowsCustomer" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'order', type: 'desc' },
}" styleClass="vgt-table" slot="outsideBody" :line-numbers="true">
            <template slot="column-filter" slot-scope="{ column, updateFilters }">
            </template>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'action'">
              </span>
              <span v-else :class="[props.row.status === 'Deleted' && 'text-muted']">
                <div v-if="props.column.field == 'question'"
                  v-html="replaceRegex(props.formattedRow[props.column.field])">
                </div>
                <div v-else>
                  {{ props.formattedRow[props.column.field] }}
                </div>
              </span>
            </template>
          </vue-good-table>
        </panel>
      </div>
      <div class="col-xl-4">
        <panel title="LEADERBOARD POINT" :noButton="true">
          <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
            :isLoading.sync="isLoading" :pagination-options="{
              enabled: false,
              dropdownAllowAll: false,
            }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'order', type: 'desc' },
}" styleClass="vgt-table" slot="outsideBody" :line-numbers="true">
            <template slot="column-filter" slot-scope="{ column, updateFilters }">
            </template>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'action'">
                <div class="row g-2 align-items-center justify-content-end" v-if="props.row.status !== 'Deleted'">
                  <div class="col-auto" v-if="edit">
                    <router-link :to="`/survey/edit/${props.row.id}`" class="btn btn-lg text-dark" v-b-tooltip.hover
                      title="Edit">
                      <i class="fas fa-pencil"></i>
                    </router-link>
                  </div>

                  <div class="col-auto" v-if="deleteSurvey">
                    <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                      @click.prevent="showAlertConfirm(props.row.id)">
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </div>
              </span>
              <span v-else :class="[props.row.status === 'Deleted' && 'text-muted']">
                <div v-if="props.column.field == 'question'"
                  v-html="replaceRegex(props.formattedRow[props.column.field])">
                </div>
                <div v-else>
                  {{ props.formattedRow[props.column.field] }}
                </div>
              </span>
            </template>
          </vue-good-table>
        </panel>
      </div>
    </div>
    <!-- END row -->
  </div>
</template>

<script>
import LineChart from "../components/vue-chartjs/LineChart";
import moment from "moment";
import DashboardService from "@/service/dashboard.service";
import TokenService from "@/service/token.service";
import formatUtc from "@/pages/Helper/formatUTC";
import _ from 'lodash';

export default {
  components: {
    LineChart,
  },
  data() {
    return {
      isLoading: false,
      totalDuplicate: 0,
      totalRedeem: 0,
      totalClaimPoint: 0,
      totalRegistration: 0,
      columns: [
        {
          label: "Name",
          field: "full_name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
        },
        {
          label: "Point",
          field: "total_points",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
        },
      ],
      rows: [],
      totalRecords: 0,
      dateRange: {
        opens: "right",
        singleDatePicker: false,
        timePicker: false,
        timePicker24Hour: false,
        showWeekNumbers: false,
        showDropdowns: false,
        autoApply: false,
        linkedCalendars: false,
        range: {
          startDate: moment().startOf('year').format('YYYY-MM-DD'),
          endDate: moment(Date.now()).add(1, "month").format("YYYY-MM-DD"),
          prevStartDate: moment().subtract("days", 15).format("D MMMM"),
          prevEndDate: moment().subtract("days", 8).format("D MMMM YYYY"),
        },
      },
      barChartRegistration: {
        options: {
          chart: {
            height: 250,
            type: "bar",
            toolbar: {
              show: false
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              distributed: false,
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter(val) {
              return new Intl.NumberFormat("id-ID").format(val);
            },
            offsetX: -5,
            style: {
              fontSize: "12px",
              colors: ["#333333"],
            },
          },
          xaxis: {
            categories: [],
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            tooltip: {
              enabled: true,
            },
            labels: {
              show: false,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: true,
            },
          },
          grid: {
            show: false
          },
        },
        series: [
          {
            name: "Data",
            data: [],
          },
        ],
        noData: {
          text: "No Data",
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '14px',
            fontFamily: undefined
          }
        }
      },
      barChartClaims: {
        options: {
          chart: {
            height: 250,
            type: "bar",
            toolbar: {
              show: false
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              distributed: false,
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter(val) {
              return new Intl.NumberFormat("id-ID").format(val);
            },
            offsetX: -5,
            style: {
              fontSize: "12px",
              colors: ["#333333"],
            },
          },
          xaxis: {
            categories: [],
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            tooltip: {
              enabled: true,
            },
            labels: {
              show: false,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: true,
            },
          },
          grid: {
            show: false
          },
          noData: {
            text: "No Data",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: '14px',
              fontFamily: undefined
            }
          }
        },
        series: [
          {
            name: "Data",
            data: [],
          },
        ],
      },
      barChartRedeem: {
        options: {
          chart: {
            height: 250,
            type: "bar",
            toolbar: {
              show: false
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              distributed: false,
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter(val) {
              return new Intl.NumberFormat("id-ID").format(val);
            },
            offsetX: -5,
            style: {
              fontSize: "12px",
              colors: ["#333333"],
            },
          },
          xaxis: {
            categories: [],
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            tooltip: {
              enabled: true,
            },
            labels: {
              show: false,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: true,
            },
          },
          grid: {
            show: false
          },
        },
        series: [
          {
            name: "Data",
            data: [],
          },
        ],
      },
      barChartLeaderBoard: {
        options: {
          chart: {
            height: 250,
            type: "bar",
            toolbar: {
              show: false
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              distributed: false,
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter(val) {
              return new Intl.NumberFormat("id-ID").format(val);
            },
            offsetX: -5,
            style: {
              fontSize: "12px",
              colors: ["#333333"],
            },
          },
          xaxis: {
            categories: [],
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            tooltip: {
              enabled: true,
            },
            labels: {
              show: false,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: true,
            },
          },
          grid: {
            show: false
          },
        },
        series: [
          {
            name: "Data",
            data: [],
          },
        ],
      },
      barChartActiveUser: {
        options: {
          chart: {
            height: 250,
            type: "bar",
            toolbar: {
              show: false
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              distributed: false,
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter(val) {
              return new Intl.NumberFormat("id-ID").format(val);
            },
            offsetX: -5,
            style: {
              fontSize: "12px",
              colors: ["#333333"],
            },
          },
          xaxis: {
            categories: [],
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            tooltip: {
              enabled: true,
            },
            labels: {
              show: false,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: true,
            },
          },
          grid: {
            show: false
          },
        },
        series: [
          {
            name: "Data",
            data: [],
          },
        ],
      },

      columnsCustomer: [
        {
          label: "Name",
          field: "full_name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
        },
        {
          label: "Total Login",
          field: "login_count",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
        },
      ],
      rowsCustomer: [],
      totalRecordsCustomer: 0,
    };
  },
  mounted() {
    if (TokenService.getUser() != null) {
      this.loadItems()
      this.loadChart()
      this.loadDuplikasi()
      this.loadLeaderboard()
      this.loadActiveCustomer()
    }
  },
  methods: {
    updateValues(d) {
      var startDate = moment(d.startDate);
      var endDate = moment(d.endDate);

      this.dateRange.range.startDate = moment(startDate).format("YYYY-MM-DD");
      this.dateRange.range.endDate = moment(endDate).format("YYYY-MM-DD");
    },
    formatDate(d) {
      var monthsName = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      d = new Date(d);
      d = monthsName[d.getMonth()] + " " + d.getDate();
      return d;
    },
    loadItems() {
      this.isLoading = true;
      let { startDate, endDate } = formatUtc(this.dateRange.range.startDate, this.dateRange.range.endDate)
      DashboardService
        .getDashboard({ startDate, endDate })
        .then(({ data: { data } }) => {
          this.totalRedeem = data.totalRedeem ? data.totalRedeem : 0
          this.totalClaimPoint = data.totalClaimPoint ? data.totalClaimPoint : 0
          this.totalRegistration = data.totalRegistration ? data.totalRegistration : 0
        }).catch((e) => {
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadChart() {
      let payload = { localDate: moment().format("Z") }
      DashboardService
        .getDashboardChart(payload)
        .then(({ data: { data } }) => {
          for (const i in data) {
            switch (i) {
              case 'customer':
                if (data[i].length > 0) {
                  let labelRegistration = [], numbersRegistration = [];
                  for (const [k, v] of Object.entries(data[i])) {
                    labelRegistration.push(v.Month)
                    numbersRegistration.push(v.totalData);
                  }

                  this.barChartRegistration.options =
                  {
                    ...this.barChartRegistration.options,
                    ...{
                      xaxis: {
                        categories: labelRegistration
                      }
                    }
                  }
                  this.barChartRegistration.series[0].data = numbersRegistration
                }
                break;
              case 'redeem':
                if (data[i].length > 0) {
                  let labelRegistration = [], numbersRegistration = [];
                  for (const [k, v] of Object.entries(data[i])) {
                    labelRegistration.push(v.Month)
                    numbersRegistration.push(v.totalData);
                  }

                  this.barChartRedeem.options =
                  {
                    ...this.barChartRedeem.options,
                    ...{
                      xaxis: {
                        categories: labelRegistration
                      }
                    }
                  }
                  this.barChartRedeem.series[0].data = numbersRegistration
                }
                break;
              case 'claimPoint':
                if (data[i].length > 0) {
                  let labelRegistration = [], numbersRegistration = [];
                  for (const [k, v] of Object.entries(data[i])) {
                    labelRegistration.push(v.Month)
                    numbersRegistration.push(v.totalData);
                  }

                  this.barChartClaims.options =
                  {
                    ...this.barChartClaims.options,
                    ...{
                      xaxis: {
                        categories: labelRegistration
                      }
                    }
                  }
                  this.barChartClaims.series[0].data = numbersRegistration
                }
                break;

              default:
                break;
            }
          }
        }).catch((e) => {
          // console.log(e)
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadDuplikasi() {
      DashboardService
        .getDashboardDuplikasi({})
        .then(({ data: { data } }) => {
          this.totalDuplicate = data.totalCustomerWithDuplicateAddress
        }).catch((e) => {
          // console.log(e)
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },

    loadLeaderboard() {
      DashboardService
        .getDashboardLeaderboard({})
        .then(({ data: { data } }) => {
          this.rows = data
        }).catch((e) => {
          // console.log(e)
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },


    loadActiveCustomer() {
      DashboardService
        .getDashboardActiveCustomer({})
        .then(({ data: { data } }) => {
          this.rowsCustomer = data.customer
        }).catch((e) => {
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },

    statusClass(row) {
      let statusClass = ''
      switch (row.status) {
        case 'Approved':
          statusClass = 'bg-green text-white align-middle';
          break;
        case 'Pending':
          statusClass = 'bg-orange text-white align-middle';
          break;
        case 'In Review':
          statusClass = 'bg-blue text-white align-middle';
          break;
        case 'Reject':
          statusClass = 'bg-red text-white align-middle';
          break;
        default:
          statusClass = 'bg-orange text-white align-middle';
          break;
      }
      return statusClass
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ skip: 0 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      let payload = {}
      if (params[0].type != 'none') {
        payload.sortByOrder = params[0].type
      } else {
        delete this.serverParams.sortByOrder
      }
      this.updateParams(payload);
      this.loadItems();
    },

    onColumnFilter: _.debounce(function (params) {
      let payload = {}
      if (params.columnFilters.status && params.columnFilters.status != '') {
        payload.status = params.columnFilters.status ? params.columnFilters.status : ''
      } else {
        delete this.serverParams.status
      }

      if (params.columnFilters['createdAt'] && params.columnFilters['createdAt'] != '') {
        let arrSplit = params.columnFilters['createdAt'].split('#')
        if (arrSplit[0] && arrSplit[1]) {
          payload.createdAtStartDate = arrSplit[0] ? moment(arrSplit[0]).startOf('day').toISOString() : ''
          payload.createdAtEndDate = arrSplit[1] ? moment(arrSplit[1]).endOf('day').toISOString() : ''
        }
      } else {
        delete this.serverParams.createdAtStartDate
        delete this.serverParams.createdAtEndDate
      }

      if (params.columnFilters.status_pengiriman && params.columnFilters.status_pengiriman != '') {
        payload.delivery_status = params.columnFilters.status_pengiriman ? params.columnFilters.status_pengiriman : ''
      } else {
        delete this.serverParams.delivery_status
      }

      if (params.columnFilters['category']) {
        payload.typeOfQuestion = params.columnFilters['category']
      } else {
        delete this.serverParams.typeOfQuestion
      }

      if (params.columnFilters['customer.phoneNumber'] && params.columnFilters['customer.phoneNumber'] != '') {
        payload.phoneNumber = params.columnFilters['customer.phoneNumber']
      } else {
        delete this.serverParams.phoneNumber
      }

      if (params.columnFilters['question'] && params.columnFilters['question'] != '') {
        payload.question = params.columnFilters['question']
      } else {
        delete this.serverParams.question
      }

      if (params.columnFilters['RelatedProductCategory.category_name'] && params.columnFilters['RelatedProductCategory.category_name']) {
        payload.relatedProductCategoryName = params.columnFilters['RelatedProductCategory.category_name']
      } else {
        delete this.serverParams.relatedProductCategoryName
      }

      if (params.columnFilters['status'] && params.columnFilters['status'] != '') {
        payload.recordStatus = params.columnFilters['status'] ? params.columnFilters['status'] : ''
      } else {
        delete this.serverParams.recordStatus
      }

      this.updateParams(payload);
      this.loadItems();
    }, 300),

  },
  filters: {
    date: function (value) {
      if (value) {
        return moment(String(value)).format("D MMMM YYYY");
      }
    },
  },

};
</script>
