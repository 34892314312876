import api from "./api";

class PromoCategoryService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('promo-category')
    }
    
    getPromoCategoryList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postPromoCategory(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getPromoCategoryById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchPromoCategory(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deletePromoCategoryById(serverParams) {
        return this.api.delete("/" + serverParams);
    }
}

export default new PromoCategoryService();