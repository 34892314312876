import api from "./api";

export class IdentityService {
  cache = new Map();

  /** @type {ApiAxiosInstance} */
  api

  constructor() {
    this.api = api.create('identity')
  }
  
}

const identityService = new IdentityService()
export default identityService
