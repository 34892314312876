<template>
  <div class="product-form">
    <FormProduct form-type="edit" :detail-data="product" @onValidateSuccess="doSave" :on-loading="onLoading" />
  </div>
</template>
<script>
import FormProduct from "./Form.vue";
import ProductService from "@/service/product.service";
import TokenService from "@/service/token.service";
import base64Converter from "@/pages/Helper/base64";
export default {
  data() {
    return {
      onLoading: false,
      product: {
        id: this.$route.params.id,
        name: null,
        category: null,
        type: null,
        qty_in_box: null,
        description: null,
        images: {
          desktop: null,
          desktopSrc: '',
          mobile: null,
          desktopSrc: '',
          alt: null,
        },
        ecommerce: [],
        testimonials: [],
        meta: {
          title: null,
          description: null,
          keywords: [],
          image: null,
          imageSrc: '',
        },
        publishedDate: null,
        lastModified: null,
        author: null,
      },
    };
  },
  components: {
    FormProduct,
  },
  mounted() {
    this.loadItems(this.$route.params.id)
  },
  methods: {
    async doSave(payload) {
      // Submit here
      this.onLoading = true
      let { product, status, slug } = payload
      if (product) {

        let ecommerces = [], testimonials = [], type = []
        for (const [k, v] of Object.entries(product.ecommerce)) {
          ecommerces.push(v.item.id)
        }

        if (product.testimonials.length > 0) {
          for (const [k, v] of Object.entries(product.testimonials)) {
            let { isShow, image, ...arrData } = v
            // console.log(image[0])
            testimonials.push({
              ...arrData,
              image: image[0] != '' && image.length > 0 ? await base64Converter(image[0]) : null
            })
          }
        }

        if (product.type.length > 0) {
          for (const [k, v] of Object.entries(product.type)) {
            let { id } = v
            type.push(id)
          }
        }
        payload = {
          user_id: (TokenService.getUser()).id,
          "product_title": product.name,
          "qty_in_box": product.qty_in_box,
          "parent_product_category_id": product.categoryParent.id,
          slug,
          "weight": product.weight ? product.weight : null,
          "description": product.description,
          "product_type_id": type,
          "desktop_image": await base64Converter(product.images.desktop[0]),
          "mobile_image": await base64Converter(product.images.mobile[0]),
          "ecommerce_id": ecommerces,
          alt_desktop_image: product.images.alt_desktop_image ? product.images.alt_desktop_image : null,
          alt_mobile_image: product.images.alt_mobile_image ? product.images.alt_mobile_image : null,
          main_image_alt_next: product.images.alt ? product.images.alt : null,
          product_category_id: product.category?.id ? product.category.id : null,
          order: product.order ? product.order : null,
          // video_url: product.video_url ? 'https://youtu.be/4ApqUMIPl0s?si=' + product.video_url : null,
          video_url: product.video_url ? product.video_url : null,
          // excerpt_video: product.excerpt_video ? product.excerpt_video : null,
          // thumbnail: product.thumbnail ? product.thumbnail : null,
          "testimony": testimonials,
          "seoInformation": {
            "meta_title": product.meta.title,
            "meta_description": product.meta.description,
            "meta_keyword": product.meta.keywords.toString(),
            image: (product.meta.image && product.meta.image.length > 0) ?
              await base64Converter(product.meta.image[0]) : null,
            alt_image: product.meta.alt_image ? product.meta.alt_image : null,
          },
          status: status
        }

        ProductService.patchProduct(this.$route.params.id, payload).then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: '/products/list' })
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          }
        }).finally(() =>
          this.onLoading = false)
      }
    },

    loadItems(id) {
      ProductService
        .getProductById(id)
        .then(async ({ data: { data } }) => {
          let ecommerces = []
          for (const [k, v] of Object.entries(data.ecommerces)) {
            ecommerces.push({ id: k, item: v.ecommerces })
          }

          let testimonials = []
          for (const [k, v] of Object.entries(data.ProductOnTestimony)) {
            let { testimonials: image } = v
            testimonials.push({
              ...v.testimonials,
              image: image ? [await this.convertURLtoFile(this.loadSrcImage(image.image))] : null,
              imageSrc: image ? this.loadSrcImage(image.image) : null
            })
          }

          let type = []
          for (const [k, v] of Object.entries(data.ProductTypeOnProduct)) {
            let { product_types } = v
            type.push({ ...product_types })
          }
          this.product = {
            id: this.$route.params.id,
            name: data.product_title ? data.product_title : null,
            qty_in_box: data.qty_in_box ? data.qty_in_box : null,
            weight: data.weight ? data.weight : null,
            category: data.product_category ? data.product_category : null,
            type: type,
            description: data.description ? data.description : null,
            order: data.order ? data.order : null,
            // video_url: data.video_url ? data.video_url.split('=')[1] : null,
            video_url: data.video_url ? data.video_url : null,
            excerpt_video: data.excerpt_video ? data.excerpt_video : null,
            images: {
              desktop: data.desktop_image ? [await this.convertURLtoFile(this.loadSrcImage(data.desktop_image))] : null,
              desktopSrc: data.desktop_image ? this.loadSrcImage(data.desktop_image) : '',
              mobile: data.mobile_image ? [await this.convertURLtoFile(this.loadSrcImage(data.mobile_image))] : '',
              mobileSrc: data.mobile_image ? this.loadSrcImage(data.mobile_image) : '',
              alt: data.main_image_alt_next ? data.main_image_alt_next : null,
              alt_desktop_image: data.alt_desktop_image ? data.alt_desktop_image : null,
              alt_mobile_image: data.alt_mobile_image ? data.alt_mobile_image : null,
            },
            ecommerce: ecommerces,
            testimonials: testimonials,
            categoryParent: data.parent_product_category ? data.parent_product_category : null,
            meta: {
              title: data.seo_information.meta_title ? data.seo_information.meta_title : null,
              description: data.seo_information.meta_description ? data.seo_information.meta_description : null,
              keywords: data.seo_information.meta_keyword ? data.seo_information.meta_keyword.split(',') : null,
              image: data.seo_information.image ? [await this.convertURLtoFile(this.loadSrcImage(data.seo_information.image))] : null,
              imageSrc: data.seo_information.image ? this.loadSrcImage(data.seo_information.image) : '',
              alt_image: data.seo_information.alt_image ? data.seo_information.alt_image : null,
            },
            publishedDate: data.updatedAt ? data.updatedAt : null,
            lastModified: data.updatedAt ? data.updatedAt : null,
            author: data.User && data.User?.name ? data.User?.name : '',
          }
        }).catch((e) => {
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadSrcImage(url) {
      return this.$api_name + '/' + url
    },
    async convertURLtoFile(url) {
      const response = await fetch(url);
      if (response.status != 200) {
        return "";
      }
      const data = await response.blob();
      const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
      const metadata = { type: `image/jpeg` };
      return new File([data], filename, metadata);
    },
  },
};
</script>
