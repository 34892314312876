import api from "./api";

class NutriExpertService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('nutri-expert')
    }

    getNutriExpertList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postNutriExpert(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getNutriExpertById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchNutriExpert(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteNutriExpertById(serverParams) {
        return this.api.delete("/" + serverParams);
    }

    getNutriExpertExport(serverParams) {
        return this.api.get("/export/excel", { params: serverParams });
    }
}

export default new NutriExpertService();