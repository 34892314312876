import api from "./api";
import apim from "./apim";
class BabyGrowthService {
    /** @type {ApiAxiosInstance} */
    api;
    apim;

    constructor() {
        this.api = api.create('baby-growth')
        this.apim = apim.create('baby-growth')
    }

    getBabyGrowthList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postBabyGrowth(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getBabyGrowthById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchBabyGrowth(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteBabyGrowthById(serverParams) {
        return this.api.delete("/" + serverParams);
    }

    postBabyGrowthImport(serverParams) {
        return this.apim.post("/import-excel", serverParams);
    }
}

export default new BabyGrowthService();