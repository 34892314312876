<template>
  <div class="survey-detail">
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          Detail filled survey
        </h1>
      </div>
    </div>
    <panel title="Form Approval" variant="inverse" :is-collapse="true">
      <div class="row">
        <div class="col-md-6">
          <div class="mb-15px">
            <label class="form-label">Approve Status</label>
            <select class="form-select" v-model="form.status">
              <option v-for="(option, key) in optionStatus" v-bind:value="option.value" :key="key" v-text="option.label"
                :disabled="form.statusAwal == option.value ? true : false" />
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-15px">
            <label class="form-label">Submission Type</label>
            <select class="form-select" v-model="typeSurveySubmission" disabled>
              <option v-for="(option, key) in optionFreeSample" v-bind:value="option.value" :key="key"
                v-text="option.label" />
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="mb-15px">
            <label class="form-label">Reject Reason</label>
            <textarea class="form-control" rows="7" v-model="form.description" placeholder="Reject Reason">
            </textarea>
            <span class="text-danger" v-if="$v.form.description.$error">Reject Reason is required</span>
          </div>
        </div>
      </div>
      <div class="row" v-if="form.status != form.statusAwal && form.status">
        <div class="row justify-content-end">
          <div class="col-auto">
            <button type="submit" class="btn btn-primary" @click.prevent="doPost()">
              <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
              Save
            </button>
          </div>
        </div>
      </div>
    </panel>
    <panel title="Duplicate" variant="inverse" :is-collapse="true">
      <vue-good-table mode="remote" @on-sort-change="onSortChange" :totalRows="totalRecords" :isLoading.sync="isLoading"
        :pagination-options="{
          enabled: false,
          dropdownAllowAll: false,
        }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'created', type: 'desc' },
}" styleClass="vgt-table" slot="outsideBody" :line-numbers="true">
        <template slot="column-filter" slot-scope="{ column, updateFilters }">
        </template>
        <template slot="table-row" slot-scope="props">
          <span>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </panel>
    <panel title="1. Data Diri" variant="inverse" :is-collapse="true">
      <div class="row">
        <div class="col-md">
          <div class="mb-4">
            <label class="form-label">Nama</label>
            <input type="text" class="form-control" :value="customer.full_name ? customer.full_name : ''" readonly
              disabled />
          </div>
        </div>
        <div class="col-md">
          <div class="mb-4">
            <label class="form-label">No. Handphone</label>
            <input type="text" class="form-control" :value="customer.phoneNumber ? customer.phoneNumber : ''" readonly
              disabled />
          </div>
        </div>
        <div class="col-md">
          <div class="mb-4">
            <label class="form-label">Alamat Email</label>
            <input type="email" class="form-control" :value="customer.email ? customer.email : ''" readonly disabled />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md">
          <div class="mb-4">
            <label class="form-label">Alamat</label>
            <textarea class="form-control" :value="customer.address ? customer.address : ''" readonly disabled />
          </div>
        </div>
      </div>

      <div v-for="(item, index) in surveyOnPersonalData" class="row">
        <div v-if="item.SurveyQuestion.type_of_question == 'free_text'" class="col-md">
          <div class="mb-4">
            <label class="form-label">
              <div v-html="item.SurveyQuestion.question"></div>
            </label>
            <br>
            <input type="text" class="form-control" :value="item.answer.length > 0 ? item.answer[0].answer : ''" readonly
              disabled />
          </div>
        </div>
        <div v-else-if="item.SurveyQuestion.type_of_question == 'multiple_choice'" class="col-md">
          <div class="row">
            <div class="mb-4">
              <label class="form-label">
                <div v-html="item.SurveyQuestion.question"></div>
              </label>
              <div class="survey-answer">
                <div v-for="( item1, index1 ) in   getUniqueItem(item)   "
                  v-bind:class="getClassSelectedMultiChoice(item1, item.answer)">
                  <div class="survey-answer__item-content">
                    <img v-if="item1.option_image" :src="loadSrcImage(item1.option_image)" />
                    <p>{{ item1.option }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="item.other">
            <div class="mt-5px">
              <div class="survey-answer">
                <input type="text" class="form-control" :value="item.other" readonly disabled />
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="item.SurveyQuestion.type_of_question == 'single_choice'" class="col-md">
          <div class="row">
            <div class="mb-4">
              <label class="form-label">
                <div v-html="item.SurveyQuestion.question"></div>
              </label>
              <div class="survey-answer">
                <div v-for="( item1, index1 ) in   getUniqueItem(item)   "
                  v-bind:class="getClassSelectedMultiChoice(item1, item.answer)" v-if="item1.is_active">
                  <div class="survey-answer__item-content">
                    <img v-if="item1.option_image" :src="loadSrcImage(item1.option_image)" />
                    <p>{{ item1.option }}</p>
                  </div>
                </div>
                <div v-if="item.other" class="survey-answer__item selected">
                  <div class="survey-answer__item-content">
                    <p>Lainnya</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br>
          <div class="row" v-if="item.other">
            <div class="mt-5px">
              <div class="survey-answer">
                <input type="text" class="form-control" :value="item.other" readonly disabled />
              </div>
            </div>
          </div>

        </div>
        <div v-else-if="item.SurveyQuestion.type_of_question == 'drop_down'" class="col-md">
          <div class="mb-4">
            <label class="form-label">
              <div v-html="item.SurveyQuestion.question"></div>
            </label>
            <div class="survey-answer">
              <div v-for="( item1, index1 ) in   item.SurveyQuestion.SurveyOption  "
                v-bind:class="getClassSelectedMultiChoice(item1, item.answer)">
                <div class="survey-answer__item-content">
                  <img v-if="item1.option_image" :src="loadSrcImage(item1.option_image)" />
                  <p>{{ item1.option }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </panel>
    <panel title="2. Survey Produk" variant="inverse" :is-collapse="true">
      <div v-for="(item, index) in surveyOnProductSurvey" class="row">
        <div v-if="item.SurveyQuestion.type_of_question == 'free_text'" class="col-md">
          <div class="mb-4">
            <label class="form-label">
              <div v-html="item.SurveyQuestion.question"></div>
            </label>
            <br>
            <input type="text" class="form-control" :value="item.answer.length > 0 ? item.answer[0].answer : ''" readonly
              disabled />
          </div>
        </div>
        <div v-else-if="item.SurveyQuestion.type_of_question == 'multiple_choice'" class="col-md">
          <div class="row">
            <div class="mb-4">
              <label class="form-label">
                <div v-html="item.SurveyQuestion.question"></div>
              </label>
              <div class="survey-answer">
                <div v-for="( item1, index1 ) in   getUniqueItem(item)  "
                  v-bind:class="getClassSelectedMultiChoice(item1, item.answer)">
                  <div class="survey-answer__item-content">
                    <img v-if="item1.option_image" :src="loadSrcImage(item1.option_image)" />
                    <p>{{ item1.option }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="item.other">
            <div class="mt-5px">
              <div class="survey-answer">
                <input type="text" class="form-control" :value="item.other" readonly disabled />
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="item.SurveyQuestion.type_of_question == 'single_choice'" class="col-md">
          <div class="row">
            <div class="mb-4">
              <label class="form-label">
                <div v-html="item.SurveyQuestion.question"></div>
              </label>
              <div class="survey-answer">
                <div v-for="( item1, index1 ) in   getUniqueItem(item)  "
                  v-bind:class="getClassSelectedMultiChoice(item1, item.answer)">
                  <div class="survey-answer__item-content">
                    <img v-if="item1.option_image" :src="loadSrcImage(item1.option_image)" />
                    <p>{{ item1.option }}</p>
                  </div>
                </div>
                <div v-if="item.other" class="survey-answer__item selected">
                  <div class="survey-answer__item-content">
                    <p>Lainnya</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="item.other">
            <div class="mt-5px">
              <div class="survey-answer">
                <input type="text" class="form-control" :value="item.other" readonly disabled />
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="item.SurveyQuestion.type_of_question == 'drop_down'" class="col-md">
          <div class="mb-4">
            <label class="form-label">
              <div v-html="item.SurveyQuestion.question"></div>
            </label>
            <div class="survey-answer">
              <div v-for="( item1, index1 ) in   item.SurveyQuestion.SurveyOption  "
                v-bind:class="getClassSelectedMultiChoice(item1, item.answer)">
                <div class="survey-answer__item-content">
                  <img v-if="item1.option_image" :src="loadSrcImage(item1.option_image)" />
                  <p>{{ item1.option }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </panel>

    <panel title="3. Survey Kepuasan" variant="inverse" :is-collapse="true">
      <div v-for="(item, index) in surveyOnSurveySaticfaction" class="row">
        <div v-if="item.SurveyQuestion.type_of_question == 'free_text'" class="col-md">
          <div class="mb-4">
            <label class="form-label">
              <div v-html="item.SurveyQuestion.question"></div>
            </label>
            <br>
            <input type="text" class="form-control" :value="item.answer.length > 0 ? item.answer[0].answer : ''" readonly
              disabled />
          </div>
        </div>
        <div v-else-if="item.SurveyQuestion.type_of_question == 'multiple_choice'" class="col-md">
          <div class="row">
            <div class="mb-4">
              <label class="form-label">
                <div v-html="item.SurveyQuestion.question"></div>
              </label>
              <div class="survey-answer">
                <div v-for="( item1, index1 ) in   getUniqueItem(item)   "
                  v-bind:class="getClassSelectedMultiChoice(item1, item.answer)">
                  <div class="survey-answer__item-content">
                    <img v-if="item1.option_image" :src="loadSrcImage(item1.option_image)" />
                    <p>{{ item1.option }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="item.other">
            <div class="mt-5px">
              <div class="survey-answer">
                <input type="text" class="form-control" :value="item.other" readonly disabled />
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="item.SurveyQuestion.type_of_question == 'single_choice'" class="col-md">
          <div class="row">
            <div class="mb-4">
              <label class="form-label">
                <div v-html="item.SurveyQuestion.question"></div>
              </label>
              <div class="survey-answer">
                <div v-for="( item1, index1 ) in   getUniqueItem(item)   "
                  v-bind:class="getClassSelectedMultiChoice(item1, item.answer)">
                  <div class="survey-answer__item-content">
                    <img v-if="item1.option_image" :src="loadSrcImage(item1.option_image)" />
                    <p>{{ item1.option }}</p>
                  </div>
                </div>
                <div v-if="item.other" class="survey-answer__item selected">
                  <div class="survey-answer__item-content">
                    <p>Lainnya</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="item.other">
            <div class="mt-5px">
              <div class="survey-answer">
                <input type="text" class="form-control" :value="item.other" readonly disabled />
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="item.SurveyQuestion.type_of_question == 'drop_down'" class="col-md">
          <div class="mb-4">
            <label class="form-label">
              <div v-html="item.SurveyQuestion.question"></div>
            </label>
            <div class="survey-answer">
              <div v-for="( item1, index1 ) in   item.SurveyQuestion.SurveyOption  "
                v-bind:class="getClassSelectedMultiChoice(item1, item.answer)">
                <div class="survey-answer__item-content">
                  <img v-if="item1.option_image" :src="loadSrcImage(item1.option_image)" />
                  <p>{{ item1.option }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </panel>
  </div>
</template>
<script>
import surveySubmissionService from "@/service/surveysubmission.service";
import freesampleService from "@/service/freesample.service";
import customerService from "@/service/customer.service";
import { required, requiredIf } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      isTypeSurveySubmission: this.$route.params.type,
      typeSurveySubmission: false,
      optionFreeSample: [
        {
          label: 'Google Form Survey',
          value: false
        },
        {
          label: 'Web Survey',
          value: true
        }
      ],
      onLoading: false,
      optionStatus: [
        {
          label: 'Approve',
          value: 'approve'
        },
        {
          label: 'Pending',
          value: 'pending'
        },
        {
          label: 'In Review',
          value: 'in_review'
        },
        {
          label: 'Reject',
          value: 'reject'
        },
        {
          label: 'Unfinished',
          value: 'need_update'
        }
      ],
      columns: [
        {
          label: "Full Name",
          field: "user.full_name",
          thClass: "text-nowrap",
          sortable: false,
          tdClass: "text-nowrap align-middle",
        },
        {
          label: "Address",
          field: "user.address",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          sortable: false
        },
        {
          label: "Status",
          field: "status.label",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          sortable: false
        },
        {
          label: "Created",
          field: "createdAt",
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          type: "date",
          sortable: false,
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
        },
        {
          label: "Updated",
          field: "updatedAt",
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          type: "date",
          sortable: false,
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
        },
      ],
      rows: [],
      totalRecords: 0,
      customerId: 0,
      isLoading: false,
      customer: {
        id: 1,
        full_name: "",
        phoneNumber: "",
        email: "test@gmail.com"
      },
      form: {
        status: ''
      },
      serverParams: {
        skip: 0,
        take: 100,
        // sort: 'desc'
      },
      survey: [],
      surveyOnPersonalData: [],
      surveyOnProductSurvey: [],
      surveyOnSurveySaticfaction: [],
    }
  },
  mounted() {
    this.getFreeSampleById(this.$route.params.id)
  },
  methods: {
    getClassSelectedMultiChoice(item, answer) {
      let find = answer.find((r) => r.answer == item.id)
      if (find) {
        return 'survey-answer__item selected'
      } else {
        return 'survey-answer__item'
      }
    },
    getUniqueItem(item) {
      let rtn = []
      let answer = item.answer
      let options = item.SurveyQuestion.SurveyOption
      if (!item.other) {
        for (const [k, v] of Object.entries(answer)) {
          let data = options.find((r) => r.id == v.answer)
          rtn = options.filter((r) => r?.is_active == data?.is_active)
        }
      } else {
        rtn = options
      }
      return rtn
    },
    loadItems() {
      this.isLoading = true;
    },
    getCustomer() {
      customerService.getCustomerById(this.customerId)
        .then(async ({ data: { data } }) => {
          this.customer = data.customer
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }).finally(() =>
          this.onLoading = false)
    },
    getSubmissionById(id) {
      surveySubmissionService
        .getSurveySubmissionById(id)
        .then(async ({ data: { data } }) => {
          this.surveyOnPersonalData = data.SurveySubmissionOnQuestion.filter((r) => r.SurveyQuestion.question_section == 'personal_data')
          this.surveyOnProductSurvey = data.SurveySubmissionOnQuestion.filter((r) => r.SurveyQuestion.question_section == 'product_survey')
          this.surveyOnSurveySaticfaction = data.SurveySubmissionOnQuestion.filter((r) => r.SurveyQuestion.question_section == 'survey_satisfaction')
          this.customerId = data.customerId
          this.getCustomer();
        }).catch((e) => {
          // console.log(e)
          this.rows = []
          if (e?.response?.statusCode !== 200) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    getFreeSampleById(id) {
      freesampleService
        .getFreeSampleById(id)
        .then(async ({ data: { data } }) => {
          let freeSample = data.freeSample
          let survey_submission_id = freeSample.survey_submission_id ? freeSample.survey_submission_id : null
          // let survey_submission_id = data.survey_submission_id ? data.survey_submission_id : null
          this.form = {
            statusAwal: freeSample.sample_state ? freeSample.sample_state : null,
            status: freeSample.sample_state ? freeSample.sample_state : null,
            description: freeSample.reject_description ? freeSample.reject_description : ''
          }

          this.typeSurveySubmission = freeSample.survey_submission_id ? true : false
          if (survey_submission_id) {
            this.getSubmissionById(survey_submission_id)
          } else {
            this.customerId = freeSample.user_id
            this.getCustomer();
          }
          let arrData = []
          if (data?.duplicateFreeSample) {
            if (data.duplicateFreeSample.length > 0) {
              for (const [k, v] of Object.entries(data.duplicateFreeSample)) {
                // this.customerId = v.user_id
                let user = v.user_id ? await this.getCustomerById(v.user_id) : null
                let status = v.sample_state ? this.optionStatus.find((r) => r.value == v.sample_state) : ''
                arrData.push({ ...v, user, status })
              }
            }
            this.totalRecords = data.duplicateFreeSample.length
            this.rows = arrData
          }
        }).catch((e) => {
          this.rows = []
          if (e?.response?.statusCode !== 200) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    getCustomerById(id) {
      return customerService.getCustomerById(id)
        .then(async ({ data: { data } }) => {
          return data.customer
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }).finally(() =>
          this.onLoading = false)
    },
    doPost() {
      this.onLoading = false
      let { status, description } = this.form
      let payload = {
        reject_description: description ? description : null,
        sample_state: status ? status : null
      }
      this.$v.$touch();
      if (!this.$v.$invalid) {
        freesampleService.patchFreeSample(this.$route.params.id, payload).then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: '/survey/submission' })
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }).finally(() =>
          this.onLoading = false)
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
        this.onLoading = false
      }
    },
    decodeHtml: function (html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
    loadSrcImage(url) {
      return this.$api_name + '/' + url
    },

    onSortChange(params) {
      this.updateParams({});
      this.loadItems();
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
  },

  validations: {
    form: {
      description: {
        required: requiredIf(function (model) {
          if (model.status == "reject") {
            return true
          } else {
            return false
          }
        })
      },
    },
  }
};
</script>
