<template>
  <form @submit.prevent="doSave('Publish')" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          {{ formTitle }} Articles
        </h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="mb-15px">
          <input type="text" class="form-control form-control-lg" placeholder="Articles Title" v-model="post.title" />
          <span class="text-danger" v-if="$v.post.title.$error">Title is required</span>
        </div>
        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Slug</label>
                  <div class="input-group">
                    <span class="input-group-text bg-white border-end-0 text-muted">http://goon.co.id/</span>
                    <input type="text" class="form-control border-start-0 ps-0" placeholder="Articles Title"
                      v-model="slug" />
                  </div>
                  <span class="text-danger" v-if="$v.slug.$error">Slug is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Articles Category</label>
                  <v-select :clearable="false" v-model="post.category" :options="optionsCategory"
                    label="category_name"></v-select>
                  <span class="text-danger" v-if="$v.post.category.$error">Category is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Tag (#)</label>
                  <input-tag class="form-control default" v-model="post.tag"></input-tag>
                  <small class="fw-normal text-muted">Separate with commas</small>
                  <br>
                  <span class="text-danger" v-if="$v.post.tag.$error">Tag is required</span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-15px">
                  <ckeditor v-model="post.content" :config="{
                    delayDetached: true,
                    autoParagraph: false,
                    enterMode: 2,
                    versionCheck: false
                  }" />
                  <span class="text-danger" v-if="$v.post.content.$error">Content is required</span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Image Thumbnail
                    <span class="fw-normal text-muted">version : 300x300 px (jpeg, jpg, png)</span></label>
                  <UploadImage v-model="post.images.thumbnail" :src="post.images.thumbnailSrc" size="1000"
                    :dimensions="{ width: 300, height: 300 }" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Alt Thumbnail</label>
                  <input type="text" v-model="post.images.alt_thumbnail" class="form-control" placeholder="Alt text" />
                  <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
                </div>
              </div>
              <div class="col-md-12">
                <label class="form-label">Main Image</label>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <p class="mb-2">(dekstop version : 1240x367 px, max. 1mb, jpeg, jpg, png)</p>
                  <UploadImage v-model="post.images.desktop" :src="post.images.desktopSrc" size="1024"
                    :dimensions="{ width: 1240, height: 367 }" @image-err-message="imgErrMessage" />
                  <span class="text-danger" v-if="$v.post.images.desktop.$error">Image desktop is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <p class="mb-2">(mobile version : 350x350 px, max. 1mb, jpeg, jpg, png)</p>
                  <UploadImage v-model="post.images.mobile" :src="post.images.mobileSrc" size="1024"
                    :dimensions="{ width: 350, height: 350 }" @image-err-message="imgErrMessage" />
                  <span class="text-danger" v-if="$v.post.images.mobile.$error">Image mobile is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Dekstop Alt Text</label>
                  <input type="text" v-model="post.images.alt_desktop_image" class="form-control"
                    placeholder="Alt text" />
                  <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Mobile Alt Text</label>
                  <input type="text" v-model="post.images.alt_mobile_image" class="form-control"
                    placeholder="Alt text" />
                  <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
                </div>
              </div>
              <!-- <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Main Image Alt Text</label>
                  <input type="text" v-model="post.images.alt" class="form-control" placeholder="Alt text" />
                  <span class="text-danger" v-if="$v.post.images.alt.$error">Alt is required</span>
                </div>
              </div> -->
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Link Youtube</label>
                  <div class="input-group">
                    <span class="input-group-text"><i class="fa-solid fa-link"></i></span>
                    <input type="url" v-model="post.youtube" class="form-control"
                      placeholder="https://www.youtube.com/@GoonIndonesia" />
                  </div>
                  <!-- <span class="text-danger" v-if="$v.post.youtube.$error">Link is required</span> -->
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Choose Thumbnail (Video/Photo)<br /><span class="fw-normal">(Ratio 4 :
                      3)</span></label>
                  <v-select :clearable="false" :options="['Foto', 'Video']" label="thumbnail"
                    v-model="post.thumbnail"></v-select>
                  <!-- <span class="text-danger" v-if="$v.post.thumbnail.$error">Thumbnail is required</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <panel title="SEO Information" variant="inverse" :is-collapse="true">
          <div class="mb-15px">
            <label class="form-label">Meta Title</label>
            <input type="text" v-model="post.meta.title" placeholder="Meta Title" class="form-control" />
            <span class="text-danger" v-if="$v.post.meta.title.$error">Meta Title is required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Meta Description</label>
            <textarea class="form-control" rows="7" v-model="post.meta.description"
              placeholder="Meta Description"></textarea>
            <span class="text-danger" v-if="$v.post.meta.description.$error">Meta description is required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Meta Keywords</label>
            <input-tag v-model="post.meta.keywords" class="form-control default"></input-tag>
            <small class="fw-normal text-muted">Separate with commas</small>
            <br>
            <span class="text-danger" v-if="$v.post.meta.keywords.$error">Meta keywords is required</span>
          </div>
          <div class="mb-15px">
            <label class="form-label">Meta Image
              <span class="fw-normal text-muted">(Optional) (jpeg, jpg, png)</span></label>
            <UploadImage v-model="post.meta.image" :src="post.meta.imageSrc" @image-err-message="imgErrMessage"
              :dimensions="{ width: 1280, height: 550 }" />
          </div>
          <div class="col-md-12">
            <div class="mb-15px">
              <label class="form-label">Alt</label>
              <input type="text" v-model="post.meta.alt_image" class="form-control" placeholder="Alt text" />
              <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
            </div>
          </div>
        </panel>
      </div>
      <div v-if="post.author" class="col-lg-3 col-md-4">
        <div class="panel panel-sticky">
          <div class="panel-body">
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Published Date</p>
              <p class="mb-0">{{ post.publishedDate ? dateFormat(post.publishedDate) : '' }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Last Modified Date</p>
              <p class="mb-0">{{ post.lastModified ? dateFormat(post.lastModified) : '' }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Author</p>
              <p class="mb-0">{{ post.author }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Total View</p>
              <p class="mb-0">{{ post.articleView }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="button" class="btn btn-outline-primary" @click.prevent="doSave('Draft')">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Save as Draft
          </button>
        </div>
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Publish
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../config/Helpers";
import UploadImage from "../../components/upload/Image.vue";
import { required, requiredIf } from "vuelidate/lib/validators";
import PostCategoryService from "@/service/postcategory.service";
import dateFormat from "@/pages/Helper/formate_date";

export default {
  components: {
    UploadImage,
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.loadItems();
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;
    return {
      optionsCategory: [],
      customToolbar: defaultToolbarEditor,
      dateFormat: dateFormat,
      post: {
        title: detail ? detail.title : null,
        category: detail ? detail.category : {},
        slug: detail ? detail.slug : null,
        content: detail ? detail.content : null,
        tag: detail ? detail.tag : [],
        thumbnail: detail ? detail.tag : null,
        youtube: detail ? detail.youtube : null,
        images: {
          desktop: detail ? detail.images.desktop : null,
          desktopSrc: detail ? detail.images.desktopSrc : "",
          mobile: detail ? detail.images.mobile : null,
          mobileSrc: detail ? detail.images.mobileSrc : "",
          alt: detail ? detail.images.alt : null,
        },
        meta: {
          title: detail ? detail.meta.title : null,
          description: detail ? detail.meta.description : null,
          keywords: detail ? detail.meta.keywords : [],
          image: detail ? detail.meta.image : null,
          imageSrc: detail ? detail.meta.imageSrc : "",
        },
        publishedDate: detail ? detail.publishedDate : null,
        lastModified: detail ? detail.lastModified : null,
        author: detail ? detail.author : null,
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
    slug: function () {
      if (this.post.title) {
        return this.slugify(this.post.title);
      }
    },
  },
  watch: {
    detailData(newVal) {
      this.post = newVal;
    },
  },
  methods: {
    doSave(status) {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const payload = {
          post: { ...this.post, slug: this.slug },
          status
        };
        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
    imgErrMessage(mssg) {
      this.$notify({
        type: "error",
        title: "Image Notification",
        text: mssg,
      });
    },
    slugify(text, ampersand = "and") {
      if (text == null) {
        return null;
      }

      const a = "àáäâèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧ";
      const b = "aaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzh";
      const p = new RegExp(a.split("").join("|"), "g");

      return text
        .toString()
        .toLowerCase()
        .replace(/[\s_]+/g, "-")
        .replace(p, (c) => b.charAt(a.indexOf(c)))
        .replace(/&/g, `-${ampersand}-`)
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-")
        .replace(/^-+|-+$/g, "");
    },
    loadItems() {
      PostCategoryService.getPostCategoryList({})
        .then(({ data: { data } }) => {
          this.optionsCategory = data.PostCategory;
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        });
    },
  },
  validations: {
    slug: { required },
    post: {
      title: { required },
      category: { required },
      content: { required },
      tag: { required },
      images: {
        desktop: {
          required,
        },
        mobile: { required },
      },
      meta: {
        title: { required },
        description: { required },
        keywords: { required },
      },
    },
  },
};
</script>
