import api from "./api";

class ProductService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('product')
    }

    getProductList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postProduct(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getProductById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchProduct(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteProductById(serverParams) {
        return this.api.delete("/" + serverParams);
    }
}

export default new ProductService();