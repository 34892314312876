<template>
  <form @submit.prevent="doSave" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          Contact Us Detail
        </h1>
      </div>
      <div class="col-lg-12 col-md-12">
        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Nama</label>
                  <input type="text" class="form-control" placeholder="Name" v-model="form.name">
                  <span class="text-danger" v-if="$v.form.name.$error">Name is required</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Email</label>
                  <input type="email" class="form-control" placeholder="Anonymous@gmail.com" v-model="form.email">
                  <span class="text-danger" v-if="$v.form.email.$error">Email is required</span>
                </div>
              </div>
              <!-- <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">No. Telp</label>
                  <input type="text" class="form-control" placeholder="0812123323432" v-model="form.phune_number">
                  <span class="text-danger" v-if="$v.form.phune_number.$error">No. Telp is required</span>
                </div>
              </div> -->

              <div class="col-md-4">
                <label class="form-label">No. Hp</label>
                <div class="input-group">
                  <span class="input-group-text">+62</span>
                  <input type="text" class="form-control" v-model="form.phune_number" :maxlength="13"
                    v-on:keypress="isNumber" />
                </div>
              </div>
              <div class="col-md-12">
                <label class="form-label">Pesan</label>
                <textarea rows="10" class="form-control" v-model="form.description"></textarea>
                <span class="text-danger" v-if="$v.form.description.$error">Pesan is required</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">Save</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../../config/Helpers";
import UploadImage from "../../../components/upload/Image.vue";
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    UploadImage,
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // console.log(this.detailData);
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;

    return {
      options: {
        format: "DD/MM/YYYY",
        useCurrent: true,
      },
      customToolbar: defaultToolbarEditor,
      form: {
        name: null,
        phune_number: null,
        email: null,
        description: null,
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
  },
  watch: {
    detailData(newVal) {
      this.form = newVal;
    },
  },
  methods: {
    doSave() {

      this.$v.$touch();

      if (!this.$v.$invalid) {
        const payload = {
          form: this.form,
        };

        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },

    isNumber(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    }
  },

  validations: {
    form: {
      name: { required },
      phune_number: { required },
      email: { required },
      description: { required },
    },
  }
};
</script>
