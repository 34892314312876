<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <h1 class="page-header">Question List Survey</h1>

      </div>
      <div class="col-auto mb-3" v-if="add">
        <router-link to="/survey/add">
          <a class="btn btn-outline-primary">Add New Survey</a>
        </router-link>
      </div>
    </div>
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
        setCurrentPage: setCurrentPage,
        perPage: serverParams.take,
      }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'order', type: 'desc' },
}" styleClass="vgt-table" slot="outsideBody">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="question" class="form-control" placeholder="Search Question Name" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'question'
          " @input="(value) => updateFilters(column, $refs['question'].value)" />
        <select ref="category" class="form-select" placeholder="Cari productCategory" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'category'
          " @change="(value) => updateFilters(column, $refs['category'].value)">
          <option />
          <option v-for="(option, key) in optionsType" v-bind:value="option.value" :key="key" v-text="option.label" />
        </select>
        <select ref="RelatedProductCategory.category_name" class="form-select" placeholder="Cari productCategory" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'RelatedProductCategory.category_name'
          " @change="(value) => updateFilters(column, $refs['RelatedProductCategory.category_name'].value)">
          <option />
          <option v-for="(option, key) in optionsCategory" v-bind:value="option.category_name" :key="key"
            v-text="option.category_name" />
        </select>
        <select ref="status" class="form-select" placeholder="Cari productCategory" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'status'
          " @change="(value) => updateFilters(column, $refs['status'].value)">
          <option />
          <option v-for="(option, key) in optionsStatus" v-bind:value="option" :key="key" v-text="option" />
        </select>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end" v-if="props.row.status !== 'Deleted'">
            <div class="col-auto" v-if="edit">
              <router-link :to="`/survey/edit/${props.row.id}`" class="btn btn-lg text-dark" v-b-tooltip.hover
                title="Edit">
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>

            <div class="col-auto" v-if="deleteSurvey">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                @click.prevent="showAlertConfirm(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else :class="[props.row.status === 'Deleted' && 'text-muted']">
          <div v-if="props.column.field == 'question'" v-html="replaceRegex(props.formattedRow[props.column.field])">
          </div>
          <div v-else>
            {{ props.formattedRow[props.column.field] }}
          </div>
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import surveyService from "@/service/survey.service";
import productCategoryService from "@/service/productcategory.service";
import redeemMilestoneService from "@/service/redeemmilestones.service";
import CustomerService from "@/service/customer.service";
import RewardService from "@/service/reward.service";
import moment from "moment";
import tokenService from "@/service/token.service";
import _ from 'lodash';
export default {
  data() {
    return {
      setCurrentPage: this.$store.state.questionsurvey.serverParams.skip / this.$store.state.questionsurvey.serverParams.take == 0 ?
        1 : this.$store.state.questionsurvey.serverParams.skip / this.$store.state.questionsurvey.serverParams.take + 1,
      permisions: tokenService.getPermission(),
      isLoading: false,
      optionsCategory: [
        {
          label: 'Personal Data',
          value: 'personal_data'
        },
        {
          label: 'Product Survey',
          value: 'product_survey'
        },
        {
          label: 'Survey Satisfaction',
          value: 'survey_satisfaction'
        }
      ],
      optionsType: [
        {
          label: 'Multiple Choice',
          value: 'multiple_choice'
        },
        {
          label: 'Free Text',
          value: 'free_text'
        },
        {
          label: 'Single Choice',
          value: 'single_choice'
        },
        {
          label: 'Product',
          value: 'product'
        },
        {
          label: 'Dropdown',
          value: 'drop_down'
        },
      ],
      optionsStatus: [
        'Publish', 'Draft'
      ],
      columns: [
        {
          label: "Question Form Name",
          field: "question",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Type",
          field: "category",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Order",
          field: "order",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          sortable: true,
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Product",
          field: "RelatedProductCategory.category_name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Status",
          field: "status",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "text-end text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: this.$store.state.questionsurvey.serverParams,
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
    };
  },
  methods: {
    statusClass(row) {
      let statusClass = ''
      switch (row.status) {
        case 'Approved':
          statusClass = 'bg-green text-white align-middle';
          break;
        case 'Pending':
          statusClass = 'bg-orange text-white align-middle';
          break;
        case 'In Review':
          statusClass = 'bg-blue text-white align-middle';
          break;
        case 'Reject':
          statusClass = 'bg-red text-white align-middle';
          break;
        default:
          statusClass = 'bg-orange text-white align-middle';
          break;
      }
      return statusClass
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.$store.state.questionsurvey.serverParams = Object.assign({}, this.$store.state.questionsurvey.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ skip: 0 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      let payload = {}
      if (params[0].type != 'none') {
        payload.sortByOrder = params[0].type
      } else {
        delete this.$store.state.questionsurvey.serverParams.sortByOrder
      }
      this.updateParams(payload);
      this.loadItems();
    },

    onColumnFilter: _.debounce(function (params) {
      let payload = {}
      if (params.columnFilters.status && params.columnFilters.status != '') {
        payload.status = params.columnFilters.status ? params.columnFilters.status : ''
      } else {
        delete this.$store.state.questionsurvey.serverParams.status
      }

      if (params.columnFilters['createdAt'] && params.columnFilters['createdAt'] != '') {
        let arrSplit = params.columnFilters['createdAt'].split('#')
        if (arrSplit[0] && arrSplit[1]) {
          payload.createdAtStartDate = arrSplit[0] ? moment(arrSplit[0]).startOf('day').toISOString() : ''
          payload.createdAtEndDate = arrSplit[1] ? moment(arrSplit[1]).endOf('day').toISOString() : ''
        }
      } else {
        delete this.$store.state.questionsurvey.serverParams.createdAtStartDate
        delete this.$store.state.questionsurvey.serverParams.createdAtEndDate
      }

      if (params.columnFilters.status_pengiriman && params.columnFilters.status_pengiriman != '') {
        payload.delivery_status = params.columnFilters.status_pengiriman ? params.columnFilters.status_pengiriman : ''
      } else {
        delete this.$store.state.questionsurvey.serverParams.delivery_status
      }

      if (params.columnFilters['category']) {
        payload.typeOfQuestion = params.columnFilters['category']
      } else {
        delete this.$store.state.questionsurvey.serverParams.typeOfQuestion
      }

      if (params.columnFilters['customer.phoneNumber'] && params.columnFilters['customer.phoneNumber'] != '') {
        payload.phoneNumber = params.columnFilters['customer.phoneNumber']
      } else {
        delete this.$store.state.questionsurvey.serverParams.phoneNumber
      }

      if (params.columnFilters['question'] && params.columnFilters['question'] != '') {
        payload.question = params.columnFilters['question']
      } else {
        delete this.$store.state.questionsurvey.serverParams.question
      }

      if (params.columnFilters['RelatedProductCategory.category_name'] && params.columnFilters['RelatedProductCategory.category_name']) {
        payload.relatedProductCategoryName = params.columnFilters['RelatedProductCategory.category_name']
      } else {
        delete this.$store.state.questionsurvey.serverParams.relatedProductCategoryName
      }

      if (params.columnFilters['status'] && params.columnFilters['status'] != '') {
        payload.recordStatus = params.columnFilters['status'] ? params.columnFilters['status'] : ''
      } else {
        delete this.$store.state.questionsurvey.serverParams.recordStatus
      }

      this.updateParams(payload);
      this.loadItems();
    }, 300),

    loadItems() {
      this.isLoading = true;
      surveyService
        .getSurveyList(this.$store.state.questionsurvey.serverParams)
        .then(async ({ data: { data } }) => {
          let arrData = []

          for (const [k, v] of Object.entries(data.surveyQuestion)) {
            arrData.push({
              ...v,
              category: v.type_of_question ? (this.optionsType.find((r) => r.value == v.type_of_question)).label : ''
            })
          }
          this.rows = arrData
          this.totalRecords = data.totalData
        }).catch((e) => {
          this.rows = []
          if (e?.response?.statusCode !== 200) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },

    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },

    doDelete(id) {
      this.isLoading = true;
      surveyService
        .deleteSurveyById(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
          this.loadItems();
        }).finally(() => {
          this.isLoading = false;
        })
    },

    loadCategory() {
      productCategoryService
        .getProductCategoryList({ status: 'Publish' })
        .then(async ({ data: { data } }) => {
          this.optionsCategory = data.productCategory
        }).catch((e) => {
          if (e.response.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        });
    },

    replaceRegex(value) {
      var regex = /(<([^>]+)>)/ig
      let result = value.replace(regex, "");
      return result
    }
  },
  computed: {
    add: function () {
      if (this.permisions.find((r) => r == 'SurveyQuestion Create')) {
        return true
      }
      return false;
    },
    edit: function () {
      if (this.permisions.find((r) => r == 'SurveyQuestion Patch')) {
        return true
      }
      return false;
    },
    deleteSurvey: function () {
      if (this.permisions.find((r) => r == 'SurveyQuestion Delete')) {
        return true
      }
      return false;
    },
  },
  mounted() {
    this.loadItems();
    this.loadCategory()
  },
};
</script>
