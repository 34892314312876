<template>
  <form @submit.prevent="doSave('Publish')" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          {{ formTitle }} FAQ
        </h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="mb-15px">
          <input type="text" class="form-control form-control-lg" placeholder="Cara Mengganti Popok"
            v-model="faq.title" />
          <span class="text-danger" v-if="$v.faq.title.$error">Title is required</span>
        </div>
        <div class="panel">
          <div class="panel-body">
            <label class="form-label">FAQ Category</label>
            <v-select v-model="faq.category" :options="optionsCategory" label="name" />
            <span class="text-danger" v-if="$v.faq.category.$error">Category is required</span>
          </div>
        </div>
        <div class="panel">
          <ckeditor v-model="faq.description" :config="{
            delayDetached: true,
            autoParagraph: false,
            enterMode: 2,
            versionCheck: false
          }" />
          <span class="text-danger" v-if="$v.faq.description.$error">Description is required</span>
        </div>
      </div>
      <div v-if="faq.author" class="col-lg-3 col-md-4">
        <div class="panel panel-sticky">
          <div class="panel-body">
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Published Date</p>
              <p class="mb-0">{{ faq.publishedDate ? dateFormat(faq.publishedDate) : '' }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Last Modified Date</p>
              <p class="mb-0">{{ faq.lastModified ? dateFormat(faq.lastModified) : '' }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Author</p>
              <p class="mb-0">{{ faq.author }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="button" class="btn btn-outline-primary" @click.prevent="doSave('Draft')">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Save as Draft
          </button>
        </div>
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Publish</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../../config/Helpers";
import UploadImage from "../../../components/upload/Image.vue";
import { CKEditor } from "ckeditor4-vue";
import { required } from 'vuelidate/lib/validators'
import faqcategoryService from "@/service/faqcategory.service";

export default {
  components: {
    UploadImage,
    CKEditor
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.loadItems()
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;

    return {
      options: {
        format: "DD/MM/YYYY",
        useCurrent: true,
      },
      customToolbar: defaultToolbarEditor,
      optionsCategory: [],
      faq: {
        title: detail ? detail.title : null,
        category: detail ? detail.category : null,
        description: detail ? detail.description : null,
        publishedDate: null,
        lastModified: null,
        author: null,
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
  },
  watch: {
    detailData(newVal) {
      this.faq = newVal;
    },
  },
  methods: {
    doSave(status) {

      if (!this.$v.$invalid) {
        const payload = {
          faq: this.faq,
          status
        };

        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },

    loadItems() {
      this.isLoading = true;
      faqcategoryService
        .getFaqCategoryList({})
        .then(({ data: { data } }) => {
          this.optionsCategory = data.FaqCategory
        }).catch((e) => {
          if (e.response.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.optionsCategory = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
  },

  validations: {
    faq: {
      title: { required },
      category: { required },
      description: { required },
    },
  }
};
</script>
