<template>
  <div class="product-form">
    <FormPost form-type="edit" :detail-data="form" @onValidateSuccess="doSave" />
  </div>
</template>
<script>
import FormPost from "./Form.vue";
import RewardService from "@/service/reward.service";
import base64Converter from "@/pages/Helper/base64";
import slugify from "@/pages/Helper/slug";
import moment from "moment";
export default {
  data() {
    return {
      form: {
        id: this.$route.params.id,
        reward_type: null,
        title: null,
        google_form_url: '',
        images: {
          desktop: null,
          desktopSrc: '',
          mobile: null,
          mobileSrc: '',
          alt_desktop_image: null,
          alt_mobile_image: null,
          // alt: data ? data.main_image_alt_text : null,
        },
        date: {
          start: null,
          end: null,
        },
        stockInventory: 0,
        pointCharge: 0,
        description: null,
        excerpt: null,
        meta: {
          title: null,
          description: null,
          keywords: [],
          image: null,
          imageSrc: ''
        },
      }
    };
  },
  components: {
    FormPost,
  },
  mounted() {
    this.loadItems(this.$route.params.id)
  },
  methods: {
    async doSave(payload) {
      // Submit here

      this.onLoading = true
      let { form, status } = payload
      if (form) {
        let req = {
          "periode_start": this.dateFormatReq(form.date.start),
          "periode_end": this.dateFormatReq(form.date.end),
          "is_active": status,
          "name": form.title,
          "slug": slugify(form.title),
          "content": form.description,
          "price_point": form.pointCharge,
          "price_currency": 0,
          "image_dekstop_file": await base64Converter(form.images.desktop[0]),
          "image_mobile_file": await base64Converter(form.images.mobile[0]),
          "stock": form.stockInventory,
          "reward_type": form.reward_type.value,
          "excerpt": form.excerpt ? form.excerpt : '',
          google_form_url: form.google_form_url ? form.google_form_url : null,
          alt_image_dekstop: form.images.alt_desktop_image ? form.images.alt_desktop_image : null,
          alt_image_mobile: form.images.alt_mobile_image ? form.images.alt_mobile_image : null,
          seo_content_title: form.meta.title ? form.meta.title : null,
          seo_content_content: form.meta.description ? form.meta.description : null,
          seo_content_image: (form.meta.image && form.meta.image.length > 0) ?
            await base64Converter(form.meta.image[0]) : null,
          seo_content_alt: form.meta.alt_image ? form.meta.alt_image : null,
          seo_content_keyword: form.meta.keywords.toString(),
        }

        RewardService.patchReward(this.$route.params.id, req).then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: '/reward/list' })
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          }
        }).finally(() =>
          this.onLoading = false)
      }
    },
    loadItems(id) {
      this.isLoading = true;
      RewardService
        .getRewardById(id)
        .then(async ({ data: { data } }) => {
          // console.log(data.seo_content_image)
          this.form = {
            reward_type: data.reward_type ? data.reward_type : null,
            title: data.name ? data.name : null,
            google_form_url: data.google_form_url ? data.google_form_url : '',
            images: {
              desktop: data ? [await this.convertURLtoFile(this.loadSrcImage(data.image_dekstop_path + data.image_dekstop_file))] : null,
              desktopSrc: data ? this.loadSrcImage(data.image_dekstop_path + data.image_dekstop_file) : '',
              mobile: data ? [await this.convertURLtoFile(this.loadSrcImage(data.image_mobile_path + data.image_mobile_file))] : null,
              mobileSrc: data ? this.loadSrcImage(data.image_mobile_path + data.image_mobile_file) : '',
              alt_desktop_image: data.alt_image_dekstop ? data.alt_image_dekstop : null,
              alt_mobile_image: data.alt_image_mobile ? data.alt_image_mobile : null,
              // alt: data ? data.main_image_alt_text : null,
            },
            date: {
              start: this.dateFormat(data.periode_start),
              end: this.dateFormat(data.periode_end),
            },
            stockInventory: data.stock ? data.stock : 0,
            pointCharge: data.price_point ? data.price_point : 0,
            description: data.content ? data.content : null,
            excerpt: data.excerpt ? data.excerpt : null,
            meta: {
              title: data.seo_content_title ? data.seo_content_title : null,
              description: data.seo_content_content ? data.seo_content_content : null,
              keywords: data.seo_content_keyword ? data.seo_content_keyword.split(',') : [],
              image: data.seo_content_image ? [await this.convertURLtoFile(this.loadSrcImage(data.image_mobile_path + data.seo_content_image))] : null,
              imageSrc: data.seo_content_image ? this.loadSrcImage(data.image_mobile_path + data.seo_content_image) : '',
              alt_image: data.seo_content_alt ? data.seo_content_alt : null,
            },
          }
        }).catch((e) => {
          if (e.response?.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadSrcImage(url) {
      return this.$api_name + '/' + url
    },
    async convertURLtoFile(url) {
      const response = await fetch(url);
      if (response.status != 200) {
        return "";
      }
      const data = await response.blob();
      const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
      const metadata = { type: `image/jpeg` };
      return new File([data], filename, metadata);
    },
    dateFormatReq(date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
    },
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY')
    },
  },
};
</script>
