<template>
  <form @submit.prevent="doSave('Publish')" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          {{ formTitle }} Banner
        </h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="panel">
          <div class="panel-body">
            <div class="mb-15px">
              <label class="form-label">Desktop version
                <span class="fw-normal">(1240px x 391px, max 1 mb, jpeg, jpg, png)</span></label>
              <UploadImage height="253px" v-model="banner.images.desktop" :src="banner.images.desktopSrc" size="1000"
                :dimensions="{ width: 1240, height: 391 }" @image-err-message="imgErrMessage" />
              <span class="text-danger" v-if="$v.banner.images.desktop.$error">Dekstop Image is required</span>
            </div>
            <div class="col-md-12">
              <div class="mb-15px">
                <label class="form-label">Dekstop Alt Text</label>
                <input type="text" v-model="banner.images.alt_desktop_image" class="form-control"
                  placeholder="Alt text" />
                <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
              </div>
            </div>
          </div>
        </div>
        <div class="mb-15px">
          <input type="text" class="form-control form-control-lg" placeholder="Banner Title" v-model="banner.title" />
          <span class="text-danger" v-if="$v.banner.title.$error">Title is required</span>
        </div>
        <div class="panel">
          <div class="panel-body">
            <div class="mb-15px">
              <label class="form-label">Location Banner Page</label>
              <v-select :options="optionsPage" v-model="banner.location" label="slug"></v-select>
              <span class="text-danger" v-if="$v.banner.location.$error">Location is required</span>
            </div>
            <div class="mb-15px">
              <label class="form-label">Product Parent Category</label>
              <v-select :options="optionsParentCategory" v-model="banner.parent_category" label="parent_name"></v-select>
            </div>
            <div class="mb-15px">
              <label class="form-label">Product Category</label>
              <v-select :options="optionsProductCategory" v-model="banner.product_category"
                label="category_name"></v-select>
            </div>
            <div class="mb-15px">
              <label class="form-label">Position</label>
              <v-select :options="['Top', 'Right', 'Left', 'Bottom']" v-model="banner.position"></v-select>
              <span class="text-danger" v-if="$v.banner.position.$error">Position is required</span>
            </div>
            <div class="mb-15px">
              <label class="form-label">Tag</label>
              <input-tag v-model="banner.tag" class="form-control default"></input-tag>
              <small class="fw-normal text-muted">Separate with commas</small>
            </div>
            <div class="mb-15px">
              <label class="form-label">Description</label>
              <ckeditor class="form-control" placeholder="Text" v-model="banner.description" rows="5" />
              <!-- <span class="text-danger" v-if="$v.banner.description.$error">Description is required</span> -->
            </div>
            <div class="mb-15px">
              <label class="form-label">CTA #1 Text</label>
              <input type="text" class="form-control" placeholder="Text" v-model="banner.cta.text1" />
              <!-- <span class="text-danger" v-if="$v.banner.cta.text1.$error">Cta #1 Text is required</span> -->
            </div>
            <div class="mb-15px">
              <label class="form-label">CTA #1 URL</label>
              <input type="url" class="form-control" placeholder="Text" v-model="banner.cta.url1" />
              <!-- <span class="text-danger" v-if="$v.banner.cta.url1.$error">Cta #1 Url is required</span> -->
            </div>
            <div class="mb-15px">
              <label class="form-label">CTA #2 Text</label>
              <input type="text" class="form-control" placeholder="Text" v-model="banner.cta.text2" />
              <!-- <span class="text-danger" v-if="$v.banner.cta.text2.$error">Cta #2 Text is required</span> -->
            </div>
            <div class="mb-15px">
              <label class="form-label">CTA #2 URL</label>
              <input type="url" class="form-control" placeholder="Text" v-model="banner.cta.url2" />
              <!-- <span class="text-danger" v-if="$v.banner.cta.url2.$error">Cta #2 Url is required</span> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4">
        <div class="panel">
          <div class="panel-body">
            <div class="mb-15px">
              <label class="form-label">Mobile version
                <span class="fw-normal">(375px x 375px, max 1 mb, jpeg, jpg, png)</span></label>
              <UploadImage height="425px" v-model="banner.images.mobile" :src="banner.images.mobileSrc" size="1000"
                :dimensions="{ width: 375, height: 375 }" @image-err-message="imgErrMessage" />
              <span class="text-danger" v-if="$v.banner.images.$error">Mobile Image is required</span>
            </div>
            <div class="col-md-12">
              <div class="mb-15px">
                <label class="form-label">Mobile Alt Text</label>
                <input type="text" v-model="banner.images.alt_mobile_image" class="form-control" placeholder="Alt text" />
                <!-- <span class="text-danger" v-if="$v.product.images.alt.$error">Main Image Alt is required</span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="button" class="btn btn-outline-primary" @click.prevent="doSave('Draft')">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Save as Draft
          </button>
        </div>
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Publish</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../../config/Helpers";
import UploadImage from "../../../components/upload/Image.vue";
import PageService from "@/service/page.service";
import ProductCategoryService from "@/service/productcategory.service";
import { required } from 'vuelidate/lib/validators'
import parentcategoryService from "@/service/parentcategory.service";
export default {
  components: {
    UploadImage,
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.loadItems();
    this.loadParentCategory()
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;
    return {
      customToolbar: defaultToolbarEditor,
      optionsProductCategory: [],
      optionsParentCategory: [],
      optionsPage: [
        'homepage'
        , 'product-list'
        , 'reseller'
        , 'article-list'
        , 'promo-list'
        , 'career-list'
        , 'free-sample'
        , 'birthday'
        , 'claim-point'
        , 'point-history'
        , 'point-store'
        , 'redeem-tracking'
        , 'contact-us'
        , 'tanya-goon'
        , 'baby-name-generator'
        , 'baby-growth-checker'
        , 'web-survey'],
      banner: {
        title: detail ? detail.title : null,
        description: detail ? detail.description : null,
        images: {
          desktop: detail ? detail.images.desktop : null,
          desktopSrc: detail ? detail.images.desktopSrc : '',
          mobile: detail ? detail.images.mobile : null,
          mobileSrc: detail ? detail.images.mobileSrc : '',
        },
        location: "",
        position: "Top",
        cta: {
          text1: "",
          url1: "",
          text2: "",
          url2: "",
        },
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
  },
  watch: {
    'detailData'(newVal) {
      this.banner = newVal
    },
    'banner.parent_category'(newVal) {
      if (newVal) {
        this.loadCategory(newVal);
      }
    }
  },
  methods: {
    doSave(status) {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const payload = {
          banner: this.banner,
          status
        };

        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
    loadPage() {
      this.isLoading = true;
      PageService
        .getPageList({})
        .then(({ data: { data } }) => {
          // this.optionsPage = data.Page
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadItems() {
      this.loadPage()
    },
    imgErrMessage(mssg) {
      this.$notify({
        type: "error",
        title: "Image Notification",
        text: mssg,
      });
    },
    loadCategory(productParent) {
      this.isLoading = true;
      ProductCategoryService
        .getProductCategoryList({ parent_product_category: productParent.parent_name })
        .then(({ data: { data } }) => {
          if (data.productCategory.length > 0) {
            this.optionsProductCategory = data.productCategory
          } else {
            this.optionsProductCategory = []
          }
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },

    loadParentCategory() {
      this.isLoading = true;
      parentcategoryService
        .getParentCategoryList(this.serverParams)
        .then(({ data: { data } }) => {
          if (data.ParentProductCategory) {
            this.optionsParentCategory = data.ParentProductCategory
            this.totalRecords = data.totalData
          } else {
            this.optionsParentCategory = []
            this.totalRecords = 0
          }
        }).catch((e) => {
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
  },
  validations: {
    banner: {
      title: { required },
      images: {
        desktop: { required },
        mobile: { required },
      },
      location: { required },
      position: { required },
    },
  }
};
</script>
