<template>
    <div class="product-form">
        <FormPost form-type="add" @onValidateSuccess="doSave" :on-loading="onLoading" />
    </div>
</template>
<script>
import FormPost from './Form.vue'
import PostService from "@/service/post.service";
import TokenService from "@/service/token.service";
import base64Converter from "@/pages/Helper/base64";

export default {
    data() {
        return {
            onLoading: false
        }
    },
    components: {
        FormPost,
    },
    methods: {
        async doSave(payload) {
            this.onLoading = true
            let { post, status } = payload
            if (post) {
                let payload = {
                    user_id: (TokenService.getUser()).id,
                    title: post.title,
                    slug: post.slug,
                    post_category_id: post.category.id,
                    post_category: post.category.id,
                    description: post.content,
                    tag: post.tag.toString(),
                    desktop_image: await base64Converter(post.images.desktop[0]),
                    mobile_image: await base64Converter(post.images.mobile[0]),
                    image_thumbnail: (post.images.thumbnail && post.images.thumbnail.length > 0) ?
                        await base64Converter(post.images.thumbnail[0]) : null,
                    alt_image_thumbnail: post.images.alt_thumbnail ? post.images.alt_thumbnail : null,
                    link_youtube: post.youtube ? post.youtube : null,
                    thumbnail: post.thumbnail ? post.thumbnail : null,
                    main_image_alt_next: post.images?.alt ? post.images?.alt : null,
                    alt_desktop_image: post.images.alt_desktop_image ? post.images.alt_desktop_image : null,
                    alt_mobile_image: post.images.alt_mobile_image ? post.images.alt_mobile_image : null,
                    seoInformation: {
                        meta_title: post.meta.title,
                        meta_description: post.meta.description,
                        meta_keyword: post.meta.keywords.toString(),
                        image: (post.meta.image && post.meta.image.length > 0) ?
                            await base64Converter(post.meta.image[0]) : null,
                        alt_image: post.meta.alt_image ? post.meta.alt_image : null,
                    },
                    status
                }

                PostService.postArticle(payload).then((rsp) => {
                    this.$swal({
                        title: "Data berhasil disimpan",
                        showCloseButton: true,
                    }).then(() => {
                        this.$router.push({ path: '/posts/list/data' })
                    });
                }).catch((e) => {
                    if (e.response?.status === 409) {
                        this.$notify({
                            type: "warn",
                            title: "Error Message",
                            text: "Data Sudah Ada",
                        });
                    } else {
                        this.$notify({
                            type: "error",
                            title: "Error Message",
                            text: e.response?.data?.message || "Terjadi Kesalahan",
                        });
                    }
                }).finally(() =>
                    this.onLoading = false)
            }
        }
    }
}
</script>