<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">Baby Name Generator List</h1>
        <!-- END page-header -->
      </div>
      <div class="col-auto mb-3">
        <div class="row">
          <div class="col-auto">
            <a class="btn btn-info" href="/assets/BabyNameTemplate.xlsx" download> Template</a>
            <!-- <button class="btn btn-info" @click="downloadTemplate">Template</button> -->
          </div>
          <div class="col-auto">
            <input id="fileUpload"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              ref="fileUpload" type="file" @change="postBabyNameFile" hidden>
            <button class="btn btn-dark" @click="chooseFiles">Import</button>
          </div>
          <div class="col-auto" v-if="add">
            <router-link to="/baby-name-generator/add">
              <a class="btn btn-outline-primary">Add Data</a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
        setCurrentPage: setCurrentPage,
        perPage: serverParams.take,
      }" :columns="columns" :rows="rows" :sort-options="{
  enabled: true,
  initialSortBy: { field: 'name', type: 'asc' },
}" styleClass="vgt-table" slot="outsideBody">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="name" class="form-control" placeholder="Search.." v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'name'
          " @input="(value) => updateFilters(column, $refs.name.value)" />
        <input ref="theme" class="form-control" placeholder="Search.." v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'theme'
          " @input="(value) => updateFilters(column, $refs.theme.value)" />
        <input ref="syllables" class="form-control" placeholder="Search.." v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'syllables'
          " @input="(value) => updateFilters(column, $refs.syllables.value)" />
        <input ref="region" class="form-control" placeholder="Search.." v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'region'
          " @input="(value) => updateFilters(column, $refs.region.value)" />
        <div class="input-group" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'createdAt'
          ">
          <input type="date" ref="createdAt1" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
          <span class="input-group-text">
            -
          </span>
          <input type="date" ref="createdAt2" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
        </div>
        <select ref="jenis_kelamin" class="form-select" placeholder="Cari.." v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'jenis_kelamin'
          " @change="(value) => updateFilters(column, $refs['jenis_kelamin'].value)">
          <option />
          <option v-for="(option, key) in optionsGender" v-bind:value="option.value" :key="key" v-text="option.title" />
        </select>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end" v-if="props.row.status !== 'Deleted'">
            <div class="col-auto" v-if="edit">
              <router-link :to="`/baby-name-generator/edit/${props.row.id}`" class="btn btn-lg text-dark"
                v-b-tooltip.hover title="Edit">
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>
            <div class="col-auto" v-if="deleteBabyName">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                @click.prevent="showAlertConfirm(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else :class="[props.row.status === 'Deleted' && 'text-muted']">
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import babynameService from "@/service/babyname.service";
import BabyNameService from "@/service/babyname.service";
import tokenService from "@/service/token.service";
import formatUtc from "@/pages/Helper/formatUTC";
import { version } from "vue-nvd3";
export default {
  data() {
    return {
      setCurrentPage: this.$store.state.babynamegenerator.serverParams.skip / this.$store.state.babynamegenerator.serverParams.take == 0 ?
        1 : this.$store.state.babynamegenerator.serverParams.skip / this.$store.state.babynamegenerator.serverParams.take + 1,
      isLoading: false,
      optionsGender: [
        { title: 'Laki-laki', value: 'male' },
        { title: 'Perempuan', value: 'female' }
      ],
      columns: [
        {
          label: "Name",
          field: "name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          sortable: false,
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Tema",
          field: "theme",
          sortable: false,
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Jumlah Suku Kata",
          field: "syllables",
          sortable: false,
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Jenis Kelamin",
          field: "jenis_kelamin",
          sortable: false,
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Negara",
          field: "region",
          sortable: false,
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Submitted At",
          field: "createdAt",
          sortable: false,
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
          filterOptions: {
            styleClass: 'vgt-relative',
            customFilter: true,
            enabled: true,
            filterValue: "",
            filterFn: this.filterFn,
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: this.$store.state.babynamegenerator.serverParams,
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
      permisions: tokenService.getPermission()
    };
  },
  computed: {
    add: function () {
      if (this.permisions.find((r) => r == 'BabyName Add')) {
        return true
      }
      return false;
    },
    edit: function () {
      if (this.permisions.find((r) => r == 'BabyName Patch')) {
        return true
      }
      return false;
    },
    deleteBabyName: function () {
      if (this.permisions.find((r) => r == 'BabyName Delete')) {
        return true
      }
      return false;
    },
    importBabyName: function () {
      if (this.permisions.find((r) => r == 'BabyName Import')) {
        return true
      }
      return false;
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.$store.state.babynamegenerator.serverParams = Object.assign({}, this.$store.state.babynamegenerator.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ skip: 1 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams();
      this.loadItems();
    },


    onColumnFilter: _.debounce(function (params) {
      let payload = {}
      if (params.columnFilters['createdAt'] && params.columnFilters['createdAt'] != '#') {
        let arrSplit = params.columnFilters['createdAt'].split('#')
        if (arrSplit[0] && arrSplit[1]) {
          let { startDate, endDate } = formatUtc(arrSplit[0], arrSplit[1])
          payload.createdAtStartDate = arrSplit[0] ? startDate : ''
          payload.createdAtEndDate = arrSplit[1] ? endDate : ''
        }
      } else {
        delete this.$store.state.babynamegenerator.serverParams.createdAtStartDate
        delete this.$store.state.babynamegenerator.serverParams.createdAtEndDate
      }

      if (params.columnFilters.name && params.columnFilters.name != '') {
        payload.name = params.columnFilters.name ? params.columnFilters.name : ''
      } else {
        delete this.$store.state.babynamegenerator.serverParams.name
      }

      if (params.columnFilters.theme && params.columnFilters.theme != '') {
        payload.theme = params.columnFilters.theme ? params.columnFilters.theme : ''
      } else {
        delete this.$store.state.babynamegenerator.serverParams.theme
      }

      if (params.columnFilters['syllables'] && params.columnFilters['syllables'] != '#') {
        payload.syllables = params.columnFilters.syllables ? params.columnFilters.syllables : ''
      } else {
        delete this.$store.state.babynamegenerator.serverParams.syllables
      }

      if (params.columnFilters['jenis_kelamin'] && params.columnFilters['jenis_kelamin'] != '#') {
        payload.gender = params.columnFilters.jenis_kelamin ? params.columnFilters.jenis_kelamin : ''
      } else {
        delete this.$store.state.babynamegenerator.serverParams.gender
      }

      if (params.columnFilters['region'] && params.columnFilters['region'] != '#') {
        payload.region = params.columnFilters.region ? params.columnFilters.region : ''
      } else {
        delete this.$store.state.babynamegenerator.serverParams.region
      }

      this.updateParams(payload);
      this.loadItems();
    }, 300),
    loadItems() {
      BabyNameService
        .getBabyNameList(this.$store.state.babynamegenerator.serverParams)
        .then(async ({ data: { data } }) => {
          let arrData = []
          if (data.babyName.length > 0) {
            for (const [k, v] of Object.entries(data.babyName)) {
              arrData.push({
                ...v,
                jenis_kelamin: v.gender ? this.optionsGender.find((r) => r.value == v.gender).title : ''
              })
            }
          }
          this.rows = arrData
          this.totalRecords = data.totalData
        }).catch((e) => {
          if (e.response?.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },

    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },

    doDelete(id) {
      this.isLoading = true;
      BabyNameService
        .deleteBabyNameById(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },

    async postBabyNameFile(e) {
      let formData = new FormData()
      formData.append('file', e.target.files[0], e.target.files[0].name)

      babynameService
        .postImportBabyName(formData)
        .then((rsp) => {
          // console.log(rsp)
          this.$swal({
            title: "Data berhasil ditambah",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
          this.loadItems();
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        })
    },
    chooseFiles() {
      const elem = this.$refs.fileUpload
      elem.click()
    },
    downloadTemplate() {
      // console.log('test')
    }
  },
  mounted() {
    this.loadItems();
  },
};
</script>
