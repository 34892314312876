<template>
  <form @submit.prevent="doSave" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          {{ formTitle }} Information Reseller
        </h1>
      </div>
      <div class="col-lg-12 col-md-12">
        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Customer</label>
                  <v-select v-model="reseller.customer" :options="optionsCustomer" label="full_name"></v-select>
                  <span class="text-danger" v-if="$v.reseller.customer.$error">Parent Category is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Nama</label>
                  <input type="text" class="form-control" placeholder="Nama" v-model="reseller.name">
                  <span class="text-danger" v-if="$v.reseller.name.$error">Nama is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <label class="form-label">No. Whatsapp</label>
                <div class="input-group">
                  <span class="input-group-text">+62</span>
                  <input type="text" class="form-control" v-model="reseller.phone" :maxlength="11"
                    v-on:keypress="isNumber" />
                </div>
                <div>Note: Phone Number must be at least {{ $v.reseller.phone.$params.minLength.min }}</div>
                <div class="text-danger" v-if="$v.reseller.phone.$error">Phone Number must have at most
                  {{ $v.reseller.phone.$params.minLength.min }} letters.</div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Nama Toko</label>
                  <input type="text" class="form-control" placeholder="Nama Toko" v-model="reseller.merchant_name">
                  <span class="text-danger" v-if="$v.reseller.merchant_name.$error">Nama Toko is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Alamat Toko</label>
                  <input type="text" class="form-control" placeholder="Alamat Toko" v-model="reseller.address">
                  <span class="text-danger" v-if="$v.reseller.address.$error">Alamat is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Area Seller</label>
                  <select class="form-select" placeholder="Cari productCategory" v-model="reseller.manager">
                    <option v-for="(option, key) in optionsSupervisor" v-bind:value="option" :key="key"
                      v-text="option.area + ' - ' + option.name" />
                  </select>
                  <span class="text-danger" v-if="$v.reseller.manager.$error">Area is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Status</label>
                  <v-select v-model="reseller.status" :options="optionStatus" label="label"></v-select>
                  <span class="text-danger" v-if="$v.reseller.manager.$error">Parent Category is required</span>
                </div>
              </div>
              <!-- <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Nomor KTP</label>
                  <input type="text" class="form-control" placeholder="Nomor KTP" v-model="reseller.no_ktp"
                    :maxlength="16" v-on:keypress="isNumber">
                  <span class="text-danger" v-if="$v.reseller.no_ktp.$error">Nomor KTP is required</span>
                </div>
              </div> -->
              <!-- <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Nomor NPWP</label>
                  <input type="text" class="form-control" placeholder="Nomor NPWP" v-model="reseller.no_npwp"
                    :maxlength="16" v-on:keypress="isNumber">
                  <span class="text-danger" v-if="$v.reseller.no_npwp.$error">Nomor NPWP is required</span>
                </div>
              </div> -->
              <div class="col-md-3">
                <div class="mb-15px">
                  <label class="form-label"></label>
                  <div class="form-check">
                    <input class="form-check-input mb-15px" type="checkbox" v-model="reseller.newsletter"
                      id="flexCheckDefault" :disabled="formType == 'edit'">
                    <label class="form-check-label" for="flexCheckDefault">
                      <b>Email Subscription</b>
                    </label>
                  </div>
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-md-6">
                  <div class="mb-15px">
                    <label class="form-label">Foto KTP</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-15px">
                    <label class="form-label">Foto NPWP</label>
                  </div>
                </div>
              </div> -->
              <!-- <div class="row">
                <div class="col-md-6">
                  <div class="mb-15px">
                    <v-zoomer v-if="editPhotoNpwp">
                      <img :src="reseller.ktpSrc" style="object-fit: contain; width: 100%; height: 100%;">
                    </v-zoomer>
                    <UploadImage v-else v-model="reseller.ktp_image" :src="reseller.ktpSrc" />
                    <span class="text-danger" v-if="$v.reseller.ktp_image.$error">Foto KTP is required</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-15px">
                    <v-zoomer v-if="editPhotoNpwp">
                      <img :src="reseller.npwpSrc" style="object-fit: contain; width: 100%; height: 100%;">
                    </v-zoomer>
                    <UploadImage v-else v-model="reseller.npwp_image" :src="reseller.npwpSrc" />
                    <span class="text-danger" v-if="$v.reseller.npwp_image.$error">Foto KTP is required</span>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="row">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="button" class="btn btn-outline-primary" @click.prevent="doSave('Draft')">
            Save as Draft
          </button>
        </div>
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Publish
          </button>
        </div>
      </div>
    </div> -->
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="submit" class="btn btn-primary" :disabled="!reseller.newsletter">Save</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../config/Helpers";
import UploadImage from "../../components/upload/Image.vue";
import { required, minLength } from 'vuelidate/lib/validators'
import resellermanagerService from "@/service/resellermanager.service";
import customerService from "@/service/customer.service";
import _ from 'lodash';

export default {
  components: {
    UploadImage,
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.loadItems()
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;

    return {
      options: {
        format: "DD/MM/YYYY",
        useCurrent: true,
      },
      optionsCustomer: [],
      optionStatus: [
        {
          label: 'Pending',
          value: 'pending'
        },
        {
          label: 'Banned',
          value: 'banned'
        },
        {
          label: 'Active',
          value: 'active'
        }
      ],
      optionsSupervisor: [],
      customToolbar: defaultToolbarEditor,
      reseller: {
        name: detail ? detail.name : null,
        phone: detail ? detail.phone : null,
        merchant_name: detail ? detail.merchant_name : null,
        address: detail ? detail.address : null,
        area: detail ? detail.area : null,
        manager: detail ? detail.manager : null,
        customer: detail ? detail.customer : null,
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
    editPhotoNpwp() {
      let validation = false;
      if (this.formType !== "add") {
        validation = true
      } else {
        validation = false
      }
      return validation
    },
    editPhotoKtp() {
      let validation = false;
      if (this.formType !== "add") {
        validation = true
      } else {
        validation = false
      }
      return validation
    }
  },
  watch: {
    detailData(newVal) {
      this.reseller = newVal;
    },
  },
  methods: {
    doSave() {

      this.$v.$touch();

      if (!this.$v.$invalid) {
        const payload = {
          reseller: this.reseller,
        };

        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
    loadItems() {
      this.loadCustomers()
      this.loadSupervisor()
    },
    loadCustomers() {
      return customerService.getCustomerList({})
        .then(({ data: { data } }) => {
          this.optionsCustomer = data.customer
        })
        .catch((e) => Promise.reject({ ...e }))
    },
    loadSupervisor() {
      return resellermanagerService.getResellerManagerList({})
        .then(({ data: { data } }) => {
          this.optionsSupervisor = data.resellerManager
        })
        .catch((e) => Promise.reject({ ...e }))
    },

    isNumber(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    }
  },
  validations: {
    reseller: {
      name: { required },
      phone: { required, minLength: minLength(10) },
      merchant_name: { required },
      address: { required },
      customer: { required },
      manager: { required },
    },
  }
};
</script>
