import api from "./api";

class AuthService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        // this.api = api.create('auth')
    }

    login(serverParams) {
        return api.create('cms-auth').post("/login", { ...serverParams });
    }

    refreshToken(serverParams) {
        return api.create('cms-auth').post("/refresh-token", { ...serverParams });
    }

    forgetPassword(serverParams) {
        return api.create('cms-auth').post("/forget-password", { ...serverParams });
    }

    resetPassword(serverParams) {
        return api.create('cms-auth').post("/reset-password", { ...serverParams });
    }
}

export default new AuthService();