import api from "./api";

class CareerCategoryService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('career-category')
    }

    getCareerCategoryList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postCareerCategory(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getCareerCategoryById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchCareerCategory(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteCareerCategoryById(serverParams) {
        return this.api.delete("/" + serverParams);
    }
}

export default new CareerCategoryService();