<template>
  <div>
    <!-- BEGIN login -->
    <div class="login login-v2 fw-bold">
      <!-- BEGIN login-cover -->
      <div class="login-cover">
        <div class="login-cover-img"></div>
        <!-- <div class="login-cover-bg"></div> -->
      </div>
      <!-- END login-cover -->

      <!-- BEGIN login-container -->
      <div class="login-container">
        <!-- BEGIN login-header -->
        <div class="login-header">
          <div class="brand">
            <div class="d-flex align-items-center justify-content-center">
              <img src="/assets/img/logo/logo-goon.png" alt="Goon" />
              &nbsp; <b>Goon</b> &nbsp;CMS
            </div>
          </div>
        </div>
        <!-- END login-header -->

        <!-- BEGIN login-content -->
        <div class="login-content">
          <form @submit.prevent="handleLogin()">
            <div class="form-floating">
              <input type="text" class="form-control fs-13px h-45px border-0" placeholder="Email" v-model="login.email"
                id="emailAddress" />
              <label for="emailAddress" class="d-flex align-items-center text-gray-600 fs-13px">Email</label>
            </div>
            <span class="text-danger" v-if="!$v.login.email.required && $v.login.email.$error">Email is required</span>
            <span class="text-danger" v-if="!$v.login.email.email && $v.login.email.$error">Wrong email format</span>
            <div class="form-floating mt-20px">
              <input :type="passwordTypeField" v-model="login.password" class="form-control fs-13px h-45px border-0"
                placeholder="Password" />
              <label for="emailAddress" class="d-flex align-items-center text-gray-600 fs-13px">Password</label>
              <span class="d-flex media-left btn btn-toggle-password" style="bottom: 8px;"><i @click="showPassword"
                  :class="passwordClass"></i></span>
            </div>

            <span class="text-danger" v-if="!$v.login.password.required && $v.login.password.$error">Password is
              required</span>
            <div class="mt-20px">
              <!-- <vue-recaptcha ref="recaptcha" @verify="onCaptchaVerified" @expired="onCaptchaExpired"
                :sitekey="siteKey"></vue-recaptcha> -->
            </div>
            <!-- <span class="text-danger" v-if="!$v.login.captcha.required && $v.login.captcha.$error">Capctha must be
              selected</span> -->
            <div v-if="serverErrorFields">
              <span class="text-danger" v-for="(item, index) in errorFields.captcha" :key="index">{{ item }}</span>
            </div>
            <div class="mt-20px">
              <button type="submit" :class="{ disabled: inProgress }" class="btn btn-primary d-block w-100 h-45px btn-lg">
                <span v-show="inProgress" class="spinner-border spinner-border-sm" role="status"
                  aria-hidden="true"></span>
                Log in
              </button>
            </div>
          </form>
          <div class="text-center">
            <b-button variant="link text-white" v-b-modal.modalLupaPassword><strong>Lupa Password?</strong></b-button>
          </div>
        </div>
        <!-- END login-content -->
      </div>
      <!-- END login-container -->
    </div>
    <!-- END login -->

    <b-modal id="modalLupaPassword" @hide="handleHide" centered no-close-on-backdrop>
      <template slot="modal-header">
        <h4 class="modal-title">Lupa Password</h4>
        <a class="btn-close" @click="$bvModal.hide('modalLupaPassword')"></a>
      </template>
      <form>
        <div class="w-300px mx-auto">
          <div class="mb-3">
            <b-form-group id="fieldset-1" label="Masukkan email yang terdaftar" label-for="email"
              valid-feedback="Thank you!">
              <b-form-input id="email" v-model="lupaPassword.emailLupaPassword"></b-form-input>
            </b-form-group>
            <span class="text-danger" v-if="!$v.lupaPassword.emailLupaPassword.required &&
              $v.lupaPassword.emailLupaPassword.$error
              ">Email is required</span>
            <span class="text-danger" v-if="!$v.lupaPassword.emailLupaPassword.email &&
              $v.lupaPassword.emailLupaPassword.$error
              ">Wrong email format</span>
            <div v-if="serverErrorFields">
              <span class="text-danger" v-for="(item, index) in errorFieldsForgotPassword.email" :key="index">{{ item
              }}</span>
            </div>
          </div>
          <div class="mb-3">
            <!-- <vue-recaptcha
              ref="recaptchaForgotPassword"
              @verify="onCaptchaVerifiedForgot"
              @expired="onCaptchaExpiredForgot"
              :sitekey="siteKey"
            ></vue-recaptcha> -->
            <!-- <span class="text-danger" v-if="!$v.lupaPassword.captcha.required &&
              $v.lupaPassword.captcha.$error
              ">Capctha must be selected</span> -->
          </div>
        </div>
      </form>
      <template slot="modal-footer">
        <a :class="{ disabled: inProgress }" class="btn btn-primary w-100px" @click="sendEmailForgotPassword()">
          <span v-show="inProgress" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Kirim</a>
      </template>
    </b-modal>
    <b-modal ref="errorAlert" hide-footer>
      <template slot="modal-header">
        <h4 class="modal-title">{{ headerAlert }}</h4>
        <a class="btn-close" @click="$refs.errorAlert.hide()"></a>
      </template>
      <div class="text-center">
        {{ message }}
      </div>
    </b-modal>
  </div>
</template>

<script>
import AppOptions from "../../config/AppOptions.vue";
// import { VueRecaptcha } from "vue-recaptcha";
import { required, email } from "vuelidate/lib/validators/";
import authService from "@/service/auth.service";

export default {
  // components: { VueRecaptcha },
  created() {
    AppOptions.appEmpty = true;
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appEmpty = false;
    next();
  },
  data() {
    return {
      inProgress: false,
      login: {
        email: "",
        password: "",
        captcha: "",
      },
      lupaPassword: {
        emailLupaPassword: "",
        captcha: "",
      },
      siteKey: process.env.VUE_APP_CAPTCHA_SITE_KEY,
      passwordTypeField: "password",
      passwordClass: "fas fa-eye",
      message: "",
      headerAlert: "Login",
      resErrorLupaPassword: [],
      serverErrorFields: false,
      errorFields: {
        captcha: [],
      },
      errorFieldsForgotPassword: {
        email: [],
      },
    };
  },
  mounted() {
    // console.log(this.loggedIn)
    // if (this.loggedIn) {
    //   this.$router.push("/profile");
    // }
  },
  methods: {
    onCaptchaVerified: function (recaptchaToken) {
      this.login.captcha = recaptchaToken;
      // console.log(recaptchaToken);
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
    onCaptchaVerifiedForgot: function (recaptchaToken) {
      this.lupaPassword.captcha = recaptchaToken;
    },
    onCaptchaExpiredForgot: function () {
      this.$refs.recaptchaForgotPassword.reset();
    },
    showPassword() {
      this.passwordTypeField =
        this.passwordTypeField === "password" ? "text" : "password";
      this.passwordClass =
        this.passwordTypeField === "password"
          ? "fas fa-eye"
          : "fas fa-eye-slash";
    },
    async handleLogin() {
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')
      this.$v.login.$touch();
      if (!this.$v.login.$invalid) {
        let user = {
          email: this.login.email,
          password: this.login.password,
        };
        this.inProgress = true;
        this.$store.dispatch("auth/login", user).then(
          (r) => {
            if (r) {
              location.reload();
            }
          },
          (error) => {
            this.inProgress = false;
            this.$refs.errorAlert.show()
            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        )
      }
    },
    sendEmailForgotPassword() {
      this.$v.lupaPassword.$touch();
      if (!this.$v.lupaPassword.$invalid) {
        this.inProgress = true;

        let data = {
          email: this.lupaPassword.emailLupaPassword,
          resetPasswordUrl: process.env.VUE_APP_URL + 'password'
        };

        authService.forgetPassword(data).then((rsp) => {
          this.$swal({
            title: "Cek email anda untuk reset password",
            showCloseButton: true,
          }).then(() => {
            // this.$router.push({ path: '/pages/list' })
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          }
        }).finally(() =>
          this.inProgress = false)
      }
    },
    handleHide() {
      this.lupaPassword.emailLupaPassword = "";
      this.lupaPassword.captcha = "";
      this.$v.lupaPassword.$reset();
    },
  },
  validations: {
    login: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
    lupaPassword: {
      emailLupaPassword: {
        required,
        email,
      },
    },
  },
};
</script>
