import api from "./api";

class CareerLevelService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('career-level')
    }

    getCareerLevelList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postCareerLevel(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getCareerLevelById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchCareerLevel(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteCareerLevelById(serverParams) {
        return this.api.delete("/" + serverParams);
    }
}

export default new CareerLevelService();