<template>
  <div class="product-form">
    <FormPages form-type="add" @onValidateSuccess="doSave" :detail-data="page" :on-loading="onLoading" />
  </div>
</template>
<script>
import FormPages from './Form.vue'
import HomepageService from "@/service/homepage.service";
import base64Converter from "@/pages/Helper/base64";
import TokenService from "@/service/token.service";
import moment from "moment";
export default {
  components: {
    FormPages,
  },
  data() {
    return {
      onLoading: false,
      optionsProductCategory: ['Popok Bayi', 'Tisu Wajah', 'Masker Wajah'],
      page: {
        id: null,
        title: null,
        template: "Homepage",
        meta: {
          title: null,
          description: null,
          keywords: [],
          image: null,
        },
        homepage: {
          sections: {
            one: {
              isShow: true,
              title: "",
              items: [],
            },
            two: {
              isShow: true,
              categories: [],
              products: {
                one: [],
                two: [],
                three: []
              },
            },
            three: {
              isShow: true,
              ecommerce: []
            },
            four: {
              isShow: true,
              promotions: [
                {
                  id: 0,
                  item: '',
                },
                {
                  id: 1,
                  item: '',
                },
              ]
            },
            five: {
              isShow: true,
              news: [
                {
                  id: 0,
                  item: '',
                },
                {
                  id: 1,
                  item: '',
                },
                {
                  id: 2,
                  item: '',
                },
                {
                  id: 3,
                  item: '',
                },
              ]
            },
            six: {
              isShow: true,
              alt: '',
              desktop: null,
              desktopSrc: '',
              alt_desktop_image: null,
              mobile: null,
              mobileSrc: '',
              cta_url: null,
              alt_mobile_image: null,
            },
          },
        },
      },
    };
  },
  methods: {
    async doSave(payload) {
      // Submit here
      this.onLoading = true
      let { page, section } = payload
      if (page) {
        let { id, meta, title, homepage: { sections: { one, two, three, four, five, six } } } = page

        let banner = [],
          product = [],
          productCategories = [],
          ecomerce = [],
          promos = [],
          articles = [],
          parent = []

        payload = {
          user_id: (TokenService.getUser()).id,
          // page_title: title,
          // banners: banner,
          // products: product,
          // parentProductCategories: parent,
          // productCategories: productCategories,
          // ecommerces: ecomerce,
          // promos: promos,
          // postArticles: articles,
        }

        switch (section) {
          case 'one':
            for (const [k, v] of Object.entries(one.items)) {
              let { isShow, order, images, cta_url, title, caption } = v
              let perBanner = {
                caption: caption ? caption : null,
                desktop_image: images.desktop[0] ? await base64Converter(images.desktop[0]) : null,
                mobile_image: images.mobile[0] ? await base64Converter(images.mobile[0]) : null,
                cta_url: cta_url ? cta_url : null,
                title: title ? title : null,
                alt_desktop_image: images.alt_desktop_image ? images.alt_desktop_image : null,
                alt_mobile_image: images.alt_mobile_image ? images.alt_mobile_image : null,
                order: order ? order : null,
              }
              banner.push(perBanner)
            }
            payload.page_title = title
            payload.banners = banner
            break;
          case 'two':
            for (const key in two.products) {
              if (two.products[key].length > 0) {
                for (const [k, v] of Object.entries(two.products[key])) {
                  for (const v1 in v) {
                    if (v[v1] && v[v1].id) {
                      product.push(v[v1].id)
                    }
                  }
                }
              }
            }

            if (two.categories) {
              for (const k in two.categories) {
                if (two.categories[k]) {
                  parent.push(two.categories[k].id)
                }
              }
            }

            payload.parentProductCategories = parent
            payload.products = product.length > 0 ? product : []
            break;
          case 'three':
            for (const [k, v] of Object.entries(three.ecommerce)) {
              let { item: { id } } = v
              ecomerce.push(id)
            }
            payload.ecommerces = ecomerce
            break;
          case 'four':
            for (const [k, v] of Object.entries(four.promotions)) {
              // let { item: { id } } = v
              if (v.item?.id) {
                promos.push(v.item?.id)
              }
            }
            payload.promos = promos.length > 0 ? promos : []
            break;
          case 'five':
            for (const [k, v] of Object.entries(five.news)) {
              let { item: { id } } = v
              articles.push(id)
            }
            payload.postArticles = articles
            break;
          case 'six':
            payload.enhancement = [
              {
                "desktop_image": (await this.$store.dispatch('file/upload', six.desktop)).data.data.filePath,
                "alt_desktop_image": six.alt_desktop_image,
                "mobile_image": (await this.$store.dispatch('file/upload', six.mobile)).data.data.filePath,
                "alt_mobile_image": six.alt_mobile_image,
                "cta_url": six.cta_url
              }
            ]
            break;
          default:
            break;
        }

        if (id) {
          payload.id = id
          HomepageService.patchHomepage(id, payload).then((rsp) => {
            this.$swal({
              title: "Data berhasil disimpan",
              showCloseButton: true,
            }).then(() => {
              location.reload()
              this.loadItems()
            });
          }).catch((e) => {
            if (e.response?.status === 409) {
              this.$notify({
                type: "warn",
                title: "Error Message",
                text: "Data Sudah Ada",
              });
            } else {
              this.$notify({
                type: "error",
                title: "Error Message",
                text: e.response?.data?.message || "Terjadi Kesalahan",
              });
            }
          }).finally(() =>
            this.onLoading = false)
        } else {
          HomepageService.postHomepage(payload).then((rsp) => {
            this.$swal({
              title: "Data berhasil disimpan",
              showCloseButton: true,
            }).then(() => {
              location.reload()
              this.loadItems()
              // this.$router.push({ path: '/sections/banner' })
            });
          }).catch((e) => {
            if (e.response?.status === 409) {
              this.$notify({
                type: "warn",
                title: "Error Message",
                text: "Data Sudah Ada",
              });
            } else {
              this.$notify({
                type: "error",
                title: "Error Message",
                text: e.response?.data?.message || "Terjadi Kesalahan",
              });
            }
          }).finally(() =>
            this.onLoading = false)
        }
      }
    },
    loadItems() {
      this.onLoading = true
      HomepageService
        .getHomepageList({})
        .then(async ({ data: { data } }) => {
          if (data.homepage[0]) {
            let { banners, productOnHomepage, ParentProductOnHomepage, productCategoryOnHomepage, promoOnHomepage, postArticleOnHomepage, ecommerceOnHomepage, parent_product_category, EnhancementLoyalty, ...arrData } = data.homepage[0]
            let banner = [], obProduct = { one: [], two: [], three: [], }, categories = {}, ecomerce = [], promo = [], articles = [], enhancement = {}
            banners = banners.sort((a, b) => a.id - b.id)
            for (const [k, v] of Object.entries(banners)) {
              banner.push({
                isShow: true,
                title: v.title,
                caption: v.caption,
                cta_url: v.cta_url,
                order: v.order,
                images: {
                  desktop: [await this.convertURLtoFile(this.loadSrcImage(v.desktop_image))],
                  desktopSrc: this.loadSrcImage(v.desktop_image),
                  mobile: [await this.convertURLtoFile(this.loadSrcImage(v.mobile_image))],
                  mobileSrc: this.loadSrcImage(v.mobile_image),
                  alt_desktop_image: v.alt_desktop_image ? v.alt_desktop_image : null,
                  alt_mobile_image: v.alt_mobile_image ? v.alt_mobile_image : null,
                },
              })
            }
            if (ParentProductOnHomepage.length > 0) {
              let newCategories = []
              let getMax = []

              for (const [k, v] of Object.entries(ParentProductOnHomepage)) {
                newCategories.push(v.parent_product_category)
                let findProduct = productOnHomepage.filter((r) => r.products.parent_product_category == v.id)
                getMax.push(findProduct.length)
              }

              let maxRow = Math.max(...getMax)
              for (const [k, v] of Object.entries(newCategories)) {
                let findProduct = productOnHomepage.filter((r) => r.products.parent_product_category_id == v.id)
                let arrProduct = []
                for (let index = 0; index < maxRow; index++) {
                  if (findProduct[index]) {
                    let { products } = findProduct[index]
                    arrProduct.push({
                      item: products
                    })
                  } else {
                    arrProduct.push({
                      item: ""
                    })
                  }
                }

                if (k == 0) {
                  obProduct.one = arrProduct
                  categories.one = v
                } else if (k == 1) {
                  obProduct.two = arrProduct
                  categories.two = v
                } else {
                  obProduct.three = arrProduct
                  categories.three = v
                }
              }

              if (maxRow > 0) {
                if (obProduct.three.length == 0 || obProduct.three.length != maxRow) {
                  for (let i = 0; i < maxRow; i++) {
                    if (!obProduct.three[i]) {
                      obProduct.three.push({
                        item: ''
                      });
                    } else {
                      i = (obProduct.three.length - 1)
                    }
                  }
                }

                if (obProduct.one.length == 0 || obProduct.one.length != maxRow) {
                  for (let i = 0; i < maxRow; i++) {
                    if (!obProduct.one[i]) {
                      obProduct.one.push({
                        item: ''
                      });
                    } else {
                      i = (obProduct.three.length - 1)
                    }
                  }
                }

                if (obProduct.two.length == 0 || obProduct.one.length != maxRow) {
                  for (let i = 0; i < maxRow; i++) {
                    if (!obProduct.two[i]) {
                      obProduct.two.push({
                        item: ''
                      });
                    } else {
                      i = (obProduct.three.length - 1)
                    }
                  }
                }
              }
            }

            for (const [k, v] of Object.entries(ecommerceOnHomepage)) {
              ecomerce.push({
                id: k,
                item: v.ecommerces,
              })
            }

            for (const [k, v] of Object.entries(promoOnHomepage)) {
              promo.push({
                id: k,
                item: v.promos,
              })
            }

            if (promo.length != 2) {
              for (let i = 0; i < 2; i++) {
                if (!promo[i]) {
                  promo.push({
                    id: '',
                    item: ''
                  })
                } else {
                  i = (promo.length - 1)
                }
              }
            }

            for (const [k, v] of Object.entries(postArticleOnHomepage)) {
              articles.push({
                id: k,
                item: v.post_articles,
              })
            }

            if (articles.length != 4) {
              for (let i = 0; i < 4; i++) {
                if (!articles[i]) {
                  articles.push({
                    id: '',
                    item: ''
                  })
                } else {
                  i = (articles.length - 1)
                }
              }
            }

            for (const [k, v] of Object.entries(EnhancementLoyalty)) {
              enhancement = {
                isShow: true,
                desktop: [await this.convertURLtoFile(this.loadSrcImage(v.desktop_image))],
                desktopSrc: this.loadSrcImage(v.desktop_image),
                alt_desktop_image: v.alt_desktop_image ? v.alt_desktop_image : null,
                mobile: [await this.convertURLtoFile(this.loadSrcImage(v.mobile_image))],
                mobileSrc: this.loadSrcImage(v.mobile_image),
                cta_url: v.cta_url ? v.cta_url : null,
                alt_mobile_image: v.alt_mobile_image ? v.alt_mobile_image : null,
              }
            }

            this.page = {
              id: arrData.id,
              title: arrData.page_title,
              template: "Homepage",
              publishedDate: arrData.User ? arrData.updatedAt : null,
              lastModified: arrData.updatedAt ? arrData.updatedAt : null,
              author: arrData.User && arrData.User?.name ? arrData.User?.name : '',
              homepage: {
                sections: {
                  one: {
                    isShow: true,
                    title: "",
                    items: banner,
                  },
                  two: {
                    isShow: true,
                    categories: categories,
                    products: obProduct
                  },
                  three: {
                    isShow: true,
                    ecommerce: ecomerce
                  },
                  four: {
                    isShow: true,
                    promotions: promo
                  },
                  five: {
                    isShow: true,
                    news: articles
                  },
                  six: enhancement,
                },
              },
              selected: categories,
            }
          } else {
            this.page = {
              id: null,
              title: null,
              template: "Homepage",
              homepage: {
                sections: {
                  one: {
                    isShow: true,
                    title: "",
                    items: [],
                  },
                  two: {
                    isShow: true,
                    categories: {},
                    products: {
                      one: [],
                      two: [],
                      three: []
                    },
                  },
                  three: {
                    isShow: true,
                    ecommerce: []
                  },
                  four: {
                    isShow: true,
                    promotions: [
                      {
                        id: 0,
                        item: '',
                      },
                      {
                        id: 1,
                        item: '',
                      },
                    ]
                  },
                  five: {
                    isShow: true,
                    news: [
                      {
                        id: 0,
                        item: '',
                      },
                      {
                        id: 1,
                        item: '',
                      },
                      {
                        id: 2,
                        item: '',
                      },
                      {
                        id: 3,
                        item: '',
                      },
                    ]
                  },
                  six: {
                    isShow: true,
                    alt: '',
                    desktop: null,
                    desktopSrc: '',
                    alt_desktop_image: null,
                    mobile: null,
                    mobileSrc: '',
                    cta_url: null,
                    alt_mobile_image: null,
                  },
                },
              },
            }
          }
        }).catch((e) => {
          // console.log(e)
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.onLoading = false;
        })
    },
    loadSrcImage(url) {
      return this.$api_name + '/' + url
    },
    async convertURLtoFile(url) {
      const response = await fetch(url);
      if (response.status != 200) {
        return "";
      }
      const data = await response.blob();
      const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
      const metadata = { type: `image/jpeg` };
      return new File([data], filename, metadata);
    },
    dateFormat(date) {
      return moment(date).format('DD MM YYYY')
    }
  },
  mounted() {
    this.loadItems()
  },
}
</script>
<style>
.disabled {
  pointer-events: none;
  color: #bfcbd9;
  cursor: not-allowed;
  background-image: none;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}
</style>
