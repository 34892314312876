<template>
  <div class="product-form">
    <FormClaim form-type="edit" :detail-data="form" @onValidateSuccess="doSave" />
  </div>
</template>
<script>
import FormClaim from "./Form.vue";
import receiptService from "@/service/receipt.service";
import CustomerService from "@/service/customer.service";
import ProductService from "@/service/product.service";
import tokenService from "@/service/token.service";
import base64Converter from "@/pages/Helper/base64";
import userService from "@/service/user.service";

export default {
  data() {
    return {
      optionStatus: [
        {
          label: 'Pending',
          value: 'pending'
        },
        {
          label: 'In Review',
          value: 'in_review'
        },
        // {
        //   label: 'Approved By Ocr',
        //   value: 'approved_by_ocr'
        // },
        // {
        //   label: 'Approved By Admin',
        //   value: 'approved_by_admin'
        // },
        {
          label: 'Rejected',
          value: 'rejected'
        }
      ],
      form: {
        id: this.$route.params.id,
      },
    };
  },
  components: {
    FormClaim,
  },
  mounted() {
    this.loadItems()
    // this.loadItemsCustomer(this.$route.params.id)
  },
  methods: {
    async doSave(payload) {
      let { form } = payload
      let { customer, approved_user, product, status, imageSrc, image, struk_path, notes, is_manual_check, total_point, struk_img, ...req } = form

      payload = {
        ...req,

        is_manual_check: 0,
        status: status,
        approved_by: tokenService.getUser().id,
        rejected_by: tokenService.getUser().id,
        notes: notes ? notes : null,
        total_point: total_point ? total_point : 0
      }
      if (image[0]) {
        payload.struk_img = await base64Converter(image[0])
      }

      receiptService.patchReceipt(this.$route.params.id, payload).then((rsp) => {
        this.$swal({
          title: "Data berhasil disimpan",
          showCloseButton: true,
        }).then(() => {
          this.$router.push({ path: '/claim-point/list' })
        });
      }).catch((e) => {
        if (e.response?.status === 409) {
          this.$notify({
            type: "warn",
            title: "Error Message",
            text: "Data Sudah Ada",
          });
        } else {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }
      }).finally(() =>
        this.onLoading = false)
    },

    loadItems() {
      this.isLoading = true;
      receiptService
        .getReceiptById(this.$route.params.id)
        .then(async ({ data: { data } }) => {
          let birthday = data.birthday
          let { customer_id, product_id, struk_path, status, struk_img, approved_by, rejected_by, notes } = data.receipt
          let { customer } = await this.loadItemsCustomer(customer_id)
          let { name } = approved_by ? await this.loadItemsUser(approved_by) : ''
          let rejectedUser = rejected_by ? await this.loadItemsUser(rejected_by) : ''
          this.form = {
            ...data.receipt,
            birthday,
            notes: notes == ' - ' ? null : notes,
            image: [await this.convertURLtoFile(this.loadSrcImage(struk_path + '/' + struk_img))],
            imageSrc: this.loadSrcImage(struk_path + '/' + struk_img),
            customer: customer ? customer : '',
            approved_user: name ? name : '',
            rejected_user: rejected_by == 0 && status == 'rejected' ? 'OCR' : rejectedUser?.name,
            product: product_id != null && product_id != 0 ? await this.loadItemsProduct(product_id) : 0
          }
        }).catch((e) => {
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },

    loadItemsCustomer(id) {
      return CustomerService
        .getCustomerById(id)
        .then(({ data: { data } }) => {
          let { customer, totalPoint } = data
          return Promise.resolve({
            customer: customer
          })
        }).catch((e) => {
          return Promise.reject({ ...e })
        })
    },

    loadItemsProduct(id) {
      return ProductService
        .getProductById(id)
        .then(({ data: { data } }) => {
          return Promise.resolve({ ...data })
        }).catch((e) => {
          return Promise.reject({ ...e })
        })
    },

    loadSrcImage(url) {
      return this.$api_name + '/' + url
    },

    async convertURLtoFile(url) {
      const response = await fetch(url);
      if (response.status != 200) {
        return "";
      }
      const data = await response.blob();
      const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
      const metadata = { type: `image/jpeg` };
      return new File([data], filename, metadata);
    },

    loadItemsUser(id) {
      return userService
        .getUserById(id)
        .then(({ data: { data } }) => {
          return Promise.resolve({
            ...data
          })
        }).catch((e) => {
          return Promise.reject({ ...e })
        })
    },
  },
};
</script>
