<template>
  <div class="product-form">
    <FormBanner form-type="edit" :detail-data="detail" @onValidateSuccess="doSave" :on-loading="onLoading" />
  </div>
</template>
<script>
import FormBanner from "./Form.vue";
import BannerService from "@/service/banner.service";
import PageService from "@/service/page.service";
import base64Converter from "@/pages/Helper/base64";
export default {
  data() {
    return {
      onLoading: false,
      detail: {
        id: this.$route.params.id,
        title: "",
        description: "",
        images: {
          desktop: null,
          mobile: null,
        },
      },
    };
  },
  components: {
    FormBanner,
  },

  methods: {
    async doSave(payload) {
      // Submit here
      this.onLoading = true
      let { banner, status } = payload
      if (banner) {
        let { cta, images, description, product_category, parent_category } = banner
        // console.log(banner)
        payload = {
          desktop_image: await base64Converter(images.desktop[0]),
          mobile_image: await base64Converter(images.mobile[0]),
          banner_title: banner.title,
          product_category_id: product_category?.id ? product_category.id : null,
          parent_product_category_id: parent_category?.id ? parent_category.id : null,
          tag: banner.tag ? banner.tag.toString() : null,
          location_banner_page: banner.location,
          position_banner: banner.position,
          alt_desktop_image: banner.images.alt_desktop_image ? banner.images.alt_desktop_image : null,
          alt_mobile_image: banner.images.alt_mobile_image ? banner.images.alt_mobile_image : null,
          cta1_text: cta.text1 ? cta.text1 : null,
          cta1_url: cta.url1 ? cta.url1 : null,
          cta2_text: cta.text2 ? cta.text2 : null,
          cta2_url: cta.url2 ? cta.url2 : null,
          description: description ? description : null,
          status
        }

        BannerService.patchBanner(this.$route.params.id, payload).then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: '/sections/banner' })
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          }
        }).finally(() =>
          this.onLoading = false)
      }
    },
    loadItems(id) {
      BannerService.getBannerById(id)
        .then(async ({ data: { data } }) => {
          this.detail = {
            title: data ? data.banner_title : null,
            description: data ? data.description : null,
            parent_category: data.parent_product_category ? data.parent_product_category : null,
            product_category: data.product_category ? data.product_category : null,
            images: {
              desktop: data ? [await this.convertURLtoFile(this.loadSrcImage(data.desktop_image))] : null,
              desktopSrc: data ? this.loadSrcImage(data.desktop_image) : '',
              mobile: data ? [await this.convertURLtoFile(this.loadSrcImage(data.mobile_image))] : '',
              mobileSrc: data ? this.loadSrcImage(data.mobile_image) : null,
              alt_desktop_image: data.alt_desktop_image ? data.alt_desktop_image : null,
              alt_mobile_image: data.alt_mobile_image ? data.alt_mobile_image : null,
            },
            location: data ? data.location_banner_page : null,
            position: data ? data.position_banner : null,
            cta: {
              text1: data ? data.cta1_text : null,
              url1: data ? data.cta1_url : null,
              text2: data ? data.cta2_text : null,
              url2: data ? data.cta2_url : null,
            },
          }
        })
    },
    loadPage(id) {
      return PageService.getPageById(id)
        .then(async ({ data: { data } }) => data)
    },
    loadSrcImage(url) {
      return this.$api_name + '/' + url
    },
    async convertURLtoFile(url) {
      const response = await fetch(url);
      if (response.status != 200) {
        return "";
      }
      const data = await response.blob();
      const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
      const metadata = { type: `image/jpeg` };
      return new File([data], filename, metadata);
    },
  },
  async mounted() {
    await this.loadItems(this.$route.params.id)
  },
};
</script>
