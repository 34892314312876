var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row align-items-center justify-content-between mb-4"},[_vm._m(0),_c('div',{staticClass:"col-auto mb-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto mb-3"},[(_vm.exportExcel)?_c('div',{staticClass:"col-auto"},[_c('a',{staticClass:"btn btn-outline-primary",on:{"click":function($event){$event.preventDefault();return _vm.downloadExcel.apply(null, arguments)}}},[_c('i',{staticClass:"fa-solid fa-file-import"}),_vm._v(" Export ")])]):_vm._e()]),(_vm.add)?_c('div',{staticClass:"col-auto"},[_c('router-link',{attrs:{"to":"/pages/contact-us/add"}},[_c('a',{staticClass:"btn btn-outline-primary"},[_vm._v("Add Data")])])],1):_vm._e()])])]),_c('vue-good-table',{attrs:{"slot":"outsideBody","mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"pagination-options":{
        enabled: true,
        dropdownAllowAll: false,
        setCurrentPage: _vm.setCurrentPage,
        perPage: _vm.serverParams.take,
      },"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
  enabled: true,
  initialSortBy: { field: 'name', type: 'asc' },
},"styleClass":"vgt-table"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},slot:"outsideBody",scopedSlots:_vm._u([{key:"column-filter",fn:function(ref){
var column = ref.column;
var updateFilters = ref.updateFilters;
return [(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'name'
          )?_c('input',{ref:"name",staticClass:"form-control",attrs:{"placeholder":"Search Name"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.name.value); }}}):_vm._e(),(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'email'
          )?_c('input',{ref:"email",staticClass:"form-control",attrs:{"placeholder":"Search email"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.email.value); }}}):_vm._e(),(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'phune_number'
          )?_c('input',{ref:"phune_number",staticClass:"form-control",attrs:{"placeholder":"Search Phone Number"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.phune_number.value); }}}):_vm._e(),(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'description'
          )?_c('input',{ref:"description",staticClass:"form-control",attrs:{"placeholder":"Search Pesan"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.description.value); }}}):_vm._e(),(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'createdAt')?_c('div',{staticClass:"input-group"},[_c('input',{ref:"createdAt1",staticClass:"form-control",attrs:{"type":"date","placeholder":"Search ..."},on:{"input":function (value) { return updateFilters(column, _vm.$refs.createdAt1.value + '#' + _vm.$refs.createdAt2.value); }}}),_c('span',{staticClass:"input-group-text"},[_vm._v(" - ")]),_c('input',{ref:"createdAt2",staticClass:"form-control",attrs:{"type":"date","placeholder":"Search ..."},on:{"input":function (value) { return updateFilters(column, _vm.$refs.createdAt1.value + '#' + _vm.$refs.createdAt2.value); }}})]):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[(props.row.status !== 'Deleted')?_c('div',{staticClass:"row g-2 align-items-center justify-content-end"},[(_vm.edit)?_c('div',{staticClass:"col-auto"},[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"to":("/pages/contact-us/edit/" + (props.row.id)),"title":"Edit"}},[_c('i',{staticClass:"fas fa-pencil"})])],1):_vm._e(),(_vm.deleteContact)?_c('div',{staticClass:"col-auto"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"type":"button","title":"Move to Trash"},on:{"click":function($event){$event.preventDefault();return _vm.showAlertConfirm(props.row.id)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()]):_vm._e()]):_c('span',{class:[props.row.status === 'Deleted' && 'text-muted']},[_vm._v(" "+_vm._s((props.formattedRow[props.column.field].length > 55) ? props.formattedRow[props.column.field].substring(0, 55) + "..." : props.formattedRow[props.column.field])+" ")])]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-auto"},[_c('h1',{staticClass:"page-header"},[_vm._v("Contact Us Data")])])}]

export { render, staticRenderFns }