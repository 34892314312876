import api from "./api";

class ProductTypeService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('product-type')
    }
    
    getProductTypeList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postProductType(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getProductTypeById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchProductType(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteProductTypeById(serverParams) {
        return this.api.delete("/" + serverParams);
    }
}

export default new ProductTypeService();