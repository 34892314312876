<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">Reseller Data</h1>
        <!-- END page-header -->
      </div>
      <div class="col-auto mb-3">
        <div class="row">
          <div class="col-auto" v-if="importReseller">
            <button type="button" class="btn btn-dark" @click.prevent="downloadExcel">Export</button>
          </div>
          <div class="col-auto" v-if="addReseller">
            <router-link to="/reseller-submission/add">
              <a class="btn btn-outline-primary">Add Data</a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
        setCurrentPage: setCurrentPage,
        perPage: serverParams.take,
      }" :columns="columns" :rows="rows" :sort-options="{
  enabled: true,
  initialSortBy: { field: 'name', type: 'asc' },
}" styleClass="vgt-table" slot="outsideBody">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="name" class="form-control" placeholder="Search.." v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'name'
          " @input="(value) => updateFilters(column, $refs.name.value)" />

        <div class="input-group" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'createdAt'
          ">
          <input type="date" ref="createdAt1" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
          <span class="input-group-text">
            -
          </span>
          <input type="date" ref="createdAt2" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
        </div>
        <input ref="manager.area" class="form-control" placeholder="Search.." v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'manager.area'
          " @input="(value) => updateFilters(column, $refs['manager.area'].value)" />
        <input ref="merchant_name" class="form-control" placeholder="Search.." v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'merchant_name'
          " @input="(value) => updateFilters(column, $refs.merchant_name.value)" />
        <input ref="phone" class="form-control" placeholder="Search.." v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'phone'
          " @input="(value) => updateFilters(column, $refs.phone.value)" />
        <select ref="status" class="form-select" placeholder="Cari..." v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'status'
          " @change="(value) => updateFilters(column, $refs['status'].value)">
          <option />
          <option v-for="(option, key) in optionStatus" v-bind:value="option.value" :key="key" v-text="option.label" />
        </select>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end" v-if="props.row.status !== 'Deleted'">
            <div class="col-auto" v-if="editReseller">
              <router-link :to="`/reseller-submission/edit/${props.row.id}`" class="btn btn-lg text-dark"
                v-b-tooltip.hover title="Edit">
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>
            <div class="col-auto" v-if="deleteReseller">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                @click.prevent="showAlertConfirm(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else :class="[props.row.status === 'Deleted' && 'text-muted']">
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import resellerService from "@/service/reseller.service";
import tokenService from "@/service/token.service";
import moment from "moment";
export default {
  data() {
    return {
      setCurrentPage: this.$store.state.reseller.serverParams.skip / this.$store.state.reseller.serverParams.take == 0 ?
        1 : this.$store.state.reseller.serverParams.skip / this.$store.state.reseller.serverParams.take + 1,
      optionStatus: [
        {
          label: 'Pending',
          value: 'pending'
        },
        {
          label: 'Banned',
          value: 'banned'
        },
        {
          label: 'Active',
          value: 'active'
        }
      ],
      permisions: tokenService.getPermission(),
      isLoading: false,
      columns: [
        {
          label: "Reseller Name",
          field: "name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          sortable: false,
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Nama Toko",
          field: "merchant_name",
          sortable: false,
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Nomor Telpon",
          field: "phone",
          sortable: false,
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Area",
          field: "manager.area",
          sortable: false,
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Status",
          field: "status",
          thClass: "text-nowrap",
          tdClass: this.statusClass,
          sortable: false,
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Submitted at",
          field: "createdAt",
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          type: "date",
          sortable: false,
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
          filterOptions: {
            styleClass: 'vgt-relative',
            customFilter: true,
            enabled: true,
            filterValue: "",
            filterFn: this.filterFn,
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: this.$store.state.reseller.serverParams,
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
    };
  },
  methods: {
    statusClass(row) {
      let statusClass = ''
      switch (row.status) {
        case 'Banned':
          statusClass = 'bg-red text-white align-middle';
          break;
        case 'Pending':
          statusClass = 'bg-yellow text-white align-middle';
          break;
        case 'Active':
          statusClass = 'bg-green text-white align-middle';
          break;
        default:
          statusClass = 'bg-orange text-white align-middle';
          break;
      }
      return statusClass
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.$store.state.reseller.serverParams = Object.assign({}, this.$store.state.reseller.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ skip: 0 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({});
      this.loadItems();
    },

    onColumnFilter: _.debounce(function (params) {
      let payload = {}

      if (params.columnFilters.name && params.columnFilters.name != '') {
        payload.name = params.columnFilters.name ? params.columnFilters.name : ''
      } else {
        delete this.$store.state.reseller.serverParams.name
      }

      if (params.columnFilters['manager.area'] && params.columnFilters['manager.area'] != '') {
        payload.area = params.columnFilters['manager.area'] ? params.columnFilters['manager.area'] : ''
      } else {
        delete this.$store.state.reseller.serverParams.area
      }

      if (params.columnFilters['createdAt'] && params.columnFilters['createdAt'] != '#') {
        let arrSplit = params.columnFilters['createdAt'].split('#')
        if (arrSplit[0] && arrSplit[1]) {
          payload.createdAtStartDate = arrSplit[0] ? moment(arrSplit[0]).startOf('day').toISOString() : ''
          payload.createdAtEndDate = arrSplit[1] ? moment(arrSplit[1]).endOf('day').toISOString() : ''
        }
      } else {
        delete this.$store.state.reseller.serverParams.createdAtStartDate
        delete this.$store.state.reseller.serverParams.createdAtEndDate
      }

      if (params.columnFilters['status'] && params.columnFilters.status != '') {
        payload.status = params.columnFilters.status ? params.columnFilters.status : ''
      } else {
        delete this.$store.state.reseller.serverParams.status
      }

      if (params.columnFilters.phone && params.columnFilters.phone != '') {
        payload.phoneNumber = params.columnFilters.phone ? params.columnFilters.phone : ''
      } else {
        delete this.$store.state.reseller.serverParams.phoneNumber
      }

      if (params.columnFilters.merchant_name && params.columnFilters.merchant_name != '') {
        payload.merchantName = params.columnFilters.merchant_name ? params.columnFilters.merchant_name : ''
      } else {
        delete this.$store.state.reseller.serverParams.merchantName
      }

      this.updateParams(payload);
      this.loadItems();
    }, 300),

    loadItems() {
      resellerService
        .getResellerList(this.$store.state.reseller.serverParams)
        .then(async ({ data: { data } }) => {
          let arrData = []
          if (data.reseller.length > 0) {
            for (const [k, v] of Object.entries(data.reseller)) {
              arrData.push({
                ...v,
                status: this.optionStatus.find((r) => r.value == v.status)?.label
              })
            }
          }
          this.rows = arrData
          this.totalRecords = data.totalData
        }).catch((e) => {
          // console.log(e)
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },

    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },
    doDelete(id) {
      this.isLoading = true;
      resellerService
        .deleteResellerById(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },

    downloadExcel() {
      this.isLoading = true;
      let { take, skip, sort, ...NewParams } = this.$store.state.reseller.serverParams
      let payload = {
        ...NewParams
      }
      resellerService.getResellerExport(payload)
        .then(({ data: { data } }) => {
          let { path } = data
          if (path) {
            window.open(this.$api_name + '/' + path, '_blank')
          }
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    }
  },
  computed: {
    addReseller: function () {
      if (this.permisions.find((r) => r == 'Reseller Add')) {
        return true
      }
      return false;
    },
    editReseller: function () {
      if (this.permisions.find((r) => r == 'Reseller Patch')) {
        return true
      }
      return false;
    },
    deleteReseller: function () {
      if (this.permisions.find((r) => r == 'Reseller Delete')) {
        return true
      }
      return false;
    },
    importReseller: function () {
      if (this.permisions.find((r) => r == 'Reseller Export')) {
        return true
      }
      return false;
    },
  },
  mounted() {
    this.loadItems();
  },
};
</script>
