import api from "./api";

class ReceiptService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('receipt')
    }

    getReceiptList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postReceipt(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getReceiptById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchReceipt(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteReceiptById(serverParams) {
        return this.api.delete("/" + serverParams);
    }

    exportExcel(serverParams) {
        return this.api.get("/export", { params: serverParams });
    }
}

export default new ReceiptService();