<template>
  <form @submit.prevent="doSave('Publish')" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          {{ formTitle }} Survey
        </h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-10">
                <div class="mb-15px">
                  <label class="form-label">Form Type</label>
                  <select class="form-select" v-model="form.type">
                    <option />
                    <option v-for="(option, key) in optionsType" v-bind:value="option.value" :key="key"
                      v-text="option.label" />
                  </select>
                </div>
              </div>
              <div class="col-md-auto" v-if="form.type != 'free_text'">
                <div class="mt-9px">
                  <label class="form-label"></label>
                  <div class="form-check">
                    <input class="form-check-input mb-15px" type="checkbox" v-model="form.is_other"
                      id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                      <b>Freetext Lainnya</b>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Product</label>
                  <!-- <v-select :clearable="false" v-model="form.product" :options="optionsProducts"
                    label="product_title"></v-select> -->
                  <select class="form-select" v-model="form.product">
                    <option v-for="(option, key) in optionsProducts" v-bind:value="option.id" :key="key"
                      v-text="option.category_name" />
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Question</label>
                  <ckeditor v-model="form.question" :config="{
                    delayDetached: true,
                    autoParagraph: false,
                    enterMode: 2,
                    extraPlugins: 'colorbutton',
                    versionCheck: false
                  }" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Category</label>
                  <select class="form-select" v-model="form.category">
                    <option />
                    <option v-for="(option, key) in optionsCategory" v-bind:value="option.value" :key="key"
                      v-text="option.label" />
                  </select>
                </div>
              </div>
            </div>
            <div class="row" v-if="form.type != 'free_text'">
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Type Answers</label>
                  <select class="form-select" v-model="form.answers_type">
                    <option />
                    <option v-for="(option, key) in optionsAnswers" v-bind:value="option.value" :key="key"
                      v-text="option.label" />
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Order</label>
                  <input type="number" class="form-control" v-model="form.order" min="0" />
                  <!-- <select class="form-select" v-model="form.type">
                    <option />
                    <option v-for="(option, key) in optionsType" v-bind:value="option.value" :key="key"
                      v-text="option.label" />
                  </select> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-9 col-md-8" v-if="form.type != 'free_text'">
        <div class="mb-15px">
          <panel title="Answers" variant="inverse" :is-collapse="true">
            <panel v-for="(item, index) in form.answers" :key="index" :index="index" variant="grey" :is-collapse="false"
              :checkbox-hidden="false" :show-collapse="true" v-model="item.isShow"
              :checkbox-name="`sliderItem-${index}`" :has-remove="true" @onRemove="removeAnswers(index)"
              :noButton="true" headerClass="d-none" bodyClass="bg-grey">
              <div class="row">
                <div class="col-md">
                  <div class="row">
                    <div class="col-md">
                      <div class="mb-15px">
                        <label class="form-label">Answer {{ index + 1 }}</label>
                        <input type="text" class="form-control" placeholder="Name" v-model="item.option" />
                      </div>
                    </div>
                    <div :class="item.srcIcon ? 'col-md-auto' : 'col-md-auto'" v-if="form.answers_type == 'text_icon'">
                      <div class="mb-15px">
                        <label class="form-label">Icon</label>
                        <!-- <input type="file" class="form-control" placeholder="Name" value="item.icon" /> -->
                        <FileUpload class="form-control" v-model.sync="item.icon" :src.sync="item.srcIcon" />
                      </div>
                    </div>
                    <div class="col-auto">
                      <label class="form-label"></label>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="item.disabled" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                          Disabled Button
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-auto text-center pt-3">
                  <a @click="removeAnswers(index)" class="btn btn-lg border-0 ps-0 text-danger mt-1">
                    <i class="fa fa-trash"></i>
                  </a>
                </div>
              </div>
            </panel>
            <button @click="addAnswers()" class="btn btn-outline-primary w-100 d-block text-start" type="button">
              <i class="fa-solid fa-plus"></i> Add new Answers
            </button>
          </panel>
        </div>
      </div>

      <!-- <div v-if="post.author" class="col-lg-3 col-md-4">
        <div class="panel panel-sticky">
          <div class="panel-body">
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Published Date</p>
              <p class="mb-0">{{ post.publishedDate ? dateFormat(post.publishedDate) : '' }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Last Modified Date</p>
              <p class="mb-0">{{ post.lastModified ? dateFormat(post.lastModified) : '' }}</p>
            </div>
            <div class="mb-15px">
              <p class="fw-bolder mb-1">Author</p>
              <p class="mb-0">{{ post.author }}</p>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="button" class="btn btn-outline-primary" @click.prevent="doSave('Draft')">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Save as Draft
          </button>
        </div>
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Publish
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "@/config/Helpers";
import FileUpload from "@/components/upload/File.vue";
import { required, requiredIf } from "vuelidate/lib/validators";
import productCategoryService from "@/service/productcategory.service";
import dateFormat from "@/pages/Helper/formate_date";

export default {
  components: {
    FileUpload,
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.loadItems();
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;
    return {
      optionsType: [
        {
          label: 'Multiple Choice',
          value: 'multiple_choice'
        },
        {
          label: 'Free Text',
          value: 'free_text'
        },
        {
          label: 'Single Choice',
          value: 'single_choice'
        },
        {
          label: 'Dropdown',
          value: 'drop_down'
        },
      ],
      optionsProducts: ['Goon Smile Baby'],
      optionsCategory: [
        {
          label: 'Personal Data',
          value: 'personal_data'
        },
        {
          label: 'Product Survey',
          value: 'product_survey'
        },
        {
          label: 'Survey Satisfaction',
          value: 'survey_satisfaction'
        }
      ],
      optionsAnswers: [
        {
          label: 'Text Icon',
          value: 'text_icon'
        },
        {
          label: 'Text Only',
          value: 'text_only'
        }
      ],
      customToolbar: defaultToolbarEditor,
      dateFormat: dateFormat,
      form: {
        title: detail ? detail.title : null,
        category: detail ? detail.category : null,
        type: detail ? detail.type : null,
        product: detail ? detail.type : null,
        question: detail ? detail.question : '',
        is_other: detail ? detail.is_other : false,
        answers: [
          {
            option: "",
            icon: null,
            disabled: false
          }
        ]
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
  },
  watch: {
    detailData(newVal) {
      this.form = newVal;
    },
  },
  methods: {
    doSave(status) {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const payload = {
          survey: { ...this.form },
          status
        };
        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
    imgErrMessage(mssg) {
      this.$notify({
        type: "error",
        title: "Image Notification",
        text: mssg,
      });
    },
    slugify(text, ampersand = "and") {
      if (text == null) {
        return null;
      }

      const a = "àáäâèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧ";
      const b = "aaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzh";
      const p = new RegExp(a.split("").join("|"), "g");

      return text
        .toString()
        .toLowerCase()
        .replace(/[\s_]+/g, "-")
        .replace(p, (c) => b.charAt(a.indexOf(c)))
        .replace(/&/g, `-${ampersand}-`)
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-")
        .replace(/^-+|-+$/g, "");
    },
    loadItems() {
      productCategoryService
        .getProductCategoryList({ status: 'Publish' })
        .then(async ({ data: { data } }) => {
          this.optionsProducts = data.productCategory
        }).catch((e) => {
          if (e.response.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        });
    },
    addAnswers() {
      this.form.answers.push({
        answer: "",
        icon: "",
        disabled: false
      });
    },
    removeAnswers(index) {
      this.form.answers.splice(index);
    },
  },
  validations: {
    form: {
    }
  },
};
</script>
