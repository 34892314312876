import api from "./api";

class CareerService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('career')
    }

    getCareerList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postCareer(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getCareerById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchCareer(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteCareerById(serverParams) {
        return this.api.delete("/" + serverParams);
    }
}

export default new CareerService();