<template>
  <draggable v-bind="dragOptions" class="dragArea" tag="ul" :list="list" :value="value" :group="{ name: 'menusGroup' }"
    @input="emitter">
    <li v-for="(item, index) in realValue" :key="item.title">
      <panel :title="item.title" variant="grey" :is-collapse="true" :checkbox-hidden="false" v-model="item.isShow"
        :show-collapse="false" :checkbox-name="`menuItem-${index}`" :has-remove="true" @onRemove="remove(index, item)">
        <div class="row">
          <div class="col-md-6">
            <div class="mb-2">
              <label class="form-label fw-normal">Navigation Label</label>
              <input type="text" v-model="item.label" placeholder="Navigation Label" class="form-control">
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-2">
              <label class="form-label fw-normal">Url</label>
              <input type="text" v-model="item.slug" placeholder="Navigation Label" class="form-control">
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="mb-2">
                <label class="form-label fw-normal">Icon</label>
                <FileUpload class="form-control" v-model.sync="item.icon" :src.sync="item.iconSrc" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-15px">
                <label class="form-label"></label>
                <div class="form-check">
                  <input class="form-check-input mb-15px" type="checkbox" v-model="item.is_free_sample" id="flexCheckDefault">
                  <label class="form-check-label" for="flexCheckDefault">
                    <b>Free Sample Menu</b>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </panel>
      <nested-draggable :list="item.submenus" @onDeleteItems="deleteItem" />
    </li>
  </draggable>
</template>
<script>
import FileUpload from "@/components/upload/File.vue";
import draggable from "vuedraggable";
export default {
  name: "nested-draggable",
  props: {
    value: {
      required: false,
      type: Array,
      default: null,
    },
    list: {
      required: false,
      type: Array,
      default: null,
    },
  },
  components: {
    draggable,
    FileUpload,
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "menusGroup",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    realValue() {
      return this.value ? this.value : this.list;
    },
  },
  methods: {
    emitter(value) {
      this.$emit("input", value);
    },
    remove(index, item) {
      this.realValue.splice(index, 1)
      this.$emit("onDeleteItems", item)
    },
    deleteItem(item) {
      this.$emit("onDeleteItems", item)
    }
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    cursor: move;
    margin-bottom: 0;
    width: 500px;

    ul {
      margin-left: 30px;
    }
  }
}
</style>
