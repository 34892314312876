<template>
  <form @submit.prevent="doSave" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          {{ formTitle }} Role
        </h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="mb-15px">
          <input type="text" class="form-control form-control-lg" placeholder="Role title" v-model="role.title" />
          <span class="text-danger" v-if="$v.role.title.$error">Title is required</span>
        </div>

        <div class="panel">
          <div class="panel-body">
            <div class="mb-4" v-for="(itemPermission, indexPermission) in permissionList">
              <h5 class="fw-normal mb-3"><b>{{ indexPermission }}</b></h5>
              <div class="row">
                <div class="col-auto" v-for="(valueItem, keyItem) in itemPermission">
                  <div v-if="valueItem === 'GetAll'" class="form-check">
                    <input class="form-check-input" type="checkbox" :value="indexPermission + ' ' + valueItem"
                      :id="keyItem" v-model="checkedRole" @change="checkAll" />
                    <label class="form-check-label" :for="keyItem">All</label>
                  </div>
                  <div v-else class="form-check">
                    <input class="form-check-input" type="checkbox" :value="indexPermission + ' ' + valueItem"
                      :id="keyItem" v-model="checkedRole" />
                    <label class="form-check-label" :for="keyItem">
                      {{ valueItem }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <span class="text-danger" v-if="$v.checkedRole.$error">Role is required</span>
          </div>
        </div>

      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">Save</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../../config/Helpers";
import RoleService from "@/service/role.service";
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // console.log(this.detailData);
    this.loadItems();
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;
    return {
      customToolbar: defaultToolbarEditor,
      checkedRole: [],
      role: {
        title: detail ? detail.title : null,
        submissions: [],
        products: {
          list: [],
          category: [],
        },
        hospitals: {
          list: [],
          category: [],
          group: [],
        },
      },
      permissionList: [],
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
  },
  watch: {
    'detailData'(newVal) {
      this.role.title = newVal.role_name;
      this.checkedRole = JSON.parse(newVal.permission)
    }
  },
  methods: {
    doSave() {
      // console.log(this.checkedRole)

      this.$v.$touch();

      if (!this.$v.$invalid) {
        const payload = {
          role_name: this.role.title,
          permissions: this.checkedRole
        };

        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
    loadItems() {
      this.isLoading = true;
      RoleService
        .getPermissionsList()
        .then(({ data: { data } }) => {
          let arrData = {}
          data = data.filter((r) => r != 'FreeSample Delete')
          data = data.filter((r) => r != 'SurveySubmission Delete')
          for (const [k, v] of Object.entries(data)) {
            let nv = v.split(' ')
            for (const [k1, v1] of Object.entries(nv)) {
              switch (k1) {
                case '0':
                  if (!arrData[nv[0]]) {
                    let data = { [v1]: [] }
                    Object.assign(arrData, data)
                  }
                  break;
                default:
                  arrData[nv[0]].push(v1)
                  break;
              }
            }
          }
          this.permissionList = arrData
        }).catch((e) => {
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.permissionList = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    checkAll(event) {
      let arrPermission = this.permissionList
      let value = event.target.value.split(' ')
      if (event.target.checked) {
        for (const [k, v] of Object.entries(arrPermission)) {
          if (value[0] === k) {
            for (const v1 of v) {
              this.checkedRole.push(k + ' ' + v1)
            }
          }
        }
      } else {
        let index = this.checkedRole.findIndex((r) => r == event.target.value)
        this.checkedRole.splice(index, 5)
      }
    }
  },
  validations: {
    checkedRole: { required },
    role: {
      title: { required }
    },
  },
};
</script>
