<template>
    <div v-if="src">
        <div class="row">
            <img :src="src" style="object-fit: contain;width: 40%; height:50%" />
            <a class="btn-close" @click="remove()"></a>
        </div>
    </div>
    <input v-else type="file" @change="onChangeFile" name="file" :value="modelValue" />
</template>

<script>
export default {
    props: {
        modelValue: String,
        src: {
            default: "",
            type: String,
        },
    },
    watch: {
        modelValue(newVal) {
            console.log(newVal)
        },
    },
    methods: {
        onChangeFile(event) {
            this.$emit('input', event.target.files)
            this.$emit('update:src', URL.createObjectURL(event.target.files[0]))
        },
        remove() {
            this.$emit('update:src', null)
            this.$emit('input', null)
        }
    }
}
</script>
