import api from "./api";

class FaqService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('faq-management')
    }

    getFaqList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postFaq(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getFaqById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchFaq(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteFaqById(serverParams) {
        return this.api.delete("/" + serverParams);
    }
}

export default new FaqService();