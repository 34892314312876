<template>
  <div>
    <h1 class="page-header">Claim Poin Data</h1>
    <div class="col-auto" v-if="exportExcel">
      <a class="btn btn-outline-primary" @click.prevent="downloadExcel">
        <i class="fa-solid fa-file-import"></i>
        Export
      </a>
    </div>
    <br>
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
        setCurrentPage: setCurrentPage,
        perPage: serverParams.take,
      }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'username', type: 'asc' },
}" styleClass="vgt-table" slot="outsideBody">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="customer.email" class="form-control" placeholder="Search Email" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'customer.email'
          " @input="(value) => updateFilters(column, $refs['customer.email'].value)" />

        <input ref="bought_at" class="form-control" placeholder="Search Store" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'bought_at'
          " @input="(value) => updateFilters(column, $refs['bought_at'].value)" />

        <input ref="customer.full_name" class="form-control" placeholder="Search Name" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'customer.full_name'
          " @input="(value) => updateFilters(column, $refs['customer.full_name'].value)" />


        <input ref="struk_id" class="form-control" placeholder="Search Nomor Struk" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'struk_id'
          " @input="(value) => updateFilters(column, $refs['struk_id'].value)" />

        <div class="input-group" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'createdAt'
          ">
          <input type="date" ref="createdAt1" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
          <span class="input-group-text">
            -
          </span>
          <input type="date" ref="createdAt2" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />

        </div>
        <select ref="status" class="form-select" placeholder="Cari productCategory" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'status'
          " @change="(value) => updateFilters(column, $refs['status'].value)">
          <option />
          <option v-for="(option, key) in optionStatus" v-bind:value="option.value" :key="key" v-text="option.label" />
        </select>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end">
            <div class="col-auto" v-if="edit">
              <router-link :to="`/claim-point/edit/${props.row.id}`" class="btn btn-lg text-dark" v-b-tooltip.hover
                title="Edit">
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>

            <div class="col-auto" v-if="deleteReceipt">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                @click.prevent="showAlertConfirm(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else :class="[props.row.status === 'Deleted' && 'text-muted']">
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import receiptService from "@/service/receipt.service";
import userService from "@/service/user.service";
import CustomerService from "@/service/customer.service";
import tokenService from "@/service/token.service";
import formatUtc from "@/pages/Helper/formatUTC";
import moment from "moment";
import _ from 'lodash';
export default {
  data() {
    return {
      setCurrentPage: this.$store.state.claimpoint.serverParams.skip / this.$store.state.claimpoint.serverParams.take == 0 ?
        1 : this.$store.state.claimpoint.serverParams.skip / this.$store.state.claimpoint.serverParams.take + 1,
      optionsCustomer: [],
      optionStatus: [
        {
          label: 'Pending',
          value: 'pending'
        },
        {
          label: 'In Review',
          value: 'in_review'
        },
        {
          label: 'Approved By Ocr',
          value: 'approved_by_ocr'
        },
        {
          label: 'Approved By Admin',
          value: 'approved_by_admin'
        },
        {
          label: 'Rejected',
          value: 'rejected'
        }
      ],
      isLoading: false,
      columns: [
        {
          label: "User Name/Email",
          field: "customer.email",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            styleClass: 'vgt-relative',
            customFilter: true,
            enabled: true,
            filterValue: "",
            filterFn: this.filterFn,
          },
        },
        {
          label: "Name",
          field: "customer.full_name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            styleClass: 'vgt-relative',
            customFilter: true,
            enabled: true,
            filterValue: "",
            filterFn: this.filterFn,
          },
        },
        {
          label: "Nomor Struk",
          field: "struk_id",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            styleClass: 'vgt-relative',
            customFilter: true,
            enabled: true,
            filterValue: "",
            filterFn: this.filterFn,
          },
        },
        {
          label: "Store",
          field: "bought_at",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            styleClass: 'vgt-relative',
            customFilter: true,
            enabled: true,
            filterValue: "",
            filterFn: this.filterFn,
          },
        },

        {
          label: "Submitted at",
          field: "createdAt",
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
          filterOptions: {
            styleClass: 'vgt-relative',
            customFilter: true,
            enabled: true,
            filterValue: "",
            filterFn: this.filterFn,
          },
        },
        {
          label: "Status",
          field: "status",
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          filterOptions: {
            styleClass: 'vgt-relative',
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Approved By",
          field: "approved_user",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            styleClass: 'vgt-relative',
            customFilter: true,
            enabled: true,
            filterValue: "",
            filterFn: this.filterFn,
          },
        },
        {
          label: "Rejected By",
          field: "rejected_user",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            styleClass: 'vgt-relative',
            customFilter: true,
            enabled: true,
            filterValue: "",
            filterFn: this.filterFn,
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "text-end text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      customerId: [],
      totalRecords: 0,
      serverParams: this.$store.state.claimpoint.serverParams,
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
      permisions: tokenService.getPermission(),
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.$store.state.claimpoint.serverParams = Object.assign({}, this.$store.state.claimpoint.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ skip: 0 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams();
      this.loadItems();
    },

    onColumnFilter: _.debounce(function (params) {
      let payload = {}
      if (params.columnFilters['createdAt'] && params.columnFilters['createdAt'] != '#') {
        let arrSplit = params.columnFilters['createdAt'].split('#')
        if (arrSplit[0] && arrSplit[1]) {
          let { startDate, endDate } = formatUtc(new Date(arrSplit[0]), new Date(arrSplit[1]))
          payload.createdAtStartDate = arrSplit[0] ? startDate : ''
          payload.createdAtEndDate = arrSplit[1] ? endDate : ''
        }
      }

      if (params.columnFilters.status && params.columnFilters.status != '') {
        payload.status = params.columnFilters.status ? params.columnFilters.status : ''
      } else {
        delete this.$store.state.claimpoint.serverParams.status
      }

      if (params.columnFilters['customer.email'] && params.columnFilters['customer.email'] != '') {
        payload.email = params.columnFilters['customer.email'] ? params.columnFilters['customer.email'] : ''
      } else {
        delete this.$store.state.claimpoint.serverParams.email
      }

      if (params.columnFilters['bought_at'] && params.columnFilters['bought_at'] != '') {
        payload.bought_at = params.columnFilters['bought_at'] ? params.columnFilters['bought_at'] : ''
      } else {
        delete this.$store.state.claimpoint.serverParams.bought_at
      }

      if (params.columnFilters['customer.full_name'] && params.columnFilters['customer.full_name'] != '') {
        payload.full_name = params.columnFilters['customer.full_name'] ? params.columnFilters['customer.full_name'] : ''
      } else {
        delete this.$store.state.claimpoint.serverParams.full_name
      }

      if (params.columnFilters['struk_id'] && params.columnFilters['struk_id'] != '') {
        payload.struk_id = params.columnFilters['struk_id'] ? params.columnFilters['struk_id'] : ''
      } else {
        delete this.$store.state.claimpoint.serverParams.struk_id
      }
      console.log('!!!');

      // payload.status = params.columnFilters['status'] ? params.columnFilters['status'] : ''
      this.updateParams(payload);
      this.loadItems();
    }, 300),
    loadItems() {
      this.isLoading = true;
      receiptService
        .getReceiptList(this.$store.state.claimpoint.serverParams)
        .then(async ({ data: { data } }) => {
          let arrData = []
          if (data.customerPointHistory.length > 0) {
            for (const [k, v] of Object.entries(data.customerPointHistory)) {
              let { status } = v
              let user = v.approved_by != 0 && v.approved_by ? (await this.loadItemsCustomer(v.approved_by))?.name : ''
              let rejectedUser = v.rejected_by != 0 && v.rejected_by ? (await this.loadItemsCustomer(v.rejected_by))?.name : ''
              arrData.push({
                ...v,
                status: status ? this.optionStatus.find((r) => r.value == status).label : '',
                approved_user: v.approved_by ? user : '',
                rejected_user: v.rejected_by == 0 && status == 'rejected' ? 'OCR' : rejectedUser
              })
            }
            this.rows = arrData
            this.totalRecords = data.totalData
          } else {
            this.rows = []
            this.totalRecords = 0
          }
        }).catch((e) => {
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },

    loadItemsCustomer(id) {
      return userService
        .getUserById(id)
        .then(({ data: { data } }) => {
          return Promise.resolve({ ...data })
        }).catch((e) => {
          return Promise.reject({ e })
        })
    },
    downloadExcel() {
      this.isLoading = true;
      let { take, skip, sort, ...NewParams } = this.$store.state.claimpoint.serverParams
      let payload = {
        ...NewParams
      }
      receiptService.exportExcel(payload)
        .then(({ data: { data } }) => {
          let { path } = data
          if (path) {
            window.open(this.$api_name + '/' + path, '_blank')
          }
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },

    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },
    doDelete(id) {
      this.isLoading = true;
      receiptService
        .deleteReceiptById(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },
  },
  computed: {
    exportExcel: function () {
      if (this.permisions.find((r) => r == 'Receipt Export')) {
        return true
      }
      return false;
    },
    edit: function () {
      if (this.permisions.find((r) => r == 'Receipt Patch')) {
        return true
      }
      return false;
    },
    deleteReceipt: function () {
      if (this.permisions.find((r) => r == 'Receipt Delete')) {
        return true
      }
      return false;
    },
  },
  mounted() {
    this.loadItems();
  },
};
</script>
