<template>
  <div class="customer-form">
    <FormCustomer form-type="add" @onValidateSuccess="doSave" />
  </div>
</template>
<script>
import FormCustomer from "./Form.vue";
import CustomerService from "@/service/customer.service";
import CustomerChildService from "@/service/customerchild.service";
import base64Converter from "../Helper/base64";
export default {
  components: {
    FormCustomer,
  },

  methods: {
    async doSave(payload) {
      try {
        let { customer, childrens } = payload
        let { rePassword, province, avatar, ...newCustomer } = customer

        newCustomer = {
          ...newCustomer,
          avatar_img: avatar ? await base64Converter(avatar[0]) : null,
          otp: 0,
        }

        let { data } = await this.addCustomer(newCustomer)

        if (data) {
          let { resp } = await this.addChildren(childrens, data.id)
        }

        this.$swal({
          title: "Data berhasil disimpan",
          showCloseButton: true,
        }).then(() => {
          this.$router.push({ path: '/customer/list' })
        })

      } catch (e) {
        if (e.response?.status === 409) {
          this.$notify({
            type: "warn",
            title: "Error Message",
            text: "Data Sudah Ada",
          });
        } else {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.errorResponse?.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }
      }
    },
    addCustomer(customer) {
      return CustomerService
        .postCustomer(customer)
        .then(({ data: { data } }) => {
          return Promise.resolve({ accepted: true, data })
        }).catch((e) => Promise.reject({ accepted: false, errorResponse: e }))
    },
    async addChildren(childrens, customer_id) {
      let arrResp = []
      for (const [k, v] of Object.entries(childrens)) {
        let req = {
          ...v,
          customer_id: customer_id,
          avatar_img: avatar_img.length > 0 ? await base64Converter(v.avatar_img) : null,
          is_active: "Publish",
        }
        arrResp.push(CustomerChildService.postCustomerChild(req))
      }
      return Promise.all(arrResp)
        .then((r) => Promise.resolve({ accepted: true }))
        .catch((e) => Promise.reject({ accepted: false, errorResponse: e }))
    }
  },
};
</script>
