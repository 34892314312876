<template>
  <form @submit.prevent="doSave" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          {{ formTitle }} Social Media
        </h1>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="mb-15px">
          <input type="text" class="form-control form-control-lg" placeholder="Twitter" v-model="form.name" name="name" />
          <span class="text-danger" v-if="$v.form.name.$error">Name is
            required</span>
        </div>
        <panel title="Icon (32x32 px)" header-class="bg-light" variant="light" :is-collapse="true">
          <!-- <div class="mb-15px">
            <UploadImage v-model="form.images" :src="form.imagesSrc" size="1000" @image-err-message="imgErrMessage"
              name="images" />
            <span class="text-danger" v-if="$v.form.images.$error">Images is
              required</span>
          </div> -->
          <div class="mb-15px">
            <label class="form-label">CTA #1 URL</label>
            <div class="input-group">
              <span class="input-group-text"><i class="fa-solid fa-link"></i></span>
              <input type="url" v-model="form.cta" class="form-control" placeholder="https://shopee.com/GoonIndonesia"
                name="cta">
            </div>
            <span class="text-danger" v-if="$v.form.cta.$error">Cta is
              required</span>
          </div>
        </panel>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">Save</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import UploadImage from "../../../components/upload/Image.vue";
import { required } from 'vuelidate/lib/validators'
import SiteSettingService from "@/service/sitesetting.service";
import base64ToFile from "@/pages/Helper/base64ToFile";

export default {
  components: {
    UploadImage,
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.loadItems(this.$route.params.id)
    }
  },

  data() {
    // let { form, id } = this.detailData;
    return {
      id: {
        name: null,
        cta: null,
        images: null,
      },
      form: {
        name: null,
        cta: null,
        // images: null,
        imagesSrc: ''
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add" : "Edit";
    },
  },
  watch: {
    detailData(newVal) {
      // console.log(newVal)
    }
  },
  methods: {
    doSave() {

      this.$v.$touch();

      if (!this.$v.$invalid) {
        const payload = {
          form: this.form,
          id: this.id
        };

        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
    imgErrMessage(mssg) {
      this.$notify({
        type: "error",
        title: "Image Notification",
        text: mssg,
      });
    },

    loadItems(id) {
      // this.isLoading = true;
      SiteSettingService
        .getSiteSettingById(id)
        .then(({ data: { data } }) => {
          this.loadItemsByGroup(data.caller_param)
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadItemsByGroup(grup) {
      // this.isLoading = true;
      SiteSettingService
        .getSiteSettingGrup(grup)
        .then(async ({ data: { data } }) => {
          if (data.length > 0) {
            for (const [k, v] of Object.entries(data)) {
              let { id, caller_param, default_value, type } = v
              let key = caller_param.split('_')[0]
              if (type === 'image') {
                let arrFile = default_value.split(',')
                this.form.imagesSrc = this.loadSrcImage(default_value)
                this.form.images = [await this.convertURLtoFile(this.loadSrcImage(default_value))]
                this.id[key] = id
              } else {
                this.form[key] = default_value
                this.id[key] = id
              }
            }
          }
        }).catch((e) => {
          // console.log(e)
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },

    loadSrcImage(url) {
      return this.$api_name + '/' + url
    },
    async convertURLtoFile(url) {
      const response = await fetch(url);
      if (response.status != 200) {
        return "";
      }
      const data = await response.blob();
      const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
      const metadata = { type: `image/jpeg` };
      return new File([data], filename, metadata);
    },
  },
  validations: {
    form: {
      name: { required },
      cta: { required },
      // images: { required },
    },
  }
};
</script>
