<template>
  <div class="product-form">
    <FormPost form-type="add" @onValidateSuccess="doSave" :on-loading="onLoading" />
  </div>
</template>
<script>
import FormPost from "./Form.vue";
import babygrowthService from "@/service/babygrowth.service";
import base64Converter from "@/pages/Helper/base64";
import slugify from "@/pages/Helper/slug";
import moment from "moment";

export default {
  components: {
    FormPost,
  },
  data() {
    return {
      onLoading: false
    }
  },
  methods: {
    async doSave(payload) {
      this.onLoading = true
      let { form } = payload
      if (form) {
        // console.log(form)
        let babyGrowthIndex = []
        if (form.input_type == 'height') {
          for (const [k, v] of Object.entries(form.babyGrowthHeight)) {
            babyGrowthIndex.push({
              age_in_month: v.age_in_month ? v.age_in_month : 0,
              threshold_id: v.threshold_id ? v.threshold_id : 0,
              heigth_or_weight_value: v.heigth_or_weight_value ? v.heigth_or_weight_value : 0
            })
          }
        } else if (form.input_type == 'weight') {
          for (const [k, v] of Object.entries(form.babyGrowthWeight)) {
            babyGrowthIndex.push({
              age_in_month: v.age_in_month ? v.age_in_month : 0,
              threshold_id: v.threshold_id ? v.threshold_id : 0,
              heigth_or_weight_value: v.heigth_or_weight_value ? v.heigth_or_weight_value : 0
            })
          }
        }

        payload = {
          gender: form.gender ? form.gender : 0,
          input_type: form.input_type ? form.input_type : 0,
          title: form.title ? form.title : 0,
          babyGrowthIndex: babyGrowthIndex
        }

        babygrowthService.postBabyGrowth(payload).then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: '/tumbuh-kembang/berat-tinggi' })
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          }
        }).finally(() =>
          this.onLoading = false)
      }
    },
    dateFormat(date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
    }
  },
};
</script>
