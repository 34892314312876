<template>
  <form @submit.prevent="doSave" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          Information Redeem Data
        </h1>
      </div>
      <div class="col-lg-12">
        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Nama sesuai KTP</label>
                  <input type="text" class="form-control" v-model="form.full_name" :disabled="disabled" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Email</label>
                  <input type="text" class="form-control" v-model="form.email" :disabled="disabled" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Alamat</label>
                  <input type="text" class="form-control" v-model="form.address" :disabled="disabled" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">No. Handphone</label>
                  <input type="text" class="form-control" v-model="form.phoneNumber" :disabled="disabled" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Nama Reward</label>
                  <input type="text" class="form-control" v-model="form.nameReward" :disabled="disabled" />
                </div>
              </div>
              <div class="col-md-6" v-if="redeemStatusPermission">
                <div class="mb-15px">
                  <label class="form-label">Status Redeem </label>
                  <v-select :options="optionsStatusPengiriman" v-model="form.status"
                    :disabled="form.reward_type == 'free_sample'" :clearable="false" />
                  <span class="text-danger" v-if="$v.form.status.$error">Status Redeem is required</span>
                </div>
              </div>
              <div class="col-md-6" v-if="redeemShipmementStatus">
                <div class="mb-15px">
                  <label class="form-label">Status Pengiriman</label>
                  <v-select :options="optionsStatusPengirimanMilestones" v-model="form.status_pengiriman"
                    :clearable="false" />
                  <!-- <span class="text-danger" v-if="$v.form.status_pengiriman.$error">Status Pengiriman is required</span> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Nomor Resi</label>
                  <input type="text" class="form-control" v-model="form.nomor" />
                  <!-- <span class="text-danger" v-if="$v.form.nomor.$error">Nomor is required</span> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Nomor Redeem</label>
                  <input type="text" class="form-control" v-model="form.no_redeem" readonly disabled />
                  <!-- <span class="text-danger" v-if="$v.form.notes.$error">Notes is required</span> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Ekspedisi</label>
                  <input type="text" class="form-control" v-model="form.notes" />
                  <!-- <span class="text-danger" v-if="$v.form.notes.$error">Notes is required</span> -->
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="mb-15px">
                    <label class="form-label"> Notes</label>
                    <textarea class="form-control" rows="7" v-model="form.reason" placeholder="Notes"></textarea>
                    <span class="text-danger" v-if="$v.form.reason.$error">Notes is required</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
          :isLoading.sync="isLoading" :pagination-options="{
            enabled: false,
            dropdownAllowAll: false,
          }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'name', type: 'asc' },
}" styleClass="vgt-table" slot="outsideBody" :line-numbers="true">
          <template slot="column-filter" slot-scope="{ column, updateFilters }">
            <input ref="name" class="form-control" placeholder="Search catalogue" v-if="column.filterOptions &&
              column.filterOptions.customFilter &&
              column.field === 'name'
              " @input="(value) => updateFilters(column, $refs.name.value)" />
          </template>
          <template slot="table-row" slot-scope="props">
            <span>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="submit" class="btn btn-primary" :disabled="onLoading">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true">
            </span>
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import redeemMilestoneService from "@/service/redeemmilestones.service";
import UserService from "@/service/user.service";
import tokenService from "@/service/token.service";
import { required, requiredIf } from "vuelidate/lib/validators";
export default {
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // console.log(this.detailData);
  },
  data() {
    const detail = this.detailData;

    return {
      disabled: true,
      form: {},
      isLoading: false,
      optionsStatusPengiriman: [
        {
          label: 'Pending',
          value: 'pending'
        },
        {
          label: 'In Review',
          value: 'in_review'
        },
        {
          label: 'Approve',
          value: 'approve'
        },
        {
          label: 'Reject',
          value: 'reject'
        }
      ],
      optionsStatusPengirimanMilestones: [
        {
          label: 'Waiting Approval',
          value: 'waiting_approval'
        },
        {
          label: 'Processed',
          value: 'processed'
        },
        {
          label: 'Delivered',
          value: 'delivered'
        },
        {
          label: 'Arrived',
          value: 'arrived'
        },
      ],
      columns: [
        {
          label: "Description",
          field: "notes",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
        },
        {
          label: "Redeem Status",
          field: "redeem_status",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
        },
        {
          label: "Delivery Status",
          field: "status",
          thClass: "text-nowrap",
          tdClass: this.statusClass,
        },
        {
          label: "Redeem Created At",
          field: "redeem.createdAt",
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
        },
        {
          label: "Delivery Created At",
          field: "createdAt",
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
        },
        {
          label: "Redeem Updated Date & Time",
          field: "redeem.updatedAt",
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
        },
        {
          label: "Delivery Updated Date & Time",
          field: "updatedAt",
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
        },
        {
          label: "Admin Name",
          field: "User.name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        skip: 0,
        take: 100
      },
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
      permisions: tokenService.getPermission()
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
    redeemStatusPermission: function () {
      if (this.permisions.find((r) => r == 'Redeem UpdateRedeemStatus')) {
        return true
      }
      return false;
    },
    redeemShipmementStatus: function () {
      if (this.permisions.find((r) => r == 'RedeemMilestone UpdateShipmementStatus')) {
        return true
      }
      return false;
    },
  },
  watch: {
    detailData(newVal) {
      let {
        customer_id,
        reward_id,
        full_name,
        phoneNumber,
        address,
        nameReward,
        incoming_request,
        status,
        nomor
      } = newVal
      this.form = newVal
    },
  },
  methods: {
    statusClass(row) {
      if (row.status === 'Diterima') {
        return 'bg-green text-white align-middle';
      }
      return 'bg-orange text-white align-middle';
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ page: 1 });
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams();
      // this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    loadItems() {
      this.isLoading = true;
      redeemMilestoneService
        .getRedeemMilestonesList({ redeem_id: this.$route.params.id, sort: 'asc' })
        .then(async ({ data: { data } }) => {
          let { redeemMilestone } = data
          if (redeemMilestone.length > 0) {
            let arrData = []
            for (const [k, v] of Object.entries(redeemMilestone)) {
              let { status, redeem, created_by, notes } = v
              let findStatus = status ? this.optionsStatusPengirimanMilestones.find((r) => r.value == status) : ''
              let findStatusRedeem = this.optionsStatusPengiriman.find((r) => r.value == redeem.status)
              arrData.push({
                ...v,
                status: findStatus.label,
                redeem_status: findStatusRedeem.label,
              })
            }
            this.rows = arrData
          } else {
            this.rows = []
          }

        }).catch((e) => {
          // console.log(e)
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },

    loadItemsUsers(id) {
      return UserService.getUserById(id)
        .then(({ data: { data } }) => Promise.resolve({ ...data })).catch((e) => Promise.reject({ ...e }))
    },
    doSave() {
      this.onLoading = true
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const payload = {
          form: this.form,
        };
        this.$emit("onValidateSuccess", payload);

        this.onLoading = false
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
        this.onLoading = false
      }
    },
  },
  mounted() {
    this.loadItems();
  },
  validations: {
    form: {
      // nomor: { required },
      // status_pengiriman: { required },
      reason: {
        required: requiredIf(function (model) {
          if (model.status?.value == "reject") {
            return true
          } else {
            return false
          }
        })
      },
      status: { required },
    },
  }
};
</script>
