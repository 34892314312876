<template>
  <div class="login login-v2 fw-bold">
    <div class="login-cover">
      <div class="login-cover-img"></div>
      <div v-bind:class="classObject">
        <h5 class="mb-0 fw-900">
          {{ alertValidasi }}
        </h5>
      </div>
    </div>
    <div class="login-container">
      <div class="login-header">
        <div class="brand">
          <div class="d-flex align-items-center justify-content-center">
            <img src="/assets/img/logo/logo-goon.png" alt="Goon" />
            &nbsp; <b>Goon</b> &nbsp;CMS
          </div>
        </div>
      </div>
      <div class="login-content">

        <div class="mx-auto">
          <div class="form-group mb-3">
            <label class="form-label">Buat password</label>
            <div :class="{
              'is-invalid': $v.password.$invalid,
              'is-valid': !$v.password.$invalid,
            }" class="input-password">
              <input v-model.trim="$v.password.$model" :class="{
                'is-invalid': $v.password.$invalid,
                'is-valid': !$v.password.$invalid,
              }" class="form-control" :type="passwordFieldType" />
              <span class="btn btn-toggle-password"><i @click="showPassword" class="fas fa-eye"></i></span>
            </div>
            <strong class="pwd-length" :class="{
              'invalid-feedback': !$v.password.required || !$v.password.minLength,
              'valid-feedback': $v.password.required && $v.password.minLength,
            }">Minimum 8 karakter</strong>
            <strong :class="{
              'invalid-feedback':
                !$v.password.required || !$v.password.pasMinOneUpp,
              'valid-feedback': $v.password.required && $v.password.pasMinOneUpp,
            }" class="pwd-uppercase">Minimum 1 huruf kapital</strong>
            <strong :class="{
              'invalid-feedback':
                !$v.password.required || !$v.password.pasMinOneLow,
              'valid-feedback': $v.password.required && $v.password.pasMinOneLow,
            }" class="pwd-lowercase">Minimum 1 huruf kecil</strong>
            <strong :class="{
              'invalid-feedback':
                !$v.password.required || !$v.password.pasMinOneNum,
              'valid-feedback': $v.password.required && $v.password.pasMinOneNum,
            }" class="pwd-number">Minimum 1 angka</strong>
          </div>

          <div class="form-group mb-3">
            <label class="form-label">Ketik ulang password</label>
            <div :class="{
              'is-invalid': $v.retypePassword.$invalid,
              'is-valid': !$v.retypePassword.$invalid,
            }" class="input-password">
              <input v-model="retypePassword" :class="{
                'is-invalid': $v.retypePassword.$invalid,
                'is-valid': !$v.retypePassword.$invalid,
              }" class="form-control" :type="retypeFieldType" />
              <span class="btn btn-toggle-password"><i @click="showRetypePassword" class="fas fa-eye"></i></span>
            </div>
            <strong :class="{
              'invalid-feedback':
                !$v.retypePassword.required || !$v.retypePassword.sameAsPassword,
              'valid-feedback':
                $v.retypePassword.required && $v.retypePassword.sameAsPassword,
            }" class="pwd-notMatch">Password tidak sama</strong>
          </div>

          <button :class="{
            disabled: $v.retypePassword.$invalid,
          }" class="btn btn-primary d-block w-100" @click="handleSubmit()">
            Buat password
          </button>
        </div>

        <!-- modal -->
        <b-modal id="modalLupaPasswordSukses" centered no-close-on-backdrop content-class="modal-question">
          <template slot="modal-header">
            <h4 class="modal-title">Reset Password</h4>
          </template>
          <p>{{ alertMessage }}</p>
          <p v-if="!serverError">Silakan login dengan password baru</p>
          <template v-if="!serverError" slot="modal-footer">
            <div class="w-100">
              <b-button variant="primary" @click="toLogin()" href="#/login"> Log in </b-button>
            </div>
          </template>
        </b-modal>

        <!-- modal -->
      </div>
    </div>
  </div>
</template>

<script>
import AppOptions from "../../config/AppOptions.vue";
import authService from "@/service/auth.service";
import {
  minLength,
  required,
  helpers,
  sameAs,
} from "vuelidate/lib/validators/";
const pasMinOneUpp = helpers.regex("pasMinOneUpp", /(?=.*?[A-Z])/);
const pasMinOneLow = helpers.regex("pasMinOneLow", /(?=.*?[a-z])/);
const pasMinOneNum = helpers.regex("pasMinOneNum", /(?=.*?[0-9])/);
export default {
  data() {
    AppOptions.appSidebarNone = true;
    AppOptions.appEmpty = true;
    return {
      passwordFieldType: "password",
      retypeFieldType: "password",
      password: "",
      retypePassword: "",
      alertMessage: "",
      serverError: false,
      token: this.$route.query.token,
      alertValidasi: 'Validasi email sukses. Lanjutkan membuat password baru.'
    };
  },
  computed: {
    classObject: function () {
      if (!this.$route.query.token) {
        return 'alert alert-danger fade show mb-0'
      } else {
        return 'alert alert-success fade show mb-0'
      }
    }
  },
  methods: {
    showPassword() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    showRetypePassword() {
      this.retypeFieldType =
        this.retypeFieldType === "password" ? "text" : "password";
    },
    handleSubmit() {
      let data = {
        newPassword: this.password,
        retypeNewPassword: this.retypePassword,
        token: this.token,
      };
      if (
        localStorage.getItem("referralEngineToken") &&
        localStorage.getItem("referralEngineRefreshToken")
      ) {

        authService
          .resetPassword(data)
          .then((rsp) => {
            this.$bvModal.show("modalLupaPasswordSukses");
          }).catch((e) => {
            if (e.response?.status === 409) {
              this.$notify({
                type: "warn",
                title: "Error Message",
                text: "Data Sudah Ada",
              });
            } else {
              this.$notify({
                type: "error",
                title: "Error Message",
                text: e.response?.data?.message || "Terjadi Kesalahan",
              });
            }
          }).finally(() =>
            this.inProgress = false)
      } else {
        authService
          .resetPassword(data)
          .then((rsp) => {
            this.$bvModal.show("modalLupaPasswordSukses");
          }).catch((e) => {
            if (e.response?.status === 409) {
              this.$notify({
                type: "warn",
                title: "Error Message",
                text: "Data Sudah Ada",
              });
            } else {
              this.$notify({
                type: "error",
                title: "Error Message",
                text: e.response?.data?.message || "Terjadi Kesalahan",
              });
            }
          }).finally(() =>
            this.inProgress = false)
      }
    },
    toLogin() {
      if (
        localStorage.getItem("accessToken") &&
        localStorage.getItem("refreshToken")
      ) {
        this.$store.dispatch("auth/logout").then(
          (response) => {
            if (response.statusCode === 200) {
              this.$router.push("/login");
            }
          },
          (error) => {
            // console.log(error);
          }
        );
      } else {
        this.$router.push("/login");
      }
    },
  },
  created() {
    if (!this.$route.query.token) {
      this.$notify({
        type: "error",
        title: "Error Message",
        text: "Token Kosong",
      });
      this.alertValidasi = 'Validasi email gagal'
      this.$router.push("/login");
    }
    AppOptions.appSidebarNone = true;
    AppOptions.appNavbarNotification = false;
    AppOptions.appNavbarUser = false;
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appSidebarNone = false;
    AppOptions.appNavbarNotification = true;
    AppOptions.appNavbarUser = true;
    next();
  },
  validations: {
    password: {
      required,
      pasMinOneUpp,
      pasMinOneLow,
      pasMinOneNum,
      minLength: minLength(8),
    },
    retypePassword: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
};
</script>
