import api from "./api";

class PromoService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('promo')
    }

    getPromoList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postPromo(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getPromoById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchPromo(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deletePromoById(serverParams) {
        return this.api.delete("/" + serverParams);
    }

    postDuplicatePromo(serverParams) {
        return this.api.post("/duplicate/" + serverParams);
    }

    getPromoListFilterActive(serverParams) {
        return this.api.get("/filter/active", { params: serverParams });
    }
}

export default new PromoService();