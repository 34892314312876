<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">E-commerce List</h1>
        <!-- END page-header -->
      </div>
      <div class="col-auto mb-3">
        <div class="row">
          <div class="col-auto mb-15px" >
            <a class="btn btn-outline-primary" @click.prevent="downloadExcel">
              <i class="fa-solid fa-file-import"></i>
              Export
            </a>
          </div>
          <div class="col-auto">
            <a class="btn btn-info" href="/assets/EccommerceTemplate.xlsx" download> Template</a>
            <!-- <button class="btn btn-info" @click="downloadTemplate">Template</button> -->
          </div>
          <div class="col-auto mb-3">
            <input id="fileUpload"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              ref="fileUpload" type="file" @change="postBabyNameFile" hidden>
            <button class="btn btn-dark" @click="chooseFiles">Import</button>
          </div>
          <div class="col-auto mb-3" v-if="add">
            <router-link to="/products/ecommerce/add">
              <a class="btn btn-outline-primary">Add New E-commerce</a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <ul class="nav nav-tabs nav-tabs--wp">
      <li class="nav-item">
        <router-link to="/products/ecommerce" class="nav-link" active-class="active" exact>
          List
        </router-link>
      </li>
    </ul>
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
        setCurrentPage: setCurrentPage,
        perPage: serverParams.take,
      }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'ecommerce_name', type: 'asc' },
}" styleClass="vgt-table" slot="outsideBody">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="ecommerce_name" class="form-control" placeholder="Search product" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'ecommerce_name'
          " @input="(value) => updateFilters(column, $refs.ecommerce_name.value)" />

        <select ref="store_status" class="form-select" placeholder="Cari productCategory" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'store_status'
          " @change="(value) => updateFilters(column, $refs['store_status'].value)">
          <option />
          <option v-for="(option, key) in optionsKategory" v-bind:value="option.value" :key="key" v-text="option.label" />
        </select>
        <div class="input-group" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'createdAt'">
          <input type="date" ref="createdAt1" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
          <span class="input-group-text">
            -
          </span>
          <input type="date" ref="createdAt2" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
        </div>
        <select ref="status" class="form-select" placeholder="Cari productCategory" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'status'
          " @change="(value) => updateFilters(column, $refs['status'].value)
    ">
          <option />
          <option v-for="(option, key) in optionsStatus" v-bind:value="option" :key="key" v-text="option" />
        </select>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end" v-if="props.row.status !== 'Deleted'">
            <div class="col-auto" v-if="edit">
              <router-link :to="`/products/ecommerce/edit/${props.row.id}`" class="btn btn-lg text-dark" v-b-tooltip.hover
                title="Edit">
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>
            <div class="col-auto" v-if="deleteEcommerce">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                @click.prevent="showAlertConfirm(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else :class="[props.row.status === 'Deleted' && 'text-muted']">
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import EcomerceService from "@/service/ecomerce.service";
import tokenService from '@/service/token.service';
import moment from "moment";
import _ from 'lodash';
export default {
  data() {
    return {
      setCurrentPage: this.$store.state.ecommerce.serverParams.skip / this.$store.state.ecommerce.serverParams.take == 0 ?
        1 : this.$store.state.ecommerce.serverParams.skip / this.$store.state.ecommerce.serverParams.take + 1,
      optionsProductCategory: [],
      optionsStatus: ['Publish', 'Draft'],
      optionsKategory: [
        { label: 'Online', value: 'online' },
        { label: 'Offline', value: 'offline' }],
      isLoading: false,
      columns: [
        {
          label: "List",
          field: "ecommerce_name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Kategory",
          field: "store_status",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Published Date",
          field: "createdAt",
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Status",
          field: "status",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: this.$store.state.ecommerce.serverParams,
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
      permisions: tokenService.getPermission()
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.$store.state.ecommerce.serverParams = Object.assign({}, this.$store.state.ecommerce.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ skip: 0 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams();
      this.loadItems();
    },

    onColumnFilter: _.debounce(function (params) {
      let payload = {}
      if (params.columnFilters['createdAt'] && params.columnFilters['createdAt'] != '#') {
        let arrSplit = params.columnFilters['createdAt'].split('#')
        if (arrSplit[0] && arrSplit[1]) {
          payload.createdAtStartDate = arrSplit[0] ? moment(arrSplit[0]).startOf('day').toISOString() : ''
          payload.createdAtEndDate = arrSplit[1] ? moment(arrSplit[1]).endOf('day').toISOString() : ''
        }
      } else {
        delete this.$store.state.ecommerce.serverParams.createdAtStartDate
        delete this.$store.state.ecommerce.serverParams.createdAtEndDate
      }

      if (params.columnFilters.status && params.columnFilters.status != '') {
        payload.status = params.columnFilters.status ? params.columnFilters.status : ''
      } else {
        delete this.$store.state.ecommerce.serverParams.status
      }

      if (params.columnFilters.ecommerce_name && params.columnFilters.ecommerce_name != '') {
        payload.ecommerceTitle = params.columnFilters.ecommerce_name ? params.columnFilters.ecommerce_name : ''
      } else {
        delete this.$store.state.ecommerce.serverParams.ecommerceTitle
      }

      if (params.columnFilters.store_status && params.columnFilters.store_status != '') {
        payload.storeStatus = params.columnFilters.store_status ? params.columnFilters.store_status : ''
      } else {
        delete this.$store.state.ecommerce.serverParams.storeStatus
      }

      this.updateParams(payload);
      this.loadItems();
    }, 300),
    loadItems() {
      this.isLoading = true;
      EcomerceService
        .getEcommerceList(this.$store.state.ecommerce.serverParams)
        .then(({ data: { data } }) => {
          this.rows = data.ecommerce
          this.totalRecords = data.totalData
        }).catch((e) => {
          if (e.response?.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },

    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },

    doDelete(id) {
      this.isLoading = true;
      EcomerceService
        .deleteEcommerceById(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },

    async postBabyNameFile(e) {
      let formData = new FormData()
      formData.append('file', e.target.files[0], e.target.files[0].name)

      EcomerceService
        .importExcel(formData)
        .then((rsp) => {
          // console.log(rsp)
          this.$swal({
            title: "Data berhasil ditambah",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
          this.loadItems();
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        })
    },
    chooseFiles() {
      const elem = this.$refs.fileUpload
      elem.click()
    },


    downloadExcel() {
      this.isLoading = true;

      EcomerceService.exportExcel({})
        .then(({ data: { data } }) => {
          let { path } = data
          if (path) {
            window.open(this.$api_name + '/' + path, '_blank')
          }
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },
  },
  computed: {
    add: function () {
      if (this.permisions.find((r) => r == 'Ecommerce Add')) {
        return true
      }
      return false;
    },
    edit: function () {
      if (this.permisions.find((r) => r == 'Ecommerce Patch')) {
        return true
      }
      return false;
    },
    deleteEcommerce: function () {
      if (this.permisions.find((r) => r == 'Ecommerce Delete')) {
        return true
      }
      return false;
    },
  },
  mounted() {
    this.loadItems();
  },
};
</script>
