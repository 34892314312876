import api from "./api";

class AmbangBatasCategoryService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('threshold-category')
    }

    getAmbangBatasCategoryList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postAmbangBatasCategory(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getAmbangBatasCategoryById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchAmbangBatasCategory(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteAmbangBatasCategoryById(serverParams) {
        return this.api.delete("/" + serverParams);
    }
}

export default new AmbangBatasCategoryService();