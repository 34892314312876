<template>
    <div class="product-form">
        <FormBanner form-type="add" @onValidateSuccess="doSave" :on-loading="onLoading" />
    </div>
</template>
<script>
import FormBanner from './Form.vue'
import base64Converter from "@/pages/Helper/base64";
import BannerService from "@/service/banner.service";
export default {
    components: {
        FormBanner,
    },
    data() {
        return {
            onLoading: false
        }
    },
    methods: {
        async doSave(payload) {
            // Submit here
            this.onLoading = true
            let { banner, status } = payload
            if (banner) {
                let { cta, images, description, product_category, parent_category } = banner
                payload = {
                    desktop_image: await base64Converter(images.desktop[0]),
                    mobile_image: await base64Converter(images.mobile[0]),
                    banner_title: banner.title,
                    product_category_id: product_category?.id ? product_category.id : null,
                    parent_product_category_id: parent_category?.id ? parent_category.id : null,
                    tag: banner.tag ? banner.tag.toString() : null,
                    location_banner_page: banner.location,
                    position_banner: banner.position,
                    alt_desktop_image: banner.images.alt_desktop_image ? banner.images.alt_desktop_image : null,
                    alt_mobile_image: banner.images.alt_mobile_image ? banner.images.alt_mobile_image : null,
                    cta1_text: cta.text1 ? cta.text1 : null,
                    cta1_url: cta.url1 ? cta.url1 : null,
                    cta2_text: cta.text2 ? cta.text2 : null,
                    cta2_url: cta.url2 ? cta.url2 : null,
                    description: description ? description : null,
                    status
                }
                BannerService.postBanner(payload).then((rsp) => {
                    this.$swal({
                        title: "Data berhasil disimpan",
                        showCloseButton: true,
                    }).then(() => {
                        this.$router.push({ path: '/sections/banner' })
                    });
                }).catch((e) => {
                    if (e.response?.status === 409) {
                        this.$notify({
                            type: "warn",
                            title: "Error Message",
                            text: "Data Sudah Ada",
                        });
                    } else {
                        this.$notify({
                            type: "error",
                            title: "Error Message",
                            text: e.response?.data?.message || "Terjadi Kesalahan",
                        });
                    }
                }).finally(() =>
                    this.onLoading = false)
            }
        }
    }
}
</script>