<template>
  <div class="product-form">
    <FormPages form-type="edit" :detail-data="detail" @onValidateSuccess="doSave" />
  </div>
</template>
<script>
import FormPages from "./Form.vue";
import base64Converter from "../../pages/Helper/base64";
import PageService from "@/service/page.service";
export default {
  data() {
    return {
      detail: {},
      body: {
        id: this.$route.params.id,
        name: "",
        title: "",
        slug: "",
        mainImageDesktop: "",
        mainImageMobile: "",
        pageStatus: "DRAFTS",
        pageTemplate: "",
        content: "",
        pageSections: [],
        seoInformation: {
          metaTitle: "",
          metaDescription: "",
          seoKeywords: "",
          metaImage: "",
        },
      },
    };
  },
  components: {
    FormPages,
  },

  methods: {
    async doSave(payload) {
      this.onLoading = true

      let req = {
        title: payload.title,
        slug: payload.slug,
        page_template: payload.template,
        description: payload.content,
        desktop_image: (payload.images.desktop && payload.images.desktop.length > 0) ?
          await base64Converter(payload.images.desktop[0]) : null,
        mobile_image: (payload.images.mobile && payload.images.mobile.length > 0) ?
          await base64Converter(payload.images.mobile[0]) : null,
        main_image_alt_text: payload.images.alt ? payload.images.alt : null,
        link_youtube: payload.youtube ? payload.youtube : null,
        status: payload.status,
        alt_desktop_image: payload.images.alt_desktop_image ? payload.images.alt_desktop_image : null,
        alt_mobile_image: payload.images.alt_mobile_image ? payload.images.alt_mobile_image : null,
        seoInformation: {
          meta_title: payload.meta.title,
          meta_description: payload.meta.description,
          meta_keyword: payload.meta.keywords.toString(),
          image: (payload.meta.image && payload.meta.image.length > 0) ?
            await base64Converter(payload.meta.image[0]) : null,
          alt_image: payload.meta.alt_image ? payload.meta.alt_image : null,
        }
      }

      PageService.patchPage(this.$route.params.id, req).then((rsp) => {
        this.$swal({
          title: "Data berhasil disimpan",
          showCloseButton: true,
        }).then(() => {
          this.$router.push({ path: '/pages/list' })
        });
      }).catch((e) => {
        if (e.response?.status === 409) {
          this.$notify({
            type: "warn",
            title: "Error Message",
            text: "Data Sudah Ada",
          });
        } else {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }
      }).finally(() =>
        this.onLoading = false)
    },
    loadItems(id) {
      PageService.getPageById(id)
        .then(async ({ data: { data } }) => {
          this.detail = {
            title: data.title ? data.title : null,
            template: data.page_template ? data.page_template : null,
            slug: data.slug ? data.slug : null,
            content: data.description ? data.description : null,
            images: {
              desktop: data.desktop_image ? [await this.convertURLtoFile(this.loadSrcImage(data.desktop_image))] : null,
              desktopSrc: data.desktop_image ? this.loadSrcImage(data.desktop_image) : '',
              mobile: data.mobile_image ? [await this.convertURLtoFile(this.loadSrcImage(data.mobile_image))] : null,
              mobileSrc: data.mobile_image ? this.loadSrcImage(data.mobile_image) : '',
              alt: data ? data.main_image_alt_text : null,
              alt_desktop_image: data.alt_desktop_image ? data.alt_desktop_image : null,
              alt_mobile_image: data.alt_mobile_image ? data.alt_mobile_image : null,
            },
            youtube: data.link_youtube ? data.link_youtube : null,
            meta: {
              title: (data.seoInfomation?.meta_title && data.seoInfomation) ? data.seoInfomation.meta_title : null,
              description: (data.seoInfomation?.meta_description && data.seoInfomation) ? data.seoInfomation.meta_description : null,
              keywords: (data.seoInfomation?.meta_keyword && data.seoInfomation) ? data.seoInfomation.meta_keyword.split(',') : [],
              image: (data.seoInfomation?.image && data.seoInfomation) ? [await this.convertURLtoFile(this.loadSrcImage(data.seoInfomation.image))] : null,
              imageSrc: (data.seoInfomation?.image && data.seoInfomation) ? this.loadSrcImage(data.seoInfomation.image) : '',
              alt_image: data.seoInfomation?.alt_image ? data?.seoInfomation.alt_image : null,
            },
            publishedDate: data.publishedDate ? data.publishedDate : null,
            lastModified: data.updatedAt ? data.updatedAt : null,
            author: data.author ? data.author : null,
          }
        })
    },
    loadSrcImage(url) {
      return this.$api_name + '/' + url
    },
    async convertURLtoFile(url) {
      const response = await fetch(url);
      if (response.status != 200) {
        return "";
      }
      const data = await response.blob();
      const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
      const metadata = { type: `image/jpeg` };
      return new File([data], filename, metadata);
    },
  },
  mounted() {
    this.loadItems(this.$route.params.id)
  },
};
</script>
