import axios from "axios";
import qs from "qs"
import TokenService from "@/service/token.service";
import AuthService from "./auth.service";

let accessToken = TokenService.getLocalAccessToken()

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    "Pragma": "no-cache",
  },
  paramsSerializer: (params) => qs.stringify(params)
});

api.create = (configOrServiceName) => {
  let serviceName = `VUE_APP_${configOrServiceName.toUpperCase()}_SERVICE`;
  let newServiceName = process.env.VUE_APP_API + '/v1/' + configOrServiceName;
  if (typeof configOrServiceName == 'string') {
    // let baseURL = process.env[serviceName];
    let baseURL = process.env[serviceName] ? process.env[serviceName] : newServiceName
    // console.log(baseURL)
    // if (!baseURL) {
    //     console.error(`API URL for Service ${configOrServiceName} is not defined`)
    //     baseURL = `${env.BASE_URL}/${configOrServiceName}/${env.SERVICE_VERSION}`
    // }
    configOrServiceName = { baseURL, service: configOrServiceName }
  }

  /** @ts-ignore @type {import("axios").AxiosRequestConfig} */
  const config = { ...api.defaults, ...configOrServiceName }
  // console.log(config)
  /**
   * @type {ApiAxiosInstance}
   */
  const apiService = axios.create(config)

  apiService.defaults.headers = (accessToken)
    ? {
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + accessToken
    }
    : api.defaults.headers

  apiService.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (originalConfig.url !== "/auth/login" && err.response) {
        // Access Token was expired

        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          // TokenService.removeUser()
          // location.reload(true);
          try {

            const rs = await AuthService.refreshToken({
              refreshToken: TokenService.getLocalRefreshToken(),
            });
            const { accessToken, refreshToken } = rs.data.data;
            TokenService.updateLocalAccessToken(accessToken, refreshToken);
            location.reload(true);
            return axiosInstance(originalConfig);
          } catch (_error) {
            //refresh token expired
            if (_error.response.status === 500) {
              TokenService.removeUser()
              location.reload(true);
            }
            return Promise.reject(_error);
          }

        }
      }

      return Promise.reject(err);
    }
  );
  // @ts-ignore
  // apiService.interceptors.request.handlers = api.interceptors.request.handlers
  // // @ts-ignore
  // apiService.interceptors.response.handlers = api.interceptors.response.handlers
  return apiService
}



export default api;
