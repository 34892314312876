<template>
    <div class="product-form">
        <FormProduct form-type="add" @onValidateSuccess="doSave" :on-loading="onLoading" />
    </div>
</template>
<script>
import FormProduct from './Form.vue'
import EcomerceService from "@/service/ecomerce.service";
import TokenService from "@/service/token.service";
import base64Converter from "@/pages/Helper/base64";
export default {
    data() {
        return {
            onLoading: false
        }
    },
    components: {
        FormProduct,
    },

    methods: {
        async doSave(payload) {
            this.onLoading = true
            // Submit here
            let { product, status } = payload
            if (product) {
                payload = {
                    user_id: (TokenService.getUser()).id,
                    ecommerce_name: product.name,
                    cta_url: product.cta ? product.cta : null,
                    store_status: product.store_status ? product.store_status : null,
                    desktop_image: (product.images.desktop && product.images.desktop.length > 0) ?
                        await base64Converter(product.images.desktop[0]) : null,
                    mobile_image: (product.images.mobile && product.images.mobile.length > 0) ?
                        await base64Converter(product.images.mobile[0]) : null,
                    alt_desktop_image: product.images.alt_desktop_image ? product.images.alt_desktop_image : null,
                    alt_mobile_image: product.images.alt_mobile_image ? product.images.alt_mobile_image : null,
                    status: status
                }

                EcomerceService.postEcommerce(payload).then((rsp) => {
                    this.$swal({
                        title: "Data berhasil disimpan",
                        showCloseButton: true,
                    }).then(() => {
                        this.$router.push({ path: '/products/ecommerce' })
                    });
                }).catch((e) => {
                    if (e.response?.status === 409) {
                        this.$notify({
                            type: "warn",
                            title: "Error Message",
                            text: "Data Sudah Ada",
                        });
                    } else {
                        this.$notify({
                            type: "error",
                            title: "Error Message",
                            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
                        });
                    }
                }).finally(() =>
                    this.onLoading = false)
            }
        }
    }
}
</script>