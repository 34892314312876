import api from "./api";

class JobTypeService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('career-type')
    }

    getJobTypeList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postJobType(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getJobTypeById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchJobType(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteJobTypeById(serverParams) {
        return this.api.delete("/" + serverParams);
    }
}

export default new JobTypeService();