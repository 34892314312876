<template>
  <div class="product-form">
    <FormPost form-type="edit" :detail-data="post" @onValidateSuccess="doSave" :on-loading="onLoading" />
  </div>
</template>
<script>
import FormPost from "./Form.vue";
import PostService from "@/service/post.service";
import TokenService from "@/service/token.service";
import base64ToFile from "@/pages/Helper/base64ToFile";
import base64Converter from "@/pages/Helper/base64";

export default {
  data() {
    return {
      onLoading: false,
      post: {
        id: this.$route.params.id,
        title: "",
        category: "",
        slug: "",
        content: "",
        images: {
          desktop: null,
          mobile: null,
        },
        meta: {
          title: "",
          description: "",
          keywords: [],
          image: null,
        },
      },
    };
  },
  components: {
    FormPost,
  },
  mounted() {
    this.loadItems(this.$route.params.id)
  },
  methods: {
    async doSave(payload) {
      // Submit here
      this.onLoading = true
      let { post, status } = payload
      if (post) {
        let payload = {
          user_id: (TokenService.getUser()).id,
          "title": post.title,
          "slug": post.slug,
          "post_category_id": post.category.id,
          "post_category": post.category.id,
          "description": post.content,
          "tag": post.tag.toString(),
          "desktop_image": (post.images.desktop[0] && post.images.desktop.length > 0) ? await base64Converter(post.images.desktop[0]) : null,
          "mobile_image": (post.images.mobile[0] && post.images.mobile.length > 0) ? await base64Converter(post.images.mobile[0]) : null,
          image_thumbnail: (post.images.thumbnail[0] && post.images.thumbnail.length > 0) ?
            await base64Converter(post.images.thumbnail[0]) : null,
          alt_image_thumbnail: post.images.alt_thumbnail ? post.images.alt_thumbnail : null,
          alt_desktop_image: post.images.alt_desktop_image ? post.images.alt_desktop_image : null,
          alt_mobile_image: post.images.alt_mobile_image ? post.images.alt_mobile_image : null,
          link_youtube: post.youtube ? post.youtube : null,
          thumbnail: post.thumbnail ? post.thumbnail : null,
          main_image_alt_next: post.images?.alt ? post.images?.alt : null,
          "seoInformation": {
            meta_title: post.meta.title,
            meta_description: post.meta.description,
            meta_keyword: post.meta.keywords.toString(),
            image: (post.meta.image && post.meta.image.length > 0) ?
              await base64Converter(post.meta.image[0]) : null,
            alt_image: post.meta.alt_image ? post.meta.alt_image : null,
          },
          status
        }

        PostService.patchArticle(this.$route.params.id, payload).then((rsp) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.$router.push({ path: '/posts/list/data' })
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          }
        }).finally(() =>
          this.onLoading = false)
      }
    },
    loadItems(id) {
      PostService.getArticleById(id)
        .then(async ({ data: { data } }) => {
          this.post = {
            title: data.title ? data.title : null,
            category: data.post_category ? data.post_category : null,
            slug: data.slug ? data.slug : null,
            content: data.description ? data.description : null,
            youtube: data.link_youtube ? data.link_youtube : null,
            tag: data.tag ? data.tag.split(',') : null,
            thumbnail: data.thumbnail ? data.thumbnail : null,
            images: {
              desktop: data ? [await this.convertURLtoFile(this.loadSrcImage(data.desktop_image))] : null,
              desktopSrc: data ? this.loadSrcImage(data.desktop_image) : '',
              mobile: data ? [await this.convertURLtoFile(this.loadSrcImage(data.mobile_image))] : '',
              mobileSrc: data ? this.loadSrcImage(data.mobile_image) : '',
              thumbnail: data.image_thumbnail ? [await this.convertURLtoFile(this.loadSrcImage(data.image_thumbnail))] : '',
              thumbnailSrc: data.image_thumbnail ? this.loadSrcImage(data.image_thumbnail) : '',
              alt_thumbnail: data.alt_image_thumbnail ? data.alt_image_thumbnail : null,
              alt_desktop_image: data.alt_desktop_image ? data.alt_desktop_image : null,
              alt_mobile_image: data.alt_mobile_image ? data.alt_mobile_image : null,
              alt: data.main_image_alt_next ? data.main_image_alt_next : null,
            },
            meta: {
              title: (data.seo_information?.meta_title && data.seo_information) ? data.seo_information.meta_title : null,
              description: (data.seo_information?.meta_description && data.seo_information) ? data.seo_information.meta_description : null,
              keywords: (data.seo_information?.meta_keyword && data.seo_information) ? data.seo_information.meta_keyword.split(',') : null,
              image: (data.seo_information?.image && data.seo_information) ? [await this.convertURLtoFile(this.loadSrcImage(data.seo_information.image))] : null,
              imageSrc: (data.seo_information?.image && data.seo_information) ? this.loadSrcImage(data.seo_information.image) : '',
              alt_image: data.seo_information.alt_image ? data.seo_information.alt_image : null,
            },
            publishedDate: data.publishedDate ? data.publishedDate : null,
            lastModified: data.updatedAt ? data.updatedAt : null,
            author: data.User && data.User?.name ? data.User?.name : '',
            articleView: data.counter ? data.counter : 0,
          }
        })
    },
    dataToFile(data) {
      let { base64Img, originalName, fileType: { mime } } = data
      return base64ToFile(base64Img, originalName, mime)
        .then((r) => {
          return r
        }).catch((e) => {
          return ''
        })
    },
    loadSrcImage(url) {
      return this.$api_name + '/' + url
    },
    async convertURLtoFile(url) {
      const response = await fetch(url);
      if (response.status != 200) {
        return "";
      }
      const data = await response.blob();
      const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
      const metadata = { type: `image/jpeg` };
      return new File([data], filename, metadata);
    },
  },
};
</script>
