<template>
  <form @submit.prevent="doSave" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          Profile
        </h1>
      </div>
      <div class="col-lg-12">
        <customer-nav v-if="formType === 'edit'" />
        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Nama sesuai KTP</label>
                  <input type="text" class="form-control" v-model="customer.full_name" v-on:keypress="isLetter" />
                  <span class="text-danger" v-if="$v.customer.full_name.$error">Nama is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="col-md-6">
                  <label class="form-label">No. Whatsapp</label>
                </div>
                <div class="input-group mb-15px">
                  <span class="input-group-text">+62</span>
                  <input type="text" class="form-control" v-model="customer.phoneNumber" :maxlength="13"
                    v-on:keypress="isNumber" />
                </div>
                <div>Note: Phone Number must be at least {{ $v.customer.phoneNumber.$params.minLength.min }}</div>
                <div class="text-danger" v-if="$v.customer.phoneNumber.$error">Phone Number must have at most
                  {{ $v.customer.phoneNumber.$params.minLength.min }} letters.</div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Email</label>
                  <input type="email" class="form-control" v-model="customer.email" />
                  <span class="text-danger" v-if="$v.customer.email.$error">Email is required</span>
                </div>
              </div>

              <!-- <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Tanggal Lahir</label>
                  <input type="date" class="form-control" v-model="customer.dob" />
                  <span class="text-danger" v-if="$v.customer.dob.$error">Tanggal Lahir is required</span>
                </div>
              </div> -->
              <div class="col-md-6 mb-15px">
                <div class="col-md-6">
                  <label class="form-label">Password</label>
                </div>
                <div class="input-group">
                  <input :type="passwordType" class="form-control" v-model.trim="customer.password"
                    :disabled="!changePassword && formType === 'edit'" />
                  <span v-if="(formType === 'edit' && changePassword) || formType === 'add'" class="input-group-text"><i
                      :class="passwordClass" id="togglePassword" @click="showPassword(1)"></i></span>
                </div>
                <p class="mb-2">Password must have at least 1 Uppercase, 1 Lowercase, 1 Number, 1 Special Character</p>
                <span class="text-danger" v-if="$v.customer.password.$error">Password is required</span>
              </div>
              <div v-if="formType === 'edit' && !changePassword" class="col-md-6 mt-20px">
                <button type="button" class="btn btn-primary" @click.prevent="doChangePassword">
                  Change Password
                </button>
              </div>
              <div v-else class="col-md-6 mb-15px">
                <div class="col-md-6">
                  <label class="form-label">Konfirmasi Password</label>
                </div>
                <div class="input-group">
                  <input :type="rePasswordType" class="form-control" v-model.trim="customer.rePassword" />
                  <span class="input-group-text"><i :class="rePasswordClass" id="togglePassword"
                      @click="showPassword(2)"></i></span>
                </div>
                <span class="text-danger" v-if="!$v.customer.rePassword.sameAsPassword">Konfirmasi Password tidak sama
                  dengan
                  Password</span>
                <br>
                <span class="text-danger" v-if="$v.customer.rePassword.$error">Konfirmasi Password is required</span>
              </div>

              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Provinsi</label>
                  <select class="form-select" v-model="customer.province">
                    <option v-for="(option, key) in optionsProvinces" v-bind:value="option.provinsi" :key="key"
                      v-text="option.provinsi" />
                  </select>
                  <span class="text-danger" v-if="$v.customer.province.$error">Provinsi is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Kota/Kabupaten</label>
                  <select class="form-select" v-model="customer.city" :disabled="!customer.province">
                    <option v-for="(option, key) in optionsCities" v-bind:value="option.kabupaten" :key="key"
                      v-text="option.kabupaten" />
                  </select>
                  <span class="text-danger" v-if="$v.customer.city.$error">Provinsi is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Kecamatan</label>
                  <select class="form-select" v-model="customer.suburban" :disabled="!customer.city">
                    <option v-for="(option, key) in optionsDistricts" v-bind:value="option.kecamatan" :key="key"
                      v-text="option.kecamatan" />
                  </select>
                  <span class="text-danger" v-if="$v.customer.suburban.$error">Kecamatan is required</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Kelurahan/Desa</label>
                  <select class="form-select" v-model="customer.ward" :disabled="!customer.suburban">
                    <option v-for="(option, key) in optionsSubDistricts" v-bind:value="option.kelurahan" :key="key"
                      v-text="option.kelurahan" />
                  </select>
                  <span class="text-danger" v-if="$v.customer.ward.$error">Kelurahan/Desa is required</span>
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Kode Pos</label>
                  <input type="text" class="form-control" v-model="customer.postal_code" v-on:keypress="isNumber"
                    :maxlength="5" />
                  <!-- <select class="form-select" v-model="customer.postal_code" :disabled="!customer.suburban">
                    <option />
                    <option v-for="(option, key) in optionsPostal" v-bind:value="option.kodepos" :key="key"
                      v-text="option.kodepos" />
                  </select>
                  <span class="text-danger" v-if="$v.customer.ward.$error">Kelurahan/Desa is required</span> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-15px">
                  <label class="form-label">Status</label>
                  <select class="form-select" v-model="customer.is_active">
                    <option v-for="(option, key) in optionsIsActive" v-bind:value="option.value" :key="key"
                      v-text="option.text" />
                  </select>
                  <span class="text-danger" v-if="$v.customer.province.$error">Provinsi is required</span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Alamat</label>
                  <textarea rows="7" class="form-control" v-model="customer.address"></textarea>
                  <span class="text-danger" v-if="$v.customer.address.$error">Alamat is required</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Foto Profile</label>
                  <UploadImage v-model="customer.avatar" :src="customer.avatar_img" size="1000"
                    @image-err-message="imgErrMessage" />
                </div>
              </div>
            </div>
            <br>
            <panel title="Anak" variant="inverse" :is-collapse="true">
              <panel v-for="(   item, index   ) in    $v.childrens.$each.$iter   " :key="index" :index="index"
                :title="`Anak ke - ${parseInt(index) + 1}`" variant="grey" :is-collapse="true" :checkbox-hidden="false"
                :show-collapse="index > 0 ? false : true" v-model="item.isShow" :checkbox-name="`sliderItem-${index}`"
                :has-remove="isEditedChild" @onRemove="removeChildren(index)">
                <div class="row">
                  <div class="col-md-12">
                    <div class="mb-15px">
                      <label class="form-label">Nama Anak</label>
                      <input type="text" class="form-control" v-model="item.full_name.$model" v-on:keypress="isLetter"
                        :disabled="!isEditedChild" />
                      <span class="text-danger" v-if="!item.full_name.isUnique">Nama Tidak Boleh Sama</span>
                      <div class="text-danger" v-if="!item.full_name.maxLength">Name must have at most
                        {{ item.full_name.$params.maxLength.max }} letters.</div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="mb-15px">
                      <label class="form-label">Jenis Kelamin</label>
                      <div class="mb-15px">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'genderAnak' + index"
                            :id="'genderAnak' + index" value="male" v-model="item.gender.$model">
                          <label class="form-check-label" :for="'genderAnak' + index">Laki - Laki </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'genderAnak' + index"
                            :id="'genderAnak' + index" value="female" v-model="item.gender.$model">
                          <label class="form-check-label" :for="'genderAnak' + index">Perempuan</label>
                        </div>
                      </div>
                      <span class="text-danger" v-if="item.gender.$error">Tinggi is required</span>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="mb-15px">
                      <label class="form-label">Tanggal Lahir</label>
                      <input type="date" class="form-control" v-model="item.dob.$model" :disabled="!isEditedChild" />
                      <span class="text-danger" v-if="item.dob.$error">Date Can't today, Date After Today or Max 5 years
                        child</span>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="mb-15px">
                      <label class="form-label">Tinggi (Cm)</label>
                      <input type="number" class="form-control" v-model="item.height.$model" step="any"
                        :disabled="!isEditedChild" />
                      <span class="text-danger" v-if="item.height.$error">Tinggi is required</span>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="mb-15px">
                      <label class="form-label">Berat (Kg)</label>
                      <input type="number" class="form-control" v-model="item.weight.$model" step="any"
                        :disabled="!isEditedChild" />
                      <span class="text-danger" v-if="item.weight.$error">Berat is required</span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-15px">
                      <label class="form-label">Foto Anak</label>
                      <UploadImage v-model="item.avatar_img.$model" :src="item.avatar_imgSrc.$model" size="1000"
                        @image-err-message="imgErrMessage" />
                    </div>
                  </div>
                </div>
              </panel>
              <button v-if="isEditedChild" @click="addChildren()" class="btn btn-outline-primary w-100 d-block text-start"
                type="button">
                <i class="fa-solid fa-plus"></i> Add Anak
              </button>
              <div class="text-danger" v-if="$v.childrens.$error">Childrens Required</div>
              <div class="text-danger" v-if="!$v.childrens.maxlength">Childrens must have at most {{
                $v.childrens.$params.maxlength.max }}.</div>
            </panel>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import CustomerNav from './Nav.vue'
import { required, minLength, sameAs, requiredIf, maxLength, maxValue } from "vuelidate/lib/validators";
import CustomerChildService from "@/service/customerchild.service";
import locationService from "@/service/location.service";
import tokenService from "@/service/token.service";
import moment from "moment";
import UploadImage from "../../components/upload/Image.vue"

export default {
  components: {
    CustomerNav,
    UploadImage
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
    childrens: {
      type: Object / Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    const detail = this.detailData;

    return {
      permisions: tokenService.getPermission(),
      optionsProvinces: [],
      optionsCities: [],
      optionsDistricts: [],
      optionsSubDistricts: [],
      optionsPostal: [],
      optionsIsActive: [
        {
          text: 'Active',
          value: 'active'
        },
        {
          text: 'Banned',
          value: 'banned'
        },
        {
          text: 'Pending',
          value: 'pending'
        },
        {
          text: 'Suspended',
          value: 'suspended'
        }
      ],
      changePassword: false,
      messagePassword: false,
      passwordClass: "fas fa-eye",
      passwordType: 'password',
      rePasswordClass: "fas fa-eye",
      rePasswordType: 'password',
      customer: {
        avatar: detail ? detail.avatar : null,
        avatar_img: detail ? detail.avatar_img : '',
        full_name: detail ? detail.full_name : null,
        // gender: detail ? detail.gender : null,
        // dob: detail ? detail.dob : null,
        phoneNumber: detail ? detail.phoneNumber : null,
        postal_code: detail ? detail.postal_code : null,
        address: detail ? detail.address : null,
        email: detail ? detail.email : null,
        password: detail ? detail.email : null,
        tnc: detail ? detail.tnc : 0,
        hide_chidl_dob: detail ? detail.hide_chidl_dob : 0,
        province: detail ? detail.province : 0,
        rePassword: null
      },
      locationRequest: {}
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
    isEditedChild: function () {
      if (this.permisions.find((r) => r == 'CustomerChild Patch')) {
        return true
      }
      return false;
    },
  },
  watch: {
    detailData(newVal) {
      let { changePassword } = newVal
      this.changePassword = changePassword
      this.customer = newVal;
    },
    customer:
    {
      handler(value) {
        if (value.rePassword && value.password) {
          if (value.rePassword !== value.password) {
            this.messagePassword = true
          } else {
            this.messagePassword = false
          }
        }
      }, deep: true
    },
    'customer.province': {
      handler: function (nv, ov) {
        if (nv) {
          if (nv != ov) {
            this.locationRequest = {}
          }
          this.loadCities(nv)
        }
      }
    },
    'customer.city': {
      handler: function (nv, ov) {
        if (nv) {
          delete this.locationRequest.subDistrictName
          this.loadDistrict(nv)
        }
      }
    },
    'customer.suburban': {
      handler: function (nv, ov) {
        if (nv) {
          this.loadSubDistrict(nv)
          this.loadPostalCode(nv)
        }
      }
    }
  },
  mounted() {
    this.loadProvinces()
  },
  methods: {
    doSave() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const payload = {
          customer: this.customer,
          childrens: this.childrens
        };

        this.$emit("onValidateSuccess", payload);
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },
    addChildren() {
      this.childrens.push({
        full_name: null,
        gender: null,
        dob: null,
        height: null,
        weight: null,
      });
    },
    removeChildren(index) {
      if (this.childrens[index].id) {
        this.reqRemoveChild(this.childrens[index].id)
      } else {
        this.childrens.splice(index, 1);
      }
    },
    reqRemoveChild(index) {
      this.isLoading = true;
      CustomerChildService
        .deleteCustomerChildById(index)
        .then((response) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.$emit("onRefresh", true);
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },
    showPassword(type) {
      if (type === 1) {
        this.passwordType =
          this.passwordType === "password" ? "text" : "password";
        this.passwordClass =
          this.passwordType === "password"
            ? "fas fa-eye"
            : "fas fa-eye-slash";
      } else {
        this.rePasswordType =
          this.rePasswordType === "password" ? "text" : "password";
        this.rePasswordClass =
          this.rePasswordType === "password"
            ? "fas fa-eye"
            : "fas fa-eye-slash";
      }
    },
    doChangePassword() {
      this.changePassword = true
      this.customer.password = null
      this.customer.rePassword = null
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z ]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    loadProvinces() {
      this.isLoading = true;
      locationService.getProvinces({})
        .then(({ data: { data } }) => {
          this.optionsProvinces = data.province
        }).catch((e) => {
          if (e.response.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadCities(province) {
      let req = { provinceName: province }
      this.locationRequest = Object.assign({}, this.locationRequest, req)
      locationService.getCities(this.locationRequest)
        .then(({ data: { data } }) => {
          this.optionsCities = data.cities
        }).catch((e) => {
          if (e.response.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadDistrict(city) {
      let req = { cityName: city }
      this.locationRequest = Object.assign({}, this.locationRequest, req)
      locationService.getDistricts(this.locationRequest)
        .then(({ data: { data } }) => {
          this.optionsDistricts = data.subDistrict
        }).catch((e) => {
          if (e.response.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadSubDistrict(district) {
      let req = { subDistrictName: district }
      this.locationRequest = Object.assign({}, this.locationRequest, req)
      locationService.getSubDistricts(this.locationRequest)
        .then(({ data: { data } }) => {
          this.optionsSubDistricts = data.ward
        }).catch((e) => {
          if (e.response.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadPostalCode(district) {
      locationService.getPostalCode(this.locationRequest)
        .then(({ data: { data } }) => {
          this.optionsPostal = data.postalCode
        }).catch((e) => {
          if (e.response.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    isUniqueChildrensName(value) {
      let findUnique = this.childrens.filter((r) => r.full_name == value)
      if (findUnique.length > 1) {
        return false
      }
      return true
    },
    imgErrMessage(mssg) {
      console.log(mssg)
      this.$notify({
        type: "error",
        title: "Image Notification",
        text: mssg,
      });
    },
  },
  validations: {
    childrens: {
      required,
      maxlength: maxLength(2),
      $each: {
        gender: { required },
        full_name: {
          required,
          maxLength: maxLength(15),
          isUnique(value) {
            if (value === '') return true
            return this.isUniqueChildrensName(value)
          }
        },
        dob: {
          valid: (value) => {
            let date_now = moment(Date.now()).format('YYYY-MM-DD')
            let gap = moment().year() - moment(value, 'YYYY-MM-DD').year()
            if (moment(value, 'YYYY-MM-DD').isSame(date_now)) {
              return false;
            } else if (gap > 5) {
              return false;
            } else if (moment(value, 'YYYY-MM-DD').isAfter(date_now)) {
              return false
            } else {
              return true
            }
          }
        },
        height: { required },
        weight: { required },
        avatar_img: {},
        avatar_imgSrc: {}
      }
    },
    customer: {
      full_name: { required },
      // dob: { required },
      phoneNumber: { required, minLength: minLength(10) },
      address: { required },
      email: { required },
      province: { required },
      city: { required },
      suburban: { required },
      ward: { required },
      rePassword: {
        requiredIfFuction: requiredIf(function (nestedModel) {
          if (!this.changePassword) {
            return false && nestedModel.rePassword
          } else {
            return true && nestedModel.rePassword
          }
        }),
        sameAsPassword: sameAs(function () {
          return this.customer.password;
        })
      },
      password: {
        valid: function (value) {
          if (!this.changePassword) {
            return true
          }
          const containsUppercase = /[A-Z]/.test(value)
          const containsLowercase = /[a-z]/.test(value)
          const containsNumber = /[0-9]/.test(value)
          const containsSpecial = /[#?!@$%^&*-]/.test(value)
          return containsUppercase && containsLowercase && containsNumber && containsSpecial
        },
        // minLength: minLength(6),
      },
    },
  },
};
</script>
