<template>
    <div class="product-form">
        <FormProduct form-type="edit" :detail-data="parent" @onValidateSuccess="doSave" :on-loading="onLoading" />
    </div>
</template>
<script>
import FormProduct from "./Form.vue";
import ParentProductCategory from "@/service/parentcategory.service";
import base64Converter from "@/pages/Helper/base64";

export default {
    data() {
        return {
            onLoading: false,
            parent: {
                id: this.$route.params.id,
                name: "",
                image: null,
                imageSrc: '',
            },
        };
    },
    components: {
        FormProduct,
    },
    mounted() {
        this.loadItems(this.$route.params.id)
    },
    methods: {
        async doSave(payload) {
            // Submit here
            this.onLoading = true
            let { parent, status } = payload
            if (parent) {
                payload = {
                    parent_name: parent.name,
                    icon_image: parent.image[0] ? await base64Converter(parent.image[0]) : null,
                    alt_icon_image: parent.alt_icon_image ? parent.alt_icon_image : null
                };

                ParentProductCategory.patchParentCategory(this.$route.params.id, payload).then((rsp) => {
                    this.$swal({
                        title: "Data berhasil disimpan",
                        showCloseButton: true,
                    }).then(() => {
                        this.$router.push({ path: '/products/parent_category' })
                    });
                }).catch((e) => {
                    if (e.response?.status === 409) {
                        this.$notify({
                            type: "warn",
                            title: "Error Message",
                            text: "Data Sudah Ada",
                        });
                    } else {
                        this.$notify({
                            type: "error",
                            title: "Error Message",
                            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
                        });
                    }
                }).finally(() =>
                    this.onLoading = false)
            }
        },
        loadItems(id) {
            ParentProductCategory.getParentCategoryById(id)
                .then(async ({ data: { data } }) => {
                    this.parent = {
                        id: id,
                        name: data.parent_name,
                        image: data.icon_image ? [await this.convertURLtoFile(this.loadSrcImage(data.icon_image))] : null,
                        imageSrc: data.icon_image ? this.loadSrcImage(data.icon_image) : null,
                        alt_icon_image: data.alt_icon_image ? data.alt_icon_image : null
                    }
                })
        },
        loadSrcImage(url) {
            return this.$api_name + '/' + url
        },
        async convertURLtoFile(url) {
            const response = await fetch(url);
            if (response.status != 200) {
                return "";
            }
            const data = await response.blob();
            const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
            const metadata = { type: `image/jpeg` };
            return new File([data], filename, metadata);
        },
    },
};
</script>
  