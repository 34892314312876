import api from "./api";

class MenuLocationService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('menu-location')
    }

    getMenuLocationList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postMenuLocation(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getMenuLocationById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchMenuLocation(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteMenuLocationById(serverParams) {
        return this.api.delete("/" + serverParams);
    }
}

export default new MenuLocationService();