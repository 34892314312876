import api from "./api";

class PageService {
  /** @type {ApiAxiosInstance} */
  api;

  constructor() {
    this.api = api.create('page')
  }

  getPageList(serverParams) {
    return this.api.get("/", { params: serverParams });
  }

  postPage(serverParams) {
    return this.api.post("/", { ...serverParams });
  }

  getPageById(serverParams) {
    return this.api.get("/" + serverParams);
  }

  patchPage(id, serverParams) {
    return this.api.patch("/" + id, { ...serverParams });
  }

  deletePageById(serverParams) {
    return this.api.delete("/" + serverParams);
  }

  postDuplicatePage(serverParams) {
    return this.api.post("/duplicate/" + serverParams);
  }
}

export default new PageService();