<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">All data</h1>
        <!-- END page-header -->
      </div>
      <div class="col-auto mb-3">
        <div class="row">
          <div class="col-auto">
            <a class="btn btn-info" href="/assets/BabyGrowthTemplate.xlsx" download> Template</a>
            <!-- <button class="btn btn-info" @click="downloadTemplate">Template</button> -->
          </div>
          <div class="col-auto" v-if="importBabyGrowth">
            <input id="fileUpload"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              ref="fileUpload" type="file" @change="postBabyGrowthImport" hidden>
            <button class="btn btn-dark" @click="chooseFiles">Import</button>
          </div>
          <div class="col-auto" v-if="add">
            <router-link to="/tumbuh-kembang/berat-tinggi/add">
              <a class="btn btn-outline-primary">Add New</a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
      :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
        setCurrentPage: setCurrentPage,
        perPage: serverParams.take,
      }" :columns="columns" :rows="rows" :sort-options="{
  enabled: true,
  initialSortBy: { field: 'name', type: 'asc' },
}" styleClass="vgt-table" slot="outsideBody">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <input ref="title" class="form-control" placeholder="Search.." v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'title'
          " @input="(value) => updateFilters(column, $refs.title.value)" />

        <div class="input-group" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'createdAt'
          ">
          <input type="date" ref="createdAt1" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
          <span class="input-group-text">
            -
          </span>
          <input type="date" ref="createdAt2" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
        </div>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end" v-if="props.row.status !== 'Deleted'">
            <div class="col-auto" v-if="edit">
              <router-link :to="`/tumbuh-kembang/berat-tinggi/edit/${props.row.id}`" class="btn btn-lg text-dark"
                v-b-tooltip.hover title="Edit">
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>
            <div class="col-auto" v-if="deleteBabyGrowth">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                @click.prevent="showAlertConfirm(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </span>
        <span v-else :class="[props.row.status === 'Deleted' && 'text-muted']">
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import babygrowthService from "@/service/babygrowth.service";
import statusgiziCategoryService from "@/service/gizistatuscategory.service";
import ambangbatascategoryService from "@/service/ambangbatascategory.service";
import tokenService from "@/service/token.service";
import moment from "moment";
import _ from 'lodash';
export default {
  data() {
    return {
      setCurrentPage: this.$store.state.babygrowth.serverParams.skip / this.$store.state.babygrowth.serverParams.take == 0 ?
        1 : this.$store.state.babygrowth.serverParams.skip / this.$store.state.babygrowth.serverParams.take + 1,
      permisions: tokenService.getPermission(),
      isLoading: false,
      columns: [
        {
          label: "Data Name",
          field: "title",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          sortable: false,
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Publish at",
          field: "createdAt",
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
          sortable: false,
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: this.$store.state.babygrowth.serverParams,
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.$store.state.babygrowth.serverParams = Object.assign({}, this.$store.state.babygrowth.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ skip: 0 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams();
      this.loadItems();
    },

    onColumnFilter: _.debounce(function (params) {
      let payload = {}
      if (params.columnFilters['createdAt'] && params.columnFilters['createdAt'] != '#') {
        let arrSplit = params.columnFilters['createdAt'].split('#')
        if (arrSplit[0] && arrSplit[1]) {
          payload.createdAtStartDate = arrSplit[0] ? moment(arrSplit[0]).startOf('day').toISOString() : ''
          payload.createdAtEndDate = arrSplit[1] ? moment(arrSplit[1]).endOf('day').toISOString() : ''
        }
      } else {
        delete this.$store.state.babygrowth.serverParams.createdAtStartDate
        delete this.$store.state.babygrowth.serverParams.createdAtEndDate
      }

      if (params.columnFilters['title'] && params.columnFilters['title'] != '#') {
        payload.title = params.columnFilters['title'] ? params.columnFilters['title'] : '';
      } else {
        delete this.$store.state.babygrowth.serverParams.title
      }

      this.updateParams(payload);
      this.loadItems();
    }, 300),
    loadItems() {
      this.isLoading = true;
      babygrowthService
        .getBabyGrowthList(this.$store.state.babygrowth.serverParams)
        .then(({ data: { data } }) => {
          this.rows = data.BabyGrowth
          this.totalRecords = data.totalData
        }).catch((e) => {
          // console.log()
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    async postBabyGrowthImport(e) {
      let formData = new FormData()
      formData.append('file', e.target.files[0], e.target.files[0].name)
      babygrowthService
        .postBabyGrowthImport(formData)
        .then((rsp) => {
          this.$swal({
            title: "Data berhasil ditambah",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
          this.loadItems();
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        })
    },
    chooseFiles() {
      const elem = this.$refs.fileUpload
      elem.click()
    },

    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },

    doDelete(id) {
      this.isLoading = true;
      babygrowthService
        .deleteBabyGrowthById(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
          this.loadItems();
        }).finally(() => {
          this.isLoading = false;
        })
    },
  },
  mounted() {
    this.loadItems();
  },
  computed: {
    add: function () {
      if (this.permisions.find((r) => r == 'BabyGrowth Create')) {
        return true
      }
      return false;
    },
    edit: function () {
      if (this.permisions.find((r) => r == 'BabyGrowth Patch')) {
        return true
      }
      return false;
    },
    deleteBabyGrowth: function () {
      if (this.permisions.find((r) => r == 'BabyGrowth Delete')) {
        return true
      }
      return false;
    },
    importBabyGrowth: function () {
      if (this.permisions.find((r) => r == 'BabyGrowth Import')) {
        return true
      }
      return false;
    },
  },
};
</script>
