var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row align-items-center justify-content-between mb-4"},[_vm._m(0),_c('div',{staticClass:"col-auto mb-3"},[(_vm.exportExcel)?_c('div',{staticClass:"col-auto"},[_c('a',{staticClass:"btn btn-outline-primary",on:{"click":function($event){$event.preventDefault();return _vm.downloadExcel.apply(null, arguments)}}},[_c('i',{staticClass:"fa-solid fa-file-import"}),_vm._v(" Export ")])]):_vm._e()])]),_c('vue-good-table',{attrs:{"slot":"outsideBody","mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"pagination-options":{
        enabled: true,
        dropdownAllowAll: false,
        setCurrentPage: _vm.setCurrentPage,
        perPage: _vm.serverParams.take,
      },"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
  enabled: true,
  initialSortBy: { field: 'createdAt', type: 'desc' },
},"styleClass":"vgt-table"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},slot:"outsideBody",scopedSlots:_vm._u([{key:"column-filter",fn:function(ref){
var column = ref.column;
var updateFilters = ref.updateFilters;
return [(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'nama'
          )?_c('input',{ref:"nama",staticClass:"form-control",attrs:{"placeholder":"Search.."},on:{"input":function (value) { return updateFilters(column, _vm.$refs.nama.value); }}}):_vm._e(),(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'email'
          )?_c('input',{ref:"email",staticClass:"form-control",attrs:{"placeholder":"Search.."},on:{"input":function (value) { return updateFilters(column, _vm.$refs.email.value); }}}):_vm._e(),(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'Category.name'
          )?_c('select',{ref:"Category.name",staticClass:"form-select",attrs:{"placeholder":"Cari.."},on:{"change":function (value) { return updateFilters(column, _vm.$refs['Category.name'].value); }}},[_c('option'),_vm._l((_vm.optionsCategory),function(option,key){return _c('option',{key:key,domProps:{"value":option.name,"textContent":_vm._s(option.name)}})})],2):_vm._e(),(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'is_answered'
          )?_c('select',{ref:"is_answered",staticClass:"form-select",attrs:{"placeholder":"Cari.."},on:{"change":function (value) { return updateFilters(column, _vm.$refs['is_answered'].value); }}},[_c('option'),_vm._l((_vm.optionsStatus),function(option,key){return _c('option',{key:key,domProps:{"value":option.value,"textContent":_vm._s(option.label)}})})],2):_vm._e(),(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'phone_number'
          )?_c('input',{ref:"phone_number",staticClass:"form-control",attrs:{"placeholder":"Search.."},on:{"input":function (value) { return updateFilters(column, _vm.$refs.phone_number.value); }}}):_vm._e(),(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'title'
          )?_c('input',{ref:"title",staticClass:"form-control",attrs:{"placeholder":"Search.."},on:{"input":function (value) { return updateFilters(column, _vm.$refs.title.value); }}}):_vm._e(),(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'createdAt')?_c('div',{staticClass:"input-group"},[_c('input',{ref:"createdAt1",staticClass:"form-control",attrs:{"type":"date","placeholder":"Search product"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.createdAt1.value + '#' + _vm.$refs.createdAt2.value); }}}),_c('span',{staticClass:"input-group-text"},[_vm._v(" - ")]),_c('input',{ref:"createdAt2",staticClass:"form-control",attrs:{"type":"date","placeholder":"Search product"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.createdAt1.value + '#' + _vm.$refs.createdAt2.value); }}})]):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[(props.row.status !== 'Deleted')?_c('div',{staticClass:"row g-2 align-items-center justify-content-end"},[(_vm.edit)?_c('div',{staticClass:"col-auto"},[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"to":("/pages/nutri-expert/edit/" + (props.row.id)),"title":"Edit"}},[_c('i',{staticClass:"fas fa-pencil"})])],1):_vm._e(),(_vm.deletePage)?_c('div',{staticClass:"col-auto"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"type":"button","title":"Move to Trash"},on:{"click":function($event){$event.preventDefault();return _vm.showAlertConfirm(props.row.id)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()]):_vm._e()]):_c('span',{class:[props.row.status === 'Deleted' && 'text-muted']},[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-auto"},[_c('h1',{staticClass:"page-header"},[_vm._v("Question List Tanya GOON")])])}]

export { render, staticRenderFns }