class TokenService {
    getLocalRefreshToken() {
        const user = JSON.parse(localStorage.getItem("user"));
        return user?.refreshToken;
    }

    getLocalAccessToken() {
        const user = JSON.parse(localStorage.getItem("user"));
        return user?.accessToken;
    }

    updateLocalAccessToken(token, refreshToken) {
        let user = JSON.parse(localStorage.getItem("user"));
        user.accessToken = token;
        user.refreshToken = refreshToken
        localStorage.setItem("user", JSON.stringify(user));
    }

    getUser() {
        return JSON.parse(localStorage.getItem("user"));
    }

    setUser(user) {
        localStorage.setItem("user", JSON.stringify(user));
    }

    removeUser() {
        localStorage.removeItem("user");
    }

    getLocalPermission() {
        const user = JSON.parse(localStorage.getItem("user")).role_cms.permission;
        const permission = JSON.parse(user);
        let arrData = {}
        for (const [k, v] of Object.entries(permission)) {
            let nv = v.split(' ')
            for (const [k1, v1] of Object.entries(nv)) {
                switch (k1) {
                    case '0':
                        if (!arrData[nv[0]]) {
                            let data = { [v1]: [] }
                            Object.assign(arrData, data)
                        }
                        break;
                    default:
                        arrData[nv[0]].push(v1)
                        break;
                }
            }
        }
        return arrData;
    }

    getPermission() {
        const user = JSON.parse(localStorage.getItem("user")).role_cms.permission;
        const permission = JSON.parse(user);
        return permission
    }
}

export default new TokenService();