import Vue from "vue";
import VueX from "vuex";
import routes from "./config/AppRoutes";
import VueJwtDecode from 'vue-jwt-decode'
import vueDebounce from 'vue-debounce'

// plugins
import VueRouter from "vue-router";
import VueBootstrap from "bootstrap-vue";
import VueNVD3 from "vue-nvd3";
import VueInsProgressBar from "vue-ins-progress-bar";
import VueEventCalendar from "vue-event-calendar";
import VueSparkline from "vue-sparklines";
import VueSweetalert2 from "vue-sweetalert2";
import VueNotification from "vue-notification";
import VuePanel from "./plugins/panel/";
import VueUploadImage from "./plugins/upload-image/";
import VueDateTimePicker from "vue-bootstrap-datetimepicker";
import VueSelect from "vue-select";
import VueDatepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import VueMaskedInput from "vue-maskedinput";
import VueInputTag from "vue-input-tag";
import VueSlider from "vue-slider-component";
import VueGoodTable from "vue-good-table";
import VueCountdown from "@chenfengyuan/vue-countdown";
import VueColorpicker from "vue-pop-colorpicker";
import VueCustomScrollbar from "vue-custom-scrollbar";
import VueApexCharts from "vue-apexcharts";
import DateRangePicker from "vue2-daterange-picker";
import Vuelidate from "vuelidate";
import vSelect from 'vue-select'
import moment from 'moment'
import { VueEditor } from "vue2-editor";
import TokenService from '@/service/token.service';
import CKEditor from 'ckeditor4-vue';
import interceptor from '@/service/interceptor.service';
import api from '@/service/api';
import InnerImageZoom from 'vue-inner-image-zoom';
import VueZoomer from 'vue-zoomer'
import VueViewer from 'v-viewer'

// plugins css
import 'viewerjs/dist/viewer.css'
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
import "bootstrap-vue/dist/bootstrap-vue.css";
import "nvd3/build/nv.d3.min.css";
import "vue-event-calendar/dist/style.css";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import "simple-line-icons/css/simple-line-icons.css";
import "flag-icon-css/css/flag-icon.min.css";
import "ionicons/dist/css/ionicons.min.css";
import "vue-good-table/dist/vue-good-table.css";
import "vue-select/dist/vue-select.css";
import "vue-slider-component/theme/antd.css";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import 'vue-select/dist/vue-select.css';
import { VueReCaptcha } from 'vue-recaptcha-v3'

// color admin css
import "./scss/vue.scss";
import "bootstrap-social/bootstrap-social.css";

import { store } from "./stores";
import App from "./App.vue";

Vue.config.productionTip = false;

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_CAPTCHA_SITE_KEY,
  loaderOptions: {
    autoHideBadge: (TokenService.getUser()) ? true : false
  }
})
Vue.use(VueViewer)
Vue.use(VueZoomer)
Vue.use(CKEditor);
Vue.use(VueX);
Vue.use(Vuelidate);
Vue.use(VueRouter);
Vue.use(VueBootstrap);
Vue.use(VueNVD3);
Vue.use(VueEventCalendar, { locale: "en" });
Vue.use(VueSparkline);
Vue.use(VueSweetalert2);
Vue.use(VueNotification);
Vue.use(VuePanel);
Vue.use(VueUploadImage);
Vue.use(VueDateTimePicker);
Vue.use(VueGoodTable);
Vue.use(VueColorpicker);
Vue.use(VueInsProgressBar, {
  position: "fixed",
  show: true,
  height: "3px",
});
// Vue.component('inner-image-zoom', InnerImageZoom)
Vue.component("v-select", VueSelect);
Vue.component("datepicker", VueDatepicker);
Vue.component("masked-input", VueMaskedInput);
Vue.component("input-tag", VueInputTag);
Vue.component("vue-slider", VueSlider);
Vue.component("vue-custom-scrollbar", VueCustomScrollbar);
Vue.component("apexchart", VueApexCharts);
Vue.component("date-range-picker", DateRangePicker);
Vue.component(VueCountdown.name, VueCountdown);
Vue.component('v-select', vSelect)
Vue.component('vue-editor', VueEditor)
Vue.use(vueDebounce, {
  defaultTime: '700ms'
})

const router = new VueRouter({
  mode: "history",
  routes,
});

Vue.prototype.$http = api;
Vue.prototype.$api_name = process.env.VUE_APP_API

// interceptor()

router.beforeEach((to, from, next) => {
  if (!!TokenService.getUser()) {
    let { accessToken } = TokenService.getUser()
    let { exp } = VueJwtDecode.decode(accessToken)

    if (Date.now() >= exp * 1000) {
      location.reload()
      TokenService.removeUser()
    }

    if (to.path === "/login" || to.path === '/') {
      // console.log('Already login in router');
      return next("/dashboard");
    } else {
      // Check permission on all matched route
      return next();
    }
  } else {
    if (to.path === "/login" || to.path.includes('/password')) {
      next();
    } else {
      next("/login");
    }
  }
})

new Vue({
  render: (h) => h(App),
  router,
  store
}).$mount("#app")
