import api from "./api";

class HomepageService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('homepage-management')
    }

    getHomepageList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postHomepage(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getHomepageById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchHomepage(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteHomepageById(serverParams) {
        return this.api.delete("/" + serverParams);
    }
}

export default new HomepageService();