import axios from "axios";

class CacheService {
    // /** @type {ApiAxiosInstance} */
    // api;

    // constructor() {
    //     this.api = api.create('apply-career')
    // }

    postCache() {
        let data = '';

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            headers: {},
            data: data
        };

        return axios.request(config);
    }
}

export default new CacheService();