<template>
  <form @submit.prevent="doSave" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          Question List Tanya GOON
        </h1>
      </div>
      <div class="col-lg-12 col-md-12">
        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Nama</label>
                  <input type="text" class="form-control" placeholder="Name" v-model="form.nama">
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-15px">
                  <label class="form-label">Email</label>
                  <input type="email" class="form-control" placeholder="Anonymous@gmail.com" v-model="form.email">
                </div>
              </div>
              <div class="col-md-4">
                <label class="form-label">No. Telp</label>
                <div class="input-group">
                  <span class="input-group-text">+62</span>
                  <input type="text" class="form-control" v-model="form.phone_number" :maxlength="11"
                    v-on:keypress="isNumber" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-15px">
                  <label class="form-label">Pertanyaan dari user</label>
                  <input type="text" class="form-control" placeholder="" v-model="form.title">
                </div>
              </div>
              <div class="col-md-9">
                <div class="mb-15px">
                  <label class="form-label">Category</label>
                  <v-select :options="optionsCategory" placeholder="Type" v-model="form.Category" label="name" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="mb-15px">
                  <label class="form-label"></label>
                  <div class="form-check">
                    <input class="form-check-input mb-15px" type="checkbox" v-model="form.is_name_displayed"
                      id="flexCheckDefault" disabled>
                    <label class="form-check-label" for="flexCheckDefault">
                      <b>Show Name</b>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <label class="form-label">Isi Jawaban</label>
                <ckeditor :editor-toolbar="customToolbar" v-model="form.answer" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-action">
      <div class="row justify-content-end">
        <!-- <div class="col-auto">
          <button type="button" class="btn btn-outline-primary">
            Save as Draft
          </button>
        </div> -->
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defaultToolbar } from "../../../config/Helpers";
import UploadImage from "../../../components/upload/Image.vue";
import faqCategoryService from "@/service/faqcategory.service";

export default {
  components: {
    UploadImage,
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.loadCategory()
    // console.log(this.detailData);
  },
  data() {
    const detail = this.detailData;
    const defaultToolbarEditor = defaultToolbar;

    return {
      optionsCategory: [],
      options: {
        format: "DD/MM/YYYY",
        useCurrent: true,
      },
      customToolbar: defaultToolbarEditor,
      form: {
        title: detail ? detail.title : null,
        category: null,
        description: '',
      },
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
  },
  watch: {
    detailData(newVal) {
      this.form = newVal;
    },
  },
  methods: {
    doSave() {
      const payload = {
        form: this.form,
      };
      // console.log(payload);

      this.$emit("onValidateSuccess", payload);
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    loadCategory() {
      this.isLoading = true;
      faqCategoryService
        .getFaqCategoryList(this.serverParams)
        .then(({ data: { data } }) => {
          this.optionsCategory = data.FaqCategory
        }).catch((e) => {
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    }
  },
};
</script>
