import api from "./api";

class CustomerService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('customer')
    }

    getCustomerList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postCustomer(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getCustomerById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchCustomer(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteCustomerById(serverParams) {
        return this.api.delete("/" + serverParams);
    }

    exportExcel(serverParams) {
        return this.api.get("/export", { params: serverParams });
    }
}

export default new CustomerService();