import api from "./api";

class AboutUsService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('about-us')
    }

    getAboutUsList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postAboutUs(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getAboutUsById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchAboutUs(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteAboutUsById(serverParams) {
        return this.api.delete("/" + serverParams);
    }
}

export default new AboutUsService();