<template>
  <div>
    <div class="row align-items-center justify-content-between mb-4">
      <div class="col-auto">
        <!-- BEGIN page-header -->
        <h1 class="page-header">Customer List</h1>
        <!-- END page-header -->

        <div class="col-auto" v-if="exportExcel">
          <a class="btn btn-outline-primary" @click.prevent="downloadExcel">
            <i class="fa-solid fa-file-import"></i>
            Export
          </a>
        </div>
      </div>
      <div class="col-auto mb-3" v-if="add">
        <router-link to="/customer/add">
          <a class="btn btn-outline-primary">Add New Customer</a>
        </router-link>
      </div>

    </div>
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords" compactMode
      row-style-class="vgt-absolute" :isLoading.sync="isLoading" :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
        setCurrentPage: setCurrentPage,
        perPage: serverParams.take,
      }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false, initialSortBy: { field: 'full_name', type: 'asc' },
}" styleClass="vgt-table vgt-absolute">
      <template slot="column-filter" slot-scope="{ column, updateFilters }">
        <div class="input-group" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'createdAt'
          ">
          <input type="date" ref="createdAt1" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
          <span class="input-group-text">
            -
          </span>
          <input type="date" ref="createdAt2" class="form-control" placeholder="Search product"
            @input="(value) => updateFilters(column, $refs.createdAt1.value + '#' + $refs.createdAt2.value)" />
        </div>

        <input ref="full_name" class="form-control" placeholder="Search Full Name" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'full_name'
          " @input="(value) => updateFilters(column, $refs.full_name.value)" />
        <input type="number" ref="phoneNumber" class="form-control" placeholder="Search Phone Number" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'phoneNumber'
          " @input="(value) => updateFilters(column, $refs.phoneNumber.value)" />
        <input type="number" ref="customerPoint" class="form-control" placeholder="Search Balance Point" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'customerPoint'
          " @input="(value) => updateFilters(column, $refs.customerPoint.value)" />
        <input ref="email" class="form-control" placeholder="Search Email" v-if="column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'email'
          " @input="(value) => updateFilters(column, $refs.email.value)" />
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'">
          <div class="row g-2 align-items-center justify-content-end" v-if="props.row.status !== 'Deleted'">
            <div class="col-auto">
              <router-link :to="`/customer/edit/${props.row.id}`" class="btn btn-lg text-dark" v-b-tooltip.hover
                title="Profile">
                <i class="fas fa-pencil"></i>
              </router-link>
            </div>

            <div class="col-auto" v-if="deleteCustomer">
              <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Move to Trash"
                @click="showAlertConfirm(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
            <!-- <div class="col-auto">
              <router-link :to="`/customer/loyalty/${props.row.id}`" class="btn btn-lg text-dark" v-b-tooltip.hover
                title="Loyalty">
                <i class="fa-solid fa-circle-exclamation"></i>
              </router-link>
            </div> -->
          </div>
        </span>
        <span v-else :class="[props.row.status === 'Deleted' && 'text-muted']">
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import CustomerService from "@/service/customer.service";
import tokenService from "@/service/token.service";
import moment from "moment";
import formatUtc from "@/pages/Helper/formatUTC";
import _ from 'lodash';
export default {
  data() {
    return {
      setCurrentPage: this.$store.state.customer.serverParams.skip / this.$store.state.customer.serverParams.take == 0 ?
        1 : this.$store.state.customer.serverParams.skip / this.$store.state.customer.serverParams.take + 1,
      dateRange: {
        opens: "right",
        singleDatePicker: false,
        timePicker: false,
        timePicker24Hour: false,
        showWeekNumbers: false,
        showDropdowns: false,
        autoApply: false,
        linkedCalendars: false,
        range: {
          startDate: Date.now(),
          endDate: Date.now(),
        },
      },
      isLoading: false,
      columns: [
        {
          label: "Customer Name",
          field: "full_name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Nomor HP",
          field: 'phoneNumber',
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Email",
          field: 'email',
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Balance Point",
          field: "customerPoint",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
        },
        {
          label: "Register At",
          field: "createdAt",
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
          filterOptions: {
            styleClass: 'vgt-relative',
            customFilter: true,
            enabled: true,
            filterValue: "",
            filterFn: this.filterFn,
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "text-end text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: this.$store.state.customer.serverParams,
      permisions: tokenService.getPermission(),
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
    };
  },
  computed: {
    exportExcel: function () {
      if (this.permisions.find((r) => r == 'Customer Export')) {
        return true
      }
      return false;
    },
    add: function () {
      if (this.permisions.find((r) => r == 'Customer Add')) {
        return true
      }
      return false;
    },
    deleteCustomer: function () {
      if (this.permisions.find((r) => r == 'Customer Delete')) {
        return true
      }
      return false;
    },
  },
  methods: {
    dateFormat(classes, date) {
      return moment(date).format('DD/MM/YYYY')
    },
    statusClass(row) {
      if (row.status === 'Diterima') {
        return 'bg-green text-white align-middle';
      }
      return 'bg-orange text-white align-middle';
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.$store.state.customer.serverParams = Object.assign({}, this.$store.state.customer.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ skip: 0 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams();
      this.loadItems();
    },

    onColumnFilter: _.debounce(function (params) {
      let payload = {}
      if (params.columnFilters['createdAt'] && params.columnFilters['createdAt'] != '#') {
        let arrSplit = params.columnFilters['createdAt'].split('#')
        if (arrSplit[0] && arrSplit[1]) {
          let { startDate, endDate } = formatUtc(arrSplit[0],arrSplit[1])
          payload.createdAtStartDate = arrSplit[0] ? startDate : ''
          payload.createdAtEndDate = arrSplit[1] ? endDate : ''
        }
      } else {
        delete this.$store.state.customer.serverParams.createdAtStartDate
        delete this.$store.state.customer.serverParams.createdAtEndDate
      }

      if (params.columnFilters.full_name != '') {
        payload.full_name = params.columnFilters.full_name
      } else {
        delete this.$store.state.customer.serverParams.full_name;
      }

      if (params.columnFilters.phoneNumber != '') {
        payload.phoneNumber = params.columnFilters.phoneNumber
      } else {
        delete this.$store.state.customer.serverParams.phoneNumber;
      }

      if (params.columnFilters.email != '') {
        payload.email = params.columnFilters.email
      } else {
        delete this.$store.state.customer.serverParams.email;
      }

      if (params.columnFilters.customerPoint != '') {
        payload.customerPoint = params.columnFilters.customerPoint
      } else {
        delete this.$store.state.customer.serverParams.customerPoint;
      }

      this.updateParams(payload);
      this.loadItems();
    }, 300),

    loadItems() {
      this.isLoading = true;
      CustomerService
        .getCustomerList(this.$store.state.customer.serverParams)
        .then(({ data: { data } }) => {
          this.rows = data?.customer
          this.totalRecords = data?.totalData
        }).catch((e) => {
          if (e.response?.status !== 404) {
            // console.log(e)
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    downloadExcel() {
      this.isLoading = true;

      let { take, skip, sort, ...NewParams } = this.serverParams
      let payload = {
        ...NewParams
      }
      CustomerService.exportExcel(payload)
        .then(({ data: { data } }) => {
          let { path } = data
          if (path) {
            window.open(this.$api_name + '/' + path, '_blank')
          }
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },

    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },
    doDelete(id) {
      this.isLoading = true;
      CustomerService
        .deleteCustomerById(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },
  },
  mounted() {
    this.loadItems();
  },
};
</script>
<style>
.vgt-table {
  overflow-y: auto;
  height: 100px;
}
</style>
