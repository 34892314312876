<template>
    <div>
      <h1 class="page-header"><span v-if="isEdit">Edit</span> Survey Category</h1>
      <div class="row">
        <div class="col-md-6">
          <div class="panel">
            <div class="panel-body">
              <form @submit.prevent="doSave">
                <div class="row align-items-end">
                  <div class="col">
                    <label class="form-label">
                      <span v-if="!isEdit && add">Add</span>
                      <span v-else>Edit</span>
                      Category Name
                    </label>
                    <input type="text" class="form-control" placeholder="Add New Category Name" v-model="category" />
                    <span class="text-danger" v-if="$v.category.$error">Category Name is
                      required</span>
                    <br v-if="!$v.category.$error">
                  </div>
                  <div class="col">
                    <label class="form-label"><span v-if="!isEdit">Add</span><span v-else>Edit</span> Parent
                      Category</label>
                    <v-select :options="optionsParentCategory" v-model="parentCategory" name="parentCategory"
                      label="parent_name" />
                    <span class="text-danger" v-if="$v.parentCategory.value.$error">Parent Category is
                      required</span>
                    <br v-if="!$v.parentCategory.$error">
                  </div>
                  <div class="col-auto" v-if="add || edit">
                    <button type="submit" class="btn btn-outline-primary">
                      <span v-if="!isEdit && add">Add</span>
                      <span v-else>Save</span></button>
                    <br>
                    <label class="form-label"></label>
                  </div>
                  <div class="col-auto">
                    <button :disabled="!isEdit" type="submit" class="btn btn-outline-primary" @click.prevent="doCancel">
                      <span>Cancel</span>
                    </button>
                    <br>
                    <label class="form-label"></label>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
            :isLoading.sync="isLoading" :pagination-options="{
              enabled: true,
              dropdownAllowAll: false,
            }" :columns="columns" :rows="rows" :sort-options="{
    enabled: false,
    initialSortBy: { field: 'category_name', type: 'asc' },
  }" styleClass="vgt-table" slot="outsideBody">
            <template slot="column-filter" slot-scope="{ column, updateFilters }">
              <input ref="category_name" class="form-control" placeholder="Search category" v-if="column.filterOptions &&
                column.filterOptions.customFilter &&
                column.field === 'category_name'
                " @input="(value) => updateFilters(column, $refs.category_name.value)" />
              <select ref="parent_product_category.parent_name" class="form-select" placeholder="Cari productCategory" v-if="column.filterOptions &&
                column.filterOptions.customFilter &&
                column.field === 'parent_product_category.parent_name'
                " @change="(value) => updateFilters(column, $refs['parent_product_category.parent_name'].value)">
                <option />
                <option v-for="(option, key) in optionsParentCategory" v-bind:value="option.parent_name" :key="key"
                  v-text="option.parent_name" />
              </select>
            </template>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'action'">
                <div class="row g-2 align-items-center justify-content-end">
                  <div class="col-auto">
                    <button type="button" class="btn btn-lg text-dark" @click="doEdit(props.row)" v-b-tooltip.hover
                      title="Edit">
                      <i class="fas fa-pencil"></i>
                    </button>
                  </div>
                  <div class="col-auto">
                    <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Delete"
                      @click.prevent="showAlertConfirm(props.row.id)">
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </div>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { required } from 'vuelidate/lib/validators'
  import ProductCategoryService from "@/service/productcategory.service";
  import parentcategoryService from '@/service/parentcategory.service';
  import tokenService from '@/service/token.service';
  export default {
    data() {
      return {
        category: "",
        optionsParentCategory: [],
        parentCategory: null,
        isEdit: false,
        isLoading: false,
        columns: [
          {
            label: "Category Name",
            field: "category_name",
            thClass: "text-nowrap",
            tdClass: "text-nowrap align-middle",
            filterOptions: {
              customFilter: true,
              enabled: true,
              filterValue: "",
            },
          },
          {
            label: "Parent Category",
            field: "parent_product_category.parent_name",
            thClass: "text-nowrap",
            tdClass: "text-nowrap align-middle",
            filterOptions: {
              customFilter: true,
              enabled: true,
              filterValue: "",
            },
          },
          {
            label: "Action",
            field: "action",
            width: "200px",
            sortable: false,
            thClass: "text-center text-nowrap",
            tdClass: "text-center text-nowrap",
          },
        ],
        rows: [],
        totalRecords: 0,
        serverParams: {
          search: '',
          take: 10,
          skip: 0,
          sort: 'desc'
        },
        errorCode: "",
        errorMessage: "",
        error: "",
        forbidenError: false,
        permisions: tokenService.getPermission()
      };
    },
    methods: {
      scrollToTop() {
        window.scrollTo(0, 0);
      },
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
  
      onPageChange(params) {
        this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
        this.loadItems();
      },
      onPerPageChange(params) {
        this.updateParams({ skip: 0 });
        this.updateParams({ take: params.currentPerPage });
        this.loadItems();
      },
  
      onSortChange(params) {
        this.updateParams({});
        this.loadItems();
      },
  
      onColumnFilter(params) {
        let payload = {}
        if (params.columnFilters['parent_product_category.parent_name'] && params.columnFilters['parent_product_category.parent_name'] != '') {
          payload.parent_product_category = params.columnFilters['parent_product_category.parent_name'] ? params.columnFilters['parent_product_category.parent_name'] : ''
        } else {
          delete this.serverParams.parent_product_category
        }
        payload.search = params.columnFilters.category_name ? params.columnFilters.category_name : ''
        this.updateParams(payload);
        this.loadItems();
      },
      loadItems() {
        this.isLoading = true;
        ProductCategoryService
          .getProductCategoryList(this.serverParams)
          .then(({ data: { data } }) => {
            if (data.productCategory) {
              this.rows = data.productCategory
              this.totalRecords = data.totalData
            } else {
              this.rows = []
              this.totalRecords = 0
            }
          }).catch((e) => {
            if (e.response?.status !== 404) {
              this.$notify({
                type: "error",
                title: "Error Message",
                text: e.response?.data?.message || "Terjadi Kesalahan",
              });
            } else {
              this.rows = []
            }
          }).finally(() => {
            this.isLoading = false;
          })
      },
      doSave() {
        this.$v.category.$touch();
  
        if (!this.$v.category.$invalid) {
          const payload = {
            category_name: this.category,
            parent_product_category_id: this.parentCategory.id
          };
  
          if (this.isEdit) {
            this.editRequest(payload)
          } else if (this.add) {
            this.addRequest(payload)
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: "Harap Pilih Dulu",
            });
          }
        }
      },
  
      doCancel() {
        this.isEdit = false
        this.category = ''
        this.id = ''
        this.parentCategory = ''
        this.$v.$reset();
      },
  
      doEdit(data) {
        this.isEdit = true
        this.category = data.category_name
        this.parentCategory = data.parent_product_category
        this.id = data.id
      },
  
      addRequest(req) {
        this.isLoading = true;
        ProductCategoryService
          .postProductCategory(req)
          .then((response) => {
            this.$swal({
              title: "Data berhasil disimpan",
              showCloseButton: true,
            }).then(() => {
              this.loadItems();
            });
          }).catch((e) => {
            if (e.response?.status === 409) {
              this.$notify({
                type: "warn",
                title: "Error Message",
                text: "Data Sudah Ada",
              });
            } else {
              this.$notify({
                type: "error",
                title: "Error Message",
                text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
              });
            }
          }).finally(() => {
            this.isLoading = false;
            this.doCancel()
          })
      },
      editRequest(req) {
        this.isLoading = true;
        ProductCategoryService
          .patchProductCategory(this.id, req)
          .then((response) => {
            this.$swal({
              title: "Data berhasil disimpan",
              showCloseButton: true,
            }).then(() => {
              this.loadItems();
            });
          }).catch((e) => {
            if (e.response?.status === 409) {
              this.$notify({
                type: "warn",
                title: "Error Message",
                text: "Data Sudah Ada",
              });
            } else {
              this.$notify({
                type: "error",
                title: "Error Message " + e.response?.data?.error,
                text: e.response?.data?.message || "Terjadi Kesalahan",
              });
            }
          }).finally(() => {
            this.isLoading = false;
            this.doCancel()
          })
      },
      showAlertConfirm(id) {
        this.$swal
          .fire({
            title: "",
            text: "Apakah Data Akan Di Hapus?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ya",
          })
          .then(async (result) => {
            if (result.value) {
              await this.doDelete(id);
            }
          });
      },
      doDelete(id) {
        this.isLoading = true;
        ProductCategoryService
          .deleteProductCategoryById(id)
          .then((response) => {
            this.$swal({
              title: "Data berhasil dihapus",
              showCloseButton: true,
            }).then(() => {
              this.loadItems();
            });
          }).catch((e) => {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          }).finally(() => {
            this.isLoading = false;
            this.doCancel()
          })
      },
  
      loadItemsParent() {
        this.isLoading = true;
        parentcategoryService
          .getParentCategoryList({})
          .then(({ data: { data } }) => {
            if (data.ParentProductCategory) {
              this.optionsParentCategory = data.ParentProductCategory
            } else {
              this.optionsParentCategory = []
            }
          }).catch((e) => {
            if (e.response?.status !== 404) {
              this.$notify({
                type: "error",
                title: "Error Message",
                text: e.response?.data?.message || "Terjadi Kesalahan",
              });
            } else {
              this.rows = []
            }
          }).finally(() => {
            this.isLoading = false;
          })
      },
    },
    computed: {
      add: function () {
        if (this.permisions.find((r) => r == 'ProductType Add')) {
          return true
        }
        return false;
      },
      edit: function () {
        if (this.permisions.find((r) => r == 'ProductType Patch')) {
          return true
        }
        return false;
      },
      deleteProductType: function () {
        if (this.permisions.find((r) => r == 'ProductType Delete')) {
          return true
        }
        return false;
      },
    },
    mounted() {
      this.loadItems();
      this.loadItemsParent();
    },
    validations: {
      parentCategory: {
        required,
        value: { required }
      },
      category: { required },
    }
  };
  </script>
  