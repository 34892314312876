import api from "./api";

class RewardService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('reward-catalogue')
    }
    
    getRewardList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postReward(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getRewardById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchReward(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteRewardById(serverParams) {
        return this.api.delete("/" + serverParams);
    }
}

export default new RewardService();