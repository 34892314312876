<template>
  <div>
    <!-- <h1 class="page-header"><span v-if="isEdit">Edit</span>Parent Product Category</h1> -->
    <div class="row">
      <div class="row align-items-center justify-content-between mb-4">
        <div class="col-auto">
          <!-- BEGIN page-header -->
          <h1 class="page-header">Parent Category List</h1>
          <!-- END page-header -->
        </div>
        <div class="col-auto mb-3" v-if="add">
          <router-link to="/products/parent_category/add">
            <a class="btn btn-outline-primary">Add New Parent Category</a>
          </router-link>
        </div>
      </div>
      <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
        :isLoading.sync="isLoading" :pagination-options="{
          enabled: true,
          dropdownAllowAll: false,
          setCurrentPage: setCurrentPage,
          perPage: serverParams.take
        }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'parent_name', type: 'asc' },
}" styleClass="vgt-table" slot="outsideBody">
        <template slot="column-filter" slot-scope="{ column, updateFilters }">
          <input ref="parent_name" class="form-control" placeholder="Search Parent Category" v-if="column.filterOptions &&
            column.filterOptions.customFilter &&
            column.field === 'parent_name'
            " @input="(value) => updateFilters(column, $refs.parent_name.value)" />
        </template>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'action'">
            <div class="row g-2 align-items-center justify-content-end">
              <div class="col-auto" v-if="edit">
                <router-link :to="`/products/parent_category/edit/${props.row.id}`" class="btn btn-lg text-dark"
                  v-b-tooltip.hover title="Edit">
                  <i class="fas fa-pencil"></i>
                </router-link>
              </div>
              <div class="col-auto" v-if="deleteParentProductCategory">
                <button type="button" class="btn btn-lg text-dark" v-b-tooltip.hover title="Delete"
                  @click.prevent="showAlertConfirm(props.row.id)">
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </div>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import ProductCategoryService from "@/service/productcategory.service";
import parentcategoryService from '@/service/parentcategory.service';
import tokenService from '@/service/token.service';
import _ from 'lodash';
export default {
  data() {
    return {
      setCurrentPage: this.$store.state.parentproductcategory.serverParams.skip / this.$store.state.parentproductcategory.serverParams.take == 0 ?
        1 : this.$store.state.parentproductcategory.serverParams.skip / this.$store.state.parentproductcategory.serverParams.take + 1,
      category: "",
      parentCategory: null,
      isEdit: false,
      isLoading: false,
      columns: [
        {
          label: "Category Name",
          field: "parent_name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
          filterOptions: {
            customFilter: true,
            enabled: true,
            filterValue: "",
          },
        },
        {
          label: "Action",
          field: "action",
          width: "200px",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: this.$store.state.parentproductcategory.serverParams,
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
      permisions: tokenService.getPermission()
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateParams(newProps) {
      this.$store.state.parentproductcategory.serverParams = Object.assign({}, this.$store.state.parentproductcategory.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ skip: 0 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({});
      this.loadItems();
    },

    onColumnFilter: _.debounce(function (params) {
      let payload = { search: params.columnFilters.parent_name ? params.columnFilters.parent_name : '' }
      this.updateParams(payload);
      this.loadItems();
    }, 300),
    loadItems() {
      this.isLoading = true;
      parentcategoryService
        .getParentCategoryList(this.$store.state.parentproductcategory.serverParams)
        .then(({ data: { data } }) => {
          if (data.ParentProductCategory) {
            this.rows = data.ParentProductCategory
            this.totalRecords = data.totalData
          } else {
            this.rows = []
            this.totalRecords = 0
          }
        }).catch((e) => {
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    doSave() {
      this.$v.category.$touch();

      if (!this.$v.category.$invalid) {
        const payload = {
          parent_name: this.category,
        };

        if (this.isEdit) {
          this.editRequest(payload)
        } else {
          this.addRequest(payload)
        }
      }
    },

    doCancel() {
      this.isEdit = false
      this.category = ''
      this.id = ''
      this.parentCategory = ''
      this.$v.$reset();
    },

    doEdit(data) {
      this.isEdit = true
      this.category = data.parent_name
      this.id = data.id
    },

    addRequest(req) {
      this.isLoading = true;
      parentcategoryService
        .postParentCategory(req)
        .then((response) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.responseMessage || "Terjadi Kesalahan",
            });
          }
        }).finally(() => {
          this.isLoading = false;
          this.doCancel()
        })
    },
    editRequest(req) {
      this.isLoading = true;
      parentcategoryService
        .patchParentCategory(this.id, req)
        .then((response) => {
          this.$swal({
            title: "Data berhasil disimpan",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          if (e.response?.status === 409) {
            this.$notify({
              type: "warn",
              title: "Error Message",
              text: "Data Sudah Ada",
            });
          } else {
            this.$notify({
              type: "error",
              title: "Error Message " + e.response?.data?.error,
              text: e.response?.data?.message || "Terjadi Kesalahan",
            });
          }
        }).finally(() => {
          this.isLoading = false;
          this.doCancel()
        })
    },
    showAlertConfirm(id) {
      this.$swal
        .fire({
          title: "",
          text: "Apakah Data Akan Di Hapus?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          if (result.value) {
            await this.doDelete(id);
          }
        });
    },
    doDelete(id) {
      this.isLoading = true;
      parentcategoryService
        .deleteParentCategoryById(id)
        .then((response) => {
          this.$swal({
            title: "Data berhasil dihapus",
            showCloseButton: true,
          }).then(() => {
            this.loadItems();
          });
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
          this.doCancel()
        })
    }
  },
  computed: {
    add: function () {
      if (this.permisions.find((r) => r == 'ParentProductCategory Add')) {
        return true
      }
      return false;
    },
    edit: function () {
      if (this.permisions.find((r) => r == 'ParentProductCategory Patch')) {
        return true
      }
      return false;
    },
    deleteParentProductCategory: function () {
      if (this.permisions.find((r) => r == 'ParentProductCategory Delete')) {
        return true
      }
      return false;
    },
  },
  mounted() {
    this.loadItems();
  },
  validations: {
    parentCategory: {
      required,
      value: { required }
    },
    category: { required },
  }
};
</script>
