<template>
  <form @submit.prevent="doSave" class="pb-5 mb-4">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          <button class="btn btn-primary" @click.prevent="$router.go(-1)">
            <span v-show="onLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          Loyalty Poin
        </h1>
      </div>
      <div class="col-lg-12">
        <customer-nav />
        <div class="panel">
          <div class="panel-body">
            <div class="card">
              <div class="card-body">
                <h6>{{ name }}</h6>
                <h2>{{ totalPoint }} Loyalty Poin</h2>
              </div>
            </div>
          </div>
        </div>

        <h3 class="fw-normal mb-3">Poin History</h3>
        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords"
          :isLoading.sync="isLoading" :pagination-options="{
            enabled: false,
            dropdownAllowAll: false,
          }" :columns="columns" :rows="rows" :sort-options="{
  enabled: false,
  initialSortBy: { field: 'created', type: 'desc' },
}" styleClass="vgt-table" slot="outsideBody" :line-numbers="true">
          <template slot="column-filter" slot-scope="{ column, updateFilters }">
            <input ref="name" class="form-control" placeholder="Search catalogue" v-if="column.filterOptions &&
              column.filterOptions.customFilter &&
              column.field === 'name'
              " @input="(value) => updateFilters(column, $refs.name.value)" />
          </template>
          <template slot="table-row" slot-scope="props">
            <span>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </form>
</template>

<script>
import CustomerNav from './Nav.vue'
import pointHistoryService from "@/service/pointhistory.service";
import CustomerService from "@/service/customer.service";
import RedeemService from "@/service/redeem.service";
import receiptService from '@/service/receipt.service';
export default {
  components: {
    CustomerNav,
  },
  props: {
    formType: {
      type: String,
      default: "add",
    },
    detailData: {
      type: Object,
      default: null,
    },
    onLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // console.log(this.detailData);
  },
  data() {
    const detail = this.detailData;
    return {
      form: {},
      isLoading: false,
      totalPoint: 0,
      optionsDescriptions: [
        { value: 'read_article', label: 'Read Article' },
        { value: 'refferal', label: 'Refferal' },
        { value: 'update_profile', label: 'Update Profile' },
        { value: 'child_birthday', label: 'Child Birthday' },
        { value: 'upload_receipt', label: 'Customer Claim Point' },
        { value: 'customer_claim_reward', label: 'Customer Redeem Reward' },
        { value: 'admin_approve_free_sample_cms', label: 'Customer Claim Free Sample' },
        { value: 'admin_reject_free_sample_cms', label: 'Customer Claim Free Sample' },
        { value: 'admin_approve_receipt_cms', label: 'Customer Claim Point [checked by admin]' },
        { value: 'admin_reject_receipt_cms', label: 'Customer Claim Point [checked by admin]' },
        { value: 'upload_free_sample', label: 'Customer Claim Free Sample' },
      ],
      name: '',
      columns: [
        {
          label: "Description",
          field: "description",
          thClass: "text-nowrap",
          sortable: true,
          tdClass: "text-nowrap align-middle",
        },
        {
          label: "Created",
          field: "createdAt",
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          type: "date",
          sortable: true,
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
        },
        {
          label: "Updated",
          field: "updatedAt",
          thClass: "vgt-left-align text-nowrap",
          tdClass: "vgt-left-align text-nowrap align-middle",
          type: "date",
          sortable: true,
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
        },
        {
          label: "Jumlah Poin",
          field: "point",
          thClass: "text-nowrap",
          tdClass: this.statusClass,
        },
        {
          label: "Status",
          field: "status",
          sortable: true,
          thClass: "text-nowrap",
          tdClass: "text-nowrap align-middle",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        skip: 0,
        take: 100,
        // sort: 'desc'
      },
      errorCode: "",
      errorMessage: "",
      error: "",
      forbidenError: false,
    };
  },
  computed: {
    formTitle() {
      return this.formType === "add" ? "Add New" : "Edit";
    },
  },
  watch: {
    detailData(newVal) {
      this.form = newVal;
    },
  },
  methods: {
    statusClass(row) {
      if (row.diff_type === 'increase') {
        return 'bg-green text-white align-middle';
      }
      return 'bg-orange text-white align-middle';
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ skip: (params.currentPage - 1) * params.currentPerPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ skip: 0 });
      this.updateParams({ take: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      let payload = {}
      switch (params[0].field) {
        case 'description':
          if (params[0].type != 'none') {
            payload.sortByHistoryType = params[0].type
          } else {
            delete this.serverParams.sortByHistoryType
          }
          break;
        case 'createdAt':
          if (params[0].type != 'none') {
            payload.sort = params[0].type
          } else {
            delete this.serverParams.sort
          }
          break;
        case 'updatedAt':
          if (params[0].type != 'none') {
            payload.sortByUpdatedAt = params[0].type
          } else {
            delete this.serverParams.sortByUpdatedAt
          }
          break;
        case 'status':
          if (params[0].type != 'none') {
            payload.sortByStatus = params[0].type
          } else {
            delete this.serverParams.sortByStatus
          }
          break;
        default:
          break;
      }
      this.updateParams(payload);
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    loadItems() {
      pointHistoryService
        .getPointHistoryByCustomerList(this.$route.params.id, this.serverParams)
        .then(async ({ data: { data } }) => {
          let arrData = []
          if (data?.pointHistoriesData.length > 0) {
            for (const [k, v] of Object.entries(data.pointHistoriesData)) {
              let { description, history_type, point, diff_type, receipt_id, redeem_id, status } = v
              // let { redeem } = (redeem_id) ? await this.loadItemsRedeem(redeem_id) : {}
              // let { receipt } = (receipt_id) ? await this.loadItemsReceipt(receipt_id) : {}
              let { redeem } = {}
              let { receipt } = {}

              let statusLabel = status[0].toUpperCase() + status.slice(1)
              let jumlahPoint = 0, operator = '';

              if (history_type == 'admin_approve_free_sample_cms' && history_type == 'admin_reject_free_sample_cms') {
                jumlahPoint = 0
                statusLabel = '-'
              } else if (status == 'pending') {
                jumlahPoint = 0
              } else {
                jumlahPoint = (diff_type == 'increase' ? '+' : '-') + point
              }

              if (point == 0) {
                jumlahPoint = point
              }

              if (point == 0) {
                jumlahPoint = point
              }

              arrData.push({
                ...v,
                description: this.optionsDescriptions.find((r) => r.value == history_type)?.label,
                point: jumlahPoint,
                status: statusLabel,
              })
            }
          }
          this.totalPoint = data.totalPoint
          this.rows = arrData
        }).catch((e) => {
          if (e.response?.status !== 404) {
            this.$notify({
              type: "error",
              title: "Error Message",
              text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
            });
          } else {
            this.rows = []
          }
        }).finally(() => {
          this.isLoading = false;
        })
    },
    loadItemsCustomer(id) {
      CustomerService.getCustomerById(id)
        .then(async ({ data: { data } }) => {
          let { customer } = data
          this.name = customer.full_name
        }).catch((e) => {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }).finally(() =>
          this.onLoading = false)
    },
    doSave() {
      const payload = {
        form: this.form,
      };
      // console.log(payload);

      this.$emit("onValidateSuccess", payload);
    },

    loadItemsRedeem(id) {
      return RedeemService.getRedeemById(id)
        .then(async ({ data: { data } }) => {
          return Promise.resolve({ redeem: data })
        }).catch((e) => {
          return Promise.reject({ ...e })
        }).finally(() =>
          this.onLoading = false)
    },

    loadItemsReceipt(id) {
      return receiptService.getReceiptById(id)
        .then(async ({ data: { data } }) => {
          return Promise.resolve({ receipt: data })
        }).catch((e) => {
          return Promise.reject({ ...e })
        }).finally(() =>
          this.onLoading = false)
    },
  },
  mounted() {
    this.loadItems();
    this.loadItemsCustomer(this.$route.params.id)
  },
};
</script>

<style lang="scss" scoped>
.card {
  background-color: #082B38;
  color: #fff;

  h6 {
    margin-bottom: 0;
    font-weight: 700;
    opacity: 0.75;
  }

  h2 {
    margin-bottom: 0;
  }
}
</style>
