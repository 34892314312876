<template>
  <!-- begin sidebar nav -->
  <div class="menu" v-if="menus">
    <div class="menu-search mb-n3" v-if="appOptions.appSidebarSearch">
      <input type="text" v-on:keyup="handleSidebarFilter" class="form-control" placeholder="Sidebar menu filter..." />
    </div>
    <template v-for="(menu, index) in menus">
      <sidebar-nav-list ref="sidebarNavList" v-bind:menu="menu" v-bind:scrollTop="scrollTop"
        v-bind:key="`${menu.path}-${index}`" v-bind:status="menu.status" v-on:collapse-other="handleCollapseOther(menu)"
        v-on:show-float-submenu="handleShowFloatSubmenu"
        v-on:hide-float-submenu="handleHideFloatSubmenu"></sidebar-nav-list>
    </template>

    <!-- BEGIN minify-button -->
    <div class="menu-item d-flex">
      <a href="javascript:;" class="app-sidebar-minify-btn ms-auto" v-on:click="handleSidebarMinify()"><i
          class="fa fa-angle-double-left"></i></a>
    </div>
    <!-- END minify-button -->
  </div>
  <!-- end sidebar nav -->
</template>

<script>
import SidebarMenu from "./SidebarMenu.vue";
import SidebarNavList from "./SidebarNavList.vue";
import AppOptions from "../../config/AppOptions.vue";
import tokenService from "@/service/token.service";

export default {
  name: "SidebarNav",
  props: ["scrollTop"],
  components: {
    SidebarNavList,
  },
  data() {
    return {
      permisions: tokenService.getLocalPermission(),
      appOptions: AppOptions,
    };
  },
  computed: {
    menus: function () {
      let arrMenu = []
      let { beforeCreate, beforeDestroy, __file, ...arrSideBarMenu } = SidebarMenu
      for (const [k, v] of Object.entries(arrSideBarMenu)) {
        let { children, ...newParent } = v
        let menu = {}
        switch (v.title) {
          case 'Dashboard':
            if (this.permisions.hasOwnProperty('Dashboard')) {
              menu = {
                ...newParent,
              }
            }
            break;
          case 'Customer':
            if (this.permisions.hasOwnProperty('Customer')) {
              menu = {
                ...newParent,
              }
            }
            break;
          case 'Products':
            if (this.permisions.hasOwnProperty('Product') || this.permisions.hasOwnProperty('ProductCategory')) {
              menu = {
                ...newParent,
              }
            }
            break;
          case 'Homepage Management':
            if (this.permisions.hasOwnProperty('HomepageManagement')) {
              menu = {
                ...newParent,
              }
            }
            break;
          case 'Tumbuh Kembang':
            if (this.permisions.hasOwnProperty('BabyGrowth')) {
              menu = {
                ...newParent,
              }
            }
            break;
          case 'Pages':
            if (this.permisions.hasOwnProperty('Page')) {
              menu = {
                ...newParent,
              }
            }
            break;
          case 'Articles & Promos':
            if (this.permisions.hasOwnProperty('PostArticle') || this.permisions.hasOwnProperty('Promo')) {
              menu = {
                ...newParent,
              }
            }
            break;
          case 'Sections':
            if (this.permisions.hasOwnProperty('BannerManagement') || this.permisions.hasOwnProperty('Menus')) {
              menu = {
                ...newParent,
              }
            }
            break;
          case 'Loyalty':
            if (this.permisions.hasOwnProperty('Receipt') || this.permisions.hasOwnProperty('RewardCatalogue') || this.permisions.hasOwnProperty('Redeem')) {
              menu = {
                ...newParent,
              }
            }
            break;
          case 'Career':
            if (this.permisions.hasOwnProperty('Career') || this.permisions.hasOwnProperty('ApplyCareer')) {
              menu = {
                ...newParent,
              }
            }
            break;
          case 'Free Sample':
            if (this.permisions.hasOwnProperty('SurveyQuestion') || this.permisions.hasOwnProperty('SurveySubmission') || this.permisions.hasOwnProperty('FreeSample')) {
              menu = {
                ...newParent,
              }
            }
            break;
          case 'Users':
            if (this.permisions.hasOwnProperty('UserCms') || this.permisions.hasOwnProperty('RoleCms')) {
              menu = {
                ...newParent,
              }
            }
            break;
          case 'Site Setting':
            if (this.permisions.hasOwnProperty('SiteSetting')) {
              menu = {
                ...newParent,
              }
            }
            break;
          case 'Reseller Submission':
            if (this.permisions.hasOwnProperty('ResellerManager') || this.permisions.hasOwnProperty('Reseller')) {
              menu = {
                ...newParent,
              }
            }
            break;
          case 'Faq & Tanya GOON':
            if (this.permisions.hasOwnProperty('Faq') || this.permisions.hasOwnProperty('NutriExpert')) {
              menu = {
                ...newParent,
              }
            }
            break;
          case 'Baby Name Generator':
            if (this.permisions.hasOwnProperty('BabyName')) {
              menu = {
                ...newParent,
              }
            }
            break;
          default:
            menu = {
              ...newParent,
            }
            break;
        }
        if (children) {
          Object.assign(menu, { children: [] })
          for (const [k1, v1] of Object.entries(children)) {
            switch (v1.title) {
              case 'Product List':
                if (this.permisions.hasOwnProperty('Product')) {
                  menu.children.push(v1)
                }
                break;
              case 'Add Product':
                if (this.permisions.hasOwnProperty('Product')) {
                  menu.children.push(v1)
                }
                break;
              case 'Product Category':
                if (this.permisions.hasOwnProperty('ProductCategory')) {
                  menu.children.push(v1)
                }
                break;
              case 'Parent Product Category':
                if (this.permisions.hasOwnProperty('ParentProductCategory')) {
                  menu.children.push(v1)
                }
                break;
              case 'Product Type':
                if (this.permisions.hasOwnProperty('ProductType')) {
                  menu.children.push(v1)
                }
                break;
              case 'Ecommerce List':
                if (this.permisions.hasOwnProperty('Ecommerce')) {
                  menu.children.push(v1)
                }
                break;
              case 'All Pages':
                if (this.permisions.hasOwnProperty('Page')) {
                  menu.children.push(v1)
                }
                break;
              case 'Add Page':
                if (this.permisions.hasOwnProperty('Page')) {
                  if (this.permisions.Page.find((r) => r == 'Add')) {
                    menu.children.push(v1)
                  }
                }
                break;
              case 'All Articles':
                if (this.permisions.hasOwnProperty('PostArticle')) {
                  menu.children.push(v1)
                }
                break;
              case 'All Promos':
                if (this.permisions.hasOwnProperty('Promo')) {
                  menu.children.push(v1)
                }
                break;
              case 'Articles Category':
                if (this.permisions.hasOwnProperty('PostArticleCategory')) {
                  menu.children.push(v1)
                }
                break;
              case 'Promo Category':
                if (this.permisions.hasOwnProperty('PromoCategory')) {
                  menu.children.push(v1)
                }
                break;
              case 'Menus':
                if (this.permisions.hasOwnProperty('Menus')) {
                  menu.children.push(v1)
                }
                break;
              case 'Banner':
                if (this.permisions.hasOwnProperty('BannerManagement')) {
                  menu.children.push(v1)
                }
                break;
              case 'Claim Point':
                if (this.permisions.hasOwnProperty('Receipt')) {
                  menu.children.push(v1)
                }
                break;
              case 'Reward Catalogue':
                if (this.permisions.hasOwnProperty('RewardCatalogue')) {
                  menu.children.push(v1)
                }
                break;
              case 'Redeem':
                if (this.permisions.hasOwnProperty('Redeem')) {
                  menu.children.push(v1)
                }
                break;
              case 'Career List':
                if (this.permisions.hasOwnProperty('Career')) {
                  menu.children.push(v1)
                }
                break;
              case 'Add Position':
                if (this.permisions.hasOwnProperty('Career')) {
                  if (this.permisions.Career.find((r) => r == 'Add')) {
                    menu.children.push(v1)
                  }
                }
                break;
              case 'Career Category':
                if (this.permisions.hasOwnProperty('Career')) {
                  menu.children.push(v1)
                }
                break;
              case 'Candidate List':
                if (this.permisions.hasOwnProperty('CareerCategory')) {
                  menu.children.push(v1)
                }
                break;
              case 'Reseller List':
                if (this.permisions.hasOwnProperty('Reseller')) {
                  menu.children.push(v1)
                }
                break;
              case 'Add Reseller':
                if (this.permisions.hasOwnProperty('Reseller')) {
                  if (this.permisions.Reseller.find((r) => r == 'Add')) {
                    menu.children.push(v1)
                  }
                }
                break;
              case 'Supervisor Reseller':
                if (this.permisions.hasOwnProperty('ResellerManager')) {
                  menu.children.push(v1)
                }
                break;
              case 'Manage User':
                if (this.permisions.hasOwnProperty('UserCms')) {
                  if (this.permisions.UserCms.find((r) => r == 'GetAll') || this.permisions.UserCms.find((r) => r == 'Get')) {
                    menu.children.push(v1)
                  }
                }
                break;

              case 'All Faq':
                if (this.permisions.hasOwnProperty('Faq')) {
                  menu.children.push(v1)
                }
                break;
              case 'All Baby Name Generator':
                if (this.permisions.hasOwnProperty('BabyName')) {
                  menu.children.push(v1)
                }
                break;
              case 'Tanya GOON':
                if (this.permisions.hasOwnProperty('NutriExpert')) {
                  menu.children.push(v1)
                }
                break;
              case 'Contact Us':
                if (this.permisions.hasOwnProperty('ContactUs')) {
                  menu.children.push(v1)
                }
                break;
              case 'About Us':
                if (this.permisions.hasOwnProperty('AboutUs')) {
                  menu.children.push(v1)
                }
                break;
              case 'Manage Role':
                if (this.permisions.hasOwnProperty('RoleCms')) {
                  if (this.permisions.RoleCms.find((r) => r == 'GetAll')) {
                    menu.children.push(v1)
                  }
                }
                break;

              case 'Question Survey':
                if (this.permisions.hasOwnProperty('SurveyQuestion') || this.permisions.hasOwnProperty('FreeSample')) {
                  menu.children.push(v1)
                }
                break;
              case 'Survey Submission':
                if (this.permisions.hasOwnProperty('SurveySubmission') || this.permisions.hasOwnProperty('FreeSample')) {
                  menu.children.push(v1)
                }
                break;
              case 'Site Setting':
                if (this.permisions.hasOwnProperty('SiteSetting')) {
                  menu.children.push(v1)
                }
                break;
              default:
                menu.children.push(v1)
                break;
            }
          }
        }
        if (Object.keys(menu).length >= 3) {
          arrMenu.push(menu)
        }
      }
      Object.assign(arrMenu, { beforeCreate, beforeDestroy, __file })
      return arrMenu
    }
  },
  methods: {
    handleShowFloatSubmenu: function (menu, offset) {
      this.$emit("show-float-submenu", menu, offset);
    },
    handleHideFloatSubmenu: function () {
      this.$emit("hide-float-submenu");
    },
    handleCollapseOther: function (menu) {
      for (var i = 0; i < this.menus.length; i++) {
        this.$refs.sidebarNavList[i].collapse(menu);
      }
    },
    handleSidebarMinify: function () {
      this.appOptions.appSidebarMinified = !this.appOptions.appSidebarMinified;
    },
    handleSidebarFilter: function (e) {
      var value = e.target.value;
      value = value.toLowerCase();

      if (value) {
        for (var x = 0; x < this.menus.length; x++) {
          var title = this.menus[x].title.toLowerCase();
          var children = this.menus[x].children;

          if (title.search(value) > -1) {
            this.$refs.sidebarNavList[x].show();

            if (children) {
              this.$refs.sidebarNavList[x].searchExpand();
            }
          } else {
            if (children) {
              var hasActive = false;
              for (var y = 0; y < children.length; y++) {
                var title2 = children[y].title.toLowerCase();

                if (title2.search(value) > -1) {
                  hasActive = true;
                  this.$refs.sidebarNavList[x].$refs.sidebarNavList[y].show();
                  this.$refs.sidebarNavList[x].searchExpand();
                } else {
                  if (hasActive) {
                    this.$refs.sidebarNavList[x].searchExpand();
                  } else {
                    this.$refs.sidebarNavList[x].hide();
                  }
                  this.$refs.sidebarNavList[x].$refs.sidebarNavList[y].hide();
                }
              }
            } else {
              this.$refs.sidebarNavList[x].hide();
            }
          }
        }
      } else {
        for (var a = 0; a < this.menus.length; a++) {
          this.$refs.sidebarNavList[a].show();

          var submenu = this.menus[a].children;
          if (submenu) {
            for (var b = 0; b < submenu.length; b++) {
              this.$refs.sidebarNavList[a].$refs.sidebarNavList[b].show();
            }
          }
        }
      }
      // console.log("------");
    },
  },
};
</script>
