var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row align-items-center justify-content-between mb-4"},[_vm._m(0),(_vm.add)?_c('div',{staticClass:"col-auto mb-3"},[_c('router-link',{attrs:{"to":"/reward/add"}},[_c('a',{staticClass:"btn btn-outline-primary"},[_vm._v("Add New Reward")])])],1):_vm._e()]),_c('ul',{staticClass:"nav nav-tabs nav-tabs--wp"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/reward/list","active-class":"active","exact":""}},[_vm._v(" List ")])],1)]),_c('vue-good-table',{attrs:{"slot":"outsideBody","mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"pagination-options":{
        enabled: true,
        dropdownAllowAll: false,
        setCurrentPage: _vm.setCurrentPage,
        perPage: _vm.serverParams.take,
      },"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
  enabled: false,
  initialSortBy: { field: 'name', type: 'asc' },
},"styleClass":"vgt-table"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},slot:"outsideBody",scopedSlots:_vm._u([{key:"column-filter",fn:function(ref){
var column = ref.column;
var updateFilters = ref.updateFilters;
return [(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'name'
          )?_c('input',{ref:"name",staticClass:"form-control",attrs:{"placeholder":"Search Catalogue"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.name.value); }}}):_vm._e(),(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'stock'
          )?_c('input',{ref:"stock",staticClass:"form-control",attrs:{"placeholder":"Search Stock"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.stock.value); }}}):_vm._e(),(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'price_point'
          )?_c('input',{ref:"price_point",staticClass:"form-control",attrs:{"placeholder":"Search Point Charge"},on:{"input":function (value) { return updateFilters(column, _vm.$refs['price_point'].value); }}}):_vm._e(),(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'is_active'
          )?_c('select',{ref:"is_active",staticClass:"form-select",attrs:{"placeholder":"Cari productCategory"},on:{"change":function (value) { return updateFilters(column, _vm.$refs['is_active'].value); }}},[_c('option'),_vm._l((_vm.optionsStatus),function(option,key){return _c('option',{key:key,domProps:{"value":option,"textContent":_vm._s(option)}})})],2):_vm._e(),(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'reward.label'
          )?_c('select',{ref:"reward.label",staticClass:"form-select",attrs:{"placeholder":"Cari productCategory"},on:{"change":function (value) { return updateFilters(column, _vm.$refs['reward.label'].value); }}},[_c('option'),_vm._l((_vm.optionsRewards),function(option,key){return _c('option',{key:key,domProps:{"value":option.value,"textContent":_vm._s(option.label)}})})],2):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[(props.row.status !== 'Deleted')?_c('div',{staticClass:"row g-2 align-items-center justify-content-end"},[(_vm.edit)?_c('div',{staticClass:"col-auto"},[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"to":("/reward/edit/" + (props.row.id)),"title":"Edit"}},[_c('i',{staticClass:"fas fa-pencil"})])],1):_vm._e(),(_vm.deleteRewardCatalogue)?_c('div',{staticClass:"col-auto"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"type":"button","title":"Move to Trash"},on:{"click":function($event){$event.preventDefault();return _vm.showAlertConfirm(props.row.id)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()]):_vm._e()]):_c('span',{class:[props.row.status === 'Deleted' && 'text-muted']},[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-auto"},[_c('h1',{staticClass:"page-header"},[_vm._v("Reward Catalogue")])])}]

export { render, staticRenderFns }