var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row align-items-center justify-content-between mb-4"},[_c('div',{staticClass:"col-auto"},[_c('h1',{staticClass:"page-header"},[_vm._v("Survey Data User")]),(_vm.exportExcel)?_c('div',{staticClass:"col-auto"},[_c('a',{staticClass:"btn btn-outline-primary",on:{"click":function($event){$event.preventDefault();return _vm.downloadExcel.apply(null, arguments)}}},[_c('i',{staticClass:"fa-solid fa-file-import"}),_vm._v(" Export ")])]):_vm._e()])]),_c('vue-good-table',{attrs:{"slot":"outsideBody","mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"pagination-options":{
        enabled: true,
        dropdownAllowAll: false,
        setCurrentPage: _vm.setCurrentPage,
        perPage: _vm.serverParams.take,
      },"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
  enabled: false,
  initialSortBy: { field: 'name', type: 'asc' },
},"styleClass":"vgt-table"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},slot:"outsideBody",scopedSlots:_vm._u([{key:"column-filter",fn:function(ref){
var column = ref.column;
var updateFilters = ref.updateFilters;
return [(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'full_name'
          )?_c('input',{ref:"full_name",staticClass:"form-control",attrs:{"placeholder":"Search Full Name"},on:{"input":function (value) { return updateFilters(column, _vm.$refs['full_name'].value); }}}):_vm._e(),(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'email'
          )?_c('input',{ref:"email",staticClass:"form-control",attrs:{"placeholder":"Search Email"},on:{"input":function (value) { return updateFilters(column, _vm.$refs['email'].value); }}}):_vm._e(),(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'phoneNumber'
          )?_c('input',{ref:"phoneNumber",staticClass:"form-control",attrs:{"placeholder":"Search Handphone"},on:{"input":function (value) { return updateFilters(column, _vm.$refs['phoneNumber'].value); }}}):_vm._e(),(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'status'
          )?_c('select',{ref:"status",staticClass:"form-select",attrs:{"placeholder":"Cari..."},on:{"change":function (value) { return updateFilters(column, _vm.$refs['status'].value); }}},[_c('option'),_vm._l((_vm.optionStatus),function(option,key){return _c('option',{key:key,domProps:{"value":option.value,"textContent":_vm._s(option.label)}})})],2):_vm._e(),(column.filterOptions &&
          column.filterOptions.customFilter &&
          column.field === 'createdAt'
          )?_c('div',{staticClass:"input-group"},[_c('input',{ref:"createdAt1",staticClass:"form-control",attrs:{"type":"date","placeholder":"Search product"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.createdAt1.value + '#' + _vm.$refs.createdAt2.value); }}}),_c('span',{staticClass:"input-group-text"},[_vm._v(" - ")]),_c('input',{ref:"createdAt2",staticClass:"form-control",attrs:{"type":"date","placeholder":"Search product"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.createdAt1.value + '#' + _vm.$refs.createdAt2.value); }}})]):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[(props.row.status !== 'Deleted')?_c('div',{staticClass:"col-auto"},[(_vm.edit)?_c('div',{staticClass:"col-auto"},[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"to":("/survey/submission/" + (props.row.id) + "/" + (props.row.isTypeSurveySubmission)),"title":"Detail"}},[_c('i',{staticClass:"fas fa-eye"})])],1):_vm._e(),(_vm.deleteFreeSample)?_c('div',{staticClass:"col-auto"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-lg text-dark",attrs:{"type":"button","title":"Move to Trash"},on:{"click":function($event){$event.preventDefault();return _vm.showAlertConfirm(props.row.id)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()]):_vm._e()]):_c('span',{class:[props.row.status === 'Deleted' && 'text-muted']},[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }