<template>
  <div class="product-form">
    <FormSubmission form-type="edit" :detail-data="candidate" @onValidateSuccess="doSave" :on-loading="onLoading" />
  </div>
</template>
<script>
import FormSubmission from "./Form.vue";
import CandidateService from "@/service/candidate.service";
import base64Converter from "@/pages/Helper/base64";
export default {
  data() {
    return {
      onLoading: false,
      candidate: {
        name: null,
        email: null,
        phone_number: null,
        portofolio_url: null,
        linkedin: null,
        upload_cv: null,
      },
    };
  },
  components: {
    FormSubmission,
  },
  mounted() {
    this.loadItems(this.$route.params.id)
  },
  methods: {
    async doSave(payload) {
      // Submit here
      let { candidate } = payload
      let { upload_cv_src, ...newCandidate } = candidate
      let pdf_file = await this.convertURLtoFile(upload_cv_src)
      candidate = {
        ...newCandidate,
        upload_cv: await base64Converter(pdf_file)
      }
      // console.log(payload);
      CandidateService.patchCandidate(this.$route.params.id, candidate).then((rsp) => {
        this.$swal({
          title: "Data berhasil disimpan",
          showCloseButton: true,
        }).then(() => {
          this.$router.push({ path: '/career/candidate/list' })
        });
      }).catch((e) => {
        if (e.response?.status === 409) {
          this.$notify({
            type: "warn",
            title: "Error Message",
            text: "Data Sudah Ada",
          });
        } else {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }
      }).finally(() =>
        this.onLoading = false)
    },

    loadItems(id) {
      CandidateService.getCandidateById(id)
        .then(async ({ data: { data } }) => {
          this.candidate = {
            name: data.name,
            email: data.email,
            phone_number: data.phone_number,
            portofolio_url: data.portofolio_url,
            linkedin: data.linkedin,
            upload_cv: data.upload_cv.split('/')[4],
            upload_cv_src: this.loadSrcImage(data.upload_cv),
            job_position: data.job_position
          }
        }).catch((e) => { 
          // console.log(e) 
        })
    },

    loadSrcImage(url) {
      return this.$api_name + '/' + url
    },
    async convertURLtoFile(url) {
      const response = await fetch(url);
      if (response.status != 200) {
        return "";
      }
      const data = await response.blob();
      const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
      const metadata = { type: `application/pdf` };
      return new File([data], filename, metadata);
    },
  },
};
</script>
