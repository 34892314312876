import api from "./api";

class PostService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('post-article')
    }

    getArticleList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postArticle(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getArticleById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchArticle(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteArticleById(serverParams) {
        return this.api.delete("/" + serverParams);
    }

    postDuplicateArticle(serverParams) {
        return this.api.post("/duplicate/" + serverParams);
    }
}

export default new PostService();