import api from "./api";

class StatusGiziService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('nutritional-status-of-children')
    }

    getStatusGiziList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postStatusGizi(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getStatusGiziById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchStatusGizi(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteStatusGiziById(serverParams) {
        return this.api.delete("/" + serverParams);
    }
}

export default new StatusGiziService();