<template>
  <div class="page-settings">
    <form @submit.prevent="doSave" class="pb-5 mb-4">
      <h1 class="page-header">Free Sample Setting</h1>
      <div class="row">
        <div class="col-lg-9 col-md-8">
          <setting-nav />
          <div class="panel">
            <div class="panel-body">
              <!-- <div class="mb-15px">
                <label class="form-label d-block mb-2">Status</label>
                <div class="pt-1">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="manualChecking" id="manualChecking1" value="false"
                      v-model="form.is_open">
                    <label class="form-check-label" for="manualChecking1">Open</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="manualChecking" id="manualChecking2" value="true"
                      v-model="form.is_open">
                    <label class="form-check-label" for="manualChecking2">Close</label>
                  </div>
                </div>
                <span class="text-danger" v-if="$v.form.is_open.$error">
                  Free Sample required</span>
              </div> -->
              <div class="mb-15px">
                <label class="form-label">Tanggal Opening</label>
                <div class="input-group">
                  <input type="date" class="form-control" v-model="form.opening_date" />
                  <input type="time" class="form-control" v-model="form.opening_time" />
                </div>
              </div>

              <div class="mb-15px">
                <label class="form-label">Tanggal Closing</label>
                <div class="input-group">
                  <input type="date" class="form-control" v-model="form.closing_date" />
                  <input type="time" class="form-control" v-model="form.closing_time" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-3 col-md-4">
          <div class="panel panel-sticky">
            <div class="panel-body">
              <div class="mb-15px">
                <p class="fw-bolder mb-1">Published Date</p>
                <p class="mb-0">22 December 2022</p>
              </div>
              <div class="mb-15px">
                <p class="fw-bolder mb-1">Last Modified Date</p>
                <p class="mb-0">22 December 2022</p>
              </div>
              <div class="mb-0">
                <p class="fw-bolder mb-1">Author</p>
                <p class="mb-0">Wanda Vu</p>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="form-action">
        <div class="row justify-content-end">
          <div class="col-auto">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import SettingNav from './Nav.vue'
import { required } from 'vuelidate/lib/validators'
import base64Converter from "@/pages/Helper/base64";
import FreeSampleService from "@/service/freesample.service";
import tokenService from "@/service/token.service";
import freesampleService from '@/service/freesample.service';
import moment from "moment";
export default {
  components: {
    SettingNav,
  },
  data() {
    return {
      permisions: tokenService.getPermission(),
      id: {
        checking: ''
      },
      form: {
        is_open: 'false',
        opening_date: '',
        opening_time: '',
        closing_date: '',
        closing_time: '',
      }
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    async doSave() {

      this.$v.$touch();

      if (!this.$v.$invalid) {
        let is_open = this.form.is_open == 'true' ? true : false;
        let data = {
          is_open,
          opening_date: this.form.opening_date ? moment(this.form.opening_date + ' ' + this.form.opening_time).utc() : null,
          closing_date: this.form.closing_date ? moment(this.form.closing_date + ' ' + this.form.closing_time).utc() : null,
        };

        let req = this.addRequest(data)

        Promise.all([req])
          .then((r) => {
            this.$swal({
              title: "Data berhasil disimpan",
              showCloseButton: true,
            }).then(() => {
              this.loadItems();
            });
          }).catch((e) => {
            if (e.response?.status === 409) {
              this.$notify({
                type: "warn",
                title: "Error Message",
                text: "Data Sudah Ada",
              });
            } else if (e.response?.status === 403) {
              this.$notify({
                type: "error",
                title: "Error Message",
                text: "Permision Denied",
              });
            } else {
              this.$notify({
                type: "error",
                title: "Error Message",
                text: e.response?.data?.message || "Terjadi Kesalahan",
              });
            }
          })
      } else {
        this.$notify({
          type: "warn",
          title: "Form",
          text: "Form Belum Lengkap",
        });
      }
    },

    loadItems() {
      // this.isLoading = true;
      FreeSampleService
        .getSettingFreeSample()
        .then(async ({ data: { data } }) => {
          // console.log(data)
          this.form =
          {
            is_open: data.isOpen ? data.isOpen : false,
            opening_date: data.openDate ? moment(data.openDate).format('yyyy-MM-DD') : false,
            opening_time: data.openDate ? moment(data.openDate).format('HH:mm') : false,
            closing_date: data.closeDate ? moment(data.closeDate).format('yyyy-MM-DD') : false,
            closing_time: data.closeDate ? moment(data.closeDate).format('HH:mm') : false,
          };

        }).catch((e) => {
          // console.log(e)
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },

    addRequest(req) {
      return FreeSampleService
        .postSettingFreeSample(req)
        .then((response) => {
          return Promise.resolve({ ...response })
        }).catch((e) => {
          return Promise.reject({ ...e })
        })
    },
    editRequest(id, req) {
      return SiteSettingService
        .patchSiteSetting(id, req)
        .then((response) => {
          return Promise.resolve({ ...response })
        }).catch((e) => {
          return Promise.reject({ ...e })
        })
    },
  },
  computed: {
  },
  validations: {
    form: {
      is_open: { required },
      // opening_date: { required },
      // closing_date: { required },
      // opening_time: { required },
      // closing_time: { required },
    },
  }
};
</script>
