var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"pb-5 mb-4",on:{"submit":function($event){$event.preventDefault();return _vm.doSave.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"page-header"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){$event.preventDefault();return _vm.$router.go(-1)}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.onLoading),expression:"onLoading"}],staticClass:"spinner-border spinner-border-sm",attrs:{"aria-hidden":"true"}}),_c('i',{staticClass:"fa fa-arrow-left",attrs:{"aria-hidden":"true"}}),_vm._v(" Back ")]),_vm._v(" Loyalty Poin ")])]),_c('div',{staticClass:"col-lg-12"},[_c('customer-nav'),_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-body"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h6',[_vm._v(_vm._s(_vm.name))]),_c('h2',[_vm._v(_vm._s(_vm.totalPoint)+" Loyalty Poin")])])])])]),_c('h3',{staticClass:"fw-normal mb-3"},[_vm._v("Poin History")]),_c('vue-good-table',{attrs:{"slot":"outsideBody","mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"pagination-options":{
            enabled: false,
            dropdownAllowAll: false,
          },"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
  enabled: false,
  initialSortBy: { field: 'created', type: 'desc' },
},"styleClass":"vgt-table","line-numbers":true},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},slot:"outsideBody",scopedSlots:_vm._u([{key:"column-filter",fn:function(ref){
var column = ref.column;
var updateFilters = ref.updateFilters;
return [(column.filterOptions &&
              column.filterOptions.customFilter &&
              column.field === 'name'
              )?_c('input',{ref:"name",staticClass:"form-control",attrs:{"placeholder":"Search catalogue"},on:{"input":function (value) { return updateFilters(column, _vm.$refs.name.value); }}}):_vm._e()]}},{key:"table-row",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }