<template>
    <div class="product-form">
        <FormProduct form-type="add" @onValidateSuccess="doSave" :on-loading="onLoading" />
    </div>
</template>
<script>
import FormProduct from './Form.vue'
import ParentProductCategory from "@/service/parentcategory.service";
import base64Converter from "@/pages/Helper/base64";
export default {
    data() {
        return {
            onLoading: false
        }
    },
    components: {
        FormProduct,
    },

    methods: {
        async doSave(payload) {
            this.onLoading = true
            // Submit here
            let { parent } = payload
            if (parent) {
                payload = {
                    parent_name: parent.name,
                    icon_image: parent.image[0] ? await base64Converter(parent.image[0]) : null,
                    alt_icon_image: parent.alt_icon_image ? parent.alt_icon_image : null
                };

                ParentProductCategory.postParentCategory(payload).then((rsp) => {
                    this.$swal({
                        title: "Data berhasil disimpan",
                        showCloseButton: true,
                    }).then(() => {
                        this.$router.push({ path: '/products/parent_category' })
                    });
                }).catch((e) => {
                    if (e.response?.status === 409) {
                        this.$notify({
                            type: "warn",
                            title: "Error Message",
                            text: "Data Sudah Ada",
                        });
                    } else {
                        this.$notify({
                            type: "error",
                            title: "Error Message",
                            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
                        });
                    }
                }).finally(() =>
                    this.onLoading = false)
            }
        }
    }
}
</script>