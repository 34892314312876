<template>
  <div class="product-form">
    <FormUsers form-type="add" @onValidateSuccess="doSave" />
  </div>
</template>
<script>
import FormUsers from "./Form.vue";
import RoleService from "@/service/role.service";
export default {
  components: {
    FormUsers,
  },

  methods: {
    async doSave(payload) {
      // console.log(payload)
      RoleService.postRole(payload).then((rsp) => {
        this.$swal({
          title: "Data berhasil disimpan",
          showCloseButton: true,
        }).then(() => {
          this.$router.push({ path: '/users/manage/role' })
        });
      }).catch((e) => {
        if (e.response?.status === 409) {
          this.$notify({
            type: "warn",
            title: "Error Message",
            text: "Data Sudah Ada",
          });
        } else {
          this.$notify({
            type: "error",
            title: "Error Message",
            text: e.response?.data?.message.toString() || "Terjadi Kesalahan",
          });
        }
      }).finally(() =>
        this.onLoading = false)
    },
  },
};
</script>
