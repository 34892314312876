    // combine the previous two functions to return a base64 encode image from url
  let base64Converter = async function( imageFile ){
    return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
            resolve(fr.result);
        }
        fr.readAsDataURL(imageFile);
    });
  }

export default base64Converter;
