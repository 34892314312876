import api from "./api";

class FreeSampleService {
  /** @type {ApiAxiosInstance} */
  api;

  constructor() {
    this.api = api.create('free-sample')
  }

  getFreeSampleList(serverParams) {
    return this.api.get("/", { params: serverParams });
  }

  postFreeSample(serverParams) {
    return this.api.post("/", { ...serverParams });
  }

  getFreeSampleById(serverParams) {
    return this.api.get("/" + serverParams);
  }

  patchFreeSample(id, serverParams) {
    return this.api.patch("/" + id, { ...serverParams });
  }

  deleteFreeSampleById(serverParams) {
    return this.api.delete("/" + serverParams);
  }

  exportExcel(serverParams) {
    return this.api.get("/export", { params: serverParams });
  }

  postSettingFreeSample(serverParams) {
    return this.api.post("/setting/update-configuration", { ...serverParams });
  }

  getSettingFreeSample(serverParams) {
    return this.api.get("/setting/get-configuration", { params: serverParams });
  }
}

export default new FreeSampleService();
