import api from "./api";

class CandidateService {
    /** @type {ApiAxiosInstance} */
    api;

    constructor() {
        this.api = api.create('apply-career')
    }

    getCandidateList(serverParams) {
        return this.api.get("/", { params: serverParams });
    }

    postCandidate(serverParams) {
        return this.api.post("/", { ...serverParams });
    }

    getCandidateById(serverParams) {
        return this.api.get("/" + serverParams);
    }

    patchCandidate(id, serverParams) {
        return this.api.patch("/" + id, { ...serverParams });
    }

    deleteCandidateById(serverParams) {
        return this.api.delete("/" + serverParams);
    }

    exportExcel(serverParams) {
        return this.api.get("/export/excel", { params: serverParams });
    }
}

export default new CandidateService();